/* eslint-disable */
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import api from '../../services/api';

const filter = createFilterOptions();

export default function Index({handleChangeProfessores, professorArray}) {
  console.log('professorArray ', professorArray)
  const [value, setValue] = useState(professorArray.length === 0 ? null : professorArray);
  const [data, setData] = useState([]);

  async function pesquisarAluno(termo) {
      //setLoading(true);
      if(!termo){
        return
      }
      var token = localStorage.getItem('@token_diario_web');

      var config = {
          headers: { Authorization: 'bearer ' + token }
      };

      try {
          const { data: result } = await api.get(`pesquisar-usuario/${encodeURI(termo)}/professor/`, config);
          // console.log('Result pesquisar', result);
          setData(result);
          // setOptions(result[0])
      } catch (error) {
          console.log('Erro ao buscar ', error, error.response);
      }
  }
  
  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        // console.log("newValue", newValue)
        if(newValue){
          handleChangeProfessores(newValue)
          setValue(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        // console.log("newInputValue", newInputValue)
        pesquisarAluno(newInputValue)
      }}
      id="controllable-states-demo"
      options={data}
      getOptionSelected={(option, value) => option.iso === value.iso}
      getOptionLabel={(option) => {
        // console.log("getOptionLabel", option)
        if (typeof option === 'string') {
          return option;
        }
        return option.name;
      }}
      style={{ width: 300 }}
      disabled={professorArray.length === 0 ? false : true}
      renderInput={(params) => <TextField {...params} label="Digite o nome do Professor" variant="outlined" />}
      style={{ width: '100%'}}
    />
  );
}