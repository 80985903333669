/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import '../../../../../styles/global.css';
import Settings from '@material-ui/icons/Settings';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import Assignment from '@material-ui/icons/Assignment';
import ThumbUpAlt from '@material-ui/icons/ThumbUpAlt';
import ThumbDownAlt from '@material-ui/icons/ThumbDownAlt';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../../../services/api';
import localePtBR from "date-fns/locale/pt-BR";
import {format, subDays, addDays, isAfter, parseISO, startOfWeek} from 'date-fns';
import Tooltip from '@material-ui/core/Tooltip';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
// import Add from '@material-ui/icons/Add';
import CloseBtn from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import {
  MuiPickersUtilsProvider, 
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import InputLabel from '@material-ui/core/InputLabel';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';


// const currencyConfig = {
//   locale: "pt-BR",
//   formats: {
//     number: {
//       BRL: {
//         style: "currency",
//         currency: "BRL",
//         minimumFractionDigits: 2,
//         maximumFractionDigits: 2,
//       },
//     },
//   },
// };
const arrayDireitodeAprendizagem = []
const arrayCamposdeExperiencias = []
const arrayAtividadesPermanentes = []
const Index = (props) => {
  const [loading, setLoading] = useState();
  const [date, setDate] = useState();
  const [experiencia, setExperiencia] = useState('');
  const [qtoAulas, setQtoAulas] = useState(1);
  const [qtoAulas2, setQtoAulas2] = useState(1);
  const [dataQto, setDataQto] = useState(5);
  const qtoPadrao = 5;
  const tipo = props.tipo;
  const dadoseditar = props.dadosEditar;
  const nivelAcesso = props.nivelAcesso;

  const resultStartOfWeek = format(addDays(new Date(startOfWeek(new Date())), 1), 'yyyy-MM-dd')
  // const resultStartOfWeek = format(startOfWeek(new Date()), 'yyyy-MM-dd')
  const resultStartOfWeek6 = format(addDays(new Date(startOfWeek(new Date())), 7), 'yyyy-MM-dd')
  const [dataFora, setDataFora] = useState(true);

  const [direitodeAprendizagem, setDireitodeAprendizagem] = useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedD: false,
    checkedE: false,
    checkedF: false,
  });

  const [direitodeAprendizagem2, setDireitodeAprendizagem2] = useState()
  
  const [camposdeExperiencias, setCamposdeExperiencias] = useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedD: false,
    checkedE: false,
  });

  const [camposdeExperiencias2, setCamposdeExperiencias2] = useState()

  const [ativadadesPermanente, setAtivadadesPermanente] = useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedD: false,
    checkedE: false,
    checkedF: false,
    checkedG: false,
    checkedH: false,
    checkedI: false,
    checkedJ: false,
  });

  const [ativadadesPermanente2, setAtivadadesPermanente2] = useState()
  const [desenvolvimentos, setDesenvolvimentos] = useState('');
  const [susgestao, setSusgestao] = useState('');
  const [avaliacao, setAvaliacao] = useState('');

  useEffect(() => {
    console.log(" props modal ", props, tipo)
    // experienciasDesenvolvidasData(date)
    setDate()
    setExperiencia('')
    if(tipo === 'edit'){
      console.log("dadoseditar ", dadoseditar, dadoseditar.qto_aula)
      setQtoAulas(dadoseditar.qto_aula)
      //direito de apren
      var direitoApre = dadoseditar.direito_de_aprendizagem
      setDireitodeAprendizagem2(direitoApre)
      if(direitoApre){
        let BRINCAR = direitoApre.includes('BRINCAR');
        let CONVIVER = direitoApre.includes('CONVIVER');
        let PARTICIPA = direitoApre.includes('PARTICIPA');
        let EXPLORAR = direitoApre.includes('EXPLORAR');
        let EXPRESSAR = direitoApre.includes('EXPRESSAR');
        let CONHECERSE = direitoApre.includes('CONHECER-SE');
  
        console.log("direito_de_aprendizagem", direitoApre)
        
        setDireitodeAprendizagem({
          checkedA: BRINCAR ? true : false,
          checkedB: CONVIVER ? true : false,
          checkedC: PARTICIPA ? true : false,
          checkedD: EXPLORAR ? true : false,
          checkedE: EXPRESSAR ? true : false,
          checkedF: CONHECERSE ? true : false,
        });
      }

      // campos de experiências 
      // setCamposdeExperiencias(dadoseditar.campo_de_experiencias)
      var camposExperien = dadoseditar.campo_de_experiencias;
      setCamposdeExperiencias2(camposExperien)
      if(camposExperien){
        let ACOLOEUO = camposExperien.includes('O EU, O OUTRO É NÓS');
        let CORPOGE = camposExperien.includes('CORPO, GESTO E MOVIMENTOS');
        let SPATEM = camposExperien.includes('ESPAÇO, TEMPO QUANTIDADE, RELAÇÕES E TRANSFORMAÇÕES');
        let TRACOS = camposExperien.includes('TRAÇOS, SONS, CORES E FORMAS');
        let ESCUTA = camposExperien.includes('ESCUTA, FALA, PENSAMENTO E IMAGINAÇÃO');
        console.log("campo_de_experiencias", camposExperien)
        console.log("campo_de_experiencias", ACOLOEUO, CORPOGE, SPATEM, TRACOS, ESCUTA)
        setCamposdeExperiencias({
          checkedA: ACOLOEUO ? true : false,
          checkedB: CORPOGE ? true : false,
          checkedC: SPATEM ? true : false,
          checkedD: TRACOS ? true : false,
          checkedE: ESCUTA ? true : false,
        })
      }

      //atividades_permanente
      var ativiPermanente = dadoseditar.atividades_permanente;
      setAtivadadesPermanente2(ativiPermanente)
      if(ativiPermanente){
        let ACOLHIMENTO = ativiPermanente.includes('ACOLHIMENTO');
        let CALENDÁRIOQUANTOSOMOS = ativiPermanente.includes('CALENDÁRIO, QUANTOS SOMOS?');
        let CHAMADINHA = ativiPermanente.includes('CHAMADINHA');
        let ROTINADODIA = ativiPermanente.includes('ROTINA DO DIA');
        let AGENDADODIA = ativiPermanente.includes('AGENDA DO DIA');
        let BRINCADEIRASLIVRES = ativiPermanente.includes('BRINCADEIRAS LIVRES');
        let BRINCADEIRASDIRECIONADAS = ativiPermanente.includes('BRINCADEIRAS DIRECIONADAS');
        let CONTACOESDEHISTORIAS = ativiPermanente.includes('CONTAÇÃO DE HISTÓRIAS');
        let MUSICALIZAÇÃO = ativiPermanente.includes('MUSICALIZAÇÃO');
        let ATIVIDADESNOCADERNOFOLHA = ativiPermanente.includes('ATIVIDADES NO CADERNO/FOLHA');
        console.log("atividades_permanente", ativiPermanente)
        setAtivadadesPermanente({
          checkedA: ACOLHIMENTO ? true : false,
          checkedB: CALENDÁRIOQUANTOSOMOS ? true : false,
          checkedC: CHAMADINHA ? true : false,
          checkedD: ROTINADODIA ? true : false,
          checkedE: AGENDADODIA ? true : false,
          checkedF: BRINCADEIRASLIVRES ? true : false,
          checkedG: BRINCADEIRASDIRECIONADAS ? true : false,
          checkedH: CONTACOESDEHISTORIAS ? true : false,
          checkedI: MUSICALIZAÇÃO ? true : false,
          checkedJ: ATIVIDADESNOCADERNOFOLHA ? true : false,
        });
      }

      dadosEditar(dadoseditar)

      setDesenvolvimentos(dadoseditar.desenvolvimento)
      setSusgestao(dadoseditar.sugestao_de_atividade)
      setAvaliacao(dadoseditar.avaliacao)
    }
  }, []);

  async function experienciasDesenvolvidasData(dt = new Date()){
    console.log("dt ", dt)
    var token = localStorage.getItem('@token_diario_web');
    var config = {
      headers: {Authorization: 'bearer ' + token},
    };
    try {
      const {data : result} = await api.get(`experiencia-desenvolvidas-data/${encodeURI(dt)}`, config);
      console.log("Result experiência desenvolvida data", result)
      if(result.length === 0){
        setQtoAulas2(0)
      }
      for (let r = 0; r < result.length; r++) {
        const element = result[r];
        setQtoAulas2(element.qto_aula)
        
      }
    } catch (error) {
      console.log("Erro ao carregar Experiências desenvolvidas por data", error, error.response);
      toast.error('🦄 Error ao carregar experiências desenvolvidas por data!', {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  

  function dadosEditar(dados){
    setDate(dados.data);
    setExperiencia(dados.experiencia_desenvolvida)
  }

  // const handleChange = (event, value, maskedValue) => {
  //   event.preventDefault();
  //   props.handleChangeValor(value)
  //   console.log(value); // value without mask (ex: 1234.56)
  //   // console.log(maskedValue); // masked value (ex: R$1234,56)
  // };

  const handleCloseModal = () => {
    props.handleCloseModal()
  };

  const handleDateChangeDataData = (date) => {
    experienciasDesenvolvidasData(date)
    setDate(date);
  };

  function handleChangeExperiencia(event){
    setExperiencia(event.target.value);
  }

  function handleChangeQtoAulas(event){
    if(event.target.value <= 5){
      setQtoAulas(event.target.value);
    }else{
      setQtoAulas(1);
      toast.error(`🦄 O Maxímo de aulas é 5 (Cinco)!`, {
        position: "bottom-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const handleChangeDireitodeAprendizagem = (event) => {
    setDireitodeAprendizagem({ ...direitodeAprendizagem, [event.target.name]: event.target.checked });

    var existe = arrayDireitodeAprendizagem.includes(event.target.value);
    var idx = arrayDireitodeAprendizagem.indexOf(event.target.value);
    if(!existe){
      arrayDireitodeAprendizagem.push(event.target.value)
    }else{
      arrayDireitodeAprendizagem.splice(idx, 1);
    }
    setDireitodeAprendizagem2(arrayDireitodeAprendizagem)
    console.log("arrayDireitodeAprendizagem", arrayDireitodeAprendizagem)

  };

  const handleChangeCamposdeExperiencia = (event) => {
    console.log("event.target.value", event.target.value)
    setCamposdeExperiencias({ ...camposdeExperiencias, [event.target.name]: event.target.checked });
    var existe = arrayCamposdeExperiencias.includes(event.target.value);
    var idx = arrayCamposdeExperiencias.indexOf(event.target.value);
    if(!existe){
      arrayCamposdeExperiencias.push(event.target.value)
    }else{
      arrayCamposdeExperiencias.splice(idx, 1);
    }
    setCamposdeExperiencias2(arrayCamposdeExperiencias)
    console.log("arrayCamposdeExperiencias", arrayCamposdeExperiencias)
  }
  // function handleChangeCamposdeExperiencia(event){
  //   console.log("event.target.value", event.target.value)
  //   setCamposdeExperiencias(event.target.value);
  // }

  const handleChangeAtividadesPermantentes = (event) => {
    setAtivadadesPermanente({ ...ativadadesPermanente, [event.target.name]: event.target.checked });

    var existe = arrayAtividadesPermanentes.includes(event.target.value);
    var idx = arrayAtividadesPermanentes.indexOf(event.target.value);
    if(!existe){
      arrayAtividadesPermanentes.push(event.target.value)
    }else{
      arrayAtividadesPermanentes.splice(idx, 1);
    }
    setAtivadadesPermanente2(arrayAtividadesPermanentes)
    console.log("arrayAtividadesPermanentes", arrayAtividadesPermanentes)
  };

  function handleChangeDesenvolvimento(event){
    setDesenvolvimentos(event.target.value);
  }

  function handleChangeSugestaodeAtividade(event){
    setSusgestao(event.target.value);
  }

  function handleChangeAvaliacao(event){
    setAvaliacao(event.target.value);
  }

  async function salvar(event) {
    event.preventDefault();
    if(!dataFora){
      toast.error(`🦄 A data informada está fora das datas permitidas!`, {
        position: "bottom-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    console.log("camposdeExperiencias2", camposdeExperiencias2)
    setLoading(true)
    // console.log("direitodeAprendizagem2 ", direitodeAprendizagem2)
    // console.log("ativadadesPermanente2 ", ativadadesPermanente2)
    //setloading(true);
    var token = localStorage.getItem('@token_diario_web');

    let form_data = new FormData();

    form_data.append("periodo_id", props.periodo.id);
    form_data.append("diario_id", props.iddodiario);
    form_data.append("data", date);
    form_data.append("experiencia_desenvolvida", experiencia);
    form_data.append("qto_aula", qtoAulas);
    form_data.append("direito_de_aprendizagem", direitodeAprendizagem2);
    form_data.append("campo_de_experiencias", camposdeExperiencias2);
    form_data.append("atividades_permanente", ativadadesPermanente2);
    form_data.append("desenvolvimento", desenvolvimentos);
    form_data.append("sugestao_de_atividade", susgestao);
    form_data.append("avaliacao", avaliacao);

    if(tipo === 'new'){
      await api
        .post(`/experiencia-desenvolvidas`, form_data, {
          headers: {
            "content-type": "application/json",
            Authorization: "bearer " + token,
            Accept: "application/json"
          }
        })
        .then(res => {
            console.log("Cadastrando experiências", res.data)
            // limpaCampos();
            handleCloseModal()
            setLoading(false);
            toast.success('🦄 Experiências cadastrado com sucesso!', {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
        })
        .catch(error => {
          console.log("error: ", error, error.response)
          console.log("response: ", error.response.data.message)
          toast.error(`🦄 Erro ao cadatrar, ${error.response.data.message}!`, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoading(false);
          // throw error;
        });

    }else if(tipo === 'edit'){

      await api
        .post(`/experiencia-desenvolvidas/${dadoseditar.id}`, form_data, {
          headers: {
            "content-type": "application/json",
            Authorization: "bearer " + token,
            Accept: "application/json"
          }
        })
        .then(res => {
            console.log("Editado com suceso", res.data)
            setLoading(false);
            toast.success('🦄 Editado com sucesso!', {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            handleCloseModal()
        })
        .catch(error => {
          console.log("error: ", error, error.response)
          toast.error('🦄 Erro ao editar, tente novamente ou fale com o suporte!', {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoading(false);
        });

    }

  }

   //validações
   const validation = [];
   validation.push(date && experiencia && dataFora);
   const validForm = validation.reduce((all, v) => all && v);

  return(
    <Container maxWidth="lg" className="container">
      <Modal
        open={props.openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="modal"
      >
        <div className="modalContent">
          <div className="modalContentHeader">
            <div style={{display: 'flex', flexDirection: 'column'}}>
              Registro de Objetivos de aprendizagem e desenvolvimento
            </div>
            <Tooltip title="Sair do cadastro" aria-label="add">
              <IconButton onClick={() => { handleCloseModal() }} color="inherit">
                  <CloseBtn style={{ color: 'red' }} />
              </IconButton>
            </Tooltip>
          </div>
          <div className="modalContentCenter">
              <form noValidate autoComplete="off" style={{position: 'relative'}} onSubmit={salvar}>
                <Grid container spacing={3}>
                  <Grid item md={8}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localePtBR}>
                      <InputLabel id="date-picker-dialog-date">Data</InputLabel>
                      <KeyboardDatePicker
                          style={{marginBottom: 0, marginTop: 0}}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          id="date-picker-dialog-date"
                          format="dd/MM/yyyy"
                          // minDate={tipo == 'new' ? new Date(resultStartOfWeek) : ''}
                          // minDateMessage={'Data inferior a permitida'}
                          // maxDate={new Date(resultStartOfWeek6)}
                          // maxDateMessage={'Data superior a permitida'}
                          invalidDateMessage={'Data inválida'}
                          value={date}
                          onChange={handleDateChangeDataData}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          onAccept={(e) => {
                            // console.log("aceita ", e)
                          }}
                          onError={(e) => {
                            // console.log("Error data", e)
                            if(e === 'Data inferior a permitida'){
                              setDataFora(false)
                            }else{
                              setDataFora(true)
                            }
                            if(e === 'Data superior a permitida'){
                              setDataFora(false)
                            }else{
                              setDataFora(true)
                            }
                            if(e === 'Data inválida'){
                              setDataFora(false)
                            }else{
                              setDataFora(true)
                            }
                          }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      fullWidth
                      id="outlined-qto"
                      label="Quantidade de aulas"
                      variant="outlined"
                      value={qtoAulas}
                      onChange={handleChangeQtoAulas}
                      type="number"
                    />
                  </Grid>
                  {qtoAulas2 < qtoPadrao && (
                    <Grid item md={12}>
                      <TextField
                        fullWidth
                        id="outlined-multiline-experiencias"
                        label="Objetivos de aprendizagens e desenvolvimento"
                        multiline
                        rows={20}
                        placeholder="Digite aqui todas os objetivos de aprendizagem desenvolvidos na sala de aula"
                        variant="outlined"
                        value={experiencia}
                        onChange={handleChangeExperiencia}
                      />
                    </Grid>
                  )}
                  {qtoAulas2 < qtoPadrao && (
                    <Grid item md={12}>
                      <span>Direitos de aprendizagem</span>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={direitodeAprendizagem.checkedA}
                              onChange={handleChangeDireitodeAprendizagem}
                              name="checkedA"
                              value={'BRINCAR'}
                              color="primary"
                            />
                          }
                          label="BRINCAR"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={direitodeAprendizagem.checkedB}
                              onChange={handleChangeDireitodeAprendizagem}
                              name="checkedB"
                              value={'CONVIVER'}
                              color="primary"
                            />
                          }
                          label="CONVIVER"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={direitodeAprendizagem.checkedC}
                              onChange={handleChangeDireitodeAprendizagem}
                              name="checkedC"
                              value={'PARTICIPAR'}
                              color="primary"
                            />
                          }
                          label="PARTICIPAR"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={direitodeAprendizagem.checkedD}
                              onChange={handleChangeDireitodeAprendizagem}
                              name="checkedD"
                              value={'EXPLORAR'}
                              color="primary"
                            />
                          }
                          label="EXPLORAR"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={direitodeAprendizagem.checkedE}
                              onChange={handleChangeDireitodeAprendizagem}
                              name="checkedE"
                              value={'EXPRESSAR'}
                              color="primary"
                            />
                          }
                          label="EXPRESSAR"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={direitodeAprendizagem.checkedF}
                              onChange={handleChangeDireitodeAprendizagem}
                              name="checkedF"
                              value={'CONHECER-SE'}
                              color="primary"
                            />
                          }
                          label="CONHECER-SE"
                        />
                      </FormGroup>
                    </Grid>
                  )}
                  {qtoAulas2 < qtoPadrao && (
                    <Grid item md={12}>
                      <span>Campos de Experiências</span>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={camposdeExperiencias.checkedA}
                              onChange={handleChangeCamposdeExperiencia}
                              name="checkedA"
                              color="primary"
                              value={'O EU, O OUTRO É NÓS'}
                            />
                          }
                          label="O EU, O OUTRO É NÓS"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={camposdeExperiencias.checkedB}
                              onChange={handleChangeCamposdeExperiencia}
                              name="checkedB"
                              color="primary"
                              value={'CORPO, GESTO E MOVIMENTOS'}
                            />
                          }
                          label="CORPO, GESTO E MOVIMENTOS"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={camposdeExperiencias.checkedC}
                              onChange={handleChangeCamposdeExperiencia}
                              name="checkedC"
                              color="primary"
                              value={'ESPAÇO, TEMPO QUANTIDADE, RELAÇÕES E TRANSFORMAÇÕES'}
                            />
                          }
                          label="ESPAÇO, TEMPO QUANTIDADE, RELAÇÕES E TRANSFORMAÇÕES"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={camposdeExperiencias.checkedD}
                              onChange={handleChangeCamposdeExperiencia}
                              name="checkedD"
                              color="primary"
                              value={'TRAÇOS, SONS, CORES E FORMAS'}
                            />
                          }
                          label="TRAÇOS, SONS, CORES E FORMAS"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={camposdeExperiencias.checkedE}
                              onChange={handleChangeCamposdeExperiencia}
                              name="checkedE"
                              color="primary"
                              value={'ESCUTA, FALA, PENSAMENTO E IMAGINAÇÃO'}
                            />
                          }
                          label="ESCUTA, FALA, PENSAMENTO E IMAGINAÇÃO"
                        />
                       
                      </FormGroup>
                      {/* <FormControl component="fieldset">
                        <FormLabel component="legend">Campos de Experiências</FormLabel>
                        <RadioGroup row aria-label="position" name="position" defaultValue="top">
                          <FormControlLabel 
                            value="O EU, O OUTRO É NÓS" 
                            control={
                              <Radio 
                                checked={camposdeExperiencias === 'O EU, O OUTRO É NÓS' ? true : false} 
                                color="primary" 
                              />
                            } 
                            label="O EU, O OUTRO É NÓS" 
                            onChange={handleChangeCamposdeExperiencia}
                          />
                          <FormControlLabel 
                            value="CORPO, GESTO E MOVIMENTOS" 
                            control={
                              <Radio 
                                checked={camposdeExperiencias === 'CORPO, GESTO E MOVIMENTOS' ? true : false} 
                                color="primary" 
                              />
                            } 
                            label="CORPO, GESTO E MOVIMENTOS" 
                            onChange={handleChangeCamposdeExperiencia}
                          />
                          <FormControlLabel 
                            value="ESPAÇO, TEMPO QUANTIDADE, RELAÇÕES E TRANSFORMAÇÕES" 
                            control={
                              <Radio 
                                checked={camposdeExperiencias === 'ESPAÇO, TEMPO QUANTIDADE, RELAÇÕES E TRANSFORMAÇÕES' ? true : false} 
                                color="primary" 
                              />
                            } 
                            label="ESPAÇO, TEMPO QUANTIDADE, RELAÇÕES E TRANSFORMAÇÕES" 
                            onChange={handleChangeCamposdeExperiencia}
                          />
                          <FormControlLabel 
                            value="TRAÇOS, SONS, CORES E FORMAS" 
                            control={
                              <Radio 
                                checked={camposdeExperiencias === 'TRAÇOS, SONS, CORES E FORMAS' ? true : false} 
                                color="primary" 
                              />
                            } 
                            label="TRAÇOS, SONS, CORES E FORMAS" 
                            onChange={handleChangeCamposdeExperiencia}
                          />
                          <FormControlLabel 
                            value="ESCUTA, FALA, PENSAMENTO E IMAGINAÇÃO" 
                            control={
                              <Radio 
                                checked={camposdeExperiencias === 'ESCUTA, FALA, PENSAMENTO E IMAGINAÇÃO' ? true : false} 
                                color="primary" 
                              />
                            } 
                            label="ESCUTA, FALA, PENSAMENTO E IMAGINAÇÃO" 
                            onChange={handleChangeCamposdeExperiencia}
                          />
                        </RadioGroup>
                      </FormControl> */}
                    </Grid>
                  )}
                  {qtoAulas2 < qtoPadrao && (
                    <Grid item md={12}>
                      <span>Atividades permanente</span>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={ativadadesPermanente.checkedA}
                              onChange={handleChangeAtividadesPermantentes}
                              name="checkedA"
                              color="primary"
                              value={'ACOLHIMENTO'}
                            />
                          }
                          label="ACOLHIMENTO"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={ativadadesPermanente.checkedB}
                              onChange={handleChangeAtividadesPermantentes}
                              name="checkedB"
                              color="primary"
                              value={'CALENDÁRIO, QUANTOS SOMOS?'}
                            />
                          }
                          label="CALENDÁRIO, QUANTOS SOMOS?"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={ativadadesPermanente.checkedC}
                              onChange={handleChangeAtividadesPermantentes}
                              name="checkedC"
                              color="primary"
                              value={'CHAMADINHA'}
                            />
                          }
                          label="CHAMADINHA"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={ativadadesPermanente.checkedD}
                              onChange={handleChangeAtividadesPermantentes}
                              name="checkedD"
                              color="primary"
                              value={'ROTINA DO DIA'}
                            />
                          }
                          label="ROTINA DO DIA"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={ativadadesPermanente.checkedE}
                              onChange={handleChangeAtividadesPermantentes}
                              name="checkedE"
                              color="primary"
                              value={'AGENDA DO DIA'}
                            />
                          }
                          label="AGENDA DO DIA"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={ativadadesPermanente.checkedF}
                              onChange={handleChangeAtividadesPermantentes}
                              name="checkedF"
                              color="primary"
                              value={'BRINCADEIRAS LIVRES'}
                            />
                          }
                          label="BRINCADEIRAS LIVRES"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={ativadadesPermanente.checkedG}
                              onChange={handleChangeAtividadesPermantentes}
                              name="checkedG"
                              color="primary"
                              value={'BRINCADEIRAS DIRECIONADAS'}
                            />
                          }
                          label="BRINCADEIRAS DIRECIONADAS"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={ativadadesPermanente.checkedH}
                              onChange={handleChangeAtividadesPermantentes}
                              name="checkedH"
                              color="primary"
                              value={'CONTAÇÃO DE HISTÓRIAS'}
                            />
                          }
                          label="CONTAÇÃO DE HISTÓRIAS"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={ativadadesPermanente.checkedI}
                              onChange={handleChangeAtividadesPermantentes}
                              name="checkedI"
                              color="primary"
                              value={'MUSICALIZAÇÃO'}
                            />
                          }
                          label="MUSICALIZAÇÃO"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={ativadadesPermanente.checkedJ}
                              onChange={handleChangeAtividadesPermantentes}
                              name="checkedJ"
                              color="primary"
                              value={'ATIVIDADES NO CADERNO/FOLHA'}
                            />
                          }
                          label="ATIVIDADES NO CADERNO/FOLHA"
                        />
                      </FormGroup>
                    </Grid>
                  )}
                  {qtoAulas2 < qtoPadrao && (
                    <Grid item md={12}>
                      <TextField
                        fullWidth
                        id="outlined-multiline-desenvolvimento"
                        label="Desenvolvimento"
                        multiline
                        rows={20}
                        placeholder="Desenvolvimento"
                        variant="outlined"
                        value={desenvolvimentos}
                        onChange={handleChangeDesenvolvimento}
                      />
                    </Grid>
                  )}
                  {qtoAulas2 < qtoPadrao && (
                    <Grid item md={12}>
                      <TextField
                        fullWidth
                        id="outlined-multiline-sugestao-de-atividade"
                        label="Sugestão de atividade"
                        multiline
                        rows={20}
                        placeholder="Sugestão de atividade"
                        variant="outlined"
                        value={susgestao}
                        onChange={handleChangeSugestaodeAtividade}
                      />
                    </Grid>
                  )}
                  {qtoAulas2 < qtoPadrao && (
                    <Grid item md={12}>
                      <TextField
                        fullWidth
                        id="outlined-multiline-avaliacao"
                        label="Avaliação"
                        multiline
                        rows={20}
                        placeholder="Avaliação"
                        variant="outlined"
                        value={avaliacao}
                        onChange={handleChangeAvaliacao}
                      />
                    </Grid>
                  )}
                  {qtoAulas2 < qtoPadrao && (
                    <Grid item md={12}>
                      {nivelAcesso === 'professor' && (
                        <Button
                          disabled={!validForm}
                          style={{maxWidth: 150, height: 50, marginRight: 10}}
                          type="submit" variant="outlined" color="primary"
                        >
                          {tipo === 'new' ? 'Salvar' : 'Editar'}
                        </Button>
                      )}
                    </Grid>
                  )}
                  {qtoAulas2 === qtoPadrao && (
                    <div style={{
                      position: 'absolute',
                      width: '100%',
                      minHeight: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '15%',
                      flexDirection: 'column'
                    }}>
                      <span>Quantidade de aulas {qtoAulas2}</span>
                      <span>Nesta data já possuí 5 aulas registradas</span>
                      <span>Fale com o seu coordenador</span>
                    </div>
                  )}
                </Grid>
              </form>
          </div>
        </div>
      </Modal>
    </Container>
  );
}

export default Index;
