import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
// import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
/* eslint-disable */
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TablePeriodos from '../../../Components/Tables/periodos';

export default function Index() {
    let history = useHistory();
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);
    // const [pesquisar, setPesquisar] = useState('');

    useEffect(() => {
        // Atualiza o titulo do documento usando a API do browser
        polos()
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    
    async function polos(){
      var token = localStorage.getItem('@token_diario_web');
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data : result} = await api.get('periodo', config);
        console.log("Result polos", result)
        setData(result)
        toast.success('🦄 Carregando Polos!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro ao carregar polos", error, error.response);
        toast.error('🦄 Error ao carregar Polos!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    function deletando(){
      polos()
    }

    function handleChangePesquisar(event){
      if(event.target.value){
        pesquisarCliente(event.target.value)
      }else{
        polos()
      }
      // setPesquisar(event.target.value)
    }

    async function pesquisarCliente(termo) {
      //setLoading(true);
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
  
  
      try {
        const {data : result} = await api.get(`pesquisar-cliente/${termo}`, config);
        console.log("Result pesquisar", result)
        setData(result)
      } catch (error) {
        console.log("Erro ao buscar ", error, error.response);
      }
    }
  

    //tabela
    function create() {
        history.push({
            pathname: '/periodo-create',
            // search: '?query=abc',
            state: { type: 'new' }
        });
      }

    function createData(id, name, ano, inicio, fim, aulas) {
      return {id, name, ano, inicio, fim, aulas};
    }
  
    var rows = [];
    if(data){
      rows = data.map((row) => {
        return createData(
          row.id, 
          row.nome_periodo, 
          row.ano_periodo,
          row.inicio,
          row.fim,
          row.aulas_previstas,
        )
      })
    }
  
    const columns = [
      { id: 'name', numeric: false, disablePadding: true, label: 'Nome' },
      { id: 'ano', numeric: true, disablePadding: false, label: 'Ano' },
      { id: 'inicio', numeric: true, disablePadding: false, label: 'Início' },
      { id: 'fim', numeric: true, disablePadding: false, label: 'Fim' },
      { id: 'aulas', numeric: true, disablePadding: false, label: 'Aulas previstas' },
      { id: 'acao', numeric: true, disablePadding: false, label: '' },
    ];
  
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link className="linkBreadcrumbs" color="inherit" href="/">
                                    Dashboard
                                </Link>
                                {/* <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/getting-started/installation/"
                                    onClick={handleClick}>
                                    Core
                                </Link> */}
                                <Typography variant="body2" color="textPrimary">
                                  Períodos
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>Períodos</h2>
                                <Button
                                    className="button-add"
                                    onClick={create}
                                    variant="contained"
                                    color="primary">
                                    Novo
                                </Button>
                            </div>
                        </Grid>
                        {/* <Grid item xs={12} md={12} lg={12}>
                            <Paper className={'paperSearch'}>
                                <TextField
                                    className="textField"
                                    id="outlined-secondary"
                                    label="Digite o nome da polo"
                                    variant="outlined"
                                    color="secondary"
                                    // value={pesquisar}
                                    onChange={handleChangePesquisar}
                                />
                            </Paper>
                        </Grid> */}
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                  {rows.length !== 0 && (
                                    <div className="paperContant">
                                      <TablePeriodos columns={columns} rows={rows} deletando={deletando}/>
                                    </div>
                                  )}
                                  {rows.length === 0 && (
                                    <div className="paperContantDois">
                                        <div>Nenhum registro encontrado</div>
                                    </div>
                                  )}
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
        </div>
    );
}
