import React, { useState, useEffect } from 'react';
import '../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
/* eslint-disable */
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Skeleton from '@material-ui/lab/Skeleton';
import {format, subDays, addDays} from 'date-fns';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import api from '../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FadeLoader from "react-spinners/FadeLoader";

export default function Index() {
    let history = useHistory();
    const location = useLocation();
    const [skeleton, seSkeleton] = useState(true);
    const [tipo, setTipo] = useState('new');
    const [txtBtn, setTxtBtn] = useState('Salvar');
    const [idEditar, setIdEditar] = useState('');

    const [loading, setloading] = useState(false);
    //cadastro
    const [idCliente, setIdCliente] = useState('');
    const [title, setTitle] = useState('Adicionar serviço ao cliente');
    const [servicosDados, setServicosDados] = useState([]);
    const [group, setGroup] = useState('');
    const [servicos, setServicos] = useState('');
    // const [servico, setServico] = useState('');
    const [valor, setValor] = useState(0);
    const [addDias, setAddDias] = useState(10);
    const [primeiroPagamento, setPrimeiroPagamento] = useState('');
    const [datavencimento, setDatavencimento] = useState('');
    const [dominio, setDominio] = useState('');
    const [status, setStatus] = useState(1);
    const [recorrencia, setRecorrencia] = useState('');
    const [infoAdd, setInfoAdd] = useState('');
    const [categoriaServico, setCategoriaServico] = useState('');
    const [txtInputPag, setTxtInputPag] = useState('Data 1° Pagamento');
    const [data, setData] = useState([]);
    const [parcelas, setParcelas] = useState(1);
    const [valorParcela, setValorParcela] = useState(0);
    const [newData, setNewData] = useState();

    useEffect(() => {
        // Atualiza o titulo do documento usando a API do browser
        console.log("um", location.pathname);
        console.log("dois", location);
        adddias(addDias)
        setDatavencimento(format(addDays(new Date(), 30), 'dd/MM/yyyy'))
        carregarServicos();
        if(location.state){
          setTipo(location.state.type)
          setTxtBtn(location.state.type === 'edit' ? 'Editar' : 'Salvar')
        }else{
          history.push('/')
        }
        if(location.state.type === 'edit'){
          // editar(parseInt(location.state.id))
          setTitle('Editar serviço')
        }else{
          // setloading(false)

          // setDataVencimento(format(addDays(new Date(), 5), 'dd/MM/yyyy'))
          // setDataVencimento()
        }
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function adddias(dias){
      var resultDias = addDays(new Date(), dias)
      setPrimeiroPagamento(format(new Date(resultDias), 'dd/MM/yyyy'))
    }

    function handleChangeServicos(event){
      setServicos(event.target.value);
      carregarServico(event.target.value)
    }
    function handleChangeValor(event){
      setValor(event.target.value);
    }
    function handleChangeAddDias(event){
      setAddDias(event.target.value);
      adddias(event.target.value)
    }
    function handleChangeParcelas(event){
      setParcelas(event.target.value);
      var divirParcela = valor / event.target.value
      setValorParcela(divirParcela)
    }
    function handleChangeValorParcela(event){
      setValorParcela(event.target.value);
    }
    function handleChangeDataPriPagamento(event){
      setPrimeiroPagamento(event.target.value);
    }
    function handleChangeDatavencimento(event){
      setDatavencimento(event.target.value);
    }
    function handleChangeDominio(event){
      setDominio(event.target.value);
    }
    function handleChangeInfAdd(event){
      setInfoAdd(event.target.value);
    }
    function handleChangeStatus(event){
      setStatus(event.target.value);
    }

    function FormataStringData(data) {
      var dia  = data.split("/")[0];
      var mes  = data.split("/")[1];
      var ano  = data.split("/")[2];
  
      return ano + '-' + ("0"+mes).slice(-2) + '-' + ("0"+dia).slice(-2);
      // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
    }

    async function carregarServicos() {
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: resultado} = await api.get(`/servico/`, config);
        console.log('resultado dados serviços', resultado);
        setServicosDados(resultado);
        toast.success('🦄 Carregando serviços!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro ao carregar serviços", error, error.response);
        toast.error('🦄 Erro ao carregar serviços, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    async function carregarServico(id) {
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: resultado} = await api.get(`/servico/${id}`, config);
        console.log('resultado dados serviço', resultado);
        for (let s = 0; s < resultado.length; s++) {
          const element = resultado[s];
          setValor(element.valor)
          setRecorrencia(element.recorrencia)
          setCategoriaServico(element.categoria.nome)
          setGroup(element.categoria.group)
          setValorParcela(element.valor)
          if(element.categoria.group === 'hosting'){
            setTxtInputPag('Data do pagamento')
            // setValorParcela(0)
            setParcelas(1)
          }else{
            setTxtInputPag('Data 1° Pagamento')
          }
          toast.success('🦄 Carregando dados do serviço!', {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        // setServico(resultado);
      } catch (error) {
        console.log("Erro ao carregar serviço", error, error.response);
        toast.error('🦄 Erro ao carregar dados do serviço, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  
    async function deletar() {
      setloading(true)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: prod} = await api.delete(`/servico-cliente/${slug[5]}`, config);
        console.log("prod", prod)
        // limpaCampos();
        setloading(false);
        // setMsgAlert('Deletado com sucesso.')
        // router.push(`/clienteServico/add-servico/cadastro/cliente/${slug[3]}`)
        toast.success('🦄 Deletado com sucesso!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro ao deletar", error, error.response);
        setloading(false);
        toast.error('🦄 Erro ao deletar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setMsgAlert('Erro ao deletar, tente novamente')
      }
    }

    /**
   *
   * @param {*} id recebe o id do servico-cliente que será editado
   */
  async function editar(id){
    console.log("ididid", id)
    var token = localStorage.getItem('@token_diario_web');

    var config = {
      headers: {Authorization: 'bearer ' + token},
    };
    try {
      const {data: edit} = await api.get(`/servico-cliente/${id}`, config);
      console.log("edit servico-cliente", edit)
      setloading(false);
      for (let e = 0; e < edit.length; e++) {
        const element = edit[e];
        setServicos(element.servico_id)
        setValor(element.valor)
        setAddDias(element.dias_carencia)
        setPrimeiroPagamento(format(new Date(element.data_primeiro_pagamento), 'dd/MM/yyyy'))
        setDatavencimento(format(new Date(element.data_proximo_pagamento), 'dd/MM/yyyy'))
        setDominio(element.dominio ? element.dominio : '')
        setInfoAdd(element.info_add ? element.info_add : '')
        setStatus(element.status)
        setRecorrencia(element.servico.recorrencia)
        console.log('element.servico.dominio, ', element.dominio)
        if(element.servico.recorrencia === '0'){
          setTxtInputPag('Data do pagamento')
        }else{
          setTxtInputPag('Data 1° Pagamento')
        }
        if(element.dominio){
          setCategoriaServico('Hospedagem de Sites')
        }else{
          setCategoriaServico('')
        }
        toast.success('🦄 Buscando dados para editar!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

    } catch (error) {
      console.log("Erro buscar dados para editar", error, error.response);
      // setMsgAlert('Erro ao buscar dados para editar, volte e tente novamente')
      toast.error('🦄 Erro ao buscar dados para editar, volte e tente novamente!', {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setloading(false);
    }
  }

  /**
   *
   * @param {*} data recebe o retorno do cadastro
   */
  async function criarFaturas(data, dataPagamento) {
    console.log("Criando fatura", data, dataPagamento)
    //setloading(true);
    var token = localStorage.getItem('@token_diario_web');

    let form_data_addfatura = new FormData();

    form_data_addfatura.append("user_id", data.user_id);
    form_data_addfatura.append("servico_id", data.servico_id);
    form_data_addfatura.append("servico_clientes_id", data.id);
    form_data_addfatura.append("vencimento", !dataPagamento ? data.data_primeiro_pagamento : dataPagamento);
    form_data_addfatura.append("valor", parcelas === 1 ? data.valor : data.valor_parcela);
    form_data_addfatura.append("status", data.status);

    await api
      .post(`/fatura`, form_data_addfatura, {
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + token,
          Accept: "application/json"
        }
      })
      .then(res => {
          console.log("Adicionando fatura", res.data)
          toast.success('🦄 Fatura foi criada com sucesso!', {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
      })
      .catch(error => {
        console.log("error: ", error, error.response)
        console.log("response: ", error.response.data.message)
        toast.error('🦄 Erro ao criar fatura do serviço!', {
          position: "bottom-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // throw error;
      });

  }

  async function salvar(event) {
    // console.log("statusstatusstatusstatusstatus", status, tipo, idEditar)
    event.preventDefault();
    // console.log("event", event)
    setloading(true)

    //setloading(true);
    var token = localStorage.getItem('@token_diario_web');

    var config = {
      headers: {Authorization: 'bearer ' + token},
    };

    let form_data = new FormData();

    form_data.append("user_id", location.state.id ? location.state.id : '');
    form_data.append("servico_id", servicos ? servicos : '');
    form_data.append("valor", valor ? valor : '');
    form_data.append("dias_carencia", addDias ? addDias : '');
    form_data.append("data_primeiro_pagamento", primeiroPagamento ? FormataStringData(primeiroPagamento) : '');
    form_data.append("data_proximo_pagamento", datavencimento ? FormataStringData(datavencimento) : '');
    form_data.append("parcelas", parcelas ? parcelas : '');
    form_data.append("valor_parcela", valorParcela ? valorParcela : '');
    form_data.append("dominio", dominio ? dominio : '');
    form_data.append("info_add", infoAdd ? infoAdd : '');
    form_data.append("status", status);

    if(tipo === 'new'){
      await api
        .post(`/servico-cliente`, form_data, {
          headers: {
            "content-type": "application/json",
            Authorization: "bearer " + token,
            Accept: "application/json"
          }
        })
        .then(res => {
            console.log("Adicionando serviço do cliente", res.data)
            limpaCampos();
            setloading(false);
            // setOpen(false);
            // carregarDados();
            // setMsgAlert('Serviço adicionado ao cliente com sucesso.')
            // servicoCliente(res.data.user_id)
            // let priPag = res.data.data_primeiro_pagamento
            if(parcelas === 1){
              criarFaturas(res.data, false)
            }else{
              // setNewData(res.data.data_primeiro_pagamento)
              var qtoInicial = 0;
              var vInicial = 0;
              for (let f = 0; f < parcelas; f++) {
                qtoInicial += 30
                vInicial += 1;
                gerarDatas(qtoInicial, vInicial)
              }
              function gerarDatas(value, v){
                console.log("VVV", v)
                var data = format(addDays(new Date(), v === 1 ? 0 : value), 'yyyy-MM-dd')
                console.log("data nova nova", data)
                criarFaturas(res.data, data)
              }
            }
            toast.success('🦄 Serviço do cliente criado com sucesso!', {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
        })
        .catch(error => {
          console.log("error: ", error, error.response)
          // setMsgAlert("Error ao adicionar serviço ao cliente")
          toast.error('🦄 Algo deu errado ao criar o serviço do cliente, volte e tente novamente!', {
            position: "bottom-left",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setloading(false);
          throw error;
        });

    }else if(tipo === 'edit'){
      await api
        .post(`/servico-cliente/${idEditar}`, form_data, {
          headers: {
            "content-type": "application/json",
            Authorization: "bearer " + token,
            Accept: "application/json"
          }
        })
        .then(res => {
            console.log("Cliente editado", res.data)
            setloading(false);
            setTypeAlert('success')
            // setMsgAlert('Dados do cliente editado com sucesso.')
            // servicoCliente(res.data.user_id)
            toast.success('🦄 Serviço do cliente editado com sucesso!', {
              position: "bottom-left",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
        })
        .catch(error => {
          console.log("error: ", error, error.response)
          // alert("Erro", "Erro ao cadastrar cliente, tente novamente");
          setloading(false);
          toast.error('🦄 Algo deu errado ao editar o serviço do cliente, volte e tente novamente!', {
            position: "bottom-left",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });

    }

  }


  function limpaCampos(){
    setServicos("");
    setValor("");
    setAddDias(10);
    setDatavencimento(format(addDays(new Date(), 30), 'dd/MM/yyyy'))
    setInfoAdd('');
    setDominio('');
    setParcelas(1);
    setTxtBtn('Salvar');
  }

  //validações
  const validation = [];
  validation.push(servicos && servicos.length !== 0);
  validation.push(valor && valor.length !== 0);
  validation.push(datavencimento && datavencimento.length !== 0);

  if(categoriaServico === 'Hospedagem de Sites'){
    validation.push(dominio && dominio.includes("."));
  }

  // if (tipo) {
  //   validation.push(nome && nome.trim());
  //   validation.push(repetirSenha);
  //   validation.push(senha === repetirSenha);
  // }

  const validForm = validation.reduce((all, v) => all && v);

  var parcelasArray = [1,2,3,4,5,6,7,8,9,10];
  function moedaBR(amount, decimalCount = 2, decimal = ",", thousands = "."){
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      console.log(e)
    }
  }

  //table
  /**
   * recebe o id do cliente
   */
  // async function servicoCliente(idDoCliente){
  //   var token = localStorage.getItem('@token_diario_web');
  //   var config = {
  //     headers: {Authorization: 'bearer ' + token},
  //   };
  //   try {
  //     const {data : result} = await api.get(`servicos-cliente/${idDoCliente}`, config);
  //     console.log("Resultado servico-cliente", result)
  //     setData(result)
  //   } catch (error) {
  //     console.log("Erro ao deletar", error, error.response);
  //   }
  // }


    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link className="linkBreadcrumbs" color="inherit" href="/">
                                    Dashboard
                                </Link>
                                <Link className="linkBreadcrumbs" color="inherit" href="/faturas/">
                                    Serviço
                                </Link>
                                <Typography variant="body2" color="textPrimary">
                                    Serviço/Cliente
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>Serviço/Cliente</h2>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                    <div className="paperContant">
                                      <div className="paperHeader">
                                          <Typography variant="subtitle1" gutterBottom>
                                            <strong>Cliente: </strong>{location.state.nome}
                                          </Typography>
                                          <Typography variant="subtitle1" gutterBottom>
                                            <strong>Email: </strong>{location.state.email}
                                          </Typography>
                                      </div>
                                      <form noValidate autoComplete="off" onSubmit={salvar}> 
                                        <Grid container spacing={3}>
                                          <Grid item xs={12} md={4}>
                                            <FormControl fullWidth variant="outlined">
                                              <InputLabel id="servicos-label">Serviços</InputLabel>
                                              <Select
                                                id="servicos"
                                                value={servicos}
                                                onChange={handleChangeServicos}
                                                label="Serviço"
                                                error={!servicos ? true : false}
                                              >
                                                {servicosDados.map((itens) =>(
                                                  <MenuItem key={itens.id} value={itens.id}>{itens.nome} - R$: {itens.valor}</MenuItem>
                                                ))}
                                              </Select>
                                            </FormControl>
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <FormControl fullWidth variant="outlined">
                                              <InputLabel htmlFor="email">Valor</InputLabel>
                                              <OutlinedInput
                                                type={'numeric'}
                                                error={!valor ? true : false}
                                                id="email"
                                                value={valor ? valor : ''}
                                                onChange={handleChangeValor}
                                                labelWidth={60}
                                              />
                                            </FormControl>
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <FormControl fullWidth variant="outlined">
                                              <InputLabel htmlFor="addDias">Carência/Dias</InputLabel>
                                              <OutlinedInput
                                              type={'number'}
                                                error={!addDias ? true : false}
                                                label="Carência/Dias"
                                                id="addDias"
                                                value={addDias ? addDias : ''}
                                                onChange={handleChangeAddDias}
                                                labelWidth={60}
                                              />
                                            </FormControl>
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <FormControl fullWidth variant="outlined">
                                              <InputLabel id="primeiroPagamento-label" htmlFor="primeiroPagamento">{txtInputPag}</InputLabel>
                                              <OutlinedInput
                                                // labelId="primeiroPagamento-label"
                                                // disabled={true}
                                                label={txtInputPag}
                                                error={!primeiroPagamento ? true : false}
                                                id="primeiroPagamento"
                                                value={primeiroPagamento ? primeiroPagamento : ''}
                                                onChange={handleChangeDataPriPagamento}
                                                labelWidth={60}
                                              />  
                                            </FormControl>
                                          </Grid>
                                          {group !== 'hosting' && (
                                            <Grid item xs={12} md={4}>
                                              <FormControl fullWidth variant="outlined">
                                                <InputLabel id="parcelar-label">Parcelas</InputLabel>
                                                <Select
                                                  labelId="parcelar-label"
                                                  id="parcelar"
                                                  value={parcelas}
                                                  onChange={handleChangeParcelas}
                                                  label="Parcelas"
                                                  error={!parcelas ? true : false}
                                                >
                                                {parcelasArray.map((item, key) => (
                                                  <MenuItem key={key} value={key + 1}>
                                                    {item}
                                                  </MenuItem>
                                                ))}
                                                </Select>
                                              </FormControl>
                                            </Grid>
                                          )}       
                                          {parcelas !== 1 &&(
                                            <Grid item xs={12} md={3}>
                                                <FormControl fullWidth  variant="outlined">
                                                  <InputLabel htmlFor="valorparcela">Valor parcela</InputLabel>
                                                  <OutlinedInput
                                                    error={!valorParcela ? true : false}
                                                    id="valorparcela"
                                                    value={moedaBR(valorParcela)}
                                                    label="Valor parcela"
                                                    onChange={handleChangeValorParcela}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                          )}
                                          {recorrencia !== '0' &&(
                                            <Grid item xs={12} md={3}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="cpgcnpj">Próximo vencimento</InputLabel>
                                                  <OutlinedInput
                                                    error={!datavencimento ? true : false}
                                                    id="datavencimento"
                                                    label="Próximo vencimento"
                                                    value={datavencimento ? datavencimento : ''}
                                                    onChange={handleChangeDatavencimento}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                          )}
                                          {group === 'hosting' && (
                                              <Grid item xs={12} md={3}>
                                                  <FormControl fullWidth variant="outlined">
                                                    <InputLabel htmlFor="cpgcnpj">Domínio</InputLabel>
                                                    <OutlinedInput
                                                      error={!validForm ? true : false}
                                                      id="dominio"
                                                      value={dominio ? dominio : ''}
                                                      onChange={handleChangeDominio}
                                                      labelWidth={60}
                                                    />
                                                  </FormControl>
                                              </Grid>
                                          )}
                                          <Grid item xs={12} md={3}>
                                              <FormControl fullWidth variant="outlined" >
                                                <InputLabel id="status-label">Status</InputLabel>
                                                <Select
                                                  labelId="status-label"
                                                  id="status"
                                                  value={status}
                                                  onChange={handleChangeStatus}
                                                  label="Status"
                                                >
                                                  <MenuItem value={1}>Ativo</MenuItem>
                                                  <MenuItem value={0}>Inativo</MenuItem>
                                                </Select>
                                              </FormControl>
                                          </Grid>

                                          <Grid item xs={12} md={12}>
                                              <FormControl fullWidth variant="outlined" >
                                                <TextField
                                                  id="filled-multiline-infadd"
                                                  label="Informação adicional"
                                                  multiline
                                                  rows={5}
                                                  variant="filled"
                                                  value={infoAdd}
                                                  onChange={handleChangeInfAdd}
                                                />
                                              </FormControl>
                                          </Grid>

                                          <Grid item xs={12} md={12}>
                                              <Button
                                                disabled={!validForm}
                                                style={{maxWidth: 150, height: 50, marginRight: 10}}
                                                type="submit" variant="outlined" color="primary"
                                              >
                                                {txtBtn}
                                              </Button>
                                              {tipo === 'editar' &&(
                                                <Button
                                                  disabled={!validForm}
                                                  style={{maxWidth: 150, height: 50, marginRight: 10}}
                                                  variant="outlined" color="secondary"
                                                  onClick={deletar}
                                                >
                                                  Deletar
                                                </Button>
                                              )}
                                              {tipo === 'editar' &&(
                                                <Button
                                                  disabled={!validForm}
                                                  style={{maxWidth: 150, height: 50}}
                                                  variant="outlined" color="primary"
                                                  // onClick={routeback}
                                                  component="a"
                                                  href={`/clienteServico/add-servico/cadastro/cliente/${slug[3]}`}
                                                >
                                                  Novo
                                                </Button>
                                              )}
                                          </Grid>
                                        </Grid>
                                      </form>
                                    </div>
                                    <ToastContainer
                                        position="top-right"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                      />
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
            {loading && (
                <div className="loading">
                    <FadeLoader color={'rgb(63, 1, 121)'} loading={loading} size={150} />
                </div>
            )}
        </div>
    );
}
