/* eslint-disable */
import api from './services/api';

export const isAuthenticated = (props) => {
    // console.log('props auth', props);
    const token = localStorage.getItem('@token_diario_web');
    if (token) {
        return true;
    } else {
        return false;
    }
};

export const veryAuth = () => {
  // verifica 
  var token = localStorage.getItem('@token_diario_web');
  var config = {
      headers: { Authorization: 'bearer ' + token }
  };
  try {
    const result = api.get('very-auth', config);
    return result;
  } catch (error) {
    console.log("error auth", error, error.response)
  }
  // console.log("resultresult ",result)
  // console.log('props auth', res);
};
