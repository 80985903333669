import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
/* eslint-disable */
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableUsuarios from '../../../Components/Tables/usuarios';
import { useLocation } from 'react-router-dom';
import {veryAuth} from '../../../auth';

export default function Index() {
    let history = useHistory();
    const location = useLocation();
    console.log("location ", location.state)
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);
    const [title, setTitle] = useState('');
    const [liberaAcesso, setLiberaAcesso] = useState(false);
    const tipoUsuario = location.state ? location.state.usuario : ''
    const [roles, setRoles] = useState('');
    const [permissaoTelas, setPermissaoTelas] = useState('');

    const [orderBy, setOrderBy] = useState('desc');
    const [condUm, setCondUm] = useState('todos');

    useEffect(() => {
        // Atualiza o titulo do documento usando a API do browser
        verificarPermissao()
        setTimeout(function () {
            seSkeleton(false);
        }, 2000);
    }, []);

    function verificarPermissao(){
      if(!location.state){
        history.push({
          pathname: `/`,
        });
        return
      } 
      veryAuth()
      .then((result) => {
        console.log('result verificarPermissao', result);
        // console.log('location.state.telasuser[0]', location.state.telasuser[0]);
        setPermissaoTelas(location.state.telasuser[0])
        if(result.data.role === 'admin' || result.data.role === 'gestor'){
          setLiberaAcesso(true)
          usuario(orderBy, condUm)
          nomearTitle()
        }else{
          history.push({
            pathname: `/`,
          });
          return
        }
        setRoles(result.data.role)
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.response, error.message);
      });
    }

    function mudarOrdeBy(){
      setOrderBy(orderBy === 'desc' ? 'asc' : 'desc')
      usuario(orderBy === 'desc' ? 'asc' : 'desc', condUm)
    }

    function exibirNaoMapeados(){
      setCondUm(condUm === 'todos' ? 'naomatriculado' : 'todos')
      usuario(orderBy, condUm === 'todos' ? 'naomatriculado' : 'todos')
    }

    function nomearTitle(){
      if(tipoUsuario === 'secretario'){
        setTitle('Secretários')
      }else if(tipoUsuario=== 'coordenador'){
        setTitle('Coordenadores')
      }else if(tipoUsuario === 'gestor'){
        setTitle('Gestores')
      }else if(tipoUsuario === 'professor'){
        setTitle('Professores')
      }else if(tipoUsuario === 'aluno'){
        setTitle('Alunos')
      }else if(tipoUsuario === 'admin'){
        setTitle('Aministradores')
      }
    }

    
    async function usuario(orderby = orderBy, condum = condUm){
      var token = localStorage.getItem('@token_diario_web');
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data : result} = await api.get(`usuario-por-role/${tipoUsuario}/${orderby}/${condum}`, config);
        console.log("Result usuario por role", result)
        setData(result)
        toast.success('🦄 Carregando alunos' , {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro ao carregar alunos", error, error.response);
        toast.error('🦄 Error ao carregar alunos!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    function deletando(){
      usuario()
    }

    function handleChangePesquisar(event){
      if(event.target.value){
        pesquisarCliente(event.target.value)
      }else{
        usuario()
      }
      // setPesquisar(event.target.value)
    }

    async function pesquisarCliente(termo) {
      //setLoading(true);
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
  
  
      try {
        const {data : result} = await api.get(`pesquisar-usuario/${encodeURI(termo)}/${encodeURI('Aluno')}`, config);
        console.log("Result pesquisar", result)
        setData(result)
      } catch (error) {
        console.log("Erro ao buscar ", error, error.response);
      }
    }
  

    //tabela
    function create() {
      history.push({
          pathname: `${location.state.usuario}-create`,
          // search: '?query=abc',
          state: { 
            telasuser: location.state.telasuser[0],
            type: 'new',
            usuario: location.state.usuario, 
            // type: 'new',
            // usuario: location.state.usuario, 
            // usuarioLogado: roles
          }
      });
    }

    function createData(id, name, cpf, cadastro, email, telefone, alunoEscola) {
      return {id, name, cpf, cadastro, email, telefone, alunoEscola};
    }
  
    var rows = [];
    if(!data){
        rows = [createData('carregando')]
    }
    if(data){
      rows = data.map((row) => {
        return createData(row.id, row.name, row.cpf, row.created_at, row.email, row.telefone, row.alunoEscola)
      })
    }
  
    const columns = [
      { id: 'name', numeric: false, disablePadding: true, label: 'Nome' },
      { id: 'cpf', numeric: false, disablePadding: true, label: 'CPF' },
      { id: 'cadastro', numeric: true, disablePadding: false, label: 'Cadastro' },
      { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
      { id: 'telefone', numeric: true, disablePadding: false, label: 'Telefone' },
      { id: 'edit', numeric: true, disablePadding: false, label: '' },
    ];
  
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link className="linkBreadcrumbs" color="inherit" href="/">
                                    Dashboard
                                </Link>
                                {/* <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/getting-started/installation/"
                                    onClick={handleClick}>
                                    Core
                                </Link> */}
                                <Typography variant="body2" color="textPrimary">
                                    {title}
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>{title}</h2>
                                {permissaoTelas.create != 0 &&(
                                  <Button
                                    className="button-add"
                                    onClick={create}
                                    variant="contained"
                                    color="primary">
                                    Novo
                                  </Button>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={'paperSearch'}>
                                <TextField
                                    className="textField"
                                    id="outlined-secondary"
                                    label="Digite o nome do aluno"
                                    variant="outlined"
                                    // color="secondary"
                                    // value={pesquisar}
                                    onChange={handleChangePesquisar}
                                />
                            </Paper>
                        </Grid>
                        {liberaAcesso && (
                          <Grid item xs={12} md={12} lg={12}>
                              {skeleton && <Skeleton variant="rect" className="paper" />}
                              {!skeleton && (
                                  <Paper className={'paper'}>
                                    {rows.length !== 0 && (
                                      <div className="paperContant">
                                        <Grid style={{padding: 10}} container spacing={3}>
                                          <Grid item xs={12} md={3}>
                                            <Paper className={'paperSearch'} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                <Button 
                                                  style={{
                                                    width: '100%',
                                                    background: 'rgb(6, 203, 29)',
                                                    // background: key === 0 ? 'rgb(6, 203, 29)' : key === 1 ? 'rgb(6, 121, 156)' : key === 2 ? 'rgb(95, 2, 210)' : key === 3 ? 'rgb(177, 1, 17)' : 'rgb(177, 1, 17)',
                                                    color: 'white'
                                                  }}
                                                  onClick={()=>{
                                                    mudarOrdeBy()
                                                  }}
                                                >
                                                Ordenar
                                                </Button>
                                            </Paper>
                                          </Grid>
                                          <Grid item xs={12} md={3}>
                                            <Paper className={'paperSearch'} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                <Button 
                                                  style={{
                                                    width: '100%',
                                                    background: 'rgb(6, 121, 156)',
                                                    // background: key === 0 ? 'rgb(6, 203, 29)' : key === 1 ? 'rgb(6, 121, 156)' : key === 2 ? 'rgb(95, 2, 210)' : key === 3 ? 'rgb(177, 1, 17)' : 'rgb(177, 1, 17)',
                                                    color: 'white'
                                                  }}
                                                  onClick={()=>{
                                                    exibirNaoMapeados()
                                                  }}
                                                >
                                                {condUm === 'todos' ? 'Não Matriculado' : 'Todos'}
                                                </Button>
                                            </Paper>
                                          </Grid>
                                          <Grid item xs={12} md={12}>
                                              <span>Total de Alunos: <strong>{data.length}</strong></span>
                                          </Grid>
                                        </Grid>
                                        <TableUsuarios 
                                          columns={columns} 
                                          rows={rows} 
                                          deletando={deletando}
                                          tipoUsuario={tipoUsuario}
                                          telasuser={permissaoTelas}
                                        />
                                      </div>
                                    )}
                                    {rows.length === 0 && (
                                      <div className="paperContantDois">
                                          <div>Nenhum registro encontrado</div>
                                      </div>
                                    )}
                                  </Paper>
                              )}
                          </Grid>
                        )}
                        {!liberaAcesso && (
                          <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                              <Paper className={'paper'}>
                                <div className="paperContantDois">
                                    <div>Acesso Negado</div>
                                </div>
                              </Paper>
                            )}
                          </Grid>
                        )}
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
        </div>
    );
}
