import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import '../../styles/global.css';
/* eslint-disable */
import Edit from '@material-ui/icons/Edit';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../services/api';
import {format, subDays, addDays, isAfter, parseISO} from 'date-fns';


const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    container: {
        overflow: 'none'
    }
});

export default function Index(props) {
    console.log('Propos serviço', props);
    const classes = useStyles();
    let history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const columns = props.columns

    const rows = props.rows

    function editar(id){
      // history.push('/fatura-create');
      history.push({
          pathname: '/servico-edit',
          // search: '?query=abc',
          state: { type: 'edit', id: id }
      });
    }
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    async function deletar(id) {
      
      // setloading(true)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: prod} = await api.delete(`/cliente/${id}`, config);
        console.log("prod", prod)
        // limpaCampos();
        // setloading(false);
        toast.success('🦄 Cliente deletado com sucesso!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.deletando()
      } catch (error) {
        console.log("Erro ao deletar", error, error.response);
        // setloading(false);
        toast.error('🦄 Erro ao tentar deletar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    function moedaBR(amount, decimalCount = 2, decimal = ",", thousands = "."){
      try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  
        const negativeSign = amount < 0 ? "-" : "";
  
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
  
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
      } catch (e) {
        console.log(e)
      }
    }

    return (
        <Paper className={'paper'}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, key) => (
                                <TableCell
                                    key={column.id}
                                    align={key === 3 || key === 4 ? 'right' : 'left'}
                                    style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow
                                        className="tableRow"
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id}>
                                          <TableCell component="th" scope="row">
                                              {row.name}
                                          </TableCell>
                                          {/* <TableCell align="left">{ format(new Date(row.cadastro), 'dd/MM/yyyy')}</TableCell> */}
                                          <TableCell align="left">{row.categoria}</TableCell>
                                          <TableCell align="left">{moedaBR(row.valor)}</TableCell>
                                          <TableCell align="right">{row.status === 1 ? 'Ativo' : 'Inativo'}</TableCell>
                                          <TableCell align="right">
                                            {/* <Tooltip title="Editar">
                                              <IconButton
                                                component="a"
                                                // href={`/clientes/editar/abc?foo=bar`}
                                                // href="/clientes/[...slug]"
                                                href={`/faturas/editar/${row.id}`}
                                                as={`/faturas/editar/${row.id}`}
                                                aria-label="editar"
                                              >
                                                <EditIcon />
                                              </IconButton>
                                            </Tooltip> */}
                                            <IconButton onClick={() => {editar(row.id)}} color="inherit">
                                                <Edit />
                                            </IconButton>
                                              <IconButton onClick={() => {deletar(row.id)}} color="inherit">
                                                  <DeleteSweepIcon style={{ color: '#ff0000' }} />
                                              </IconButton>
                                          </TableCell>
                                        {/* {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })} */}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Por página'}
            />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
        </Paper>
    );
}
