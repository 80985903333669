import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
// import TextField from '@material-ui/core/TextField';
/* eslint-disable */
import Skeleton from '@material-ui/lab/Skeleton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FadeLoader from "react-spinners/FadeLoader";
import api from '../../../services/api';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {veryAuth} from '../../../auth';
import SelectAluno from '../../../Components/Select/pesqusarAluno';
import {format} from 'date-fns';

export default function Index() {
    let history = useHistory();
    const location = useLocation();
    const [skeleton, seSkeleton] = useState(true);
    const [loading, setloading] = useState(false);

    //cadastro
    const [tipo, setTipo] = useState('');
    const [txtBtn, setTxtBtn] = useState('');
    const [idEditar, setIdEditar] = useState('');
    const [title, setTitle] = useState('');
    const [disabled, setDisabled] = useState(false);

    const [alunos, setAlunos] = useState([]);
    const [aluno, setAluno] = useState('');
    const [alunoArray, setAlunoArray] = useState([]);
    const [dadosParaInsert, setDadosParaInsert] = useState([]);
    const [escolas, setEscolas] = useState([]);
    const [escola, setEscola] = useState('');
    const [anoLetivos, setAnoLetivos] = useState([]);
    const [anoLetivo, setAnoLetivo] = useState('');
    const [turno, setTurno] = useState('');
    const [turmasEscola, setTurmasEscola] = useState([]);
    const [turma, setTurma] = useState('');
    const [ano, setAno] = useState(format(new Date(), "yyyy"));

    useEffect(() => {
      console.log("location.state.", location.state)
      // Atualiza o titulo do documento usando a API do browser
      verificarPermissao()
      
      setTimeout(function () {
          seSkeleton(false);
      }, 3000);
    }, []);

    function verificarPermissao(){
      veryAuth()
      .then((result) => {
        if(result.data.role === 'admin'){
          carregarUsuario();
          carregarEscolas();
          // carregarAnoLetivo();
          if(location.state.dadosAluno){
            setAlunoArray(location.state.dadosAluno)
            setAluno(location.state.dadosAluno.id);
          }
          
          if(location.state){
            setTipo(location.state.type)
            setTxtBtn(location.state.type === 'edit' ? 'Editar' : 'Salvar')
          }else{
            history.push('/')
          }
          if(location.state.type === 'edit'){
            editar(parseInt(location.state.id))
            // setTitle('Configurações do ' + tipoUsuario )
            setDisabled(true)
          }else{
            // setTitle('Cadastro de ' + tipoUsuario )
            setloading(false)
            // gerarPassword();
            setDisabled(false)
          }
        }else{
          history.push({
            pathname: `/`,
          });
          return
        }
        // setRoles(result.data.role)
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.response, error.message);
      });
    }

    async function carregarAno(idescola) {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get(`pesquisar-ano-escola/${idescola}`, config);
          console.log('resultado turma da escola', result);
          setTurmasEscola(result)
      } catch (error) {
          console.log('Erro ao buscar ano', error, error.response);
      }
    } 

    // async function buscarInfTurma(id){
      
    //   var token = localStorage.getItem('@token_diario_web');
    //   var config = {
    //       headers: { Authorization: 'bearer ' + token }
    //   };
    //   try {
    //       // const { data: result } = await api.get('ano-letivo', config);
    //       const { data: result } = await api.get(`escola-turma/${id}`, config);
    //       for (let d = 0; d < result.length; d++) {
    //         const element = result[d];
    //         console.log('resultado turma', element);
    //         setTurma(element.id)
    //         setTurno(element.turno)
    //       }
    //   } catch (error) {
    //       console.log('Erro ao buscar ano letivo', error, error.response);
    //   }
    // }

    async function carregarAnoLetivo(idescola) {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          // const { data: result } = await api.get('ano-letivo', config);
          const { data: result } = await api.get(`pesquisar-ano-escola/${idescola}`, config);
          console.log('resultado ano letivos', result);
          setAnoLetivos(result);
      } catch (error) {
          console.log('Erro ao buscar ano letivo', error, error.response);
      }

    } 

    async function carregarUsuario() {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get(`usuario-por-role/aluno/desk/null`, config);
          // console.log('resultado alunos', result);
          setAlunos(result);
      } catch (error) {
          console.log('Erro ao buscar alunos', error, error.response);
      }
    } 

    async function carregarEscolas() {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get(`escola`, config);
          // console.log('resultado escola', result);
          setEscolas(result);
      } catch (error) {
          console.log('Erro ao buscar escola', error, error.response);
      }
    } 

    // function rota(link) {
    //   history.push({
    //       pathname: link,
    //       // search: '?query=abc',
    //       state: { 
    //         type: 'new',
    //       }
    //   });
    // }

    function handleChangeAlunos(event){
      // setAluno(event.target.value);
      console.log('event handleChangeAlunos', event)
      if(event){
        setAluno(event.id);
      }
    }
    function handleChangeEscola(event){
      carregarAnoLetivo(event.target.value)
      carregarAno(event.target.value)
      setEscola(event.target.value);
    }
    // function handleChangeTurno(event){
    //   setTurno(event.target.value);
    // }
    function handleChangeAnoLetivo(event){
      // console.log("Busca informações ", event.target.value)
      // console.log("Busca informações2 ", anoLetivos)
      var result = anoLetivos.filter(function(obj2) { 
        return obj2.id == event.target.value; 
      });
      console.log("Busca informaçõe3 ", result[0])
      if(result[0].alunoEscola.length >= result[0].qto_vagas){
        alert('Turna já está cheia.')
      }
      setDadosParaInsert(result[0])
      // setTurma(event.target.value)
      setAnoLetivo(event.target.value);
      // buscarInfTurma(event.target.value);

      setTurma(event.target.value)
      setTurno(result[0].turno)
    }
    function handleChangeTurma(event){
      setTurma(event.target.value);
    }
    function handleChangeAno(event){
      setAno(event.target.value);
    }
    
    function habilitarEdit(){
      setDisabled(false)
    }

    async function deletar() {
      console.log("id", idEditar)
      setloading(true)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: prod} = await api.delete(`/aluno-escola/${idEditar}`, config);
        // limpaCampos();
        setloading(false);
        // setOpenAlert(true)
        // setTypeAlert('success')
        // setMsgAlert('Fatura foi deletada com sucesso.')
        toast.success('🦄 Matrícula deletada com sucesso!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push('/matricula')
        // history.push('/clientes')
      } catch (error) {
        console.log("Erro ao deletar", error, error.response);
        setloading(false);
        // setOpenAlert(true)
        // setTypeAlert('error')
        // setMsgAlert('Erro ao deletar, tente novamente')
        toast.error('🦄 Erro ao deletar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    async function editar(id){
      setIdEditar(id)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      console.log("aqui", id)
      try {
        const {data: edit} = await api.get(`/aluno-escola/${id}`, config);
        console.log("editar Matrícula", edit)
        carregarAnoLetivo(edit.escola_id)
        carregarAno(edit.escola_id)
        setloading(false);
        setAlunoArray(edit.user)
        setAluno(edit.user_id)
        setEscola(edit.escola_id)
        setTurma(edit.escola_turma_id)
        setAnoLetivo(edit.escola_turma_id)
        setTurno(edit.turno)

      } catch (error) {
        console.log("Erro buscar dados para editar", error, error.response);
        toast.error('🦄 Erro ao buscar dados para editar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setloading(false);
      }
    }
  

    async function salvar(event) {
      event.preventDefault();
      // console.log("event", event)
      setloading(true)

      //setloading(true);
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
  
      let form_data = new FormData();
  
      console.log('Dados para insierrir ', dadosParaInsert)
      form_data.append("aluno_id", aluno);
      form_data.append("escola_id", escola);
      form_data.append("escola_turma_id", turma);
      form_data.append("ano_letivo_id", dadosParaInsert.anoLetivo.id);
      form_data.append("ano", ano);
      form_data.append("turno", turno);
  
      if(tipo === 'new'){
        await api
          .post(`/aluno-escola`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Cadastrando matrícula do aluno", res.data)
              limpaCampos();
              setloading(false);
              toast.success('🦄 Matrícula do aluno cadastrado com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            console.log("response: ", error.response.data.message)
            toast.error(`🦄 Erro ao cadatrar matricular do aluno, ${error.response.data.message}!`, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setloading(false);
            // throw error;
          });
  
      }else if(tipo === 'edit'){
  
        await api
          .post(`/aluno-escola/${idEditar}`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Matrícula do aluno editado", res.data)
              setloading(false);
              toast.success('🦄 Matrícula do aluno editado com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              editar(res.data.id)
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            toast.error('🦄 Erro ao editar matrícula do aluno, tente novamente ou fale com o suporte!', {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setloading(false);
          });
  
      }
  
    }

    function limpaCampos(){
      setAlunoArray([])
      setEscola('')
      setTurma('')
      setTurno('')
      setAno('')
      setAnoLetivo('')
    }

    //validações
    const validation = [];
    validation.push(aluno && escola);

    const validForm = validation.reduce((all, v) => all && v);

    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link className="linkBreadcrumbs" color="inherit" href="/">
                                    Dashboard
                                </Link>
                                <Link className="linkBreadcrumbs" color="inherit" href="/matricula">
                                    Matrícula
                                </Link>
                                <Typography variant="body2" color="textPrimary">
                                    { location.state.type === 'edit' ? 'Editar ' + title : 'Nova Matrícula ' + title}
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>{ location.state.type === 'edit' ? 'Editar ' + title : 'Nova Matrícula ' + title}</h2>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                    <div className="paperContant">
                                        <form noValidate autoComplete="off" onSubmit={salvar}>
                                          <Grid container spacing={3}>
                                              <Grid item xs={12} md={12}>
                                                <SelectAluno handleChangeAlunos={handleChangeAlunos} alunoArray={alunoArray}/>
                                              </Grid>
                                              {aluno && (
                                                <Grid item xs={12} md={4}>
                                                  <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="select-polo">Escolas</InputLabel>
                                                    <Select
                                                      disabled={disabled}
                                                      labelId="select-escola"
                                                      id="select-escola"
                                                      value={escola}
                                                      onChange={handleChangeEscola}
                                                      label="Escolas"
                                                    >
                                                      <MenuItem value="">
                                                        <em>Nenhum</em>
                                                      </MenuItem>
                                                      {escolas.map((item, key) => (
                                                        <MenuItem key={key} value={item.id}>{item.escola_nome}</MenuItem>
                                                      ))}
                                                    </Select>
                                                  </FormControl>
                                                </Grid>
                                              )}
                                              {escola && (
                                                // <Grid item xs={12} md={4}>
                                                //   <FormControl variant="outlined" fullWidth>
                                                //     <InputLabel id="select-turma">Turma</InputLabel>
                                                //     <Select
                                                //       disabled={disabled}
                                                //       labelId="select-turma"
                                                //       id="select-turma"
                                                //       value={turma}
                                                //       onChange={handleChangeTurma}
                                                //       label="Turma"
                                                //     >
                                                //       <MenuItem value="">
                                                //         <em>Nenhum</em>
                                                //       </MenuItem>
                                                //       {turmasEscola.map((item, key) => (
                                                //         <MenuItem key={key} value={item.id}>{item.turma_nome}</MenuItem>
                                                //       ))}
                                                //     </Select>
                                                //   </FormControl>
                                                // </Grid>
                                                <Grid item xs={12} md={4}>
                                                  <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="select-polo">Ano</InputLabel>
                                                    <Select
                                                      disabled={disabled}
                                                      labelId="select-anoLetivo"
                                                      id="select-anoLetivo"
                                                      value={anoLetivo}
                                                      onChange={handleChangeAnoLetivo}
                                                      label="Ano"
                                                    >
                                                      <MenuItem value="">
                                                        <em>Nenhum</em>
                                                      </MenuItem>
                                                      {anoLetivos.map((item, key) => (
                                                        <MenuItem key={key} value={item.id}>{item.anoLetivo.nome_ano} - {item.turma_nome} - {item.turno} - Vagas: ({item.qto_vagas} / {item.alunoEscola.length})</MenuItem>
                                                      ))}
                                                    </Select>
                                                  </FormControl>
                                                </Grid>      
                                              )}
                                              {anoLetivo && (
                                                <Grid item xs={12} md={4}>
                                                  <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="select-turma">Turma</InputLabel>
                                                    <Select
                                                      disabled={disabled}
                                                      labelId="select-turma"
                                                      id="select-turma"
                                                      value={turma}
                                                      onChange={handleChangeTurma}
                                                      label="Turma"
                                                      disabled
                                                    >
                                                      <MenuItem value="">
                                                        <em>Nenhum</em>
                                                      </MenuItem>
                                                      {turmasEscola.map((item, key) => (
                                                        <MenuItem key={key} value={item.id}>{item.turma_nome}</MenuItem>
                                                      ))}
                                                    </Select>
                                                  </FormControl>
                                                  {/* <FormControl fullWidth variant="outlined">
                                                    <InputLabel htmlFor="nameEndereco">Turma</InputLabel>
                                                    <OutlinedInput
                                                      id="turma"
                                                      label={"Turma"}
                                                      value={turma}
                                                      // onChange={handleChangeEnderecoEscola}
                                                      // labelWidth={60}
                                                    />
                                                  </FormControl> */}
                                                </Grid>
                                              )}
                                              {turma && (
                                                <Grid item xs={12} md={4}>
                                                  {/* <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="select-polo">Turnos</InputLabel>
                                                    <Select
                                                      disabled={disabled}
                                                      labelId="select-turno"
                                                      id="select-turno"
                                                      value={turno}
                                                      onChange={handleChangeTurno}
                                                      label="Tornos"
                                                    >
                                                      <MenuItem value="">
                                                        <em>Nenhum</em>
                                                      </MenuItem>
                                                      <MenuItem value={'Matutino'}>Matutino</MenuItem>
                                                      <MenuItem value={'Vespertino'}>Vespertino</MenuItem>
                                                    </Select>
                                                  </FormControl> */}
                                                  <FormControl fullWidth variant="outlined">
                                                    <InputLabel htmlFor="nameEndereco">Turno</InputLabel>
                                                    <OutlinedInput
                                                      id="turno"
                                                      label={"Turno"}
                                                      value={turno}
                                                      disabled
                                                      // onChange={handleChangeEnderecoEscola}
                                                      // labelWidth={60}
                                                    />
                                                  </FormControl>
                                                </Grid>
                                              )}
                                              {/* {turno && (
                                                <Grid item xs={12} md={4}>
                                                  <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="select-polo">Ano</InputLabel>
                                                    <Select
                                                      disabled={disabled}
                                                      labelId="select-anoLetivo"
                                                      id="select-anoLetivo"
                                                      value={anoLetivo}
                                                      onChange={handleChangeAnoLetivo}
                                                      label="Ano"
                                                    >
                                                      <MenuItem value="">
                                                        <em>Nenhum</em>
                                                      </MenuItem>
                                                      {anoLetivos.map((item, key) => (
                                                        <MenuItem key={key} value={item.id}>{item.nome_ano}</MenuItem>
                                                      ))}
                                                    </Select>
                                                  </FormControl>
                                                </Grid>                                              
                                              )} */}
                                              {anoLetivo && (
                                                <Grid item xs={12} md={4}>
                                                  <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="select-polo">Ano Letivo</InputLabel>
                                                    <Select
                                                      disabled={disabled}
                                                      labelId="select-ano"
                                                      id="select-ano"
                                                      value={ano}
                                                      onChange={handleChangeAno}
                                                      label="Ano Letivo"
                                                      disabled
                                                    >
                                                      <MenuItem value={ano}>{ano}</MenuItem>
                                                    </Select>
                                                  </FormControl>
                                                </Grid>
                                              )}
                                            <Grid item xs={12} md={12}>
                                                <Button
                                                  disabled={!validForm}
                                                  style={{maxWidth: 150, height: 50, marginRight: 10}}
                                                  type="submit" variant="outlined" color="primary"
                                                >
                                                  {txtBtn}
                                                </Button>
                                                {tipo === 'edit' &&(
                                                  <Button
                                                    disabled={!validForm}
                                                    style={{maxWidth: 150, height: 50}}
                                                    variant="outlined" color="secondary"
                                                    onClick={deletar}
                                                  >
                                                    Deletar
                                                  </Button>
                                                ) }
                                            </Grid>
                                          </Grid>

                                        </form>
                                        <ToastContainer
                                          position="top-right"
                                          autoClose={5000}
                                          hideProgressBar={false}
                                          newestOnTop={false}
                                          closeOnClick
                                          rtl={false}
                                          pauseOnFocusLoss
                                          draggable
                                          pauseOnHover
                                        />
                                    </div>
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
            {loading && (
                <div className="loading">
                    <FadeLoader color={'rgb(63, 1, 121)'} loading={loading} size={150} />
                </div>
            )}
        </div>
    );
}
