import React, { useState, useEffect } from 'react';
/* eslint-disable */
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
    background: '#fff'
  },
}));;

export default function NativeSelects({tipoUsuario, idaluno, idEscolaSelecionada, idAnoLetivo, idTurma, diarioSelecionado}) {
    const classes = useStyles();
    const [state, setState] = useState();
    const [data, setData] = useState([]);

    useEffect(() => {
      diariosAluno();
    }, []);
    
    const handleChange = (event) => {
        setState(event.target.value.id);
        diarioSelecionado(event.target.value)
    };

    async function diariosAluno() {
        var token = localStorage.getItem('@token_diario_web');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get(`diarios-aluno/${idEscolaSelecionada}/${idAnoLetivo}`, config);
            console.log('Result diarios alunos', result);
            setData(result);
        } catch (error) {
            console.log('Erro ao carregar escolas', error, error.response);
        }
    }

    return (
        <div>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <InputLabel id="escolas-outlined">Diários</InputLabel>
              <Select
                labelId="escolas-outlined"
                id="escolas"
                value={state}
                onChange={handleChange}
                label="Diários"
                className={classes.selectEmpty}
              >
                {data.map((item, key) => (
                  <MenuItem key={key} value={item}>
                    {'Ano: ' + item.ano} {' - '} 
                    {/* {item.anoLetivo.nome_ano} {' - '} 
                    {item.escolaTurma.turma_nome}  {' - '} 
                    {item.turno} {' - '}  */}
                    {'Professor(a): ' + item.user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
        </div>
    );
}
