import React, { useState, useEffect } from 'react';
/* eslint-disable */
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
    background: '#fff'
  },
}));;

export default function NativeSelects({idescola}) {
    const classes = useStyles();
    const [state, setState] = useState();
    const [data, setData] = useState([]);

    useEffect(() => {
      escolas();
    }, []);
    
    const handleChange = (event) => {
        setState(event.target.value);
        idescola(event.target.value)
    };

    async function escolas() {
        var token = localStorage.getItem('@token_diario_web');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get('escola-gestor', config);
            console.log('Result escolas', result);
            setData(result);
        } catch (error) {
            console.log('Erro ao carregar escolas', error, error.response);
        }
    }

    return (
        <div>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <InputLabel id="escolas-outlined">Esolas</InputLabel>
              <Select
                labelId="escolas-outlined"
                id="escolas"
                value={state}
                onChange={handleChange}
                label="Esolas"
                className={classes.selectEmpty}
              >
                {data.map((item, key) => (
                  <MenuItem key={key} value={item.escola.id}>{item.escola.escola_nome}</MenuItem>
                ))}
              </Select>
            </FormControl>
        </div>
    );
}
