import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import '../../../../styles/global.css';
/* eslint-disable */
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import EditPolo from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../../services/api';
import {format, parseISO} from 'date-fns';
import Tooltip from '@material-ui/core/Tooltip';
import Settings from '@material-ui/icons/Settings';


const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    container: {
        overflow: 'none'
    }
});

export default function Index(props) {
    console.log('Propos experiencias', props);
    const classes = useStyles();
    let history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const columns = props.columns

    const rows = props.rows

    const nivelAcesso = props.nivelAcesso;

    function editar(id){
      props.handleOpenModal(id, 'edit')
      // history.push('/fatura-create');
      // history.push({
      //     pathname: '/escola-edit',
      //     // search: '?query=abc',
      //     state: { type: 'edit', id: id }
      // });
    }
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    async function deletar(id) {
      // setloading(true)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: prod} = await api.delete(`/experiencia-desenvolvidas/${id}`, config);
        props.deletando()
        toast.success('🦄 Deletado com sucesso!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      } catch (error) {
        console.log("Erro ao deletar", error, error.response);
        // setloading(false);
        // setOpenAlert(true)
        // setTypeAlert('error')
        // setMsgAlert('Erro ao deletar, tente novamente')
        toast.error('🦄 Erro ao deletado, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    function formatDate(date){
        var dataInput = date;
        var data = new Date(dataInput);
        var dataFormatada = data.toLocaleDateString('pt-BR', {timeZone: 'UTC'});
        return dataFormatada  
    }

    return (
        <Paper className={'paper'}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, key) => (
                                <TableCell
                                    key={column.id}
                                    align={key >= 3 ? 'right' : 'left'}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow
                                        className="tableRow"
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id}>
                                          <TableCell component="th" scope="row">
                                              {/* {format(parseISO(row.data), "dd/MM/yyyy")} - */}
                                              {formatDate(row.data)}
                                          </TableCell>
                                          <TableCell component="th" scope="row">
                                              {row.periodo.nome_periodo}
                                          </TableCell>
                                          <TableCell component="th" scope="row">
                                              {row.qto}
                                          </TableCell>
                                          <TableCell align="right">
                                            <Tooltip title={`Modificar o texto`} aria-label="add">
                                              <IconButton onClick={() => {editar(row.id)}} color="inherit">
                                                  <Settings style={{ color: 'green' }} />
                                              </IconButton>
                                            </Tooltip>
                                            {nivelAcesso === 'admin' && (
                                                <Tooltip title={`Remover`} aria-label="add">
                                                    <IconButton onClick={() => {deletar(row.id)}} color="inherit">
                                                        <DeleteSweepIcon style={{ color: '#ff0000' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                          </TableCell>
                                        
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Por página'}
            />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
        </Paper>
    );
}
