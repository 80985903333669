import React, { useState, useEffect } from 'react';
/* eslint-disable */
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import '../../../../styles/global.css';
import Settings from '@material-ui/icons/Settings';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import Add from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../../services/api';
import { format, subDays, addDays, isAfter, parseISO } from 'date-fns';
import Tooltip from '@material-ui/core/Tooltip';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import CloseBtn from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    container: {
        overflow: 'none'
    }
});

export default function Index(props) {
    console.log('Propos relatorio', props);
    const classes = useStyles();
    let history = useHistory();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [openModal, setOpenModal] = useState(false);
    const [idAluno, setIdAluno] = useState('');
    const [nomeAluno, setNomeAluno] = useState('');
    const [relatorio, setRelatorio] = useState('');
    const [existeDisgnostico, setExisteDiagnostico] = useState(false);
    const [idEditar, setIdEditar] = useState('');
    const [dadosCadastrados, setDadosCadastrados] = useState([]);
    const [dados, setDados] = useState([]);
    const [periodos, setPeriodos] = useState([]);
    // const [idPeriodo, setIdPeriodo] = useState('');

    const columns = props.columns;

    const rows = props.rows;

    const configr = props.config;

    const iddodiario = props.iddodiario;

    let idperiodo = props.periodo.id;

    const nivelAcesso = props.nivelAcesso;

    let myArray = [];
    let myArrayAlunos = [];
    let newArrayAluno = []; // alunos cadastrados

    useEffect(() => {
        // alert('mudou')
        buscarRelatorio(iddodiario, idperiodo);
        buscarPeriodo();
    }, []);

    const handleOpenModal = (id, nome) => {
        editar(id);
        setOpenModal(true);
        setIdAluno(id);
        setNomeAluno(nome);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        // props.carregarDados()
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    function handleChangeRelatorio(event) {
        setRelatorio(event.target.value);
    };
    async function rescreverPeriodo(novoPeriodo){
        props.mudarPeriodo(novoPeriodo)
        idperiodo = novoPeriodo.id
        // alunosDaTurma()
        // setPeriodo(novoPeriodo)
        buscarRelatorio(iddodiario, novoPeriodo.id);
        toast.success(`🦄 Você mudou parar o ${novoPeriodo.nome_periodo}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }

    async function buscarPeriodo(){
        // const dataAtual = format(new Date(), 'yyyy-MM-dd')
        var token = localStorage.getItem('@token_diario_web');
    
        var config = {
          headers: {Authorization: 'bearer ' + token},
        };
  
        try {
          const {data: periodo} = await api.get(`periodo/`, config);
          console.log("buscarPeriodos", periodo)
          setPeriodos(periodo)
          
   
        } catch (error) {
          console.log("Erro buscar período", error, error.response);
          toast.error('🦄 Erro buscar período, volte e tente novamente!', {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // setloading(false);
        }
      }

    async function buscarRelatorio(id_diario, id_periodo) {
        // // alert(id_diario)
        // alert(id_periodo)
        var token = localStorage.getItem('@token_diario_web');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };

        let newObjeto;

        try {
            const { data: diag } = await api.get(`/relatorio-avaliacaos/${id_diario}/${id_periodo}`, config);
            // console.log("buscar relatorio", diag)
            for (let i = 0; i < diag.length; i++) {
                const element = diag[i];
                myArray.push(element.user_id);
                // myArrayAlunos.pu
            }
            //for para todos os alunos cadastrados
            for (let a = 0; a < rows.length; a++) {
                const elementRows = rows[a];
                if (myArray.includes(elementRows.idAluno)) {
                    var resRelatorio = diag.filter(function(obj2) { 
                        return obj2.user_id == elementRows.idAluno; 
                      });
                    newObjeto = { ...elementRows, relatorio: resRelatorio[0].relatorio, temRegistro: true };
                    newArrayAluno.push(newObjeto);
                } else {
                    console.log('Não tem');
                    newObjeto = { ...elementRows, relatorio: '', temRegistro: false };
                    newArrayAluno.push(newObjeto);
                }
            }
            setDados(newArrayAluno);
            console.log('newArrayAluno', newArrayAluno);
        } catch (error) {
            console.log('Erro buscar diagnóstico', error, error.response);
            toast.error('🦄 Erro buscar diagnóstico, volte e tente novamente!', {
                position: 'bottom-left',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
            // setloading(false);
        }
    }

    async function editar(id) {
        setExisteDiagnostico(false);
        setIdEditar('');
        setRelatorio('');
        var token = localStorage.getItem('@token_diario_web');

        var config = {
            headers: { Authorization: 'bearer ' + token }
        };

        try {
            const { data: edit } = await api.get(
                `/relatorio-avaliacao/${id}/${iddodiario}/${idperiodo}`,
                config
            );
            console.log('editar diagnóstico', edit);
            if (edit.length != 0) {
                setExisteDiagnostico(true);
                setIdEditar(edit[0].id);
                setRelatorio(edit[0].relatorio);
            }
        } catch (error) {
            console.log('Erro buscar diagnóstico', error, error.response);
            toast.error('🦄 Erro buscar diagnóstico, volte e tente novamente!', {
                position: 'bottom-left',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
            // setloading(false);
        }
    }

    async function salvar(id, ideditar, existe) {
        //setloading(true);
        var token = localStorage.getItem('@token_diario_web');

        var config = {
            headers: { Authorization: 'bearer ' + token }
        };

        let form_data = new FormData();

        form_data.append('user_id', id);
        form_data.append('diario_id', iddodiario);
        form_data.append('periodo_id', idperiodo);
        form_data.append('relatorio', relatorio);

        if (!existe) {
            await api
                .post(`/relatorio-avaliacao`, form_data, {
                    headers: {
                        'content-type': 'application/json',
                        Authorization: 'bearer ' + token,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    console.log('Adicionando Diagnóstico', res.data);
                    handleCloseModal();
                    buscarRelatorio(iddodiario, idperiodo);
                    toast.success('🦄 Relatório cadastrado com sucesso!', {
                        position: 'bottom-left',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                })
                .catch((error) => {
                    console.log('error: ', error, error.response);
                    console.log('response: ', error.response.data.message);

                    toast.error(`🦄 Erro ao cadatrar Relatório, ${error.response.data.message}!`, {
                        position: 'bottom-left',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                    // setloading(false);
                    // throw error;
                });
        } else if (existe) {
            await api
                .post(`/relatorio-avaliacao/${ideditar}`, form_data, {
                    headers: {
                        'content-type': 'application/json',
                        Authorization: 'bearer ' + token,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    console.log('Diagnótico editado', res.data);
                    editar(res.data.user_id);
                    buscarRelatorio(iddodiario, idperiodo);
                    // setloading(false);
                    toast.success('🦄 Relatório editado com sucesso!', {
                        position: 'bottom-left',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                    editar(res.data.id);
                })
                .catch((error) => {
                    console.log('error: ', error, error.response);
                    toast.error(
                        '🦄 Erro ao editar Relatório, tente novamente ou fale com o suporte!',
                        {
                            position: 'bottom-left',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        }
                    );
                    // setloading(false);
                });
        }
    }

    async function deletar(id) {
        // setloading(true)
        var token = localStorage.getItem('@token_diario_web');

        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: prod } = await api.delete(`/relatorio-avaliacao/${id}`, config);
            console.log('prod', prod);
            // limpaCampos();
            // setloading(false);
            toast.success('🦄 Deletado com sucesso!', {
                position: 'bottom-left',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
            props.deletando();
        } catch (error) {
            console.log('Erro ao deletar', error, error.response);
            // setloading(false);
            toast.error('🦄 Erro ao tentar deletar, volte e tente novamente!', {
                position: 'bottom-left',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        }
    }

    function moedaBR(amount, decimalCount = 2, decimal = ',', thousands = '.') {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = amount < 0 ? '-' : '';

            let i = parseInt(
                (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
            ).toString();
            let j = i.length > 3 ? i.length % 3 : 0;

            return (
                negativeSign +
                (j ? i.substr(0, j) + thousands : '') +
                i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
                (decimalCount
                    ? decimal +
                      Math.abs(amount - i)
                          .toFixed(decimalCount)
                          .slice(2)
                    : '')
            );
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <Paper className={'paper'}>
            <Grid container spacing={3}>
                {periodos.map((item, key) => (
                    <Grid key={key} item xs={12} md={3}>
                        <Paper className={'paperSearch'} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Button 
                            style={{
                                width: '100%',
                                background: key === 0 ? 'rgb(6, 203, 29)' : key === 1 ? 'rgb(6, 121, 156)' : key === 2 ? 'rgb(95, 2, 210)' : key === 3 ? 'rgb(177, 1, 17)' : 'rgb(177, 1, 17)',
                                color: 'white'
                            }}
                            onClick={()=>{
                                rescreverPeriodo(item)
                            }}
                            >
                                {item.nome_periodo}
                            </Button>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 10 }}>
                <div></div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 5
                    }}>
                    <InsertCommentIcon style={{ color: 'green' }} />
                    <span style={{ marginLeft: 5 }}>Já foi cadastrado</span>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 5
                    }}>
                    <InsertCommentIcon style={{ color: 'red' }} />
                    <span style={{ marginLeft: 5 }}>Falta o cadastro</span>
                </div>
            </div>
            <TableContainer style={{ border: '1px solid #212121' }} className={classes.container}>
                <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, key) => (
                                <TableCell
                                    key={column.id}
                                    style={{
                                        width: key === 0 ? '80%' : '10%',
                                        textAlign: key != 0 ? 'center' : 'left',
                                        background: '#e8e8e8',
                                        borderRight: key <= 2 ? '1px solid #212121' : ''
                                    }}
                                    align={key != 0 ? 'right' : 'left'}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dados
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow
                                        className="tableRow"
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id}>
                                        <TableCell
                                            style={{ borderRight: '1px solid #212121' }}
                                            component="th"
                                            scope="row">
                                            {row.nome}
                                            <p style={{fontSize: 14, fontStyle: 'italic', background: '#f8f8f8', padding: 5, color: 'green'}}>{row.relatorio}</p>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            style={{
                                                textAlign: 'center',
                                                borderRight: '1px solid #212121'
                                            }}>
                                            {row.sexo}
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            style={{
                                                textAlign: 'center',
                                                borderRight: '1px solid #212121'
                                            }}>
                                            {format(new Date(row.nascimento), 'dd/MM/yyyy')}
                                        </TableCell>
                                        <TableCell align="right" style={{ textAlign: 'center' }}>
                                            <Tooltip
                                                title={
                                                    row.temRegistro
                                                        ? 'Edite o Diagnóstico cadastrado'
                                                        : 'Adicionar Diagnóstico inicial do aluno'
                                                }
                                                aria-label="add">
                                                <IconButton
                                                    onClick={() => {
                                                        handleOpenModal(row.idAluno, row.nome);
                                                    }}
                                                    color="inherit">
                                                    <InsertCommentIcon
                                                        style={{
                                                            color: row.temRegistro ? 'green' : 'red'
                                                        }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className="modal">
                <div className="modalContent">
                    <div className="modalContentHeader">
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            Diagnóstico inicial
                            <strong>{nomeAluno}</strong>
                        </div>
                        {/* <Tooltip title="Sair do cadastro" aria-label="add">
                            <IconButton
                                onClick={() => {
                                    handleCloseModal();
                                }}
                                color="inherit">
                                <CloseBtn style={{ color: 'red' }} />
                            </IconButton>þ
                        </Tooltip> */}
                    </div>
                    <div className="modalContentCenter">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    label="Aspectos observados"
                                    multiline
                                    rows={20}
                                    placeholder={`Digite aqui o seu diagnóstico inicial do aluno(a) ${nomeAluno}`}
                                    variant="outlined"
                                    value={relatorio}
                                    onChange={handleChangeRelatorio}
                                />
                            </Grid>
                            
                                <Grid item xs={12} md={12} lg={12}>
                                    {nivelAcesso === 'professor' && (
                                        <Tooltip
                                            title="Adicionar Diagnóstico inicial do aluno"
                                            aria-label="add">
                                            <Button
                                                onClick={() => {
                                                    salvar(idAluno, idEditar, existeDisgnostico);
                                                }}
                                                variant="contained"
                                                color="primary"
                                                style={{
                                                    margin: 5
                                                }}
                                            >
                                                {existeDisgnostico ? 'Editar' : 'Salvar'}
                                            </Button>
                                        </Tooltip>
                                    )}
                                    <Tooltip
                                        title="Sair"
                                        aria-label="add">
                                        <Button
                                            onClick={() => {
                                                handleCloseModal();
                                            }}
                                            variant="contained"
                                            style={{
                                                background: 'red',
                                                color: '#fff',
                                                margin: 5
                                            }}
                                        >
                                            Sair
                                        </Button>
                                    </Tooltip>
                                   
                                </Grid>
                            
                        </Grid>
                    </div>
                </div>
            </Modal>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Por página'}
            />
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </Paper>
    );
}