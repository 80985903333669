import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
// import TextField from '@material-ui/core/TextField';
/* eslint-disable */
import Skeleton from '@material-ui/lab/Skeleton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FadeLoader from "react-spinners/FadeLoader";
import api from '../../../services/api';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import Edit from '@material-ui/icons/Edit';
import Info from '@material-ui/icons/Info';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import { format, subYears, differenceInCalendarYears } from 'date-fns';
import {
  MuiPickersUtilsProvider, 
  KeyboardDatePicker,
} from '@material-ui/pickers';

import CpfCnpj from '@react-br-forms/cpf-cnpj-mask';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import {veryAuth} from '../../../auth';

export default function Index() {
    let history = useHistory();
    const location = useLocation();
    const [skeleton, seSkeleton] = useState(true);
    const [loading, setloading] = useState(false);

    //cadastro
    const [tipo, setTipo] = useState('');
    const [txtBtn, setTxtBtn] = useState('');
    const [idEditar, setIdEditar] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [sexo, setSexo] = useState('');
    const [dataNascimento, setDataNascimento] = useState();
    const [menorIdade, setMenorIdade] = useState('');
    const [cep, setCep] = useState('');
    const [pais, setPais] = useState('');
    const [estado, setEstado] = useState('');
    const [cidade, setCidade] = useState('');
    const [rua, setRua] = useState('');
    const [numero, setNumero] = useState('');
    const [bairro, setBairro] = useState('');
    const [telefone, setTelefone] = useState('');
    const [whatsapp, setWhatsApp] = useState('');
    const [password, setPassWord] = useState('');
    const [title, setTitle] = useState('');
    const [disabled, setDisabled] = useState(false);




    //responsavel
    const [nameResponsavel, setNameResponsavel] = useState('');
    const [emailResponsavel, setEmailResponsavel] = useState('');
    const [cpfCnpjResponsavel, setCpfCnpjResponsavel] = useState('');
    const [sexoResponsavel, setSexoResponsavel] = useState('');
    const [dataNascimentoResponsavel, setDataNascimentoResponsavel] = useState();
    const [cepResponsavel, setCepResponsavel] = useState('');
    const [paisResponsavel, setPaisResponsavel] = useState('');
    const [estadoResponsavel, setEstadoResponsavel] = useState('');
    const [cidadeResponsavel, setCidadeResponsavel] = useState('');
    const [ruaResponsavel, setRuaResponsavel] = useState('');
    const [numeroResponsavel, setNumeroResponsavel] = useState('');
    const [bairroResponsavel, setBairroResponsavel] = useState('');
    const [telefoneResponsavel, setTelefoneResponsavel] = useState('');
    const [whatsappResponsavel, setWhatsAppResponsavel] = useState('');
    const [parentesco, setParentesco] = useState('');

    const [stateRepetirEnd, setStateRepetirEnd] = React.useState({
      checkedA: true,
    });

    const tipoUsuario = 'aluno';
    const [liberaAcesso, setLiberaAcesso] = useState(false);
    const [roles, setRoles] = useState('');

    // const nomeUsuario = location.state.telasuser;

    useEffect(() => {
      verificarPermissao()
      nomearTitle()
      // console.log("location.state.", location.state, tipoUsuario)
      // Atualiza o titulo do documento usando a API do browser
      // console.log("um", location.pathname);
      // console.log("dois", location);
      if(location.state){
        setTipo(location.state.type)
        setTxtBtn(location.state.type === 'edit' ? 'Editar' : 'Salvar')
      }else{
        history.push('/')
      }
      if(location.state.type === 'edit'){
        editar(parseInt(location.state.id))
        // setTitle('Configurações do ' + tipoUsuario )
        setDisabled(true)
      }else{
        // setTitle('Cadastro de ' + tipoUsuario )
        setloading(false)
        gerarPassword();
        setDisabled(false)
      }
      setTimeout(function () {
          seSkeleton(false);
      }, 3000);
      
    }, []);

    function verificarPermissao(){
      veryAuth()
      .then((result) => {
        if(result.data.role === 'gestor'){
          setLiberaAcesso(true)
        }else{
          history.push({
            pathname: `/`,
          });
          return
        }
        setRoles(result.data.role)
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.response, error.message);
      });
    }

    function rota(link) {
      history.push({
          pathname: link,
          // search: '?query=abc',
          state: { 
            type: 'new',
            usuario: location.state.usuario, 
          }
      });
    }

    function nomearTitle(){
      setTitle('Aluno')
    }

    function handleChangeName(event){
      setName(event.target.value);
    }
    function handleChangeEmail(event){
      setEmail(event.target.value);
    }
    function handleChangeCpfCnpj(event){
      // setCpfCnpj(event.target.value);
      let cpfDifitado = event.target.value;
      // console.log('event.target', cpfDifitado.length);
      if (cpfDifitado.length <= 14) {
          // setCpf();
          setCpfCnpj(cpfDifitado);
      }
    }
    function handleChangeSexo(event){
      setSexo(event.target.value);
    }
    const handleChangeDataNascimento = (date) => {
      const result = differenceInCalendarYears(
        new Date(new Date()),
        new Date(date)
      )
      if(result <= 18){
        //é menor de idade
        setMenorIdade('Sim')
      }else{
        setMenorIdade('Não')
      }
      setDataNascimento(date);
    };
    function handleChangeMenorIdade(event){
      setMenorIdade(event.target.value);
    }
    function handleChangeCep(event){
      setCep(event.target.value);
    }
    function handleChangePais(event){
      setPais(event.target.value);
    }
    function handleChangeEstado(event){
      setEstado(event.target.value);
    }
    function handleChangeCidade(event){
      setCidade(event.target.value);
    }
    function handleChangeRua(event){
      setRua(event.target.value);
    }
    function handleChangeNumero(event){
      setNumero(event.target.value);
    }
    function handleChangeBairro(event){
      setBairro(event.target.value);
    }
    function handleChangeTelefone(event){
      setTelefone(event.target.value);
    }
    function handleChangeWhatsApp(event){
      setWhatsApp(event.target.value);
    }
    function handleChangePassword(event){
      setPassWord(event.target.value);
    }


    //responsável
    function handleChangeNameResponsavel(event){
      setNameResponsavel(event.target.value);
    }
    function handleChangeEmailResponsavel(event){
      setEmailResponsavel(event.target.value);
    }
    function handleChangeCpfCnpjResponsavel(event){
      // setCpfCnpjResponsavel(event.target.value);
      let cpfDifitado = event.target.value;
      console.log('event.target', cpfDifitado.length);
      if (cpfDifitado.length <= 14) {
        // setCpf();
        // setCpfCnpjResponsavel(event.target.value);
        setCpfCnpjResponsavel(cpfDifitado);
      }
    }
    function handleChangeSexoResponsavel(event){
      setSexoResponsavel(event.target.value);
    }
    const handleChangeDataNascimentoResponsavel = (date) => {
      setDataNascimentoResponsavel(date);
    };
    function handleChangeCepResponsavel(event){
      setCepResponsavel(event.target.value);
      if(stateRepetirEnd)
        setCep(event.target.value)
    }
    function handleChangePaisResponsavel(event){
      setPaisResponsavel(event.target.value);
      if(stateRepetirEnd)
        setPais(event.target.value)
    }
    function handleChangeEstadoResponsavel(event){
      setEstadoResponsavel(event.target.value);
      if(stateRepetirEnd)
        setEstado(event.target.value)
    }
    function handleChangeCidadeResponsavel(event){
      setCidadeResponsavel(event.target.value);
      if(stateRepetirEnd)
        setCidade(event.target.value)
    }
    function handleChangeRuaResponsavel(event){
      setRuaResponsavel(event.target.value);
      if(stateRepetirEnd)
        setRua(event.target.value)
    }
    function handleChangeNumeroResponsavel(event){
      setNumeroResponsavel(event.target.value);
      if(stateRepetirEnd)
        setNumero(event.target.value)
    }
    function handleChangeBairroResponsavel(event){
      setBairroResponsavel(event.target.value);
      if(stateRepetirEnd)
        setBairro(event.target.value)
    }
    function handleChangeTelefoneResponsavel(event){
      setTelefoneResponsavel(event.target.value);
      if(stateRepetirEnd)
        setTelefone(event.target.value)
    }
    function handleChangeWhatsAppResponsavel(event){
      setWhatsAppResponsavel(event.target.value);
      if(stateRepetirEnd)
        setWhatsApp(event.target.value)
    }
    function handleChangeParentesco(event){
      setParentesco(event.target.value);
    }

    const handleChangeRepetirEnd = (event) => {
      setStateRepetirEnd({ ...stateRepetirEnd, [event.target.name]: event.target.checked });
    };
  
    // fim responsavel

    function habilitarEdit(){
      setDisabled(false)
    }


    async function deletar() {
      console.log("id", idEditar)
      setloading(true)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: prod} = await api.delete(`/usuario/${idEditar}`, config);
        console.log("prod", prod)
        // limpaCampos();
        setloading(false);
        toast.success('🦄 Aluno deletada com sucesso!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        rota()
        // history.push('/clientes')
      } catch (error) {
        console.log("Erro ao deletar", error, error.response);
        setloading(false);
        // setOpenAlert(true)
        // setTypeAlert('error')
        // setMsgAlert('Erro ao deletar, tente novamente')
        toast.error('🦄 Erro ao deletar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    async function editar(id){
      setIdEditar(id)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      // console.log("aqui", id)
      try {
        const {data: editar} = await api.get(`/usuario/${id}`, config);
        console.log("edit", editar)
        var edit = editar[0]
        setloading(false);
        setName(edit.name)
        setEmail(edit.email)
        setCpfCnpj(edit.cpf)
        setSexo(edit.sexo)
        setDataNascimento(edit.data_nascimento)
        setMenorIdade(edit.menor_idade)
        setCep(edit.cep)
        setPais(edit.pais)
        setEstado(edit.estado)
        setCidade(edit.cidade)
        setRua(edit.rua)
        setNumero(edit.numero)
        setBairro(edit.bairro)
        setTelefone(edit.telefone)
        setWhatsApp(edit.whatsapp)
        // console.log("edit.professorFormacao", edit.professorFormacao, edit.responsavel.length)
        if(edit.responsavel.length != 0){
          setNameResponsavel(edit.responsavel[0].name)
          setEmailResponsavel(edit.responsavel[0].email)
          setCpfCnpjResponsavel(edit.responsavel[0].cpf)
          setSexoResponsavel(edit.responsavel[0].sexo)
          setDataNascimentoResponsavel(edit.responsavel[0].data_nascimento)
          setCepResponsavel(edit.responsavel[0].cep)
          setPaisResponsavel(edit.responsavel[0].pais)
          setEstadoResponsavel(edit.responsavel[0].estado)
          setCidadeResponsavel(edit.responsavel[0].cidade)
          setRuaResponsavel(edit.responsavel[0].rua)
          setNumeroResponsavel(edit.responsavel[0].numero)
          setBairroResponsavel(edit.responsavel[0].bairro)
          setTelefoneResponsavel(edit.responsavel[0].telefone)
          setWhatsAppResponsavel(edit.responsavel[0].celular_whatsapp)
          setParentesco(edit.responsavel[0].parentesco)
        }
        // setPassWord(edit.password)
  
        // toast.info('🦄 Buscando dados para editar', {
        //   position: "bottom-left",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
      } catch (error) {
        console.log("Erro buscar dados para editar", error, error.response);
        toast.error('🦄 Erro ao buscar dados para editar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setloading(false);
      }
    }

    async function salvarResponsavel(tp, idaluno) {

      var token = localStorage.getItem('@token_diario_web');
  
      let form_data = new FormData();
  
      form_data.append("user_id", idaluno);
      form_data.append("name", nameResponsavel);
      form_data.append("email", emailResponsavel);
      form_data.append("parentesco", parentesco);
      form_data.append("sexo", sexoResponsavel);
      form_data.append("data_nascimento", dataNascimentoResponsavel);
      form_data.append("cpf", cpfCnpjResponsavel);
      form_data.append("cep", cepResponsavel);
      form_data.append("pais", paisResponsavel);
      form_data.append("estado", estadoResponsavel);
      form_data.append("cidade", cidadeResponsavel);
      form_data.append("rua", ruaResponsavel);
      form_data.append("numero", numeroResponsavel);
      form_data.append("bairro", bairroResponsavel);
      form_data.append("telefone", telefoneResponsavel);
      form_data.append("whatsapp", whatsappResponsavel);
  
      if(tp === 'new'){
        await api
          .post(`/responsavel`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Adicionando responsavel", res.data)
              limpaCampos();
              setloading(false);
              toast.success('🦄 Responsável cadastrado com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            console.log("response: ", error.response.data.message)
            toast.error(`🦄 Erro ao cadatrar responsável, ${error.response.data.message}!`, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setloading(false);
            // throw error;
          });
  
      }else if(tp === 'edit'){
        await api
          .post(`/responsavel/${idaluno}`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Responsável editado", res.data)
              setloading(false);
              // toast.success('🦄 Responsável editado com sucesso!', {
              //   position: "bottom-left",
              //   autoClose: 5000,
              //   hideProgressBar: false,
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   draggable: true,
              //   progress: undefined,
              // });
              // editar(res.data.id)
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            toast.error('🦄 Erro ao editar responsável, tente novamente ou fale com o suporte!', {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setloading(false);
          });
  
      }
  
    }

    async function salvar(event) {
      event.preventDefault();
      // console.log("event", event)
      setloading(true)
  
      //setloading(true);
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
  
      let form_data = new FormData();
  
      form_data.append("name", name ? name : '');
      form_data.append("email", email ? email : '');
      form_data.append("password", password);
      form_data.append("sexo", sexo);
      form_data.append("data_nascimento", dataNascimento);
      form_data.append("menor_idade", menorIdade);
      form_data.append("tipo_conta", 'aluno');
      form_data.append("cpf", cpfCnpj ? cpfCnpj : '');
      form_data.append("cep", cep ? cep : '');
      form_data.append("pais", pais ? pais : '');
      form_data.append("estado", estado ? estado : '');
      form_data.append("cidade", cidade ? cidade : '');
      form_data.append("rua", rua ? rua : '');
      form_data.append("numero", numero ? numero : '');
      form_data.append("bairro", bairro ? bairro : '');
      form_data.append("telefone", telefone ? telefone : '');
      form_data.append("whatsapp", whatsapp ? whatsapp : '');
      form_data.append("status", 1);
  
      if(tipo === 'new'){
        await api
          .post(`/usuario`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Adicionando usuario", res.data)
              if(tipoUsuario === 'aluno' && menorIdade === 'Sim'){
                salvarResponsavel('new', res.data.id)
              }
              if(menorIdade === 'Não'){
                limpaCampos()
              }
              // limpaCampos();
              setloading(false);
              toast.success('🦄 Aluno cadastrado com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            console.log("response: ", error.response.data.message)
            toast.error(`🦄 Erro ao cadatrar Aluno, ${error.response.data.message}!`, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setloading(false);
            // throw error;
          });
  
      }else if(tipo === 'edit'){
        await api
          .post(`/usuario/${idEditar}`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Aluno editado", res.data)
              if(tipoUsuario === 'professor'){
                salvarFormacaoProfissional(res.data.id, 'edit')
              }
              if(tipoUsuario === 'aluno'){
                salvarResponsavel('edit', res.data.id)
              }
              setloading(false);
              toast.success('🦄 Aluno editado com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              editar(res.data.id)
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            toast.error('🦄 Erro ao editar Aluno, tente novamente ou fale com o suporte!', {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setloading(false);
          });
  
      }
  
    }

    function limpaCampos(){
      //cadastro
      setTipo('')
      setTxtBtn('')
      setIdEditar('')
      setName('')
      setEmail('')
      setCpfCnpj('')
      setSexo('')
      setDataNascimento('')
      setMenorIdade('')
      setCep('')
      setPais('')
      setEstado('')
      setCidade('')
      setRua('')
      setNumero('')
      setBairro('')
      setTelefone('')
      setWhatsApp('')
      setPassWord('')
      setTitle('')
      setDisabled('')

      //responsavel
      setNameResponsavel('')
      setEmailResponsavel('')
      setCpfCnpjResponsavel('')
      setSexoResponsavel('')
      setDataNascimentoResponsavel('')
      setCepResponsavel('')
      setPaisResponsavel('')
      setEstadoResponsavel('')
      setCidadeResponsavel('')
      setRuaResponsavel('')
      setNumeroResponsavel('')
      setBairroResponsavel('')
      setTelefoneResponsavel('')
      setWhatsAppResponsavel('')
      setParentesco('')

      setTipo('new')
      setTxtBtn('Salvar')
    }


    //gerar senha aleatório
    function gerarPassword() {
      setPassWord(Math.random().toString(36).slice(-10))
    }

    //validações
    const validation = [];
    // validation.push(email && email.includes("@") && email.includes(".com"));
    // validation.push(cpfCnpj && cpfCnpj.length >= 11);
    validation.push(name && cpfCnpj && sexo && dataNascimento);
    if(!idEditar){
      validation.push(password && password.length >= 6);
    }
    if(menorIdade === 'Sim'){
      validation.push(sexoResponsavel && cpfCnpjResponsavel && nameResponsavel && parentesco)
    }

    // if (tipo) {
    //   validation.push(nome && nome.trim());
    //   validation.push(repetirSenha);
    //   validation.push(senha === repetirSenha);
    // }

    const validForm = validation.reduce((all, v) => all && v);

  

    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link className="linkBreadcrumbs" color="inherit" href="/">
                                    Dashboard
                                </Link>
                                <Link className="linkBreadcrumbs" color="inherit" onClick={() => {rota('alunos-gestor')}}>
                                    Aluno
                                </Link>
                                <Typography variant="body2" color="textPrimary">
                                    { location.state.type === 'edit' ? 'Editar ' + title : 'Cadastrar ' + title}
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>{ location.state.type === 'edit' ? 'Editar ' + title : 'Cadastrar ' + title}</h2>
                            </div>
                        </Grid>
                        {tipo === 'edit' && (
                            <Grid item xs={12} md={12} lg={12}>
                                {skeleton && <Skeleton variant="rect" className="paper" />}
                                {!skeleton && (
                                  <Paper className={'paper'}>
                                    <div className="itemConfig">
                                      <div onClick={habilitarEdit} className="itemConfigDois">
                                        <Edit style={{ fontSize: 40, color: 'rgb(63, 1, 121)' }} />
                                        <div>Editar perfil</div>
                                      </div>
                                      <div 
                                        onClick={() => {
                                          history.push({
                                            pathname: '/info-aluno-gestor',
                                            // search: '?query=abc',
                                            state: { 
                                              usuario: location.state.usuario, 
                                              idaluno: parseInt(location.state.id),
                                              nomeAluno: location.state.nome
                                            }
                                          });
                                        }} 
                                        className="itemConfigDois"
                                      >
                                        <Info style={{ fontSize: 40, color: 'rgb(63, 1, 121)' }} />
                                        <div>Informações</div>
                                      </div>
                                    </div>
                                  </Paper>
                                )}
                            </Grid>
                        )}
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                    <div className="paperContant">
                                        <form noValidate autoComplete="off" onSubmit={salvar}>
                                          <Grid container spacing={3}>
                                            <Grid item xs={12} md={12}>
                                              <span>Campos obrigatórios *</span>
                                              <p>
                                              <span>OBS: Um CPF é exigido para login do aluno ou responsável. Caso o aluno não possui CPF, utilize do responsável</span>
                                              </p>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="name">Nome do Aluno *</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    error={!name ? true : false}
                                                    id="name"
                                                    label={'Nome do Aluno *'}
                                                    value={name ? name : ''}
                                                    onChange={handleChangeName}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="email">Email</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    id="email"
                                                    label={'Email'}
                                                    value={email ? email : ''}
                                                    onChange={handleChangeEmail}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                {/* <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="cpgcnpj">CPF *</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    error={!cpfCnpj ? true : false}
                                                    id="cpgcnpj"
                                                    label={'CPF *'}
                                                    value={cpfCnpj ? cpfCnpj : cpfCnpj}
                                                    onChange={handleChangeCpfCnpj}
                                                    labelWidth={60}
                                                  />
                                                </FormControl> */}
                                                <FormControl fullWidth className="formControl" variant="outlined">
                                                  <CpfCnpj
                                                    disabled={disabled}
                                                    id="cpgcnpj"
                                                    label={'CPF *'}
                                                    className="customizedInput"
                                                    placeholder="Digite um cpf válido *"
                                                    type="tel"
                                                    value={cpfCnpj}
                                                    onChange={handleChangeCpfCnpj}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                              <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="select-polo">Sexo *</InputLabel>
                                                <Select
                                                  disabled={disabled}
                                                  labelId="select-sexo"
                                                  id="select-sexo"
                                                  value={sexo}
                                                  onChange={handleChangeSexo}
                                                  label="Sexo *"
                                                >
                                                  <MenuItem value={'M'}>M</MenuItem>
                                                  <MenuItem value={'F'}>F</MenuItem>
                                                </Select>
                                              </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    style={{marginBottom: 0, marginTop: 0}}
                                                    disabled={disabled}
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    id="date-picker-dialog-nascimento"
                                                    label="Data Nascimento *"
                                                    format="dd/MM/yyyy"
                                                    value={dataNascimento}
                                                    onChange={handleChangeDataNascimento}
                                                    KeyboardButtonProps={{
                                                      'aria-label': 'change date',
                                                    }}
                                                />
                                              </MuiPickersUtilsProvider>
                                            </Grid>
                                            {tipoUsuario === 'aluno' && (
                                              <Grid item xs={12} md={2}>
                                                <FormControl variant="outlined" fullWidth>
                                                  <InputLabel id="select-polo">Menor de idade *</InputLabel>
                                                  <Select
                                                    disabled={disabled}
                                                    labelId="select-menoridade"
                                                    id="select-menoridade"
                                                    value={menorIdade}
                                                    onChange={handleChangeMenorIdade}
                                                    label="Menor de idade *"
                                                  >
                                                    <MenuItem value={'Sim'}><em>Sim</em></MenuItem>
                                                    <MenuItem value={'Não'}>Não</MenuItem>
                                                  </Select>
                                                </FormControl>
                                              </Grid>
                                            )}
                                            {menorIdade === 'Sim' && (
                                              <Grid style={{background: '#eafff6', margin: 10, borderRadius: 5}} container spacing={3}>
                                                <Grid item xs={12} md={12}>
                                                  <div>
                                                    <h2>Dados do Responsável</h2>
                                                    <FormGroup row>
                                                      <FormControlLabel
                                                        control={
                                                          <Switch
                                                            checked={stateRepetirEnd.checkedA}
                                                            onChange={handleChangeRepetirEnd}
                                                            name="checkedB"
                                                            color="primary"
                                                          />
                                                        }
                                                        label="O endereço do responsável é o mesmo do aluno"
                                                      />
                                                    </FormGroup>
                                                  </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl fullWidth variant="outlined">
                                                      <InputLabel htmlFor="nameResponsavel">Nome do cliente *</InputLabel>
                                                      <OutlinedInput
                                                        disabled={disabled}
                                                        error={!nameResponsavel ? true : false}
                                                        id="nameResponsavel"
                                                        label={'Nome do cliente *'}
                                                        value={nameResponsavel}
                                                        onChange={handleChangeNameResponsavel}
                                                        labelWidth={60}
                                                      />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl fullWidth variant="outlined">
                                                      <InputLabel htmlFor="emailResponsavel">Email</InputLabel>
                                                      <OutlinedInput
                                                        disabled={disabled}
                                                        id="emailResponsavel"
                                                        label={'Email'}
                                                        value={emailResponsavel}
                                                        onChange={handleChangeEmailResponsavel}
                                                        labelWidth={60}
                                                      />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    {/* <FormControl fullWidth variant="outlined">
                                                      <InputLabel htmlFor="cpgcnpjResponsavel">CPF *</InputLabel>
                                                      <OutlinedInput
                                                        disabled={disabled}
                                                        error={!cpfCnpjResponsavel ? true : false}
                                                        id="cpgcnpjResponsavel"
                                                        label={'CPF *'}
                                                        value={cpfCnpjResponsavel ? cpfCnpjResponsavel : ''}
                                                        onChange={handleChangeCpfCnpjResponsavel}
                                                        // labelWidth={60}
                                                      />
                                                    </FormControl> */}
                                                    <FormControl fullWidth className="formControl" variant="outlined">
                                                      <CpfCnpj
                                                        disabled={disabled}
                                                        id="cpgcnpjResponsavel"
                                                        label={'CPF *'}
                                                        className="customizedInput"
                                                        placeholder="Digite um cpf válido *"
                                                        type="tel"
                                                        value={cpfCnpjResponsavel}
                                                        onChange={handleChangeCpfCnpjResponsavel}
                                                      />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                  <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="select-sexo-Responsavel">Sexo *</InputLabel>
                                                    <Select
                                                      disabled={disabled}
                                                      labelId="select-sexo-Responsavel"
                                                      id="select-sexo-Responsavel"
                                                      value={sexoResponsavel}
                                                      onChange={handleChangeSexoResponsavel}
                                                      label="Sexo *"
                                                    >
                                                      <MenuItem value={'M'}>M</MenuItem>
                                                      <MenuItem value={'F'}>F</MenuItem>
                                                    </Select>
                                                  </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                      disabled={disabled}
                                                      style={{marginBottom: 0, marginTop: 0}}
                                                      fullWidth
                                                      variant="outlined"
                                                      margin="normal"
                                                      id="date-picker-dialog-nascimento-Responsavel"
                                                      label="Data Nascimento *"
                                                      format="dd/MM/yyyy"
                                                      value={dataNascimentoResponsavel}
                                                      onChange={handleChangeDataNascimentoResponsavel}
                                                      KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                      }}
                                                    />
                                                  </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                  <FormControl fullWidth variant="outlined">
                                                    <InputLabel htmlFor="cepResponsavel">CEP *</InputLabel>
                                                    <OutlinedInput
                                                      disabled={disabled}
                                                      id="cepResponsavel"
                                                      label={'CEP *'}
                                                      value={cepResponsavel}
                                                      onChange={handleChangeCepResponsavel}
                                                      labelWidth={60}
                                                    />
                                                  </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <FormControl fullWidth variant="outlined">
                                                      <InputLabel htmlFor="paisResponsavel">País *</InputLabel>
                                                      <OutlinedInput
                                                        disabled={disabled}
                                                        id="paisResponsavel"
                                                        label={'País *'}
                                                        value={paisResponsavel}
                                                        onChange={handleChangePaisResponsavel}
                                                        labelWidth={60}
                                                      />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <FormControl fullWidth variant="outlined">
                                                      <InputLabel htmlFor="outlined-adornment-amount">Estado *</InputLabel>
                                                      <OutlinedInput
                                                        disabled={disabled}
                                                        id="outlined-adornment-amount"
                                                        label={'Estado *'}
                                                        value={estadoResponsavel}
                                                        onChange={handleChangeEstadoResponsavel}
                                                        labelWidth={60}
                                                      />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <FormControl fullWidth variant="outlined">
                                                      <InputLabel htmlFor="cidadeResponsavel">Cidade *</InputLabel>
                                                      <OutlinedInput
                                                        disabled={disabled}
                                                        id="cidadeResponsavel"
                                                        label={'Cidade *'}
                                                        value={cidadeResponsavel}
                                                        onChange={handleChangeCidadeResponsavel}
                                                        labelWidth={60}
                                                      />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl fullWidth variant="outlined">
                                                      <InputLabel htmlFor="ruaResponsavel">Rua *</InputLabel>
                                                      <OutlinedInput
                                                        disabled={disabled}
                                                        id="ruaResponsavel"
                                                        label={'Rua *'}
                                                        value={ruaResponsavel}
                                                        onChange={handleChangeRuaResponsavel}
                                                        labelWidth={60}
                                                      />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <FormControl fullWidth variant="outlined">
                                                      <InputLabel htmlFor="numeroResponsavel">Número *</InputLabel>
                                                      <OutlinedInput
                                                        disabled={disabled}
                                                        id="numeroResponsavel"
                                                        label={'Número *'}
                                                        value={numeroResponsavel}
                                                        onChange={handleChangeNumeroResponsavel}
                                                        labelWidth={60}
                                                      />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <FormControl fullWidth variant="outlined">
                                                      <InputLabel htmlFor="bairroResponsavel">Bairro *</InputLabel>
                                                      <OutlinedInput
                                                        disabled={disabled}
                                                        id="bairroResponsavel"
                                                        label={'Bairro *'}
                                                        value={bairroResponsavel}
                                                        onChange={handleChangeBairroResponsavel}
                                                        labelWidth={60}
                                                      />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl fullWidth variant="outlined">
                                                      <InputLabel htmlFor="telefoneResponsavel">Telefone</InputLabel>
                                                      <OutlinedInput
                                                        disabled={disabled}
                                                        id="telefoneResponsavel"
                                                        label={'Telefone'}
                                                        value={telefoneResponsavel}
                                                        onChange={handleChangeTelefoneResponsavel}
                                                        labelWidth={60}
                                                      />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl fullWidth variant="outlined">
                                                      <InputLabel htmlFor="whatsappResponsavel">WhatsApp</InputLabel>
                                                      <OutlinedInput
                                                        disabled={disabled}
                                                        id="whatsappResponsavel"
                                                        label={'WhatsApp'}
                                                        value={whatsappResponsavel}
                                                        onChange={handleChangeWhatsAppResponsavel}
                                                        labelWidth={60}
                                                      />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl fullWidth variant="outlined">
                                                      <InputLabel htmlFor="parentenco">Grau de parentesco *</InputLabel>
                                                      <OutlinedInput
                                                        disabled={disabled}
                                                        id="parentenco"
                                                        label={'Grau de parentesco *'}
                                                        value={parentesco}
                                                        onChange={handleChangeParentesco}
                                                        labelWidth={60}
                                                        placeholder={'Pai, Mãe, Tio, Tia, Avô, Avó'}
                                                      />
                                                    </FormControl>
                                                </Grid>
                                              </Grid>
                                            )}
                                            <Grid item xs={12} md={3}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="cep">CEP *</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    id="cep"
                                                    label={'CEP *'}
                                                    value={cep ? cep : ''}
                                                    onChange={handleChangeCep}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="pais">País *</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    id="pais"
                                                    label={'País *'}
                                                    value={pais ? pais : ''}
                                                    onChange={handleChangePais}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="outlined-adornment-amount">Estado *</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    id="outlined-adornment-amount"
                                                    label={'Estado *'}
                                                    value={estado ? estado : ''}
                                                    onChange={handleChangeEstado}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="cidade">Cidade *</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    id="cidade"
                                                    label={'Cidade *'}
                                                    value={cidade ? cidade : ''}
                                                    onChange={handleChangeCidade}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="rua">Rua *</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    id="rua"
                                                    label={'Rua *'}
                                                    value={rua ? rua : ''}
                                                    onChange={handleChangeRua}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="numero">Número *</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    id="numero"
                                                    label={'Número *'}
                                                    value={numero ? numero : ''}
                                                    onChange={handleChangeNumero}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="bairro">Bairro *</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    id="bairro"
                                                    label={'Bairro *'}
                                                    value={bairro ? bairro : ''}
                                                    onChange={handleChangeBairro}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="telefone">Telefone *</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    id="telefone"
                                                    label={'Telefone *'}
                                                    value={telefone ? telefone : ''}
                                                    onChange={handleChangeTelefone}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="whatsapp">WhatsApp</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    id="whatsapp"
                                                    label={'WhatsApp'}
                                                    value={whatsapp ? whatsapp : ''}
                                                    onChange={handleChangeWhatsApp}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="password">Senha</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    error={!password ? true : false}
                                                    id="password"
                                                    // label={'Password'}
                                                    value={password ? password : ''}
                                                    onChange={handleChangePassword}
                                                    labelWidth={60}
                                                    // type="password"
                                                    // placeholder="*******"
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Button
                                                  disabled={!validForm}
                                                  style={{maxWidth: 150, height: 50, marginRight: 10}}
                                                  type="submit" variant="outlined" color="primary"
                                                >
                                                  {txtBtn}
                                                </Button>
                                                {tipo === 'edit' &&(
                                                  <Button
                                                    disabled={!validForm}
                                                    style={{maxWidth: 150, height: 50}}
                                                    variant="outlined" color="secondary"
                                                    onClick={deletar}
                                                  >
                                                    Deletar
                                                  </Button>
                                                )}
                                            </Grid>
                                          </Grid>

                                        </form>
                                        <ToastContainer
                                          position="top-right"
                                          autoClose={5000}
                                          hideProgressBar={false}
                                          newestOnTop={false}
                                          closeOnClick
                                          rtl={false}
                                          pauseOnFocusLoss
                                          draggable
                                          pauseOnHover
                                        />
                                    </div>
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
            {loading && (
                <div className="loading">
                    <FadeLoader color={'rgb(63, 1, 121)'} loading={loading} size={150} />
                </div>
            )}
        </div>
    );
}
