import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
/* eslint-disable */
import Skeleton from '@material-ui/lab/Skeleton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FadeLoader from "react-spinners/FadeLoader";
import api from '../../../services/api';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import Edit from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {format, differenceInBusinessDays} from 'date-fns';

export default function Index() {
    let history = useHistory();
    const location = useLocation();
    const [skeleton, seSkeleton] = useState(true);
    const [loading, setloading] = useState(false);

    //cadastro
    const [tipo, setTipo] = useState('');
    const [txtBtn, setTxtBtn] = useState('');
    const [idEditar, setIdEditar] = useState('');
    const [name, setName] = useState('');
    const [ano, setAno] = useState();
    const [inicio, setInicio] = useState('');
    const [fim, setFim] = useState('');
    const [aulasPrevistas, setAulasPrevistas] = useState(0);
    const [status, setStatus] = useState('Ativo');
    const [title, setTitle] = useState('Cadastrar Períodos');

    useEffect(() => {
        // Atualiza o titulo do documento usando a API do browser
        console.log("um", location.pathname);
        console.log("dois", location);
        console.log("ano", ano);
        setAno(format(new Date(), 'yyyy'))
        if(location.state){
          setTipo(location.state.type)
          setTxtBtn(location.state.type === 'edit' ? 'Editar' : 'Salvar')
        }else{
          history.push('/')
        }
        if(location.state.type === 'edit'){
          editar(parseInt(location.state.id))
          setTitle('Configurações do período')
        }else{
          setloading(false)
        }
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function abrirPageServicos(){
      console.log("location.state.id", location.state.id, location.state.nome, location.state.email)
      history.push({
          pathname: '/listar-servicos-cliente',
          // search: '?query=abc',
          state: { id: location.state.id, nome: location.state.nome , email: location.state.email }
      });
    }

    function handleChangeName(event){
      setName(event.target.value);
    }
    function handleChangeAno(event){
      setAno(event.target.value);
    }
    function handleChangeInicio(event){
      setInicio(event.target.value);
    }
    function handleChangeFim(event){
      setFim(event.target.value);
      diasUteis(inicio, event.target.value)
    }
    function handleChangeAulas(event){
      setAulasPrevistas(event.target.value);
    }

    function diasUteis(inicio, fim){
      var result = differenceInBusinessDays(
        new Date(fim),
        new Date(inicio)
      )
      setAulasPrevistas(result)
      console.log("resul dias uteis", result)
    }

    async function deletar() {
      console.log("id", idEditar)
      setloading(true)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: prod} = await api.delete(`/cliente/${idEditar}`, config);
        console.log("prod", prod)
        // limpaCampos();
        setloading(false);
        toast.success('🦄 Período deletado com sucesso!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push('/polos')
      } catch (error) {
        console.log("Erro ao deletar", error, error.response);
        setloading(false);
        // setOpenAlert(true)
        // setTypeAlert('error')
        // setMsgAlert('Erro ao deletar, tente novamente')
        toast.error('🦄 Erro ao deletar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    async function editar(id){
      setIdEditar(id)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: edit} = await api.get(`/periodo/${id}`, config);
        console.log("Dados para editar", edit)
        setloading(false);
        setName(edit.nome_periodo)
        setAno(edit.ano_periodo)
        setInicio(format(new Date(edit.inicio), 'yyyy-MM-dd'))
        setFim(format(new Date(edit.fim), 'yyyy-MM-dd'))
        setAulasPrevistas(edit.aulas_previstas)
      } catch (error) {
        console.log("Erro buscar dados para editar", error, error.response);
        toast.error('🦄 Erro ao buscar dados para editar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setloading(false);
      }
    }

    async function salvar(event) {
      event.preventDefault();
      // console.log("event", event)
      setloading(true)
  
      //setloading(true);
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
  
      let form_data = new FormData();
  
      form_data.append("nome_periodo", name);
      form_data.append("ano_periodo", ano);
      form_data.append("inicio", inicio);
      form_data.append("fim", fim);
      form_data.append("aulas_previstas", aulasPrevistas);
      
      if(tipo === 'new'){
        await api
          .post(`/periodo`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Adicionando período", res.data)
              limpaCampos();
              setloading(false);
              toast.success('🦄 Período cadastrado com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            console.log("response: ", error.response.data.message)
            toast.error(`🦄 Erro ao cadatrar Período, ${error.response.data.message}!`, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setloading(false);
            // throw error;
          });
  
      }else if(tipo === 'edit'){
  
        await api
          .post(`/periodo/${idEditar}`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Príodo editado", res.data)
              setloading(false);
              toast.success('🦄 Período editado com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              editar(res.data.id)
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            toast.error('🦄 Erro ao editar Período, tente novamente ou fale com o suporte!', {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setloading(false);
          });
  
      }
  
    }

    function limpaCampos(){
      setName('')
      setInicio('')
      setFim('')
      setAulasPrevistas('')
    }

    //validações
    const validation = [];
    validation.push(name && ano && inicio && fim && aulasPrevistas);


    const validForm = validation.reduce((all, v) => all && v);

  

    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link className="linkBreadcrumbs" color="inherit" href="/">
                                    Dashboard
                                </Link>
                                <Link className="linkBreadcrumbs" color="inherit" href="/periodos/">
                                    Periodos
                                </Link>
                                <Typography variant="body2" color="textPrimary">
                                    {title}
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>{title}</h2>
                            </div>
                        </Grid>
              
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                    <div className="paperContant">
                                        <form noValidate autoComplete="off" onSubmit={salvar}>
                                          <Grid container spacing={3}>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="name">Nome do Período</InputLabel>
                                                  <OutlinedInput
                                                    autoComplete="fperido"
                                                    name="periodo"
                                                    error={!name ? true : false}
                                                    id="periodo"
                                                    label={'Nome do Período'}
                                                    value={name}
                                                    onChange={handleChangeName}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="name">Ano letivo</InputLabel>
                                                  <OutlinedInput
                                                    autoComplete="fano"
                                                    name="ano"
                                                    id="name"
                                                    label={'Ano letivo'}
                                                    value={ano}
                                                    onChange={handleChangeAno}
                                                    labelWidth={60}
                                                    type='number'
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                              <TextField
                                                fullWidth
                                                id="date"
                                                label="Data início"
                                                type="date"
                                                value={inicio}
                                                onChange={handleChangeInicio}
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                              <TextField
                                                fullWidth
                                                id="date"
                                                label="Data fim"
                                                type="date"
                                                value={fim}
                                                onChange={handleChangeFim}
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="name">Aulas previstas</InputLabel>
                                                  <OutlinedInput
                                                    // error={ano}
                                                    // id="name"
                                                    autoComplete="faulas"
                                                    name="aulas"
                                                    label={'Aulas previstas'}
                                                    value={aulasPrevistas}
                                                    onChange={handleChangeAulas}
                                                    labelWidth={60}
                                                    type='number'
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Button
                                                  disabled={!validForm}
                                                  style={{maxWidth: 150, height: 50, marginRight: 10}}
                                                  type="submit" variant="outlined" color="primary"
                                                >
                                                  {txtBtn}
                                                </Button>
                                                {tipo === 'edit' &&(
                                                  <Button
                                                    disabled={!validForm}
                                                    style={{maxWidth: 150, height: 50}}
                                                    variant="outlined" color="secondary"
                                                    onClick={deletar}
                                                  >
                                                    Deletar
                                                  </Button>
                                                ) }
                                            </Grid>
                                          </Grid>

                                        </form>
                                        <ToastContainer
                                          position="top-right"
                                          autoClose={5000}
                                          hideProgressBar={false}
                                          newestOnTop={false}
                                          closeOnClick
                                          rtl={false}
                                          pauseOnFocusLoss
                                          draggable
                                          pauseOnHover
                                        />
                                    </div>
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
            {loading && (
                <div className="loading">
                    <FadeLoader color={'rgb(63, 1, 121)'} loading={loading} size={150} />
                </div>
            )}
        </div>
    );
}
