import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
/* eslint-disable */
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableUsuarios from '../../../Components/Tables/usuariosFaltosos';
import { useLocation } from 'react-router-dom';
import {veryAuth} from '../../../auth';

export default function Index() {
    let history = useHistory();
    const location = useLocation();
    console.log("location.state", location.state)
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);
    const [title, setTitle] = useState('Alunos faltosos');
    const alunos = location.state ? location.state.usuario : ''
    const [liberaAcesso, setLiberaAcesso] = useState(false);
    const [roles, setRoles] = useState('');
   
    useEffect(() => {
      verificarPermissao()
        // Atualiza o titulo do documento usando a API do browser
        // usuario()
        // nomearTitle()
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function verificarPermissao(){
      veryAuth()
      .then((result) => {
        console.log('result verificarPermissao', result);
        console.log('location.state.telasuser[0]', location.state);
        // setPermissaoTelas(location.state.telasuser[0])
        if(result.data.role === 'admin'){
          setLiberaAcesso(true)
          alunosFaltosos()
        }else{
          history.push({
            pathname: `/`,
          });
          return
        }
        setRoles(result.data.role)
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.response, error.message);
      });
    }

    
    
    async function alunosFaltosos(){
      var token = localStorage.getItem('@token_diario_web');
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data : result} = await api.get(`alerta-alunos-falta/2`, config);
        console.log("Result de alunos faltosos", result)
        setData(result)
        toast.success('🦄 Carregando alunos' , {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro ao carregar escolas", error, error.response);
        toast.error('🦄 Error ao carregar escolas!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    
    function handleChangePesquisar(event){
      if(event.target.value){
        pesquisarCliente(event.target.value)
      }else{
        alunosFaltosos()
      }
      // setPesquisar(event.target.value)
    }

    async function pesquisarCliente(termo) {
      //setLoading(true);
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
  
  
      try {
        const {data : result} = await api.get(`pesquisar-usuario/${encodeURI(termo)}`, config);
        console.log("Result pesquisar", result)
        setData(result)
      } catch (error) {
        console.log("Erro ao buscar ", error, error.response);
      }
    }
  

    //tabela
    function createData(id, name, cadastro, email, telefone) {
      return {id, name, cadastro, email, telefone};
    }
  
    var rows = [];
    if(data){
      rows = data.map((row) => {
        return createData(row.id, row.name, row.created_at, row.email, row.telefone)
      })
    }
  
    const columns = [
      { id: 'name', numeric: false, disablePadding: true, label: 'Nome' },
      { id: 'cadastro', numeric: true, disablePadding: false, label: 'Cadastro' },
      { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
      { id: 'telefone', numeric: true, disablePadding: false, label: 'Telefone' },
      { id: 'edit', numeric: true, disablePadding: false, label: 'Editar' },
    ];
  
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link className="linkBreadcrumbs" color="inherit" href="/">
                                    Dashboard
                                </Link>
                                {/* <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/getting-started/installation/"
                                    onClick={handleClick}>
                                    Core
                                </Link> */}
                                <Typography variant="body2" color="textPrimary">
                                    {title}
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>{title}</h2>
                                {/* <Button
                                    className="button-add"
                                    onClick={create}
                                    variant="contained"
                                    color="primary">
                                    Novo
                                </Button> */}
                            </div>
                        </Grid>
                        {/* <Grid item xs={12} md={12} lg={12}>
                            <Paper className={'paperSearch'}>
                                <TextField
                                    className="textField"
                                    id="outlined-secondary"
                                    label="Digite o nome da escola"
                                    variant="outlined"
                                    // color="secondary"
                                    // value={pesquisar}
                                    onChange={handleChangePesquisar}
                                />
                            </Paper>
                        </Grid> */}
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                  {rows.length !== 0 && (
                                    <div className="paperContant">
                                      <TableUsuarios 
                                        columns={columns} 
                                        rows={rows}
                                        // deletando={deletando}
                                        tipoUsuario={'aluno'}
                                        // telasuser={permissaoTelas}
                                      />
                                    </div>
                                  )}
                                  {rows.length === 0 && (
                                    <div className="paperContantDois">
                                        <div>Nenhum registro encontrado</div>
                                    </div>
                                  )}
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
        </div>
    );
}
