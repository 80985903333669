import React, { useState, useEffect } from 'react';
/* eslint-disable */
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import '../../../../styles/global.css';
import Settings from '@material-ui/icons/Settings';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import Assignment from '@material-ui/icons/Assignment';
import ThumbUpAlt from '@material-ui/icons/ThumbUpAlt';
import ThumbDownAlt from '@material-ui/icons/ThumbDownAlt';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../../services/api';
import {format, subDays, addDays, isAfter, parseISO, startOfWeek} from 'date-fns';
import localePtBR from "date-fns/locale/pt-BR";
import Tooltip from '@material-ui/core/Tooltip';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
// import Add from '@material-ui/icons/Add';
import CloseBtn from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import fi from 'date-fns/esm/locale/fi/index.js';


const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    container: {
        overflow: 'none'
    }
});

export default function Index(props) {
    console.log('Propos', props);
    const classes = useStyles();
    let history = useHistory();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [openModal, setOpenModal] = useState(false);
    const [idAluno, setIdAluno] = useState('');
    const [nomeAluno, setNomeAluno] = useState('');
    const [diagnostico, setDiagnostico] = useState('');
    const [existeDisgnostico, setExisteDiagnostico] = useState(false);
    const [idEditar, setIdEditar] = useState('');
    const [diagnosticosCadastrados, setDiagnosticosCadastrados] = useState([]);
    const [periodos, setPeriodos] = useState([]);
    const [periodo, setPeriodo] = useState('');
    const [idPeriodo, setIdPeriodo] = useState('');
    const [frequenciasAluno, setFrequenciasAluno] = useState([]);
    const [totalPresenca, setTotalPresenca] = useState(0);
    const [totalFalta, setTotalFalta] = useState(0);
    const [modificarData, setModificarData] = useState(false);
    const [dataAtualModificada, setDataAtualModificada] = useState(new Date());
    const [dataFora, setDataFora] = useState(true);
    const [qto, setQto] = useState(1);

    const columns = props.columns

    const rows = props.rows

    const configr = props.config;

    const iddodiario = props.iddodiario;

    const nivelAcesso = props.nivelAcesso;

    // const dataAtualModificada = format(new Date(), 'yyyy-MM-dd')
    const anoAtual = format(new Date(), 'yyyy')
    const resultStartOfWeek = format(addDays(new Date(startOfWeek(new Date())), 1), 'yyyy-MM-dd')
    // const resultStartOfWeek = format(startOfWeek(new Date()), 'yyyy-MM-dd')
    const resultStartOfWeek6 = format(addDays(new Date(startOfWeek(new Date())), 7), 'yyyy-MM-dd')


    let myArray = [];

    const StyledTableCell = withStyles((theme) => ({
      head: {
        backgroundColor: '#f7f7f7',
        color: theme.palette.common.black,
      },
      body: {
        fontSize: 14,
      },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }))(TableRow);


    useEffect(() => {
      buscarPeriodo()
      // console.log('resultStartOfWeek', resultStartOfWeek)
      // console.log('resultStartOfWeek', resultStartOfWeek6)
    }, []);

    // function editar(id, name, email){
    //   // history.push('/fatura-create');
    //   history.push({
    //       // pathname: '/cliente-edit',
    //       pathname: `${props.tipoUsuario}-create`,
    //       // search: '?query=abc',
    //       state: { 
    //         type: 'edit', 
    //         id: id, 
    //         nome: name, 
    //         email: email,
    //         usuario: props.tipoUsuario,
    //       }
    //   });
    // }

    const handleOpenModal = (id, nome, periodo) => {
      editar(id)
      setOpenModal(true);
      setIdAluno(id)
      setNomeAluno(nome)
      setIdPeriodo(periodo.id)
      buscarPeriodos()
      //idaluno, idperiodo, iddiario
      buscarPresencas(id, periodo.id, iddodiario)
    };
  
    const handleCloseModal = () => {
      setOpenModal(false);
    };
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function handleChangeDiagnostico(event){
      setDiagnostico(event.target.value);
    }

    function handleDateChangeDataModificada(date){
      setDataAtualModificada(date);
    }

    function handleDateChangeQto(event){
      if(event.target.value === ''){
        setQto(1);
        toast.error('🦄 Você não está digitando a quantidade de aulas!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      if(event.target.value === 0){
        setQto(1);
        toast.error('🦄 Quantidade de aulas não pode ser ZERO (0)!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      if(event.target.value > 5){
        setQto(1);
        toast.error('🦄 Quantidade de aulas não pode ser maior que 5!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      setQto(event.target.value);
    }

    // function diagnosticoExisteuser(idaluno){
    //   console.log("diagnosticosCadastrados.includes(idaluno)", diagnosticosCadastrados.includes(idaluno))
    //   return diagnosticosCadastrados.includes(idaluno)
    // }


    async function buscarPresencas(idaluno, idperiodo, iddiario){
      console.log("idaluno, idperiodo, iddiario ", idaluno, idperiodo, iddiario)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };

      try {
        //:idaluno/:idperiodo/:iddiario
        const {data: frequencia} = await api.get(`/frequencias/${idaluno}/${idperiodo}/${iddiario}`, config);
        console.log("buscar frequencia", frequencia)
        var qtoPresenca = 0;
        var qtoFalta = 0;
        for (let p = 0; p < frequencia.length; p++) {
          const element = frequencia[p];
          if(element.presenca === 'Sim'){
            qtoPresenca += 1
          }      
          if(element.presenca === 'Não'){
            qtoFalta += 1
          }      
        }
        setTotalPresenca(qtoPresenca)
        setTotalFalta(qtoFalta)
        setFrequenciasAluno(frequencia)
 
      } catch (error) {
        console.log("Erro buscar frequência", error, error.response);
        toast.error('🦄 Erro buscar frequência, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setloading(false);
      }
    }
  
    async function buscarPeriodo(){
      
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };

      try {
        const {data: periodo} = await api.get(`pesquisar-periodo/${format(new Date(dataAtualModificada), 'yyyy-MM-dd')}`, config);
        console.log("buscarPeriodo", periodo)
        setPeriodo(periodo[0])
 
      } catch (error) {
        console.log("Erro buscar período", error, error.response);
        toast.error('🦄 Erro buscar período, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setloading(false);
      }
    }
  
    async function buscarPeriodos(idperiodo){
      // buscar todos os periodos do ano
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };

      try {
        const {data: periodo} = await api.get(`pesquisar-periodo-ano/${anoAtual}`, config);
        console.log("buscarPeriodos", periodo)
        setPeriodos(periodo)
 
      } catch (error) {
        console.log("Erro buscar períodos", error, error.response);
        toast.error('🦄 Erro buscar períodos, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setloading(false);
      }
    }
  

    async function editar(id){
      setExisteDiagnostico(false)
      setIdEditar('')
      setDiagnostico('')
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };

      try {
        const {data: edit} = await api.get(`/frequencia/${id}/${iddodiario}`, config);
        console.log("editar frequência", edit)
        if(edit.length != 0){
          setExisteDiagnostico(true)
          setIdEditar(edit[0].id)
          setDiagnostico(edit[0].diagnostico)
        }

      } catch (error) {
        console.log("Erro buscar frequência", error, error.response);
        toast.error('🦄 Erro buscar frequência, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setloading(false);
      }
    }

    function dataAleatoria(dataIni) {
      var dataAtual = new Date();
      return dataIni.getTime() + Math.random() * (dataAtual.getTime() - dataIni.getTime());
    }
  
    async function salvar(id, idperiodo, presenca) {
      var minhaDataAleatoria = dataAleatoria(new Date());
      console.log("minhaDataAleatoria ", minhaDataAleatoria)
      //setloading(true);
      if(!dataFora){
        toast.error(`🦄 A data informada está fora das datas permitidas!`, {
          position: "bottom-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      var token = localStorage.getItem('@token_diario_web');
      for (let p = 0; p < qto; p++) {
        let form_data = new FormData();
  
        form_data.append("user_id", id);
        form_data.append("periodo_id", idperiodo);
        form_data.append("diario_id", iddodiario);
        form_data.append("data", format(new Date(dataAtualModificada), 'yyyy-MM-dd'));
        form_data.append("presenca", presenca);
        form_data.append("qto", qto);
        form_data.append("hash", minhaDataAleatoria);
  
        await api
            .post(`/frequencia`, form_data, {
              headers: {
                "content-type": "application/json",
                Authorization: "bearer " + token,
                Accept: "application/json"
              }
            })
            .then(res => {
                console.log("Adicionando Frequência", res.data)
                handleCloseModal()
                toast.success('🦄 Frequência cadastrada com sucesso!', {
                  position: "bottom-left",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
            })
            .catch(error => {
              console.log("error: ", error, error.response)
              console.log("response: ", error.response.data.message)
              
              toast.error(`🦄 ${error.response.data.message}!`, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              // setloading(false);
              // throw error;
            });
      }
  
 
        // await api
        //   .post(`/diagnostico/${ideditar}`, form_data, {
        //     headers: {
        //       "content-type": "application/json",
        //       Authorization: "bearer " + token,
        //       Accept: "application/json"
        //     }
        //   })
        //   .then(res => {
        //       console.log("Diagnótico editado", res.data)
        //       editar(res.data.user_id)
        //       // setloading(false);
        //       toast.success('🦄 Diagnótico editado com sucesso!', {
        //         position: "bottom-left",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //       });
        //       editar(res.data.id)
        //   })
        //   .catch(error => {
        //     console.log("error: ", error, error.response)
        //     toast.error('🦄 Erro ao editar diagnótico, tente novamente ou fale com o suporte!', {
        //       position: "bottom-left",
        //       autoClose: 5000,
        //       hideProgressBar: false,
        //       closeOnClick: true,
        //       pauseOnHover: true,
        //       draggable: true,
        //       progress: undefined,
        //     });
        //     // setloading(false);
        //   });


    }

    async function deletar(id) {
      
      // setloading(true)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: prod} = await api.delete(`/usuario/${id}`, config);
        console.log("prod", prod)
        // limpaCampos();
        // setloading(false);
        toast.success('🦄 Deletado com sucesso!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.deletando()
      } catch (error) {
        console.log("Erro ao deletar", error, error.response);
        // setloading(false);
        toast.error('🦄 Erro ao tentar deletar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    function moedaBR(amount, decimalCount = 2, decimal = ",", thousands = "."){
      try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  
        const negativeSign = amount < 0 ? "-" : "";
  
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
  
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
      } catch (e) {
        console.log(e)
      }
    }

    return (
        <Paper className={'paper'}>
          {nivelAcesso === 'professor' && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                {modificarData && (
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5}}>
                    <span>Data da presença 
                      <strong>{format(new Date(dataAtualModificada), "dd/MM/yyyy")}</strong>
                    </span>
                  </div>
                )}
                {!modificarData && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localePtBR}>
                      <KeyboardDatePicker
                        style={{marginTop: 0}}
                        margin="normal"
                        id="date-picker-data-presenca"
                        label="Data da presença "
                        format="dd/MM/yyyy"
                        // minDate={new Date(resultStartOfWeek)}
                        // minDateMessage={'Data inferior a permitida'}
                        // maxDate={new Date(resultStartOfWeek6)}
                        // maxDateMessage={'Data superior a permitida'}
                        invalidDateMessage={'Data inválida'}
                        value={dataAtualModificada}
                        onChange={handleDateChangeDataModificada}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        onAccept={(e) => {
                          // console.log("aceita ", e)
                        }}
                        onError={(e) => {
                          // console.log("Error data", e)
                          if(e === 'Data inferior a permitida'){
                            setDataFora(false)
                          }else{
                            setDataFora(true)
                          }
                          if(e === 'Data superior a permitida'){
                            setDataFora(false)
                          }else{
                            setDataFora(true)
                          }
                          if(e === 'Data inválida'){
                            setDataFora(false)
                          }else{
                            setDataFora(true)
                          }
                        }}
                      />
                  </MuiPickersUtilsProvider>
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField 
                  style={{marginBottom: 10}} 
                  id="qto" 
                  label="Quantidade" 
                  variant="outlined" 
                  type="number"
                  value={qto}
                  onChange={handleDateChangeQto}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5}}>
                  <span style={{marginLeft: 5}}><strong>{periodo ? periodo.nome_periodo : 'Não definido'}</strong></span>
                </div>
              </Grid>
              <Grid item xs={12} md={3}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5}}>
                  <span style={{marginLeft: 5}}>
                    {periodo ? 'Início': ''} <strong>{periodo ? format(new Date(periodo.inicio), "dd/MM/yyyy") : ''}</strong>
                  </span>
                  <span style={{marginLeft: 5}}>
                  {periodo ? 'Fim': ''} <strong>{periodo ? format(new Date(periodo.fim), "dd/MM/yyyy") : ''}</strong>
                  </span>
                </div>
              </Grid>
            </Grid>
          )}
            {/* <div style={{display: 'flex', justifyContent: 'flex-end', padding: 10}}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5}}>
                <span style={{marginLeft: 5}}>
                  {modificarData && (
                    <span>Data da presença 
                      <strong>{format(new Date(dataAtualModificada), "dd/MM/yyyy")}</strong>
                    </span>
                  )}
                  {!modificarData && (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify="space-around">
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-data-presenca"
                          label="Data da presença "
                          format="MM/dd/yyyy"
                          // value={selectedDate}
                          // onChange={handleDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  )}
                </span>
              </div>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5}}>
                <span style={{marginLeft: 5}}><strong>{periodo ? periodo.nome_periodo : 'Não definido'}</strong></span>
              </div>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5}}>
                <span style={{marginLeft: 5}}>
                  {periodo ? 'Início': ''} <strong>{periodo ? format(new Date(periodo.inicio), "dd/MM/yyyy") : ''}</strong>
                </span>
                <span style={{marginLeft: 5}}>
                {periodo ? 'Fim': ''} <strong>{periodo ? format(new Date(periodo.fim), "dd/MM/yyyy") : ''}</strong>
                </span>
              </div>
            </div> */}
         
            <TableContainer style={{border: '1px solid #212121'}} className={classes.container}>
                <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, key) => (
                                <TableCell
                                    key={column.id}
                                    style={{ 
                                      width: key === 0 ? '80%' : '10%', 
                                      textAlign: key != 0 ? 'center' : 'left',
                                      background: '#e8e8e8',
                                      borderRight: key <= 2 ? '1px solid #212121' : ''
                                    }}
                                    align={key != 0 ? 'right' : 'left'}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow
                                        className="tableRow"
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id}>
                                          <TableCell style={{borderRight: '1px solid #212121'}} component="th" scope="row">
                                              {row.nome}
                                          </TableCell>
                                          <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121'}}>{row.sexo}</TableCell>
                                          <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121'}}>{ format(new Date(row.nascimento), 'dd/MM/yyyy')}</TableCell>
                                          <TableCell align="right" style={{textAlign: 'center'}}>
                                            {periodo && (

                                              <div
                                                style={{
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  alignItems: 'center'
                                                }}
                                              >
                                                <Tooltip title={"Frequências do aluno" } aria-label="add">
                                                  <IconButton onClick={() => { handleOpenModal(row.idAluno, row.nome, periodo) }} color="inherit">
                                                      <Assignment style={{ color: 'blue' }} />
                                                  </IconButton>
                                                </Tooltip>
                                                {nivelAcesso === 'professor' && (
                                                  <>
                                                    <Tooltip title={"Adicionar frequência" } aria-label="add">
                                                      <IconButton onClick={() => { salvar(row.idAluno, periodo.id, 'Sim') }} color="inherit">
                                                          <ThumbUpAlt style={{ color: 'green' }} />
                                                      </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={"Adicionar falta" } aria-label="add">
                                                      <IconButton onClick={() => { salvar(row.idAluno, periodo.id, 'Não') }} color="inherit">
                                                          <ThumbDownAlt style={{ color: 'red' }} />
                                                      </IconButton>
                                                    </Tooltip>
                                                  </>
                                                )}
                                              </div>
                                            )}
                                            {!periodo && (
                                              <div
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                              }}
                                              >
                                                Não permitido
                                              </div>
                                            )}
                                          </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              className="modal"
            >
              <div className="modalContent">
                <div className="modalContentHeader">
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    Registro de Frequência
                    <strong>{nomeAluno}</strong>
                  </div>
                  <Tooltip title="Sair do cadastro" aria-label="add">
                    <IconButton onClick={() => { handleCloseModal() }} color="inherit">
                        <CloseBtn style={{ color: 'red' }} />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className="modalContentCenter">
                  <Grid container spacing={3}>
                      <Grid item md={12}>
                          <div style={{display:'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}>
                            {periodos.map((item, key) => (
                              <Tooltip 
                                key={key} 
                                title={`Frenquência dos alunos do ${item.nome_periodo}`} 
                                aria-label="add"
                              >
                                <IconButton
                                //periodo.id, iddodiario
                                  onClick={() =>{buscarPresencas(idAluno, item.id, iddodiario)}}
                                  style={{
                                    padding: 10, 
                                    background: 'blue', 
                                    color: 'white', 
                                    fontSize: 15,
                                    borderRadius: 10, 
                                    margin: 3
                                  }} 
                                >
                                  <span>{item.nome_periodo}</span>
                                </IconButton>  
                              </Tooltip>
                            ))}
                          </div>
                      </Grid>
                      <Grid item md={6}>
                        <TableContainer style={{height: 450}} component={Paper}>
                          <Table aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell style={{minWidth: 150}}>Data</StyledTableCell>
                                <StyledTableCell style={{width: 30}} align="right">
                                  Presença
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {frequenciasAluno.map((item, key) => (
                                <StyledTableRow key={key}>
                                  <StyledTableCell style={{minWidth: 150}} component="th" scope="row">
                                    {format(new Date(item.data), "dd/MM/yyyy")}
                                  </StyledTableCell>
                                  <StyledTableCell style={{width: 30}} align="center">
                                    {item.presenca === 'Sim' &&(
                                      <Chip size="small" style={{color: 'white', background: 'green'}} label={item.presenca} />
                                    )}
                                    {item.presenca === 'Não' &&(
                                      <Chip size="small" style={{color: 'white', background: 'red'}} label={item.presenca} />
                                    )}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                            {frequenciasAluno.length === 0 && (
                              <div
                                style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20}}
                              >
                                Nenhum frequência encontrada
                              </div>
                            )}
                        </TableContainer>
                      </Grid>
                      <Grid item md={6}>
                        <div style={{display: 'flex', width: '100%',flexDirection: 'column'}}>
                          <div style={{display: 'flex',  width: '100%', background: '#f7f7f7',  justifyContent: 'space-between', alignItems: 'center', padding: 10, margin: 5}}>
                            <div>Número de presenças</div><strong>{totalPresenca}</strong>
                          </div>
                          <div style={{display: 'flex',  width: '100%', background: '#f7f7f7', justifyContent: 'space-between', alignItems: 'center', padding: 10, margin: 5}}>
                            <div>Número de faltas</div><strong>{totalFalta}</strong>
                          </div>
                        </div>
                      </Grid>
                  </Grid>
                </div>
              </div>
            </Modal>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Por página'}
            />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
        </Paper>
    );
}
