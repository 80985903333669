import React, { useState, useEffect } from 'react';
import '../../../../styles/global.css';
/* eslint-disable */
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../../Components/Header';
import Footer from '../../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import api from '../../../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableExperiencias from '../Tabelas/experienciasDesenvolvidas';
import ModalComponent from './Components/Modal';

export default function Index() {
    let history = useHistory();
    const location = useLocation();
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [dadosEditar, setDadosEditar] = useState('');
    const [tipo, setTipo] = useState('new');
    // const [pesquisar, setPesquisar] = useState('');

    const anoLetivo = location.state.anoLetivo;
    const idEscola = location.state.idEscola;
    const iddodiario = location.state.iddiario;
    const idProfessor = location.state.adicionais.idProfessor;
    const periodo = location.state.periodo;
    const nivelAcesso = location.state.nivelAcesso;

    useEffect(() => {
      console.log("location.state. index expe", location.state)
      console.log("iddodiario", iddodiario)
      console.log("idProfessor", idProfessor)
        // Atualiza o titulo do documento usando a API do browser
        experienciasDesenvolvidas()
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function handleOpenModal(id, tipo){
      setTipo(tipo)
      if(tipo == 'edit'){
        editar(id)
      }else{
        setDadosEditar('')
        setOpenModal(true);
      }
    };

    async function editar(id){
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: edit} = await api.get(`/experiencia-desenvolvidas/${id}`, config);
        const result = edit[0];
        setDadosEditar(result)
        setOpenModal(true);
        console.log("Dados da esperiência desenvolvida para editar", result)
      } catch (error) {
        console.log("Erro buscar dados da esperiência desenvolvida para editar", error, error.response);
      }
    }
  

    function handleCloseModal(){
      setOpenModal(false);
      experienciasDesenvolvidas()
    };

    
    async function experienciasDesenvolvidas(){
      var token = localStorage.getItem('@token_diario_web');
      var idprofessor = nivelAcesso === 'professor' ? 0 : idProfessor
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data : result} = await api.get(`experiencia-desenvolvidas/${idprofessor}/${iddodiario}/`, config);
        console.log("Result experiência desenvolvida", result)
        setData(result)
        toast.success('🦄 Carregando experiências desenvolvidas!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro ao carregar Experiências desenvolvidas", error, error.response);
        toast.error('🦄 Error ao carregar experiências desenvolvidas!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    
    
    function deletando(){
      experienciasDesenvolvidas()
    }

    function create() {
      history.push({
          pathname: `experiencias-desenvolvidas-create`,
          // search: '?query=abc',
          state: { 
            type: 'new',
          }
      });
    }
  

    function createData(id, periodo, data, qto) {
      return {id, periodo, data, qto};
    }
  
    var rows = [];
    if(data){
      rows = data.map((row) => {
        return createData(
          row.id, 
          row.periodo,
          row.data,
          row.qto_aula,
        )
      })
    }
  
    const columns = [
      { id: 'data', numeric: false, disablePadding: true, label: 'Dia' },
      { id: 'perido', numeric: false, disablePadding: true, label: 'Periodo' },
      { id: 'qto', numeric: false, disablePadding: true, label: 'Qto aulas' },
      { id: 'acao', numeric: false, disablePadding: true, label: '' },
    ];
  
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                          
                            <Breadcrumbs aria-label="breadcrumb">
                              <Breadcrumbs aria-label="breadcrumb">
                                <Link className="linkBreadcrumbs" color="inherit" href="/">
                                    Dashboard
                                </Link>
                                <Link className="linkBreadcrumbs" color="inherit" href="/diarios-classe/">
                                    Diários
                                </Link>
                                <Link 
                                  className="linkBreadcrumbs" 
                                  color="inherit" 
                                  style={{ cursor: 'pointer'}}
                                  onClick={() => {
                                    history.goBack()
                                  }}
                                >
                                  Configurações do Diário
                                </Link>
                                <Typography variant="body2" color="textPrimary">
                                  Sequência Didática
                                </Typography>
                              </Breadcrumbs>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>Sequência Didática</h2>
                                {nivelAcesso === 'professor' &&(
                                  <Button
                                    className="button-add"
                                    onClick={() => {
                                        handleOpenModal('' , 'new')
                                    }}
                                    variant="contained"
                                    color="primary">
                                    Novo
                                  </Button>
                                )}
                            </div>
                            <div className="headerPage">
                                <Button
                                    style={{marginTop: 10}}
                                    className="button-add"
                                    onClick={() => {
                                      history.goBack()
                                    }}
                                    variant="contained"
                                    color="primary">
                                    Retornar ao diário
                                </Button>
                            </div>
                        </Grid>
                        {/* <Grid item xs={12} md={12} lg={12}>
                            <Paper className={'paperSearch'}>
                                <TextField
                                    className="textField"
                                    id="outlined-secondary"
                                    label="Digite o nome do professor(a)"
                                    variant="outlined"
                                    // color="secondary"
                                    // value={pesquisar}
                                    onChange={handleChangePesquisar}
                                />
                            </Paper>
                        </Grid> */}
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                  <div className="diarioHeader">
                                    <div className="ih">
                                      <span>Ano letivo:</span>
                                      <span><strong>{location.state.periodo.ano_periodo}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Escola:</span>
                                      <span><strong>{location.state.adicionais.escola}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Professor:</span>
                                      <span><strong>{location.state.adicionais.professor}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Ano:</span>
                                      <span><strong>{location.state.adicionais.anoLetivo}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Turno:</span>
                                      <span><strong>{location.state.turno}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Turma:</span>
                                      <span><strong>{location.state.turma}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Período:</span>
                                      <span><strong>{location.state.periodo.nome_periodo}</strong></span>
                                    </div>
                                  </div>
                                  {rows.length !== 0 && (
                                    <div className="paperContant">
                                      {/* <div style={{
                                        background: '#f7f7f7',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: 10
                                      }}>
                                        <span>Número de registros {' - '}</span>
                                        <span style={{fontSize: 21, fontWeight:'bold'}}>{'  '}{rows.length}</span>
                                      </div> */}
                                      <TableExperiencias 
                                        columns={columns} 
                                        rows={rows} 
                                        deletando={deletando}
                                        config='diagnostico_inicial'
                                        iddodiario={iddodiario}
                                        handleOpenModal={handleOpenModal}
                                        nivelAcesso={nivelAcesso}
                                      />
                                    </div>
                                  )}
                                  {rows.length === 0 && (
                                    <div className="paperContantDois">
                                        <div>Nenhum registro encontrado</div>
                                    </div>
                                  )}
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                    
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
                {openModal && (
                  <ModalComponent 
                    openModal={openModal}
                    anoLetivo={anoLetivo}
                    idEscola={idEscola}
                    iddodiario={iddodiario}
                    handleCloseModal={handleCloseModal}
                    periodo={periodo}
                    dadosEditar={dadosEditar}
                    tipo={tipo}
                    nivelAcesso={nivelAcesso}
                  />
                )}
            </main>
        </div>
    );
}
