import React, { useState, useEffect } from 'react';
import '../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
/* eslint-disable */
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import api from '../../services/api';
import { veryAuth } from '../../auth';
import ServicoCliente from '../../Components/Tables/servicoCliente';

export default function Index() {
    let history = useHistory();
    const location = useLocation();
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);
    const [roles, setRoles] = useState('');

    useEffect(() => {
        // Atualiza o titulo do documento usando a API do browser
        verificarPermissao()
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    },[ptBR]);

    function verificarPermissao(){
      veryAuth()
      .then((result) => {
        console.log("result", result);
        setRoles(result.data.role)
        if(result.data.role === 'admin'){
          servicoCliente(location.state.id)
        }else if(result.data.role === 'usuario'){
          servicoDoCliente()
        }
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.message);
      });
    }

    function deletando(){
      alert("aqui")
    }

    async function servicoCliente(idDoCliente){
      // alert(idDoCliente)
      var token = localStorage.getItem('@token_diario_web');
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data : result} = await api.get(`servicos-cliente/${idDoCliente}`, config);
        console.log("Resultado servico-cliente", result)
        setData(result)
      } catch (error) {
        console.log("Erro ao servidocliente", error, error.response);
      }
    }

    async function servicoDoCliente(){
      // alert(idDoCliente)
      var token = localStorage.getItem('@token_diario_web');
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data : result} = await api.get(`servicos-cliente/`, config);
        console.log("Resultado servico-do-cliente", result)
        setData(result)
      } catch (error) {
        console.log("Erro ao carregar serviço do cliente", error, error.response);
      }
    }
    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    /*table */

    function createData(id, userId, servico, recorrencia, data, valor, status) {
      return {id, userId, servico, recorrencia, data, valor, status};
    }
  
    var rows = [];
    if(!data){
        rows = [createData('carregando')]
    }
    if(data){
      rows = data.map((row) => {
        console.log("rowrowe", row)
        return createData(
          row.id,
          // location.state.id,
          row.user_id,
          row.dominio !== null ? row.servico.nome + ' - ' + row.dominio : row.servico.nome,
          row.servico.recorrencia,
          row.data_primeiro_pagamento,
          row.valor,
          row.status)
      })
    }
  
    const columns = [
      { id: 'servico', numeric: false, disablePadding: true, label: 'Serviço' },
      { id: 'recorrencia', numeric: true, disablePadding: false, label: 'Recorrência' },
      { id: 'data', numeric: true, disablePadding: false, label: 'Data' },
      { id: 'valor', numeric: true, disablePadding: false, label: 'Valor' },
      { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
      { id: 'edit', numeric: true, disablePadding: false, label: '' },
    ];
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/"
                                    onClick={handleClick}>
                                    Dashboard
                                </Link>
                                {/* <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/getting-started/installation/"
                                    onClick={handleClick}>
                                    Core
                                </Link> */}
                                {roles === 'admin' &&(
                                  <Typography variant="body2" color="textPrimary">
                                      Lista serviços do cliente
                                  </Typography>
                                )}
                                {roles === 'usuario' &&(
                                  <Typography variant="body2" color="textPrimary">
                                      Meus serviços
                                  </Typography>
                                )}
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>{roles === 'admin' ? 'Lista serviços do cliente' : 'Meus serviços' }</h2>
                                {roles === 'admin' &&(
                                  <Button className="button-add" variant="contained" color="primary">
                                      Novo
                                  </Button>
                                )}
                            </div>
                        </Grid>
                        {roles === 'admin' &&(
                          <Grid item xs={12} md={12} lg={12}>
                              <Paper className={'paperSearch'}>
                                  <TextField
                                      className="textField"
                                      id="outlined-secondary"
                                      label="Faça uma busca aqui!"
                                      variant="outlined"
                                      color="secondary"
                                  />
                              </Paper>
                          </Grid>
                        )}
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                    <div className="paperContant">
                                        {roles === 'admin' &&(
                                          <div className="paperHeader">
                                              <Typography variant="subtitle1" gutterBottom>
                                                <strong>Cliente: </strong>{location.state.nome}
                                              </Typography>
                                              <Typography variant="subtitle1" gutterBottom>
                                                <strong>Email: </strong>{location.state.email}
                                              </Typography>
                                          </div>
                                        )}
                                        <ServicoCliente columns={columns} rows={rows} deletando={deletando}/>
                                    </div> 
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
        </div>
    );
}
