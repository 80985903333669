import React, { useState, useEffect } from 'react';
import '../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
import Servicos from '../../Components/Tables/servicos';
import api from '../../services/api';
import { veryAuth } from '../../auth';
// import api from '../../services/api';
// import { ToastContainer, toast } from 'react-toastify';
/* eslint-disable */

export default function Index() {
    const [skeleton, seSkeleton] = useState(true);
    const [roles, setRoles] = useState('');
    const [data, setData] = useState([]);
    let history = useHistory();

    useEffect(() => {
        // Atualiza o titulo do documento usando a API do browser
        verificarPermissao()
        
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function verificarPermissao(){
      veryAuth()
      .then((result) => {
        console.log(result);
        setRoles(result)
        if(result.data.role === 'admin'){
          servicos();
        }else if(result.data.role === 'usuario'){
          alert("Sem permissão, retorne")
        }
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.message);
      });
    }

    async function servicos(){
      var token = localStorage.getItem('@token_diario_web');
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data : result} = await api.get('servico', config);
        console.log("Result serviços: ", result)
        setData(result)
      } catch (error) {
        console.log("Erro ao deletar", error, error.response);

      }
    }

    function create() {
        history.push({
            pathname: '/servico-create',
            // search: '?query=abc',
            state: { type: 'new' }
        });
    }

    function deletando(){
      alert('deletando')
    }


    //tabela
    function createData(id, name, categoria, valor, status) {
      return {id, name, categoria, valor, status};
    }
  
    var rows = [];
    // if(!data){
    //     rows = [createData('carregando')]
    // }
    if(data){
      rows = data.map((row) => {
        return createData(row.id, row.nome, row.categoria.nome, row.valor, row.status)
      })
    }
  
    const columns = [
      { id: 'servico', numeric: false, disablePadding: true, label: 'Serviço' },
      { id: 'categoria', numeric: true, disablePadding: false, label: 'Categoria' },
      { id: 'valor', numeric: true, disablePadding: false, label: 'Valor R$' },
      { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
      { id: 'edit', numeric: true, disablePadding: false, label: 'Editar' },
    ];
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link className="linkBreadcrumbs" color="inherit" href="/">
                                    Dashboard
                                </Link>
                                {/* <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/getting-started/installation/"
                                    onClick={handleClick}>
                                    Core
                                </Link> */}
                                <Typography variant="body2" color="textPrimary">
                                    Serviços
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>Serviços</h2>
                                {localStorage.getItem('@perfil:tipo_conta') === 'admin' && (
                                  <Button
                                      className="button-add"
                                      onClick={create}
                                      variant="contained"
                                      color="primary">
                                      Novo
                                  </Button>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={'paperSearch'}>
                                <TextField
                                    className="textField"
                                    id="outlined-secondary"
                                    label="Faça uma busca aqui!"
                                    variant="outlined"
                                    color="secondary"
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                              <>
                                {data.length !== 0 && (
                                  <Paper className={'paper'}>
                                      <div className="paperContant">
                                        <Servicos columns={columns} rows={rows} deletando={deletando}/>
                                      </div>
                                  </Paper>
                                  
                                )}
                                {data.length === 0 && (
                                  <Paper className={'paper'}>
                                      <div className="paperContantDois">
                                          <div>Nenhum registro encontrado</div>
                                      </div>
                                  </Paper>
                                )}
                              </>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
        </div>
    );
}
