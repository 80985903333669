import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import api from '../../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableEscolas from '../../../Components/Tables/escolas-coordenadores';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Assignment from '@material-ui/icons/Assignment';

export default function Index() {
    let history = useHistory();
    const location = useLocation();
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);

    const idescola = location.state.idescola
    const nomeescola = location.state.nomeescola
    // const [pesquisar, setPesquisar] = useState('');

    useEffect(() => {
        // Atualiza o titulo do documento usando a API do browser
        console.log("ocation.state idescola", idescola)
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function opcoes(id, nome){
      //diarios-coordenadores
      history.push({
        pathname: '/diarios-professor',
        // search: '?query=abc',
        state: { 
          // type: 'edit', 
          idescola: id,
          nomeescola: nome
        }
    });
    }
    

    
    async function escolas(){
      var token = localStorage.getItem('@token_diario_web');
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data : result} = await api.get('escola', config);
        console.log("Result escolas", result)
        setData(result)
        toast.success('🦄 Carregando escolas!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro ao carregar escolas", error, error.response);
        toast.error('🦄 Error ao carregar escolas!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  
    return (
      <div className={'root'}>
          <CssBaseline />
          <Header />
          <main className="content">
              <div className="appBarSpacer" />
              <Container maxWidth="lg" className="container">
                  <Grid container spacing={3}>
                      <Grid item xs={12} md={12} lg={12}>
                          <Breadcrumbs aria-label="breadcrumb">
                              <Link className="linkBreadcrumbs" color="inherit" href="/">
                                  Dashboard
                              </Link>
                              <Link
                                  className="linkBreadcrumbs"
                                  color="inherit"
                                  href="/escolas-coordenadores"
                                  >
                                  Escolas
                              </Link>
                              <Typography variant="body2" color="textPrimary">
                                  Opções
                              </Typography>
                          </Breadcrumbs>
                          <div className="headerPage">
                              <h2>Opções</h2>
                          </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div style={{display: 'flex', flexDirection: 'column', background: '#ffff', padding: 10, borderRadius: 10}}>
                          <span>Escola</span>
                          <span style={{fontSize: 21, fontWeight: 'bold'}}>
                            {nomeescola}
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={3}>
                          {skeleton && <Skeleton variant="rect" className="paper" />}
                          {!skeleton && (
                            <Paper style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className={'paper'}>
                              <Tooltip title="Diários dos professores" arrow>
                                <Button style={{position: 'absolute', width: '100%', height: '100%'}} onClick={() => {opcoes(idescola, nomeescola)}}>
                                  <Assignment style={{ color: 'blue' }} />
                                  Diários
                                </Button>
                              </Tooltip>
                            </Paper>
                          )}
                      </Grid>
                  </Grid>
                  <Box pt={4}>
                      <Footer />
                  </Box>
              </Container>
          </main>
      </div>
    );
}
