import React, { useState, useEffect } from 'react';
import '../../styles/global.css';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import ReactStoreIndicator from 'react-score-indicator';
import api from '../../services/api';
import {veryAuth} from '../../auth';
import {format, subDays, addDays, isAfter,} from 'date-fns';
import AlertFaltasAlunos from '../../Components/AlertFaltasAlunos';
import Icon from '@material-ui/core/Icon';
import { useHistory } from 'react-router-dom';
import Tabelaparecer from '../Diarios/Components/Tabelas/tableParecerTecnico'

export default function Index() {
    const [skeleton, seSkeleton] = useState(true);
    let history = useHistory();

    const [dataFaturasVencidas, setDataFaturasVencidas] = useState([]);
    const [valorVencido, setValorVencido] = useState(0);
    const [totalFaturasVencidas, setTotalFaturasVencidas] = useState(0);
    const [dataFaturasPagas, setDataFaturasPagas] = useState([]);
    const [dataServicos, setDataServicos] = useState([]);
    const [valorPago, setValorPago] = useState(0);
    const [totalFaturasPagas, setTotalFaturasPagas] = useState(0);
    const dataAtual = format(new Date(), 'dd/MM/yyyy');
    const [roles, setRoles] = useState('');
    const [dadosUsuario, setDadosUsuario] = useState('');
    const [escolas, setEscolas] = useState([]);
    const [professores, setProfessores] = useState([]);
    const [alunos, setAlunos] = useState([]);
    const [paracerTecnico, setParaceTecnico] = useState([]);

    useEffect(() => {
      // console.log('location.state.telasuser[0]', location.state);
        verificarPermissao()
        // clientes()
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function verificarPermissao(){
      veryAuth()
      .then((result) => {
        console.log("result verificarPermissao dashboard", result);
        setRoles(result.data.role)
        setDadosUsuario(result.data.user)
        if(result.data.role === 'admin'){
          usuarios()
          carregarEscolas()
        }else if(result.data.role === 'gestor'){
          carregarEscolas()
        }
        verificarPerecerPendente()
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.response, error.message);
      });
    }

    async function usuarios() {
      let arrAluno = []
      let arrProfessor = []
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get('usuario', config);
          for (let u = 0; u < result.length; u++) {
            const element = result[u];
            // console.log('Result usuarios', element);
            if(element.tipo_conta === 'Aluno'){
              arrAluno.push(element)
            }
            if(element.tipo_conta === 'Professor'){
              arrProfessor.push(element)
            }
            
          }
          // console.log('arrAluno', arrAluno)
          // console.log('arrProfessor', arrProfessor)
          setAlunos(arrAluno)
          setProfessores(arrProfessor)
      } catch (error) {
          console.log('Erro ao buscar usuarios', error, error.response);
      }
    }

    async function verificarPerecerPendente() {
      // let arrAluno = []
      // let arrProfessor = []
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get('verificar-parecer-pendente', config);
          console.log(" data parecer tecnico ", result)
          setParaceTecnico(result)
      } catch (error) {
          console.log('Erro ao buscar parecer tecnico', error, error.response);
      }
    }

    async function carregarEscolas() {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get('escola', config);
          console.log('result escola', result)
          setEscolas(result)
      } catch (error) {
          console.log('Erro ao buscar escolas', error, error.response);
      }
    }


    function rota(rota) {
      // history.push('/fatura-create');
      history.push({
          pathname: rota,
          // search: '?query=abc',
          // state: { type: 'new' }
      });
    }

    function create(rota) {
      history.push({
          pathname: rota,
          // search: '?query=abc',
          state: { 
            type: 'new',
          }
      });
    }

    //tabela
    function createData(
      row,
      id, 
      periodo, 
      parece_tecnico,
      parece_tecnico_pendente,
      created_at, 
      updated_at,
    ) {
      return {
        row,
        id, 
        periodo, 
        parece_tecnico,
        parece_tecnico_pendente,
        created_at, 
        updated_at,
      };
    }
  
    var rows = [];
    if(paracerTecnico){
      rows = paracerTecnico.map((row) => {
        return createData(
          row,
          row.id, 
          row.periodo.nome_periodo, 
          row.parece_tecnico,
          row.parece_tecnico_pendente,
          row.created_at, 
          row.updated_at
        )
      })
    }
  
    const columns = [
      { id: 'periodo', numeric: false, disablePadding: true, label: 'Período' },
      { id: 'criado', numeric: true, disablePadding: false, label: 'Criado em' },
      { id: 'atualizacao', numeric: true, disablePadding: false, label: 'Atualizado em' },
      { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
      { id: 'acao', numeric: true, disablePadding: false, label: '' },
    ];

    return (
        <div className={'root'}>
            <CssBaseline />
            <Header/>
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        {/* Chart */}
                        <Grid item xs={12} md={12} lg={12}>
                            <div className="headerPage">
                                {skeleton && (
                                    <Skeleton className={'skelTitlePage'} variant="text" />
                                )}
                                {!skeleton && <h2>Bem Vindo(a), {dadosUsuario.name}</h2>}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <div className="headerPage">
                                {skeleton && (
                                    <Skeleton className={'skelTitlePage'} variant="text" />
                                )}
                                {!skeleton && <spam>Você está acessando como: {roles}</spam>}
                            </div>
                        </Grid>
                        {roles === 'admin' && (
                          <Grid item xs={12} md={12}>
                              {skeleton && <Skeleton variant="rect" className="paper" />}
                              {!skeleton && (
                                <AlertFaltasAlunos/>
                              )}
                          </Grid>
                        )}
                        {/* coordenador */}
                        {roles === 'coordenador' && (
                          <Grid item xs={12} md={4} lg={3}>
                              {skeleton && <Skeleton variant="rect" className="paper" />}
                              {!skeleton && (
                                  <Paper style={{padding: 0}} className={'paper'}>
                                      <div onClick={() => {rota('/escolas-coordenadores')}} className="paperContantTres">
                                        <Icon className={'fa fa-school'} style={{ color: '#3f0279', width: 61, fontSize: 50,  }} />
                                        <span>Minhas Escolas</span>
                                      </div>
                                  </Paper>
                              )}
                          </Grid>
                        )}
                        {/* coordenador */}
                        
                        {/* gestor */}
                        {roles === 'gestor' && (
                          <Grid item xs={12} md={12} lg={12}>
                              {skeleton && <Skeleton variant="rect" className="paper" />}
                              {!skeleton && (
                                  <Paper style={{padding: 0}} className={'paper'}>
                                      <Grid container spacing={3}>
                                        <Grid item xs={12} md={3}>
                                          <div style={{display: 'flex', background: '#3f0279', width: '100%', height: 200, justifyContent: 'center', alignItems: 'center'}}>
                                            <Icon className={'fa fa-school'} style={{ color: '#ffff', width: 61, fontSize: 50,  }} />
                                          </div>
                                        </Grid>
                                        <Grid item xs={12} md={9}>
                                          {escolas.map((item, key) => (
                                            <div key={key}>
                                              <h2>{item.escola_nome}</h2>
                                              <p>Endereço: <strong>{item.escola_endereco} - {item.escola_polo_sede}</strong></p>
                                              <p>Grupo: <strong>{item.grupo.grupo_nome}</strong></p>
                                            </div>
                                          ))}
                                          {escolas.length === 0 && (
                                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', width: '100%', height: '100%'}}>
                                              <span>Você ainda não possui escola</span>
                                            </div>
                                          )}
                                        </Grid>
                                      </Grid>
                                  </Paper>
                              )}
                          </Grid>
                        )}
                        {roles === 'gestor' && (
                          <Grid item xs={12} md={4} lg={3}>
                              {skeleton && <Skeleton variant="rect" className="paper" />}
                              {!skeleton && (
                                  <Paper style={{padding: 0}} className={'paper'}>
                                      <div onClick={() => {create('aluno-gestor-create')}} className="paperContantTres">
                                        <Icon className={'fa fa-users'} style={{ color: '#3f0279', width: 61, fontSize: 50,  }} />
                                        <span>Cadastrar Aluno</span>
                                      </div>
                                  </Paper>
                              )}
                          </Grid>
                        )}
                        {roles === 'gestor' && (
                          <Grid item xs={12} md={4} lg={3}>
                              {skeleton && <Skeleton variant="rect" className="paper" />}
                              {!skeleton && (
                                  <Paper style={{padding: 0}} className={'paper'}>
                                      <div onClick={() => {create('matricula-gestor-create')}} className="paperContantTres">
                                        <Icon className={'fa fa-user-plus'} style={{ color: '#3f0279', width: 61, fontSize: 50,  }} />
                                        <span>Matricular aluno</span>
                                      </div>
                                  </Paper>
                              )}
                          </Grid>
                        )}
                        {/* professor */}
                        {roles === 'professor' && (
                          <Grid item xs={12} md={4} lg={3}>
                              {skeleton && <Skeleton variant="rect" className="paper" />}
                              {!skeleton && (
                                  <Paper style={{padding: 0}} className={'paper'}>
                                      <div onClick={() => {rota('/escolas-professor')}} className="paperContantTres">
                                        <Icon className={'fa fa-school'} style={{ color: '#3f0279', width: 61, fontSize: 50,  }} />
                                        <span>Minhas Escolas</span>
                                      </div>
                                  </Paper>
                              )}
                          </Grid>
                        )}
                        {/* alunos */}
                        {roles === 'aluno' && (
                          <Grid item xs={12} md={4} lg={3}>
                              {skeleton && <Skeleton variant="rect" className="paper" />}
                              {!skeleton && (
                                  <Paper style={{padding: 0}} className={'paper'}>
                                      <div onClick={() => {rota('/presenca-aluno')}} className="paperContantTres">
                                        <Icon className={'fa fa-chalkboard-teacher'} style={{ color: '#3f0279', width: 61, fontSize: 50,  }} />
                                        <span>Presença do Aluno</span>
                                      </div>
                                  </Paper>
                              )}
                          </Grid>
                        )}
                        {/* administradores */}
                        {roles === 'admin' && (
                          <>
                            <Grid item xs={12} md={4} lg={3}>
                                {skeleton && <Skeleton variant="rect" className="paper" />}
                                {!skeleton && (
                                    <Paper style={{padding: 0}} className={'paper'}>
                                        <div onClick={() => {rota('/escolas-professor')}} className="paperContantTres">
                                          {/* <Icon className={'fa fa-school'} style={{ color: '#3f0279', width: 61, fontSize: 50,  }} /> */}
                                          <span style={{ color: '#3f0279', width: 61, fontSize: 50, textAlign: 'center'}}>{escolas.length}</span>
                                          <span>Escolas</span>
                                        </div>
                                    </Paper>
                                )}
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                {skeleton && <Skeleton variant="rect" className="paper" />}
                                {!skeleton && (
                                    <Paper style={{padding: 0}} className={'paper'}>
                                        <div onClick={() => {rota('/escolas-professor')}} className="paperContantTres">
                                          {/* <Icon className={'fa fa-school'} style={{ color: '#3f0279', width: 61, fontSize: 50,  }} /> */}
                                          <span style={{ color: '#3f0279', width: 61, fontSize: 50, textAlign: 'center'}}>{professores.length}</span>
                                          <span>Professores</span>
                                        </div>
                                    </Paper>
                                )}
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                {skeleton && <Skeleton variant="rect" className="paper" />}
                                {!skeleton && (
                                    <Paper style={{padding: 0}} className={'paper'}>
                                        <div onClick={() => {rota('/escolas-professor')}} className="paperContantTres">
                                          {/* <Icon className={'fa fa-school'} style={{ color: '#3f0279', width: 61, fontSize: 50,  }} /> */}
                                          <span style={{ color: '#3f0279', width: 61, fontSize: 50, textAlign: 'center'}}>{alunos.length}</span>
                                          <span>Alunos</span>
                                        </div>
                                    </Paper>
                                )}
                            </Grid>
                          </>
                        )}
                        {((roles === 'coordenador' ? true : false) || (roles === 'professor' ? true : false) || (roles === 'admin' ? true : false)) && (
                          <Grid item xs={12} md={12} lg={12}>
                              {skeleton && <Skeleton variant="rect" className="paper" />}
                              {!skeleton && (
                                  <Paper style={{padding: 0}} className={'paper'}>
                                      <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: 10}}>
                                        Parecer Técnico
                                      </div>
                                      {paracerTecnico.length > 0 && (
                                        <Tabelaparecer
                                          columns={columns} 
                                          rows={rows} 
                                          // iddodiario={iddodiario}
                                          // periodo={periodo}
                                          nivelAcesso={roles}
                                          tela='dashboard'
                                        />
                                      )}
                                      {paracerTecnico.length === 0 && (
                                        <div className={'notFound'}>
                                            <p>Nenhum registro encontrado</p>
                                        </div>
                                      )}
                                  </Paper>
                              )}
                          </Grid>
                        )}
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
        </div>
    );
}
