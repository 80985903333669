import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
/* eslint-disable */
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
// import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TablePolos from '../../../Components/Tables/polos';
import {veryAuth} from '../../../auth';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { format, parseISO } from 'date-fns';

export default function Index() {
    let history = useHistory();
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);

    const [liberaAcesso, setLiberaAcesso] = useState(false);
    const [roles, setRoles] = useState('');

    const [diarios, setDiarios] = useState([]);
    const [diario, setDiario] = useState([]);
    const [frequencias, setFrequencias] = useState([]);
    const [frequencias1, setFrequencias1] = useState([]);
    const [frequencias2, setFrequencias2] = useState([]);
    const [frequencias3, setFrequencias3] = useState([]);
    const [frequencias4, setFrequencias4] = useState([]);
    const [totalPresenca, setTotalPresenca] = useState(0);
    const [totalFalta, setTotalFalta] = useState(0);
    // const [pesquisar, setPesquisar] = useState('');

    useEffect(() => {
      verificarPermissao()
      setTimeout(function () {
          seSkeleton(false);
      }, 3000);
    }, []);

    function verificarPermissao(){
      veryAuth()
      .then((result) => {
        console.log('result verificarPermissao', result);
        if(result.data.role === 'aluno'){
          setLiberaAcesso(true)
          bucarDiarios()
        }else{
          history.push({
            pathname: `/`,
          });
          return
        }
        setRoles(result.data.role)
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.response, error.message);
      });
    }

    const handleChangeDiarios = (event) => {
      setDiario(event.target.value);
      buscarPresencas(event.target.value)
    };

    async function bucarDiarios(){

      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };

      try {
        const {data : result} = await api.get(`diagnosticos/0`, config);
        console.log("buscar diarios", result)
        setDiarios(result)
      } catch (error) {
        console.log("Erro buscar diarios", error, error.response);
      }

    }

    async function buscarPresencas(iddiario){
      
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };

      try {
        //:idaluno/:idperiodo/:iddiario
        const {data: frequencia} = await api.get(`/frequencia-aluno-diario/${iddiario}`, config);
        console.log("buscar frequencia", frequencia)
        setFrequencias(frequencia)
        var qtoPresenca = 0;
        var qtoFalta = 0;
        for (let p = 0; p < frequencia.length; p++) {
          const element = frequencia[p];
          if(element.presenca === 'Sim'){
            qtoPresenca += 1
          }      
          if(element.presenca === 'Não'){
            qtoFalta += 1
          }      
        }
        setTotalPresenca(qtoPresenca)
        setTotalFalta(qtoFalta)

        var filtrado1Periodo = frequencia.filter(function(obj2) { 
          return obj2.periodo.nome_periodo == '1° Período'; 
        });
        setFrequencias1(filtrado1Periodo)

        var filtrado2Periodo = frequencia.filter(function(obj2) { 
          return obj2.periodo.nome_periodo == '2° Período'; 
        });
        setFrequencias2(filtrado2Periodo)

        var filtrado3Periodo = frequencia.filter(function(obj2) { 
          return obj2.periodo.nome_periodo == '3° Período'; 
        });
        setFrequencias3(filtrado3Periodo)

        var filtrado4Periodo = frequencia.filter(function(obj2) { 
          return obj2.periodo.nome_periodo == '4° Período'; 
        });
        setFrequencias4(filtrado4Periodo)
 
      } catch (error) {
        console.log("Erro buscar frequência", error, error.response);
        toast.error('🦄 Erro buscar frequência, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setloading(false);
      }
    }
  
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link className="linkBreadcrumbs" color="inherit" href="/">
                                    Dashboard
                                </Link>
                                {/* <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/getting-started/installation/"
                                    onClick={handleClick}>
                                    Core
                                </Link> */}
                                <Typography variant="body2" color="textPrimary">
                                    Presenças
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>Presenças</h2>
                            </div>
                        </Grid>
      
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                  <div className="paperContant">
                                    <Grid style={{marginBottom: 10}} container spacing={3}>
                                      <Grid item xs={12} md={12}>
                                        <FormControl fullWidth variant="outlined">
                                          <InputLabel id="anoDiario">Ano Letivo</InputLabel>
                                          <Select
                                            labelId="anoDiario"
                                            id="ano-diario"
                                            value={diario}
                                            onChange={handleChangeDiarios}
                                            label="Ano Letivo"
                                          >
                                            {diarios.map((item, key) => (
                                              <MenuItem key={key} value={item.diario.id}>Ano: {item.diario.ano}, Turno: {item.diario.turno} </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  {frequencias.length !== 0 && (
                                    <div className="paperContant">
                                      <Grid container spacing={3}>
                                        <Grid 
                                          style={{
                                            display: 'flex', 
                                            justifyContent: 'flex-start', 
                                            alignItems: 'center',
                                            flexDirection: 'row',
                                          }}
                                          item md={3}
                                        >
                                          <span>Quantidade de presenças: </span>
                                          <span style={{margin: 5}}>{totalPresenca}</span>
                                        </Grid>
                                        <Grid 
                                          style={{
                                            display: 'flex', 
                                            justifyContent: 'flex-start', 
                                            alignItems: 'center',
                                            flexDirection: 'row',
                                          }}
                                          item md={3}
                                        >
                                          <span>Quantidade de faltas: </span>
                                          <span style={{margin: 5}}>{totalFalta}</span>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                          <div className="info-bloco">
                                            <div className="info-bloco-header">
                                                <span>1°</span>
                                                <span>Período</span>
                                            </div>
                                            {frequencias1.length > 0 && (
                                              <div className="info-bloco-body">
                                                <div className="info-registro-frequencias">
                                                  {frequencias1.map((item, key) => (
                                                    <div style={{background: item.presenca === 'Sim' ? 'green' : 'red'}} key={key}>
                                                      <span>{format(parseISO(item.data), 'dd/MM/yyyy')}</span>
                                                      <span>{item.presenca}</span>
                                                    </div>
                                                  ))}
                                                </div>
                                              </div>
                                            )}
                                            {frequencias1.length === 0 && (
                                              <div className="notfound">
                                                <span>Nenhum registro encontrado</span>
                                              </div>
                                            )}
                                          </div>
                                          <div className="info-bloco">
                                            <div className="info-bloco-header">
                                                <span>2°</span>
                                                <span>Período</span>
                                            </div>
                                            {frequencias2.length > 0 && (
                                              <div className="info-bloco-body">
                                                <div className="info-registro-frequencias">
                                                  {frequencias2.map((item, key) => (
                                                    <div style={{background: item.presenca === 'Sim' ? 'green' : 'red'}} key={key}>
                                                      <span>{format(parseISO(item.data), 'dd/MM/yyyy')}</span>
                                                      <span>{item.presenca}</span>
                                                    </div>
                                                  ))}
                                                </div>
                                              </div>
                                            )}
                                            {frequencias2.length === 0 && (
                                              <div className="notfound">
                                                <span>Nenhum registro encontrado</span>
                                              </div>
                                            )}
                                          </div>
                                          <div className="info-bloco">
                                            <div className="info-bloco-header">
                                                <span>3°</span>
                                                <span>Período</span>
                                            </div>
                                            {frequencias3.length > 0 && (
                                              <div className="info-bloco-body">
                                                <div className="info-registro-frequencias">
                                                  {frequencias3.map((item, key) => (
                                                    <div style={{background: item.presenca === 'Sim' ? 'green' : 'red'}} key={key}>
                                                      <span>{format(parseISO(item.data), 'dd/MM/yyyy')}</span>
                                                      <span>{item.presenca}</span>
                                                    </div>
                                                  ))}
                                                </div>
                                              </div>
                                            )}
                                            {frequencias3.length === 0 && (
                                              <div className="notfound">
                                                <span>Nenhum registro encontrado</span>
                                              </div>
                                            )}
                                          </div>
                                          <div className="info-bloco">
                                            <div className="info-bloco-header">
                                                <span>4°</span>
                                                <span>Período</span>
                                            </div>
                                            {frequencias4.length > 0 && (
                                              <div className="info-bloco-body">
                                                <div className="info-registro-frequencias">
                                                  {frequencias4.map((item, key) => (
                                                    <div style={{background: item.presenca === 'Sim' ? 'green' : 'red'}} key={key}>
                                                      <span>{format(parseISO(item.data), 'dd/MM/yyyy')}</span>
                                                      <span>{item.presenca}</span>
                                                    </div>
                                                  ))}
                                                </div>
                                              </div>
                                            )}
                                            {frequencias4.length === 0 && (
                                              <div className="notfound">
                                                <span>Nenhum registro encontrado</span>
                                              </div>
                                            )}
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  )}
                                  {frequencias.length === 0 && (
                                    <div className="paperContantDois">
                                        <div>Nenhum registro encontrado, selecione o ano letivo</div>
                                    </div>
                                  )}
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
        </div>
    );
}
