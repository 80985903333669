import React, { useState, useEffect } from 'react';
import '../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
// import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FadeLoader from "react-spinners/FadeLoader";
import api from '../../services/api';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import ControlPoint from '@material-ui/icons/ControlPoint';
import Edit from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import {format} from 'date-fns';

export default function Index() {
    let history = useHistory();
    const location = useLocation();
    const [skeleton, seSkeleton] = useState(true);
    const [loading, setloading] = useState(false);

    //cadastro
    const [tipo, setTipo] = useState('');
    const [txtBtn, setTxtBtn] = useState('');
    const [idEditar, setIdEditar] = useState('');
    const [polos, setIPolos] = useState([]);
    const [polo, setPolo] = useState('');
    const [povoados, setPovoados] = useState([]);
    const [povoado, setPovoado] = useState('');
    const [nomeEscola, setNomaEscola] = useState('');
    const [esnderecoEscola, setEnderecoEscola] = useState('');
    const [ePolo, setEPolo] = useState('Não');
    const [grupos, setGrupos] = useState([]);
    const [grupo, setGrupo] = useState('');
    const [coordenadores, setCoordenadores] = useState([]);
    const [coordenador, setCoordenador] = useState('');
    const [gestores, setGestores] = useState([]);
    const [gestor, setGestor] = useState('');
    const [status, setStatus] = useState('Ativo');
    const [title, setTitle] = useState('Cadastrar Diário');
    const [paraPolo, setParaPolo] = useState(false);


    const [idEscola, setIdEscola] = useState('');
    const [idAnoLetivo, setIdAnoLetivo] = useState('');
    const [ano, setAno] = useState('');
    const [escola, setEscola] = useState('');
    const [professor, setProfessor] = useState('');
    const [idProfessor, setIdProfessor] = useState('');
    const [anoLetivo, setAnoLetivo] = useState('');
    const [turno, setTurno] = useState('');
    const [turma, setTurma] = useState('');

    const [periodo, setPeriodo] = useState('');

    useEffect(() => {
        // Atualiza o titulo do documento usando a API do browser
        console.log("um", location.pathname);
        console.log("dois", location);
        carregarGrupos()
        carregarPolo();
        carregarCoordenadores();
        carregarGestores();
        buscarPeriodo();
        if(location.state){
          setTipo(location.state.type)
          setTxtBtn(location.state.type === 'view' ? 'Editar' : 'Salvar')
        }else{
          history.push('/')
        }
        if(location.state.type === 'view'){
          editar(parseInt(location.state.id))
          setTitle('Configurações do Diário')
        }else{
          setloading(false)
        }
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    async function buscarPeriodo(){
      const dataAtual = format(new Date(), 'yyyy-MM-dd')
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };

      try {
        const {data: periodo} = await api.get(`pesquisar-periodo/${dataAtual}`, config);
        console.log("buscarPeriodo", periodo)
        setPeriodo(periodo[0])
 
      } catch (error) {
        console.log("Erro buscar período", error, error.response);
        toast.error('🦄 Erro buscar período, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setloading(false);
      }
    }

    async function carregarGrupos() {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get('grupo', config);
          console.log('resultado grupos', result);
          setGrupos(result);
      } catch (error) {
          console.log('Erro ao buscar grupos', error, error.response);
      }

    } 
    async function carregarPolo() {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get('polo', config);
          console.log('resultado polos', result);
          setIPolos(result);
      } catch (error) {
          console.log('Erro ao buscar polo', error, error.response);
      }
    } 

    async function carregarPovoados(idpolo) {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get(`povoado-por-polo/${idpolo}`, config);
          console.log('resultado povoados', result);
          setPovoados(result);
      } catch (error) {
          console.log('Erro ao buscar povoado', error, error.response);
      }
    } 

    async function carregarCoordenadores() {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get(`usuario-por-role/coordenador/desk/null`, config);
          console.log('resultado coordenadores', result);
          setCoordenadores(result);
      } catch (error) {
          console.log('Erro ao buscar coordenadores', error, error.response);
      }
    } 
    async function carregarGestores() {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get(`usuario-por-role/gestor/desk/null`, config);
          console.log('resultado gestores', result);
          setGestores(result);
      } catch (error) {
          console.log('Erro ao buscar coordenadores', error, error.response);
      }
    } 


    function handleChangeGrupos(event){
      setGrupo(event.target.value);
    }
    function handleChangePolos(event){
      setPolo(event.target.value);
      carregarPovoados(event.target.value)
    }
    function handleChangePovoados(event){
      setPovoado(event.target.value);
    }
    function handleChangeNomeEscola(event){
      setNomaEscola(event.target.value);
    }
    function handleChangeEnderecoEscola(event){
      setEnderecoEscola(event.target.value);
    }
    function handleChangeEPolo(event){
      setEPolo(event.target.value);
    }
    function handleChangeCoordenador(event){
      setCoordenador(event.target.value);
    }
    function handleChangeGestor(event){
      setGestor(event.target.value);
    }

    function habilitarPolos(){
      setParaPolo(!paraPolo)
    }
    function habilitarEdit(){
      setDisabled(false)
    }

    async function deletar() {
      setloading(true)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: prod} = await api.delete(`/escola/${idEditar}`, config);
        console.log("prod", prod)
        // limpaCampos();
        setloading(false);
        // setOpenAlert(true)
        // setTypeAlert('success')
        // setMsgAlert('Fatura foi deletada com sucesso.')
        toast.success('🦄 Povoado deletado com sucesso!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push('/escolas')
      } catch (error) {
        console.log("Erro ao deletar", error, error.response);
        setloading(false);
        // setOpenAlert(true)
        // setTypeAlert('error')
        // setMsgAlert('Erro ao deletar, tente novamente')
        toast.error('🦄 Erro ao deletar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    function abrirComponent(rota, iddiario, idEscola, anoLetivo, turno, turma, periodo, adicionais){
      // history.push('/fatura-create');
      history.push({
          pathname: rota,
          // search: '?query=abc',
          state: { 
            iddiario: iddiario ,
            idEscola,
            anoLetivo,
            turno,
            turma,
            periodo,
            adicionais,
            nivelAcesso: location.state.nivelAcesso
          },
      });
    }

    async function editar(id){
      setIdEditar(id)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: edit} = await api.get(`/diario/${id}`, config);
        const result = edit[0];
        console.log("Dados do diario para editar", result)
        setAno(result.ano)
        setEscola(result.escola.escola_nome)
        setIdEscola(result.escola.id)
        setProfessor(result.user.name)
        setIdProfessor(result.user_id)
        setAnoLetivo(result.anoLetivo.nome_ano)
        setIdAnoLetivo(result.anoLetivo.id)
        setTurno(result.turno)
        setTurma(result.escolaTurma.turma_nome)
        // if(result.polo.length > 0){
        //   setParaPolo(true)
        // }
        // setloading(false);
        // setGrupo(result.grupo_id)
        // if(result.polo.length > 0){
        //   carregarPovoados(result.polo[0].id)
        //   setPolo(result.polo[0].id)
        // }
        // if(result.povoado.length > 0){
        //   setPovoado(result.povoado[0].id)
        // }
        // setNomaEscola(result.escola_nome)
        // setEnderecoEscola(result.escola_endereco)
        // setEPolo(result.escola_polo)
        // setCoordenador(result.coordenador[0].id)
        // setGestor(result.gestor[0].id)
        // setStatus(result.status)
      } catch (error) {
        console.log("Erro buscar dados para editar", error, error.response);
        toast.error('🦄 Erro ao buscar dados para editar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setloading(false);
      }
    }
  

    async function salvar(event) {
      event.preventDefault();
      // console.log("event", event)
      setloading(true)
  
      //setloading(true);
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
  
      let form_data = new FormData();

      form_data.append("grupo_id", grupo);
      form_data.append("escola_nome", nomeEscola);
      form_data.append("escola_endereco", esnderecoEscola);
      form_data.append("status", status);
      
      if(tipo === 'new'){
        await api
          .post(`/escola`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Adicionando escola", res.data)
              limpaCampos();
              setloading(false);
              vincularPolo(res.data.id)
              vincularPovoado(res.data.id)
              vincularCoordenador(res.data.id)
              vincularGestor(res.data.id)
              toast.success('🦄 Escola cadastrada com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            console.log("response: ", error.response.data.message)
            toast.error(`🦄 Erro ao cadatrar Escola, ${error.response.data.message}!`, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setloading(false);
            // throw error;
          });

          async function vincularPolo(idescola){
            // polo-povoado-escola
            let form_data_polo = new FormData();
            form_data_polo.append("escola_id", idescola);
            form_data_polo.append("polo_id", polo);
            await api
              .post(`/escola-polo`, form_data_polo, {
                headers: {
                  "content-type": "application/json",
                  Authorization: "bearer " + token,
                  Accept: "application/json"
                }
              })
              .then(res => {
                  console.log("Vinculando escola ao polo", res.data)
              })
              .catch(error => {
                console.log("error: ", error, error.response)
              });
          }

          async function vincularPovoado(idescola){
            // polo-povoado-escola
            let form_data_polo = new FormData();
            form_data_polo.append("escola_id", idescola);
            form_data_polo.append("povoado_id", povoado);
            await api
              .post(`/escola-povoado`, form_data_polo, {
                headers: {
                  "content-type": "application/json",
                  Authorization: "bearer " + token,
                  Accept: "application/json"
                }
              })
              .then(res => {
                  console.log("Vinculando escola ao povoado", res.data)
              })
              .catch(error => {
                console.log("error vincular povoado: ", error, error.response)
              });
          }

          async function vincularCoordenador(idescola){
            // polo-povoado-escola
            let form_data_polo = new FormData();
            form_data_polo.append("escola_id", idescola);
            form_data_polo.append("user_id", coordenador);
            await api
              .post(`/escola-coordenador`, form_data_polo, {
                headers: {
                  "content-type": "application/json",
                  Authorization: "bearer " + token,
                  Accept: "application/json"
                }
              })
              .then(res => {
                  console.log("Vinculando escola ao coordenador", res.data)
              })
              .catch(error => {
                console.log("error vincular coordenador: ", error, error.response)
              });
          }
  
          async function vincularGestor(idescola){
            // polo-povoado-escola
            let form_data_polo = new FormData();
            form_data_polo.append("escola_id", idescola);
            form_data_polo.append("user_id", gestor);
            await api
              .post(`/escola-gestor`, form_data_polo, {
                headers: {
                  "content-type": "application/json",
                  Authorization: "bearer " + token,
                  Accept: "application/json"
                }
              })
              .then(res => {
                  console.log("Vinculando escola ao gestor", res.data)
              })
              .catch(error => {
                console.log("error vincular gestor: ", error, error.response)
              });
          }
  
      }else if(tipo === 'view'){
  
        await api
          .post(`/escola/${idEditar}`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Povoado editado", res.data)
              setloading(false);
              toast.success('🦄 Escola editada com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              editarVincularPolo(res.data.id)
              editarVincularPovoado(res.data.id)
              editarVincularCoordenador(res.data.id)
              editarVincularGestor(res.data.id)
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            toast.error('🦄 Erro ao editar Escola, tente novamente ou fale com o suporte!', {
              position: "bottom-left",
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setloading(false);
          });

          async function editarVincularPolo(idescola){
            // polo-povoado-escola
            let form_data_polo = new FormData();
            form_data_polo.append("escola_id", idescola);
            form_data_polo.append("polo_id", polo);
            await api
              .post(`/escola-polo/${idescola}`, form_data_polo, {
                headers: {
                  "content-type": "application/json",
                  Authorization: "bearer " + token,
                  Accept: "application/json"
                }
              })
              .then(res => {
                  console.log("editar vincular escola ao polo", res.data)
              })
              .catch(error => {
                console.log("erro ao editar vincular escola ao polo: ", error, error.response)
              });
          }

          async function editarVincularPovoado(idescola){
            // polo-povoado-escola
            let form_data_polo = new FormData();
            form_data_polo.append("escola_id", idescola);
            form_data_polo.append("povoado_id", povoado);
            await api
              .post(`/escola-povoado/${idescola}`, form_data_polo, {
                headers: {
                  "content-type": "application/json",
                  Authorization: "bearer " + token,
                  Accept: "application/json"
                }
              })
              .then(res => {
                  console.log("editar Vinculando escola ao povoado", res.data)
                  editar(idescola)
              })
              .catch(error => {
                console.log("erro ao editar vincular povoado: ", error, error.response)
              });
          }

          async function editarVincularCoordenador(idescola){
            // polo-povoado-escola
            let form_data_polo = new FormData();
            form_data_polo.append("escola_id", idescola);
            form_data_polo.append("user_id", coordenador);
            await api
              .post(`/escola-coordenador/${idescola}`, form_data_polo, {
                headers: {
                  "content-type": "application/json",
                  Authorization: "bearer " + token,
                  Accept: "application/json"
                }
              })
              .then(res => {
                  console.log("editar vinculando escola ao coordenador", res.data)
              })
              .catch(error => {
                console.log("error editar vincular coordenador: ", error, error.response)
              });
          }

          async function editarVincularGestor(idescola){
            // polo-povoado-escola
            let form_data_polo = new FormData();
            form_data_polo.append("escola_id", idescola);
            form_data_polo.append("user_id", gestor);
            await api
              .post(`/escola-gestor/${idescola}`, form_data_polo, {
                headers: {
                  "content-type": "application/json",
                  Authorization: "bearer " + token,
                  Accept: "application/json"
                }
              })
              .then(res => {
                  console.log("editar vinculando escola ao gestor", res.data)
              })
              .catch(error => {
                console.log("error editar vincular gestor: ", error, error.response)
              });
          }
  
      }
  
    }

    function limpaCampos(){
      setNomaEscola('')
      setEnderecoEscola('')
    }

    //validações
    const validation = [];
    validation.push(grupo);
    validation.push(nomeEscola);
    validation.push(esnderecoEscola);


    const validForm = validation.reduce((all, v) => all && v);

  

    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link className="linkBreadcrumbs" color="inherit" href="/">
                                    Dashboard
                                </Link>
                                <Link
                                  className="linkBreadcrumbs"
                                  color="inherit"
                                  href="/escolas-coordenadores"
                                  >
                                  Escolas
                                </Link>
                                <Link
                                  className="linkBreadcrumbs"
                                  color="inherit"
                                  style={{ cursor: 'pointer'}}
                                  onClick={() => {
                                    history.goBack()
                                  }}
                                  >
                                  Opções
                                </Link>
                                <Typography variant="body2" color="textPrimary">
                                    {title}
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>{title}</h2>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                    <div className="diario">
                                      <div className="diarioHeader">
                                        <div className="ih">
                                          <span>Ano letivo:</span>
                                          <span><strong>{ano}</strong></span>
                                        </div>
                                        <div className="ih">
                                          <span>Escola:</span>
                                          <span><strong>{escola}</strong></span>
                                        </div>
                                        <div className="ih">
                                          <span>Professor:</span>
                                          <span><strong>{professor}</strong></span>
                                        </div>
                                        <div className="ih">
                                          <span>Ano:</span>
                                          <span><strong>{anoLetivo}</strong></span>
                                        </div>
                                        <div className="ih">
                                          <span>Turno:</span>
                                          <span><strong>{turno}</strong></span>
                                        </div>
                                        <div className="ih">
                                          <span>Turma:</span>
                                          <span><strong>{turma}</strong></span>
                                        </div>
                                        <div className="ih">
                                          <span>Período:</span>
                                          <span><strong>{periodo.nome_periodo}</strong></span>
                                        </div>
                                      </div>
                                      <div className="diarioItem">
                                        <Tooltip className="it" title={`Lista de todos os alunos da turma`} arrow>
                                          <IconButton 
                                            onClick={() => {
                                              abrirComponent(
                                                '/diario-identificacao-turma', 
                                                parseInt(location.state.id), 
                                                idEscola, 
                                                idAnoLetivo, 
                                                turno,
                                                turma,
                                                periodo,
                                                {
                                                  'escola' : escola,
                                                  'professor' : professor,
                                                  'anoLetivo' : anoLetivo
                                                }
                                              )
                                            }} 
                                            color="inherit"
                                          >
                                            <span>Identificaçao da turma</span>   
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip className="it" title={`Diagnóstico inicial de cada aluno`} arrow>
                                          <IconButton
                                           onClick={() => {
                                              abrirComponent(
                                                '/diario-diagnostico-inicial', 
                                                parseInt(location.state.id), 
                                                idEscola, 
                                                idAnoLetivo, 
                                                turno,
                                                turma,
                                                periodo,
                                                {
                                                  'escola' : escola,
                                                  'professor' : professor,
                                                  'anoLetivo' : anoLetivo
                                                }
                                              )
                                            }} 
                                           color="inherit">
                                            <span>Diagnóstico inicial</span>   
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip className="it" title={`Registro de frequência de cada aluno`} arrow>
                                          <IconButton 
                                            onClick={() => {
                                              abrirComponent(
                                                '/diario-registro-frenquencia', 
                                                parseInt(location.state.id), 
                                                idEscola, 
                                                idAnoLetivo, 
                                                turno,
                                                turma,
                                                periodo,
                                                {
                                                  'escola' : escola,
                                                  'professor' : professor,
                                                  'anoLetivo' : anoLetivo
                                                }
                                              )
                                            }} 
                                            color="inherit">
                                            <span>Registro de frequência</span>   
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip className="it" title={`Sequência Didática`} arrow>
                                        {/* <Tooltip className="it" title={`Experiências e Aprendizagem desenvolvida na sala de aula`} arrow> */}
                                          <IconButton
                                          onClick={() => {
                                            abrirComponent(
                                              '/experiencias-desenvolvidas', 
                                              parseInt(location.state.id), 
                                              idEscola, 
                                              idAnoLetivo, 
                                              turno,
                                              turma,
                                              periodo,
                                              {
                                                'escola' : escola,
                                                'professor' : professor,
                                                'anoLetivo' : anoLetivo,
                                                'idProfessor': idProfessor
                                              }
                                            )
                                          }}  
                                          color="inherit">
                                            {/* <span>Experiências e Aprendizagem</span>    */}
                                            <span>Sequência Didática</span>   
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip className="it" title={`Relatório de avaliação individual`} arrow>
                                          <IconButton
                                            onClick={() => {
                                              abrirComponent(
                                                '/relatorio-avaliacao-individual', 
                                                parseInt(location.state.id), 
                                                idEscola, 
                                                idAnoLetivo, 
                                                turno,
                                                turma,
                                                periodo,
                                                {
                                                  'escola' : escola,
                                                  'professor' : professor,
                                                  'anoLetivo' : anoLetivo
                                                }
                                              )
                                            }}  
                                            color="inherit">
                                            <span>Relatório de avaliação</span>   
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip className="it" title={`Reorganização de planejamento`} arrow>
                                          <IconButton
                                            onClick={() => {
                                              abrirComponent(
                                                '/reorganizacao-planejamento', 
                                                parseInt(location.state.id), 
                                                idEscola, 
                                                idAnoLetivo, 
                                                turno,
                                                turma,
                                                periodo,
                                                {
                                                  'escola' : escola,
                                                  'professor' : professor,
                                                  'anoLetivo' : anoLetivo
                                                }
                                              )
                                            }}  
                                            color="inherit">
                                            <span>Reorganização de planejamento</span>   
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip className="it" title={`Parecer técnico/orientação`} arrow>
                                          <IconButton
                                            onClick={() => {
                                              abrirComponent(
                                                '/parecer-tecnico', 
                                                parseInt(location.state.id), 
                                                idEscola, 
                                                idAnoLetivo, 
                                                turno,
                                                turma,
                                                periodo,
                                                {
                                                  'escola' : escola,
                                                  'professor' : professor,
                                                  'anoLetivo' : anoLetivo
                                                }
                                              )
                                            }}  
                                            color="inherit">
                                            <span>Parecer técnico/orientação</span>   
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip className="it" title={`Relatório final individual`} arrow>
                                          <IconButton
                                            onClick={() => {
                                              abrirComponent(
                                                '/relatorio-final-individual', 
                                                parseInt(location.state.id), 
                                                idEscola, 
                                                idAnoLetivo, 
                                                turno,
                                                turma,
                                                periodo,
                                                {
                                                  'escola' : escola,
                                                  'professor' : professor,
                                                  'anoLetivo' : anoLetivo
                                                }
                                              )
                                            }}  
                                            color="inherit">
                                            <span>Relatório final individual</span>   
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip className="it" title={`Quadro demonstrativo de faltas anuais`} arrow>
                                          <IconButton
                                            onClick={() => {
                                              abrirComponent(
                                                '/faltas-anuais', 
                                                parseInt(location.state.id), 
                                                idEscola, 
                                                idAnoLetivo, 
                                                turno,
                                                turma,
                                                periodo,
                                                {
                                                  'escola' : escola,
                                                  'professor' : professor,
                                                  'anoLetivo' : anoLetivo,
                                                }
                                              )
                                            }}  
                                            color="inherit">
                                            <span>Faltas anuais</span>   
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip className="it" title={`Quadro demonstrativo de carga horária anual`} arrow>
                                          <IconButton
                                            onClick={() => {
                                              abrirComponent(
                                                '/carga-horaria-anual', 
                                                parseInt(location.state.id), 
                                                idEscola, 
                                                idAnoLetivo, 
                                                turno,
                                                turma,
                                                periodo,
                                                {
                                                  'escola' : escola,
                                                  'professor' : professor,
                                                  'anoLetivo' : anoLetivo,
                                                  'idProfessor': idProfessor
                                                }
                                              )
                                            }}  
                                            color="inherit">
                                            <span>Carga horária</span>   
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                        {/* <div onClick={abrirPageServicos} className="itemConfigDois">
                                          <SettingsApplicationsIcon style={{ fontSize: 40, color: 'rgb(63, 1, 121)' }} />
                                          <div>Saques</div>
                                        </div> */}
                                        {/* <div onClick={habilitarPolos} style={{background: paraPolo ? 'rgb(63, 1, 121)' : ''}} className="itemConfigDois">
                                          <ControlPoint style={{ fontSize: 40, color: paraPolo ? '#fff' : 'rgb(63, 1, 121)' }} />
                                          <div style={{color: paraPolo ? '#fff' : ''}}>Polos</div>
                                        </div> */}
                                        {/* <div onClick={habilitarEdit} className="itemConfigDois">
                                          <Edit style={{ fontSize: 40, color: 'rgb(63, 1, 121)' }} />
                                          <div>Editar perfil</div>
                                        </div> */}
                                    </div>
                                </Paper>
                            )}
                        </Grid>
                        {/* {!idEditar && (
                          <Grid item xs={12} md={12} lg={12}>
                              {skeleton && <Skeleton variant="rect" className="paper" />}
                              {!skeleton && (
                                  <Paper className={'paper'}>
                                      <div className="paperContant">
                                        {idEditar}
                                          <form noValidate autoComplete="off" onSubmit={salvar}>
                                            <Grid container spacing={3}>
                                              <Grid item xs={12} md={4}>
                                                <FormControl variant="outlined" fullWidth>
                                                  <InputLabel id="select-polo">Grupos de escola</InputLabel>
                                                  <Select
                                                    labelId="select-polo"
                                                    id="select-polo"
                                                    value={grupo}
                                                    onChange={handleChangeGrupos}
                                                    label="Grupos de escola"
                                                  >
                                                    <MenuItem value="">
                                                      <em>Nenhuma</em>
                                                    </MenuItem>
                                                    {grupos.map((item, key) => (
                                                      <MenuItem key={key} value={item.id}>{item.grupo_nome}</MenuItem>
                                                    ))}
                                                  </Select>
                                                </FormControl>
                                              </Grid>
                                              {paraPolo && (
                                                <Grid item xs={12} md={4}>
                                                  <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="select-polo">Polos</InputLabel>
                                                    <Select
                                                      labelId="select-polo"
                                                      id="select-polo"
                                                      value={polo}
                                                      onChange={handleChangePolos}
                                                      label="Polos"
                                                    >
                                                      <MenuItem value="">
                                                        <em>Nenhuma</em>
                                                      </MenuItem>
                                                      {polos.map((item, key) => (
                                                        <MenuItem key={key} value={item.id}>{item.polo_nome}</MenuItem>
                                                      ))}
                                                    </Select>
                                                  </FormControl>
                                                </Grid>
                                              )}
                                              {paraPolo && (
                                                <Grid item xs={12} md={4}>
                                                  <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="select-povoados">Povoados</InputLabel>
                                                    <Select
                                                      labelId="select-povoados"
                                                      id="select-povoados"
                                                      value={povoado}
                                                      onChange={handleChangePovoados}
                                                      label="Povoados"
                                                    >
                                                      <MenuItem value="">
                                                        <em>Nenhuma</em>
                                                      </MenuItem>
                                                      {povoados.map((item, key) => (
                                                        <MenuItem key={key} value={item.id}>{item.povoado_nome}</MenuItem>
                                                      ))}
                                                    </Select>
                                                  </FormControl>
                                                </Grid>
                                              )}
                                              <Grid item xs={12} md={4}>
                                                  <FormControl fullWidth variant="outlined">
                                                    <InputLabel htmlFor="name">Nome da Escola</InputLabel>
                                                    <OutlinedInput
                                                      error={!nomeEscola ? true : false}
                                                      id="name"
                                                      label={'Nome da Escola'}
                                                      value={nomeEscola}
                                                      onChange={handleChangeNomeEscola}
                                                      labelWidth={60}
                                                    />
                                                  </FormControl>
                                              </Grid>
                                              <Grid item xs={12} md={4}>
                                                  <FormControl fullWidth variant="outlined">
                                                    <InputLabel htmlFor="name">Endereço da Escola</InputLabel>
                                                    <OutlinedInput
                                                      error={!esnderecoEscola ? true : false}
                                                      id="name"
                                                      label={"Endereço da Escola"}
                                                      value={esnderecoEscola}
                                                      onChange={handleChangeEnderecoEscola}
                                                      labelWidth={60}
                                                    />
                                                  </FormControl>
                                              </Grid>
                                              <Grid item xs={12} md={4}>
                                                <FormControl variant="outlined" fullWidth>
                                                  <InputLabel id="select-coordenador">Coordenador</InputLabel>
                                                  <Select
                                                    labelId="select-coordenador"
                                                    id="select-coordenador"
                                                    value={coordenador}
                                                    onChange={handleChangeCoordenador}
                                                    label="Coordenador"
                                                  >
                                                    <MenuItem value="">
                                                      <em>Nenhuma</em>
                                                    </MenuItem>
                                                    {coordenadores.map((item, key) => (
                                                      <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                                    ))}
                                                  </Select>
                                                </FormControl>
                                              </Grid>
                                              <Grid item xs={12} md={4}>
                                                <FormControl variant="outlined" fullWidth>
                                                  <InputLabel id="select-gestor">Gestor</InputLabel>
                                                  <Select
                                                    labelId="select-gestor"
                                                    id="select-gestor"
                                                    value={gestor}
                                                    onChange={handleChangeGestor}
                                                    label="Gestor"
                                                  >
                                                    <MenuItem value="">
                                                      <em>Nenhuma</em>
                                                    </MenuItem>
                                                    {gestores.map((item, key) => (
                                                      <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                                    ))}
                                                  </Select>
                                                </FormControl>
                                              </Grid>
                                              <Grid item xs={12} md={12}>
                                                  <Button
                                                    disabled={!validForm}
                                                    style={{maxWidth: 150, height: 50, marginRight: 10}}
                                                    type="submit" variant="outlined" color="primary"
                                                  >
                                                    {txtBtn}
                                                  </Button>
                                                  
                                                  {tipo === 'view' &&(
                                                    <Button
                                                      disabled={!validForm}
                                                      style={{maxWidth: 150, height: 50}}
                                                      variant="outlined" color="secondary"
                                                      onClick={deletar}
                                                    >
                                                      Deletar
                                                    </Button>
                                                  ) }
                                              </Grid>
                                            </Grid>
  
                                          </form>
                                          <ToastContainer
                                            position="top-right"
                                            autoClose={5000}
                                            hideProgressBar={false}
                                            newestOnTop={false}
                                            closeOnClick
                                            rtl={false}
                                            pauseOnFocusLoss
                                            draggable
                                            pauseOnHover
                                          />
                                      </div>
                                  </Paper>
                              )}
                        </Grid>
                        )} */}
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
            {loading && (
                <div className="loading">
                    <FadeLoader color={'rgb(63, 1, 121)'} loading={loading} size={150} />
                </div>
            )}
        </div>
    );
}
