import React, { useState, useEffect } from 'react';
/* eslint-disable */
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import '../../../../styles/global.css';
import Settings from '@material-ui/icons/Settings';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import Add from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../../services/api';
import {format, subDays, addDays, isAfter, parseISO} from 'date-fns';
import Tooltip from '@material-ui/core/Tooltip';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import CloseBtn from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';


const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    container: {
        overflow: 'none'
    }
});

export default function Index(props) {
    const classes = useStyles();
    let history = useHistory();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);

    const columns = props.columns

    const rows = props.rows
    console.log('props.rows ', props.rows)

    const iddodiario = props.iddodiario;

    const periodo = props.periodo;

    const nivelAcesso = props.nivelAcesso;

    const tela = props.tela ? props.tela : 'index';

    useEffect(() => {
      //
      
    }, []);

    function editar(id, row){
        console.log("row", row)
        console.log("tela", tela)
        if(tela === 'dashboard'){
            // history.push('/fatura-create');
            history.push({
                pathname: '/parecer-tecnico-create',
                // search: '?query=abc',
                state: { 
                  type: 'edit', 
                  id: id,
                  iddodiario: row.row.diario_id,
                  periodo: row.row.periodo_id,
                  nivelAcesso: nivelAcesso
                }
            });

        }else{
            // history.push('/fatura-create');
            history.push({
                pathname: '/parecer-tecnico-create',
                // search: '?query=abc',
                state: { 
                  type: 'edit', 
                  id: id,
                  iddodiario: iddodiario,
                  periodo: periodo,
                  nivelAcesso: nivelAcesso
                }
            });

        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper className={'paper'}>
            <TableContainer style={{border: '1px solid #212121'}} className={classes.container}>
                <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, key) => (
                                <TableCell
                                    key={column.id}
                                    style={{ 
                                      width: key === 0 ? '80%' : '10%', 
                                      textAlign: key != 0 ? 'center' : 'left',
                                      background: '#e8e8e8',
                                      borderRight: key <= 2 ? '1px solid #212121' : ''
                                    }}
                                    align={key != 0 ? 'right' : 'left'}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow
                                        className="tableRow"
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id}>
                                          <TableCell style={{borderRight: '1px solid #212121'}} component="th" scope="row">
                                              {row.periodo}
                                              <p style={{fontSize: 14, fontStyle: 'italic', background: '#f8f8f8', padding: 5, color: 'green'}}>
                                                  {row.parece_tecnico}
                                              </p>
                                          </TableCell>
                                          <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121'}}>{format(parseISO(row.created_at), 'dd/MM/yyyy')}</TableCell>
                                          <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121'}}>{format(parseISO(row.updated_at), 'dd/MM/yyyy')}</TableCell>
                                          <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121'}}>
                                              {row.parece_tecnico_pendente === 'Pendente' && (
                                                  <Chip label={row.parece_tecnico_pendente} color={'secondary'} />
                                              )}
                                              {row.parece_tecnico_pendente === 'Análise do Coordenador' && (
                                                  <Chip label={row.parece_tecnico_pendente} color={'primary'} />
                                              )}
                                              {row.parece_tecnico_pendente === 'Finalizado' && (
                                                  <Chip label={row.parece_tecnico_pendente} style={{background: 'green', color: '#fff'}}/>
                                              )}
                                          </TableCell>
                                          <TableCell align="right" style={{textAlign: 'center'}}>
                                            <Tooltip title={"Ver o parecer técnico"} aria-label="add">
                                              <IconButton onClick={() => { editar(row.id, tela === 'dashboard' ? row : []) }} color="inherit">
                                                  <InsertCommentIcon style={{ color:'green'}} />
                                              </IconButton>
                                            </Tooltip>
                                          </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Por página'}
            />
            
        </Paper>
    );
}
