import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableEscolas from '../../../Components/Tables/escolas-coordenadores';

export default function Index() {
    let history = useHistory();
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);
    // const [pesquisar, setPesquisar] = useState('');

    useEffect(() => {
      // Atualiza o titulo do documento usando a API do browser
      escolas()
      setTimeout(function () {
          seSkeleton(false);
      }, 3000);
    }, []);

    
    async function escolas(){
      var token = localStorage.getItem('@token_diario_web');
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data : result} = await api.get('escola', config);
        console.log("Result escolas", result)
        setData(result)
        toast.success('🦄 Carregando escolas!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro ao carregar escolas", error, error.response);
        toast.error('🦄 Error ao carregar escolas!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    function deletando(){
      escolas()
    }

    function handleChangePesquisar(event){
      if(event.target.value){
        pesquisarCliente(event.target.value)
      }else{
        escolas()
      }
      // setPesquisar(event.target.value)
    }

    async function pesquisarCliente(termo) {
      //setLoading(true);
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
  
  
      try {
        const {data : result} = await api.get(`pesquisar-escola/${encodeURI(termo)}`, config);
        console.log("Result pesquisar", result)
        setData(result)
      } catch (error) {
        console.log("Erro ao buscar ", error, error.response);
      }
    }
  

    //tabela
    function createData(id, name, endereco, escola_polo_sede, status) {
      return {id, name, endereco, escola_polo_sede, status};
    }
  
    var rows = [];
    if(data){
      rows = data.map((row) => {
        return createData(
          row.id, 
          row.escola_nome, 
          row.escola_endereco, 
          row.escola_polo_sede, 
          row.status
        )
      })
    }
  
    const columns = [
      { id: 'name', numeric: false, disablePadding: true, label: 'Nome' },
      { id: 'endereco', numeric: false, disablePadding: true, label: 'Endereço' },
      { id: 'sedepolo', numeric: false, disablePadding: true, label: 'Sede/Polo' },
      { id: 'acao', numeric: true, disablePadding: false, label: '' },
    ];
  
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link className="linkBreadcrumbs" color="inherit" href="/">
                                    Dashboard
                                </Link>
                                {/* <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/getting-started/installation/"
                                    onClick={handleClick}>
                                    Core
                                </Link> */}
                                <Typography variant="body2" color="textPrimary">
                                    Escolas
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>Escolas</h2>
                            </div>
                        </Grid>
                        {/* <Grid item xs={12} md={12} lg={12}>
                            <Paper className={'paperSearch'}>
                                <TextField
                                    className="textField"
                                    id="outlined-secondary"
                                    label="Digite o nome da escola"
                                    variant="outlined"
                                    // color="secondary"
                                    // value={pesquisar}
                                    onChange={handleChangePesquisar}
                                />
                            </Paper>
                        </Grid> */}
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                  {rows.length !== 0 && (
                                    <div className="paperContant">
                                      <div style={{
                                        background: '#f7f7f7',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: 10
                                      }}>
                                        <span>Quantidade de escolas {' - '}</span>
                                        <span style={{fontSize: 21, fontWeight:'bold'}}>{'  '}{rows.length}</span>
                                      </div>
                                      <TableEscolas columns={columns} rows={rows} deletando={deletando}/>
                                    </div>
                                  )}
                                  {rows.length === 0 && (
                                    <div className="paperContantDois">
                                        <div>Nenhum registro encontrado</div>
                                    </div>
                                  )}
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
        </div>
    );
}
