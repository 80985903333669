import React, { useState, useEffect } from 'react';
/* eslint-disable */
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import '../../../../styles/global.css';
import Settings from '@material-ui/icons/Settings';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import Assignment from '@material-ui/icons/Assignment';
import ThumbUpAlt from '@material-ui/icons/ThumbUpAlt';
import ThumbDownAlt from '@material-ui/icons/ThumbDownAlt';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../../services/api';
import {format, subDays, addDays, isAfter, parseISO} from 'date-fns';
import Tooltip from '@material-ui/core/Tooltip';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
// import Add from '@material-ui/icons/Add';
import CloseBtn from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';


const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    container: {
        overflow: 'none'
    }
});

export default function Index(props) {
    console.log('Props', props);
    const classes = useStyles();
    let history = useHistory();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [openModal, setOpenModal] = useState(false);
    const [idAluno, setIdAluno] = useState('');
    const [nomeAluno, setNomeAluno] = useState('');
    const [diagnostico, setDiagnostico] = useState('');
    const [existeDisgnostico, setExisteDiagnostico] = useState(false);
    const [idEditar, setIdEditar] = useState('');
    const [diagnosticosCadastrados, setDiagnosticosCadastrados] = useState([]);
    const [periodos, setPeriodos] = useState([]);
    const [periodo, setPeriodo] = useState('');
    const [idPeriodo, setIdPeriodo] = useState('');
    const [frequenciasAluno, setFrequenciasAluno] = useState([]);
    const [totalPresenca, setTotalPresenca] = useState(0);
    const [totalFalta, setTotalFalta] = useState(0);

    const columns = props.columns

    const rows = props.rows

    const configr = props.config;

    const iddodiario = props.iddodiario;

    const dataAtual = format(new Date(), 'yyyy-MM-dd')
    const anoAtual = format(new Date(), 'yyyy')

    let myArray = [];

    const StyledTableCell = withStyles((theme) => ({
      head: {
        backgroundColor: '#f7f7f7',
        color: theme.palette.common.black,
      },
      body: {
        fontSize: 14,
      },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }))(TableRow);


    useEffect(() => {
      console.log("rows", rows)
      // buscarPeriodo()
    }, []);




    return (
        <Paper className={'paper'}>
            <TableContainer style={{border: '1px solid #212121'}} className={classes.container}>
                <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, key) => (
                                <TableCell
                                    key={column.id}
                                    style={{ 
                                      width: key === 0 ? '20%' : '20%', 
                                      textAlign: key != 0 ? 'center' : 'left',
                                      background: '#e8e8e8',
                                      borderRight: key <= 4 ? '1px solid #212121' : ''
                                    }}
                                    align={key != 0 ? 'right' : 'left'}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, key) => {
                          return (
                              <TableBody key={key}>
                                <TableRow
                                  className="tableRow"
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  >
                                    <TableCell style={{borderRight: '1px solid #212121', background: '#d6fbe7'}} component="th" scope="row">
                                      <div style={{display:'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection:'column'}}>
                                        <span>{row.periodo}</span>
                                        <span> de <strong>{format(new Date(row.inicio), 'dd/MM/yyyy')}</strong></span>
                                        <span> a <strong>{format(new Date(row.fim), 'dd/MM/yyyy')}</strong></span>
                                      </div>
                                    </TableCell>
                                    <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121'}}>
                                      {row.qtoaulas}
                                    </TableCell>
                                    <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121'}}>
                                      {row.aulas.length}
                                    </TableCell>
                                    <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121'}}>
                                      {'-'}
                                    </TableCell>
                                    <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121'}}>
                                      {row.qtoaulas - row.aulas.length}
                                    </TableCell>
                                    <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121'}}>
                                      <strong>
                                        {row.qtoaulas}
                                      </strong>
                                    </TableCell>
                                </TableRow>
                                {key === 1 && (
                                  <TableRow
                                    className="tableRow"
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    >
                                      <TableCell style={{borderRight: '1px solid #212121', background: '#cfe9ff'}} component="th" scope="row">
                                        <div style={{display:'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection:'column'}}>
                                          <span>Total</span>
                                          <span>1° Semestre</span>
                                        </div>
                                      </TableCell>
                                      <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121', background: '#cfe9ff'}}>
                                        {parseInt(rows[0].qtoaulas) + parseInt(rows[1].qtoaulas)}
                                      </TableCell>
                                      <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121', background: '#cfe9ff'}}>
                                        {parseInt(rows[0].aulas.length) + parseInt(rows[1].aulas.length)}
                                      </TableCell>
                                      <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121', background: '#cfe9ff'}}>
                                        {'-'}
                                      </TableCell>
                                      <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121', background: '#cfe9ff'}}>
                                        {(rows[0].qtoaulas - rows[0].aulas.length) + (rows[1].qtoaulas - rows[1].aulas.length)}
                                      </TableCell>
                                      <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121', background: '#cfe9ff'}}>
                                        <strong>
                                          {parseInt(rows[0].qtoaulas) + parseInt(rows[1].qtoaulas)}
                                        </strong>
                                      </TableCell>
                                  </TableRow>
                                )}
                                {key === 3 && (
                                  <TableRow
                                    className="tableRow"
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    >
                                      <TableCell style={{borderRight: '1px solid #212121', background: '#cfe9ff'}} component="th" scope="row">
                                        <div style={{display:'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection:'column'}}>
                                          <span>Total</span>
                                          <span>2° Semestre</span>
                                        </div>
                                      </TableCell>
                                      <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121', background: '#cfe9ff'}}>
                                        {parseInt(rows[2].qtoaulas) + parseInt(rows[3].qtoaulas)}
                                      </TableCell>
                                      <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121', background: '#cfe9ff'}}>
                                        {parseInt(rows[2].aulas.length) + parseInt(rows[3].aulas.length)}
                                      </TableCell>
                                      <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121', background: '#cfe9ff'}}>
                                        {'-'}
                                      </TableCell>
                                      <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121', background: '#cfe9ff'}}>
                                        {(rows[2].qtoaulas - rows[2].aulas.length) + (rows[3].qtoaulas - rows[3].aulas.length)}
                                      </TableCell>
                                      <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121', background: '#cfe9ff'}}>
                                        <strong>
                                          {parseInt(rows[2].qtoaulas) + parseInt(rows[3].qtoaulas)}
                                        </strong>
                                      </TableCell>
                                  </TableRow>
                                )}
                                {key === 3 && (
                                  <TableRow
                                    className="tableRow"
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    >
                                      <TableCell style={{borderRight: '1px solid #212121', background: '#f6fbb0'}} component="th" scope="row">
                                        <div style={{display:'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection:'column'}}>
                                          <span>Total Anual</span>
                                        </div>
                                      </TableCell>
                                      <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121', background: '#f6fbb0'}}>
                                        {parseInt(rows[0].qtoaulas) + parseInt(rows[1].qtoaulas) + parseInt(rows[2].qtoaulas) + parseInt(rows[3].qtoaulas)}
                                      </TableCell>
                                      <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121', background: '#f6fbb0'}}>
                                        {parseInt(rows[0].aulas.length) + parseInt(rows[1].aulas.length) + parseInt(rows[2].aulas.length) + parseInt(rows[3].aulas.length)}
                                      </TableCell>
                                      <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121', background: '#f6fbb0'}}>
                                        {'-'}
                                      </TableCell>
                                      <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121', background: '#f6fbb0'}}>
                                        {(rows[0].qtoaulas - rows[0].aulas.length) + (rows[1].qtoaulas - rows[1].aulas.length) + (rows[2].qtoaulas - rows[2].aulas.length) + (rows[3].qtoaulas - rows[3].aulas.length)}
                                      </TableCell>
                                      <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121', background: '#f6fbb0'}}>
                                        <strong>
                                          {parseInt(rows[0].qtoaulas) + parseInt(rows[1].qtoaulas) + parseInt(rows[2].qtoaulas) + parseInt(rows[3].qtoaulas)}
                                        </strong>
                                      </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            );
                        })}
                </Table>
            </TableContainer>
            {/* <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Por página'}
            /> */}
        </Paper>
    );
}
