import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './style.css';
// import Paper from '@material-ui/core/Paper';
/* eslint-disable */
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import AlunoEscola from '../Select/alunoEscola';
import DiariosAluno from '../Select/diariosAluno';
import api from '../../services/api';
import { format, parseISO } from 'date-fns'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    }
}));

export default function Index(props) {
    // console.log("props Dados do aluno", props)
    const classes = useStyles();
    const prop = props;
    const tipoUsuario = prop.tipoUsuario;
    const idaluno = prop.idaluno;
    const nomeAluno = prop.nomeAluno;
    const [idEscolaSelecionada, setIdEscolaSelecionada] = useState(null);
    const [idAnoLetivo, setIdAnoLetivo] = useState(null);
    const [idTurma, setIdTurma] = useState(null);
    const [diario, setDiario] = useState(null);
    const [data, setData] = useState([]);
    const [dataFrequencia, setDataFrequencia] = useState(null);
    const [dataFrequencia1Per, setDataFrequencia1Per] = useState([]);
    const [totalFrequencia1Per, setTotalFrequencia1Per] = useState(0);
    const [dataFrequencia2Per, setDataFrequencia2Per] = useState([]);
    const [totalFrequencia2Per, setTotalFrequencia2Per] = useState(0);
    const [dataFrequencia3Per, setDataFrequencia3Per] = useState([]);
    const [totalFrequencia3Per, setTotalFrequencia3Per] = useState(0);
    const [dataFrequencia4Per, setDataFrequencia4Per] = useState([]);
    const [totalFrequencia4Per, setTotalFrequencia4Per] = useState(0);
    const [dataAvaliacaoInicialIndividual, setDataAvaliacaoInicialIndividual] = useState([]);
    const [dataAvaliacaoInicialIndividual1Per, setDataAvaliacaoInicialIndividual1Per] = useState([]);
    const [dataAvaliacaoInicialIndividual2Per, setDataAvaliacaoInicialIndividual2Per] = useState([]);
    const [dataAvaliacaoInicialIndividual3Per, setDataAvaliacaoInicialIndividual3Per] = useState([]);
    const [dataAvaliacaoInicialIndividual4Per, setDataAvaliacaoInicialIndividual4Per] = useState([]);
    const [relatorioFinalIndividual, setRelatorioFinalIndividual] = useState([]);

    useEffect(() => {
      //
    }, []);

    function escolaSelecionada(dados){
      console.log("escolaSelecionada", dados)
      setIdEscolaSelecionada(dados.escola.id)
      setIdAnoLetivo(dados.ano_letivo_id)
      setIdTurma(dados.escola_turma_id)
    }
    function diarioSelecionado(dados){
      //recebe dados do diário selecionado
      console.log("dário selecionado", dados)
      setDiario(dados)
      diagnoticoInicial(idaluno, dados.id)
      frequenciaAluno(idaluno, dados.id)
      relatoriodeAvaliacao(idaluno, dados.id)
      diagnosticoFinal(idaluno, dados.id)
    }

    async function diagnoticoInicial(idaluno, iddiario) {
        var token = localStorage.getItem('@token_diario_web');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get(`diagnostico-idaluno/${idaluno}/${iddiario}`, config);
            console.log('Result diagnostico', result);
            setData(result);
        } catch (error) {
            console.log('Erro ao carregar diagnostico', error, error.response);
        }
    }

    async function frequenciaAluno(idaluno, iddiario) {
        var token = localStorage.getItem('@token_diario_web');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get(`frequencia-idaluno/${idaluno}/${iddiario}`, config);
            // console.log('Result frequencia', result);
            setDataFrequencia(result)
            var filtrado1Periodo = result.filter(function(obj2) { 
              return obj2.periodo.nome_periodo == '1° Período'; 
            });
            setDataFrequencia1Per(filtrado1Periodo)
            var filtrado2Periodo = result.filter(function(obj2) { 
              return obj2.periodo.nome_periodo == '2° Período'; 
            });
            setDataFrequencia2Per(filtrado2Periodo)
            var filtrado3Periodo = result.filter(function(obj2) { 
              return obj2.periodo.nome_periodo == '3° Período'; 
            });
            setDataFrequencia3Per(filtrado3Periodo)
            var filtrado4Periodo = result.filter(function(obj2) { 
              return obj2.periodo.nome_periodo == '4° Período'; 
            });
            setDataFrequencia4Per(filtrado4Periodo)

            //Qto 1° periodo
            var qtoPresenca1Periodo = filtrado1Periodo.filter(function(obj2) { 
              return obj2.presenca == 'Sim'; 
            });
            var qtoFalta1Periodo = filtrado1Periodo.filter(function(obj2) { 
              return obj2.presenca == 'Não'; 
            });

            setTotalFrequencia1Per({'sim': qtoPresenca1Periodo.length, 'nao' : qtoFalta1Periodo.length})
            //Qto 2° periodo
            var qtoPresenca2Periodo = filtrado2Periodo.filter(function(obj2) { 
              return obj2.presenca == 'Sim'; 
            });
            var qtoFalta2Periodo = filtrado2Periodo.filter(function(obj2) { 
              return obj2.presenca == 'Não'; 
            });
            setTotalFrequencia2Per({'sim': qtoPresenca2Periodo.length, 'nao' : qtoFalta2Periodo.length})

            //Qto 3° periodo
            var qtoPresenca3Periodo = filtrado3Periodo.filter(function(obj2) { 
              return obj2.presenca == 'Sim'; 
            });
            var qtoFalta3Periodo = filtrado3Periodo.filter(function(obj2) { 
              return obj2.presenca == 'Não'; 
            });
            setTotalFrequencia3Per({'sim': qtoPresenca3Periodo.length, 'nao' : qtoFalta3Periodo.length})

            //Qto 4° periodo
            var qtoPresenca4Periodo = filtrado4Periodo.filter(function(obj2) { 
              return obj2.presenca == 'Sim'; 
            });
            var qtoFalta4Periodo = filtrado4Periodo.filter(function(obj2) { 
              return obj2.presenca == 'Não'; 
            });
            setTotalFrequencia4Per({'sim': qtoPresenca4Periodo.length, 'nao' : qtoFalta4Periodo.length})
            
        } catch (error) {
            console.log('Erro ao carregar frequencia', error, error.response);
        }
    }

    async function relatoriodeAvaliacao(idaluno, iddiario) {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get(`relatorio-avaliacao-individual-idaluno/${idaluno}/${iddiario}`, config);
          console.log('Result avaliação individual', result);
          setDataAvaliacaoInicialIndividual(result);

          var filtrado1Periodo = result.filter(function(obj2) { 
            return obj2.periodo.nome_periodo == '1° Período'; 
          });
          // console.log("filtrado1Periodo", filtrado1Periodo)
          setDataAvaliacaoInicialIndividual1Per(filtrado1Periodo)

          var filtrado2Periodo = result.filter(function(obj2) { 
            return obj2.periodo.nome_periodo == '2° Período'; 
          });
          // console.log("filtrado2Periodo", filtrado2Periodo)
          setDataAvaliacaoInicialIndividual2Per(filtrado2Periodo)

          var filtrado3Periodo = result.filter(function(obj2) { 
            return obj2.periodo.nome_periodo == '3° Período'; 
          });
          setDataAvaliacaoInicialIndividual3Per(filtrado3Periodo)

          var filtrado4Periodo = result.filter(function(obj2) { 
            return obj2.periodo.nome_periodo == '4° Período'; 
          });
          setDataAvaliacaoInicialIndividual4Per(filtrado4Periodo)

      } catch (error) {
          console.log('Erro ao carregar avaliação individual', error, error.response);
      }
    }

    async function diagnosticoFinal(idaluno, iddiario) {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get(`relatorio-final-idaluno/${idaluno}/${iddiario}`, config);
          console.log('Result relatorio final', result);
          setRelatorioFinalIndividual(result);
      } catch (error) {
          console.log('Erro ao carregar relatorio final', error, error.response);
      }
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <h1 style={{margin: 0, padding: 0, fontSize: 18}}>{nomeAluno}</h1>
                </Grid>
                <Grid item xs={12}>
                    <AlunoEscola tipoUsuario={tipoUsuario} idaluno={idaluno} escolaSelecionada={escolaSelecionada} />
                </Grid>
                {idEscolaSelecionada && (
                  <Grid item xs={12}>
                      <DiariosAluno 
                        tipoUsuario={tipoUsuario} 
                        idaluno={idaluno} 
                        idEscolaSelecionada={idEscolaSelecionada} 
                        idAnoLetivo={idAnoLetivo}
                        idTurma={idTurma}
                        diarioSelecionado={diarioSelecionado} 
                      />
                  </Grid>
                )}
                <Grid item xs={12}>
                    {/* <div className="btntop">
                        <Tooltip
                            style={{ margin: 3 }}
                            title="Histórico de presenças do Aluno"
                            arrow>
                            <Button className="btn-top" variant="contained" color="primary">
                                Presenças
                            </Button>
                        </Tooltip>
                        <Tooltip style={{ margin: 3 }} title="Notas do Aluno" arrow>
                            <Button className="btn-top" variant="contained" color="primary">
                                Notas
                            </Button>
                        </Tooltip>
                    </div> */}
                    {diario && (
                      <>
                        <div className="infoAuno">
                            <h2>Diagnótico inicial</h2>
                            {data.map((item, key) => (
                              <div key={key} className="info-diagnostico">
                                <span>Data <strong>{format(parseISO(item.created_at), 'dd/MM/yyyy')}</strong></span>
                                <p>{item.diagnostico}</p>
                              </div>
                            ))}
                        </div>
                        {/* Por periodo */}
                        <div className="infoAuno">
                            <h2>Frequência do aluno</h2>
                            {dataFrequencia && (
                              <div className="info-bloco">
                                <div className="info-bloco-header">
                                    <span>1°</span>
                                    <span>Período</span>
                                </div>
                                {dataFrequencia1Per.length > 0 && (
                                  <div className="info-bloco-body">
                                    <div className="info-registro-frequencias">
                                      {dataFrequencia1Per.map((item, key) => (
                                        <div style={{background: item.presenca === 'Sim' ? 'green' : 'red'}} key={key}>
                                          <span>{format(parseISO(item.data), 'dd/MM/yyyy')}</span>
                                          <span>{item.presenca}</span>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                                {dataFrequencia1Per.length === 0 && (
                                  <div className="notfound">
                                    <span>Nenhum registro encontrado</span>
                                  </div>
                                )}
                                <div className="info-bloco-resumo-frequencia">
                                  <div>
                                    <span>1°</span>
                                    <span>Período</span>
                                  </div>
                                  <div style={{color: 'red', marginLeft: 10}}>
                                    <span>Total de Faltas {': '}</span>
                                    <span> {totalFrequencia1Per.nao}</span>
                                  </div>
                                  <div>{' , '}</div>
                                  <div style={{color: 'green', marginLeft: 10}}>
                                    <span>Total de Presenças {': '}</span>
                                    <span> {totalFrequencia1Per.sim}</span>
                                  </div>
                                </div>
                              </div>
                            )}
                            {dataFrequencia && (
                              <div className="info-bloco">
                                <div className="info-bloco-header">
                                    <span>2°</span>
                                    <span>Período</span>
                                </div>
                                {dataFrequencia2Per.length > 0 && (
                                  <div className="info-bloco-body">
                                    <div className="info-registro-frequencias">
                                      {dataFrequencia2Per.map((item, key) => (
                                        <div style={{background: item.presenca === 'Sim' ? 'green' : 'red'}} key={key}>
                                          <span>{format(parseISO(item.data), 'dd/MM/yyyy')}</span>
                                          <span>{item.presenca}</span>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                                {dataFrequencia2Per.length === 0 && (
                                  <div className="notfound">
                                    <span>Nenhum registro encontrado</span>
                                  </div>
                                )}
                                <div className="info-bloco-resumo-frequencia">
                                  <div>
                                    <span>2°</span>
                                    <span>Período</span>
                                  </div>
                                  <div style={{color: 'red', marginLeft: 10}}>
                                    <span>Total de Faltas {': '}</span>
                                    <span> {totalFrequencia2Per.nao}</span>
                                  </div>
                                  <div>{' , '}</div>
                                  <div style={{color: 'green', marginLeft: 10}}>
                                    <span>Total de Presenças {': '}</span>
                                    <span> {totalFrequencia2Per.sim}</span>
                                  </div>
                                </div>
                              </div>
                            )}
                            {dataFrequencia && (
                              <div className="info-bloco">
                                <div className="info-bloco-header">
                                    <span>3°</span>
                                    <span>Período</span>
                                </div>
                                {dataFrequencia3Per.length > 0 && (
                                  <div className="info-bloco-body">
                                    <div className="info-registro-frequencias">
                                      {dataFrequencia3Per.map((item, key) => (
                                        <div style={{background: item.presenca === 'Sim' ? 'green' : 'red'}} key={key}>
                                          <span>{format(parseISO(item.data), 'dd/MM/yyyy')}</span>
                                          <span>{item.presenca}</span>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                                {dataFrequencia3Per.length === 0 && (
                                  <div className="notfound">
                                    <span>Nenhum registro encontrado</span>
                                  </div>
                                )}
                                <div className="info-bloco-resumo-frequencia">
                                  <div>
                                    <span>3°</span>
                                    <span>Período</span>
                                  </div>
                                  <div style={{color: 'red', marginLeft: 10}}>
                                    <span>Total de Faltas {': '}</span>
                                    <span> {totalFrequencia3Per.nao}</span>
                                  </div>
                                  <div>{' , '}</div>
                                  <div style={{color: 'green', marginLeft: 10}}>
                                    <span>Total de Presenças {': '}</span>
                                    <span> {totalFrequencia3Per.sim}</span>
                                  </div>
                                </div>
                              </div>
                            )}
                            {dataFrequencia && (
                              <div className="info-bloco">
                                <div className="info-bloco-header">
                                    <span>4°</span>
                                    <span>Período</span>
                                </div>
                                {dataFrequencia4Per.length > 0 && (
                                  <div className="info-bloco-body">
                                    <div className="info-registro-frequencias">
                                      {dataFrequencia4Per.map((item, key) => (
                                        <div style={{background: item.presenca === 'Sim' ? 'green' : 'red'}} key={key}>
                                          <span>{format(parseISO(item.data), 'dd/MM/yyyy')}</span>
                                          <span>{item.presenca}</span>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                                {dataFrequencia4Per.length === 0 && (
                                  <div className="notfound">
                                    <span>Nenhum registro encontrado</span>
                                  </div>
                                )}
                                <div className="info-bloco-resumo-frequencia">
                                  <div>
                                    <span>4°</span>
                                    <span>Período</span>
                                  </div>
                                  <div style={{color: 'red', marginLeft: 10}}>
                                    <span>Total de Faltas {': '}</span>
                                    <span> {totalFrequencia4Per.nao}</span>
                                  </div>
                                  <div>{' , '}</div>
                                  <div style={{color: 'green', marginLeft: 10}}>
                                    <span>Total de Presenças {': '}</span>
                                    <span> {totalFrequencia4Per.sim}</span>
                                  </div>
                                </div>
                              </div>
                            )}
                            {!dataFrequencia && (
                              <div className="notfound">
                                <span>Nenhum registro encontrado</span>
                              </div>
                            )}
                        </div>
                        {/* Por periodo */}
                        <div className="infoAuno">
                            <h2>Relatório de avaliação individual</h2>
                            {dataAvaliacaoInicialIndividual && (
                              <div className="info-bloco">
                                <div className="info-bloco-header">
                                    <span>1°</span>
                                    <span>Período</span>
                                </div>
                                {dataAvaliacaoInicialIndividual1Per.length > 0 && (
                                  <div className="info-bloco-body">
                                    <div className="info-registro-relatorio-final">
                                      {dataAvaliacaoInicialIndividual1Per.map((item, key) => (
                                        <div key={key}>
                                          <span>{format(parseISO(item.created_at), 'dd/MM/yyyy')}</span>
                                          <span>{item.relatorio}</span>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                                {dataAvaliacaoInicialIndividual1Per.length === 0 && (
                                  <div className="notfound">
                                    <span>Nenhum registro encontrado</span>
                                  </div>
                                )}
                              </div>
                            )}
                            {dataAvaliacaoInicialIndividual && (
                              <div className="info-bloco">
                                <div className="info-bloco-header">
                                    <span>2°</span>
                                    <span>Período</span>
                                </div>
                                {dataAvaliacaoInicialIndividual2Per.length > 0 && (
                                  <div className="info-bloco-body">
                                    <div className="info-registro-relatorio-final">
                                      {dataAvaliacaoInicialIndividual2Per.map((item, key) => (
                                        <div key={key}>
                                          <span>{format(parseISO(item.created_at), 'dd/MM/yyyy')}</span>
                                          <span>{item.relatorio}</span>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                                {dataAvaliacaoInicialIndividual2Per.length === 0 && (
                                  <div className="notfound">
                                    <span>Nenhum registro encontrado</span>
                                  </div>
                                )}
                              </div>
                            )}
                            {dataAvaliacaoInicialIndividual && (
                              <div className="info-bloco">
                                <div className="info-bloco-header">
                                    <span>3°</span>
                                    <span>Período</span>
                                </div>
                                {dataAvaliacaoInicialIndividual3Per.length > 0 && (
                                  <div className="info-bloco-body">
                                    <div className="info-registro-relatorio-final">
                                      {dataAvaliacaoInicialIndividual3Per.map((item, key) => (
                                        <div key={key}>
                                          <span>{format(parseISO(item.created_at), 'dd/MM/yyyy')}</span>
                                          <span>{item.relatorio}</span>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                                {dataAvaliacaoInicialIndividual3Per.length === 0 && (
                                  <div className="notfound">
                                    <span>Nenhum registro encontrado</span>
                                  </div>
                                )}
                              </div>
                            )}
                            {dataAvaliacaoInicialIndividual && (
                              <div className="info-bloco">
                                <div className="info-bloco-header">
                                    <span>4°</span>
                                    <span>Período</span>
                                </div>
                                {dataAvaliacaoInicialIndividual4Per.length > 0 && (
                                  <div className="info-bloco-body">
                                    <div className="info-registro-relatorio-final">
                                      {dataAvaliacaoInicialIndividual4Per.map((item, key) => (
                                        <div key={key}>
                                          <span>{format(parseISO(item.created_at), 'dd/MM/yyyy')}</span>
                                          <span>{item.relatorio}</span>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                                {dataAvaliacaoInicialIndividual4Per.length === 0 && (
                                  <div className="notfound">
                                    <span>Nenhum registro encontrado</span>
                                  </div>
                                )}
                              </div>
                            )}
                            {!dataAvaliacaoInicialIndividual && (
                              <div className="notfound">
                                <span>Nenhum registro encontrado</span>
                              </div>
                            )}
                        </div>
                        <div className="infoAuno">
                            <h2>Relatório final individual</h2>
                            {relatorioFinalIndividual.map((item, key) => (
                              <div key={key} className="info-diagnostico">
                                <span>Data <strong>{item.created_at}</strong></span>
                                <p>{item.relatorio_final}</p>
                              </div>
                            ))}
                        </div>
                      </>
                    )}
                </Grid>
            </Grid>
        </div>
    );
}
