import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
/* eslint-disable */
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableMapeamento from '../../../Components/Tables/mapeamentos';
import { useLocation } from 'react-router-dom';
import {veryAuth} from '../../../auth';

export default function Index() {
    let history = useHistory();
    const location = useLocation();
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);
    const [title, setTitle] = useState('Mapeamentos');
    const tipoUsuario = ''
    const [roles, setRoles] = useState('');
    const [liberaAcesso, setLiberaAcesso] = useState(false);
    const [permissaoTelas, setPermissaoTelas] = useState('');

    useEffect(() => {
        verificarPermissao()
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function verificarPermissao(){
      if(!location.state){
        history.push({
          pathname: `/`,
        });
        return
      } 
      veryAuth()
      .then((result) => {
        console.log('result verificarPermissao', result);
        setPermissaoTelas(location.state.telasuser[0])
        if(result.data.role === 'admin'){
          setLiberaAcesso(true)
          carregarMapeamento()
        }else{
          history.push({
            pathname: `/`,
          });
          return
        }
        setRoles(result.data.role)
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.response, error.message);
      });
    }

    
    async function carregarMapeamento(){
      console.log("Result mapeamento 1",)
      var token = localStorage.getItem('@token_diario_web');
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data : result} = await api.get(`professor-escola`, config);
        console.log("Result mapeamento", result)
        setData(result)
        toast.success('🦄 Carregando mapeamento' , {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro ao carregar mapeamento", error, error.response);
        toast.error('🦄 Error ao carregar mapeamento!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    function deletando(){
      carregarMapeamento()
    }

    function handleChangePesquisar(event){
      if(event.target.value){
        pesquisarMapeamento(event.target.value)
      }else{
        carregarMapeamento()
      }
      // setPesquisar(event.target.value)
    }

    async function pesquisarMapeamento(termo) {
      //setLoading(true);
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
  
  
      try {
        const {data : result} = await api.get(`pesquisar-professor-escola/${encodeURI(termo)}`, config);
        console.log("Result pesquisar", result)
        setData(result)
      } catch (error) {
        console.log("Erro ao buscar ", error, error.response);
      }
    }
  

    //tabela
    function create() {
      history.push({
          pathname: `mapeamento-professor-create`,
          // search: '?query=abc',
          state: { 
            // type: 'new',
            telasuser: location.state.telasuser[0],
            type: 'new',
            usuario: location.state.usuario,
          }
      });
    }

    function createData(
      id, 
      name, 
      escola, 
      created_at, 
      telefone, 
    ) {
      return {
        id, 
        name, 
        escola, 
        created_at, 
        telefone, 
      };
    }
  
    var rows = [];
    if(data){
      rows = data.map((row) => {
        return createData(
          row.id, 
          row.user ? row.user.name : '', 
          row.escola ? row.escola.escola_nome : '',
          row.created_at, 
          row.user ? row.user.telefone :''
        )
      })
    }
  
    const columns = [
      { id: 'professor', numeric: false, disablePadding: true, label: 'Professor' },
      { id: 'escola', numeric: true, disablePadding: false, label: 'Escola' },
      { id: 'data', numeric: true, disablePadding: false, label: 'Data' },
      { id: 'telefone', numeric: true, disablePadding: false, label: 'Telefone' },
      { id: 'edit', numeric: true, disablePadding: false, label: 'Editar' },
    ];
  
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link className="linkBreadcrumbs" color="inherit" href="/">
                                    Dashboard
                                </Link>
                                {/* <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/getting-started/installation/"
                                    onClick={handleClick}>
                                    Core
                                </Link> */}
                                <Typography variant="body2" color="textPrimary">
                                    {title}
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>{title}</h2>
                                {permissaoTelas.create != 0 &&(
                                  <Button
                                    className="button-add"
                                    onClick={create}
                                    variant="contained"
                                    color="primary">
                                    Novo
                                  </Button>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={'paperSearch'}>
                                <TextField
                                    className="textField"
                                    id="outlined-secondary"
                                    label="Pesquisar professor(a)"
                                    variant="outlined"
                                    // color="secondary"
                                    // value={pesquisar}
                                    onChange={handleChangePesquisar}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                  {rows.length !== 0 && (
                                    <div className="paperContant">
                                      <TableMapeamento
                                        columns={columns} 
                                        rows={rows} 
                                        deletando={deletando}
                                        tipoUsuario={tipoUsuario}
                                        telasuser={permissaoTelas}
                                      />
                                    </div>
                                  )}
                                  {rows.length === 0 && (
                                    <div className="paperContantDois">
                                        <div>Nenhum registro encontrado</div>
                                    </div>
                                  )}
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
        </div>
    );
}
