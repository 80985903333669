import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
/* eslint-disable */
import Skeleton from '@material-ui/lab/Skeleton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FadeLoader from "react-spinners/FadeLoader";
import api from '../../../services/api';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import ControlPoint from '@material-ui/icons/ControlPoint';
import Edit from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

export default function Index() {
    let history = useHistory();
    const location = useLocation();
    const [skeleton, seSkeleton] = useState(true);
    const [loading, setloading] = useState(false);

    //cadastro
    const [tipo, setTipo] = useState('');
    const [txtBtn, setTxtBtn] = useState('');
    const [idEditar, setIdEditar] = useState('');
    const [polos, setIPolos] = useState([]);
    const [polo, setPolo] = useState('');
    const [povoados, setPovoados] = useState([]);
    const [povoado, setPovoado] = useState('');
    const [nomeEscola, setNomaEscola] = useState('');
    const [esnderecoEscola, setEnderecoEscola] = useState('');
    // const [ePolo, setEPolo] = useState('Não');
    const [grupos, setGrupos] = useState([]);
    const [grupo, setGrupo] = useState('');
    const [coordenadores, setCoordenadores] = useState([]);
    const [coordenador, setCoordenador] = useState('');
    const [gestores, setGestores] = useState([]);
    const [gestor, setGestor] = useState('');
    const [status, setStatus] = useState('Ativo');
    const [title, setTitle] = useState('Cadastrar Escola');
    const [paraPolo, setParaPolo] = useState(false);
    const [poloSede, setPoloSede] = useState('Sede');

    const [addTurmas, setAddTurma] = useState(false);
    const [turmaNome, setTurmaNome] = useState('');
    const [qtoVaga, setQtoVaga] = useState(0);
    const [turmas, setTurmas] = useState([]);
    const [anoLetivos, setAnoLetivos] = useState([]);
    const [anoLetivo, setAnoLetivo] = useState('');
    const [turno, setTurno] = useState('');

    const [permissoes, setPermissoes] = useState([]);
    const [permissao, setPermissao] = useState('');

    useEffect(() => {
        // Atualiza o titulo do documento usando a API do browser
        console.log("um", location.pathname);
        console.log("dois", location);
        carregarGrupos()
        carregarPolo();
        carregarCoordenadores();
        carregarGestores();
        carregarPermission()
        if(location.state){
          setTipo(location.state.type)
          setTxtBtn(location.state.type === 'edit' ? 'Editar' : 'Salvar')
        }else{
          history.push('/')
        }
        if(location.state.type === 'edit'){
          editar(parseInt(location.state.id))
          carregarAnoLetivo()
          carregarTurmas(parseInt(location.state.id))
          setTitle('Configurações da escola')
        }else{
          setloading(false)
        }
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    async function carregarPermission() {
      var token = localStorage.getItem('@token_diario_web');

      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      // console.log("aqui", id)
      try {
          const { data: list } = await api.get(`/permissao/`, config);
          console.log('Permissões ', list);
          setPermissoes(list)
      } catch (error) {
          console.log('Erro buscar permissões', error, error.response);

          // setloading(false);
      }
    }

    async function carregarAnoLetivo() {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get('ano-letivo', config);
          console.log('resultado ano letivos', result);
          setAnoLetivos(result);
      } catch (error) {
          console.log('Erro ao buscar grupos', error, error.response);
      }

    } 

    async function carregarTurmas(id) {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get(`escola-turmas/${id}`, config);
          console.log('resultado turmas', result);
          setTurmas(result);
      } catch (error) {
          console.log('Erro ao buscar grupos', error, error.response);
      }

    } 
    async function carregarGrupos() {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get('grupo', config);
          console.log('resultado grupos', result);
          setGrupos(result);
      } catch (error) {
          console.log('Erro ao buscar grupos', error, error.response);
      }

    } 
    async function carregarPolo() {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get('polo', config);
          console.log('resultado polos', result);
          setIPolos(result);
      } catch (error) {
          console.log('Erro ao buscar polo', error, error.response);
      }
    } 

    async function carregarPovoados(idpolo) {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get(`povoado-por-polo/${idpolo}`, config);
          console.log('resultado povoados', result);
          setPovoados(result);
      } catch (error) {
          console.log('Erro ao buscar povoado', error, error.response);
      }
    } 

    async function carregarCoordenadores() {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      // var arrayCoordenador = []
      try {
          const { data: result } = await api.get(`usuario-por-role/coordenador/desk/null`, config);
          // console.log('resultado coordenadores', result);
          // for (let c = 0; c < result.length; c++) {
          //   const element = result[c];
          //   console.log('resultado coordenadores element', element);
          //   if(element.escolaCoordenador.length === 0){
          //     arrayCoordenador.push(element)
          //   }
          
          // }
          setCoordenadores(result);
      } catch (error) {
          console.log('Erro ao buscar coordenadoress', error, error.response);
      }
    } 
    async function carregarGestores() {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      var arrayGestor = [];
      try {
          const { data: result } = await api.get(`usuario-por-role/gestor/desk/null`, config);
          console.log('resultado gestores', result);
          // for (let g = 0; g < result.length; g++) {
          //   const element = result[g];
          //   console.log('resultado coordenadores element', element);
          //   if(element.escolaGestor.length === 0){
          //     arrayGestor.push(element)
          //   }
          
          // }
          setGestores(result);
      } catch (error) {
          console.log('Erro ao buscar gestores', error, error.response);
      }
    } 


    function handleChangePermissao(event){
      setPermissao(event.target.value);
    }
    function handleChangeGrupos(event){
      setGrupo(event.target.value);
    }
    function handleChangePolos(event){
      setPolo(event.target.value);
      carregarPovoados(event.target.value)
    }
    function handleChangePovoados(event){
      setPovoado(event.target.value);
    }
    function handleChangeNomeEscola(event){
      setNomaEscola(event.target.value);
    }
    function handleChangeEnderecoEscola(event){
      setEnderecoEscola(event.target.value);
    }
    // function handleChangeEPolo(event){
    //   setEPolo(event.target.value);
    // }
    function handleChangeCoordenador(event){
      setCoordenador(event.target.value);
    }
    function handleChangeGestor(event){
      setGestor(event.target.value);
    }
    function handleChangeTurmaNome(event){
      setTurmaNome(event.target.value);
    }
    function handleChangeQtoVaga(event){
      setQtoVaga(event.target.value);
    }

    function handleChangeTurno(event){
      setTurno(event.target.value);
    }

    function handleChangeTipoCadastro(event){
      
      if(event.target.value === 'Sede'){
        setPoloSede('Sede')
        setParaPolo(false)
      }else{
        setPoloSede('Polo')
        setParaPolo(true)
      }
    }
    function handleChangeAnoLetivo(event){
      setAnoLetivo(event.target.value);
    }
    function habilitarEdit(){
      setDisabled(false)
    }

    async function deletar() {
      setloading(true)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: prod} = await api.delete(`/escola/${idEditar}`, config);
        console.log("prod", prod)
        // limpaCampos();
        setloading(false);
        // setOpenAlert(true)
        // setTypeAlert('success')
        // setMsgAlert('Fatura foi deletada com sucesso.')
        toast.success('🦄 Povoado deletado com sucesso!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push('/escolas')
      } catch (error) {
        console.log("Erro ao deletar", error, error.response);
        setloading(false);
        // setOpenAlert(true)
        // setTypeAlert('error')
        // setMsgAlert('Erro ao deletar, tente novamente')
        toast.error('🦄 Erro ao deletar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    async function editar(id){
      setIdEditar(id)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: edit} = await api.get(`/escola/${id}`, config);
        const result = edit[0];
        console.log("Dados da escola para editar", result)
        setAddTurma(true)
        if(result.polo.length > 0){
          setParaPolo(true)
        }
        setloading(false);
        setGrupo(result.grupo_id)
        if(result.polo.length > 0){
          carregarPovoados(result.polo[0].id)
          setPolo(result.polo[0].id)
        }
        if(result.povoado.length > 0){
          setPovoado(result.povoado[0].id)
        }
        setNomaEscola(result.escola_nome)
        setEnderecoEscola(result.escola_endereco)
        setPoloSede(result.escola_polo_sede)
        if(result.escola_polo_sede === 'Sede'){
          setParaPolo(false)
        }else{
          setParaPolo(true)
        }
        // setEPolo(result.escola_polo)
        if(result.coordenador.length > 0){
          setCoordenador(result.coordenador[0].id)
        }
        if(result.gestor.length > 0){
          setGestor(result.gestor[0].id)
        }
        setStatus(result.status)
        setPermissao(result.permission_id)
      } catch (error) {
        console.log("Erro buscar dados para editar", error, error.response);
        toast.error('🦄 Erro ao buscar dados para editar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setloading(false);
      }
    }

    async function cadastrarTurma(idescola){

      if(qtoVaga === 0){
        toast.error('🦄 Diga a quantidade de vagas para esta turma!', {
          position: "bottom-left",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return
      }

      let form_data = new FormData();

      var token = localStorage.getItem('@token_diario_web');

      form_data.append("escola_id", idescola);
      form_data.append("ano_letivo_id", anoLetivo);
      form_data.append("turma_nome", turmaNome);
      form_data.append("qto_vagas", qtoVaga);
      form_data.append("turno", turno);

      await api
        .post(`/escola-turma/`, form_data, {
          headers: {
            "content-type": "application/json",
            Authorization: "bearer " + token,
            Accept: "application/json"
          }
        })
        .then(res => {
            console.log("Povoado editado", res.data)
            setloading(false);
            carregarTurmas(idescola)
            setTurmaNome('')
            setQtoVaga(0)
            toast.success('🦄 Turma cadastrada com sucesso!', {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
        })
        .catch(error => {
          console.log("error: ", error, error.response)
          toast.error('🦄 Erro ao cadastrar turma, tente novamente ou fale com o suporte!', {
            position: "bottom-left",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setloading(false);
        });

    }
  

    async function salvar(event) {
      event.preventDefault();
      // console.log("event", event)
      setloading(true)
  
      //setloading(true);
      var token = localStorage.getItem('@token_diario_web');

      let form_data = new FormData();

      form_data.append("grupo_id", grupo);
      form_data.append("permission_id", permissao);
      form_data.append("escola_nome", nomeEscola);
      form_data.append("escola_endereco", esnderecoEscola);
      form_data.append("escola_polo_sede", poloSede);
      form_data.append("status", status);
      
      if(tipo === 'new'){
        await api
          .post(`/escola`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Adicionando escola", res.data)
              limpaCampos();
              setloading(false);
              editar(res.data.id)
              vincularPolo(res.data.id)
              vincularPovoado(res.data.id)
              vincularCoordenador(res.data.id)
              vincularGestor(res.data.id)
              setAddTurma(true)
              carregarAnoLetivo()
              toast.success('🦄 Escola cadastrada com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            console.log("response: ", error.response.data.message)
            toast.error(`🦄 Erro ao cadatrar Escola, ${error.response.data.message}!`, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setloading(false);
            // throw error;
          });

          async function vincularPolo(idescola){
            // polo-povoado-escola
            let form_data_polo = new FormData();
            form_data_polo.append("escola_id", idescola);
            form_data_polo.append("polo_id", polo);
            await api
              .post(`/escola-polo`, form_data_polo, {
                headers: {
                  "content-type": "application/json",
                  Authorization: "bearer " + token,
                  Accept: "application/json"
                }
              })
              .then(res => {
                  console.log("Vinculando escola ao polo", res.data)
              })
              .catch(error => {
                console.log("error: ", error, error.response)
              });
          }

          async function vincularPovoado(idescola){
            // polo-povoado-escola
            let form_data_polo = new FormData();
            form_data_polo.append("escola_id", idescola);
            form_data_polo.append("povoado_id", povoado);
            await api
              .post(`/escola-povoado`, form_data_polo, {
                headers: {
                  "content-type": "application/json",
                  Authorization: "bearer " + token,
                  Accept: "application/json"
                }
              })
              .then(res => {
                  console.log("Vinculando escola ao povoado", res.data)
              })
              .catch(error => {
                console.log("error vincular povoado: ", error, error.response)
              });
          }

         
          async function vincularCoordenador(idescola){
            // polo-povoado-escola
            let form_data_polo = new FormData();
            form_data_polo.append("escola_id", idescola);
            form_data_polo.append("user_id", coordenador);
            await api
              .post(`/escola-coordenador`, form_data_polo, {
                headers: {
                  "content-type": "application/json",
                  Authorization: "bearer " + token,
                  Accept: "application/json"
                }
              })
              .then(res => {
                  console.log("Vinculando escola ao coordenador", res.data)
              })
              .catch(error => {
                console.log("error vincular coordenador: ", error, error.response)
              });
          }
  
          async function vincularGestor(idescola){
            // polo-povoado-escola
            let form_data_polo = new FormData();
            form_data_polo.append("escola_id", idescola);
            form_data_polo.append("user_id", gestor);
            await api
              .post(`/escola-gestor`, form_data_polo, {
                headers: {
                  "content-type": "application/json",
                  Authorization: "bearer " + token,
                  Accept: "application/json"
                }
              })
              .then(res => {
                  console.log("Vinculando escola ao gestor", res.data)
              })
              .catch(error => {
                console.log("error vincular gestor: ", error, error.response)
              });
          }
  
      }else if(tipo === 'edit'){
  
        await api
          .post(`/escola/${idEditar}`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Povoado editado", res.data)
              setloading(false);
              toast.success('🦄 Escola editada com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              editarVincularPolo(res.data.id)
              editarVincularPovoado(res.data.id)
              editarVincularCoordenador(res.data.id)
              editarVincularGestor(res.data.id)
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            toast.error('🦄 Erro ao editar Escola, tente novamente ou fale com o suporte!', {
              position: "bottom-left",
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setloading(false);
          });

          async function editarVincularPolo(idescola){
            // polo-povoado-escola
            let form_data_polo = new FormData();
            form_data_polo.append("escola_id", idescola);
            form_data_polo.append("polo_id", polo);
            await api
              .post(`/escola-polo/${idescola}`, form_data_polo, {
                headers: {
                  "content-type": "application/json",
                  Authorization: "bearer " + token,
                  Accept: "application/json"
                }
              })
              .then(res => {
                  console.log("editar vincular escola ao polo", res.data)
              })
              .catch(error => {
                console.log("erro ao editar vincular escola ao polo: ", error, error.response)
              });
          }

          async function editarVincularPovoado(idescola){
            // polo-povoado-escola
            let form_data_polo = new FormData();
            form_data_polo.append("escola_id", idescola);
            form_data_polo.append("povoado_id", povoado);
            await api
              .post(`/escola-povoado/${idescola}`, form_data_polo, {
                headers: {
                  "content-type": "application/json",
                  Authorization: "bearer " + token,
                  Accept: "application/json"
                }
              })
              .then(res => {
                  console.log("editar Vinculando escola ao povoado", res.data)
                  editar(idescola)
              })
              .catch(error => {
                console.log("erro ao editar vincular povoado: ", error, error.response)
              });
          }

          async function editarVincularCoordenador(idescola){
            // polo-povoado-escola
            let form_data_polo = new FormData();
            form_data_polo.append("escola_id", idescola);
            form_data_polo.append("user_id", coordenador);
            await api
              .post(`/escola-coordenador/${idescola}`, form_data_polo, {
                headers: {
                  "content-type": "application/json",
                  Authorization: "bearer " + token,
                  Accept: "application/json"
                }
              })
              .then(res => {
                  console.log("editar vinculando escola ao coordenador", res.data)
              })
              .catch(error => {
                console.log("error editar vincular coordenador: ", error, error.response)
              });
          }

          async function editarVincularGestor(idescola){
            // polo-povoado-escola
            let form_data_polo = new FormData();
            form_data_polo.append("escola_id", idescola);
            form_data_polo.append("user_id", gestor);
            await api
              .post(`/escola-gestor/${idescola}`, form_data_polo, {
                headers: {
                  "content-type": "application/json",
                  Authorization: "bearer " + token,
                  Accept: "application/json"
                }
              })
              .then(res => {
                  console.log("editar vinculando escola ao gestor", res.data)
              })
              .catch(error => {
                console.log("error editar vincular gestor: ", error, error.response)
              });
          }
  
      }
  
    }

    function limpaCampos(){
      // setNomaEscola('')
      // setEnderecoEscola('')
    }

    //validações
    const validation = [];
    validation.push(grupo);
    validation.push(nomeEscola);
    validation.push(esnderecoEscola);


    const validForm = validation.reduce((all, v) => all && v);

  

    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Breadcrumbs aria-label="breadcrumb">
                              <Link className="linkBreadcrumbs" color="inherit" href="/">
                                  Dashboard
                              </Link>
                              <Link className="linkBreadcrumbs" color="inherit" href="/escolas/">
                                  Escolas
                              </Link>
                              <Typography variant="body2" color="textPrimary">
                                  {title}
                              </Typography>
                          </Breadcrumbs>
                          <div className="headerPage">
                              <h2>{title}</h2>
                          </div>
                        </Grid>

                        {/* <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                              <Paper className={'paper'}>
                                <div className="itemConfig">
                                  <div onClick={abrirPageServicos} className="itemConfigDois">
                                    <SettingsApplicationsIcon style={{ fontSize: 40, color: 'rgb(63, 1, 121)' }} />
                                    <div>Saques</div>
                                  </div>
                                  <div onClick={habilitarPolos} style={{background: paraPolo ? 'rgb(63, 1, 121)' : ''}} className="itemConfigDois">
                                    <ControlPoint style={{ fontSize: 40, color: paraPolo ? '#fff' : 'rgb(63, 1, 121)' }} />
                                    <div style={{color: paraPolo ? '#fff' : ''}}>{poloSede}</div>
                                  </div>
                                  <div onClick={habilitarEdit} className="itemConfigDois">
                                    <Edit style={{ fontSize: 40, color: 'rgb(63, 1, 121)' }} />
                                    <div>Editar perfil</div>
                                  </div>
                                </div>
                              </Paper>
                            )}
                        </Grid> */}
              
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                    <div className="paperContant">
                                        <form noValidate autoComplete="off" onSubmit={salvar}>
                                          <Grid container spacing={3}>
                                            <Grid item xs={12} md={12}>
                                              <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="select-tipo-cadastro">Tipo Cadastro</InputLabel>
                                                <Select
                                                  labelId="select-tipo-cadastro"
                                                  id="select-tipo-cadastro"
                                                  value={poloSede}
                                                  onChange={handleChangeTipoCadastro}
                                                  label="Tipo Cadastro"
                                                >
                                                  <MenuItem value={'Sede'}>Sede</MenuItem>
                                                  <MenuItem value={'Polo'}>Polo</MenuItem>
                                                </Select>
                                              </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                              <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="select-polo">Permissão para coordenador</InputLabel>
                                                <Select
                                                  labelId="select-polo"
                                                  id="select-polo"
                                                  value={permissao}
                                                  onChange={handleChangePermissao}
                                                  label="Permissão para coordenador"
                                                >
                                                  <MenuItem value="">
                                                    <em>Nenhum</em>
                                                  </MenuItem>
                                                  {permissoes.map((item, key) => (
                                                    <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                                  ))}
                                                </Select>
                                              </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                              <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="select-polo">Grupos de escola</InputLabel>
                                                <Select
                                                  labelId="select-polo"
                                                  id="select-polo"
                                                  value={grupo}
                                                  onChange={handleChangeGrupos}
                                                  label="Grupos de escola"
                                                >
                                                  {grupos.map((item, key) => (
                                                    <MenuItem key={key} value={item.id}>{item.grupo_nome}</MenuItem>
                                                  ))}
                                                </Select>
                                              </FormControl>
                                            </Grid>
                                            {paraPolo && (
                                              <Grid item xs={12} md={4}>
                                                <FormControl variant="outlined" fullWidth>
                                                  <InputLabel id="select-polo">Polos</InputLabel>
                                                  <Select
                                                    labelId="select-polo"
                                                    id="select-polo"
                                                    value={polo}
                                                    onChange={handleChangePolos}
                                                    label="Polos"
                                                  >
                                                    <MenuItem value="">
                                                      <em>Nenhum</em>
                                                    </MenuItem>
                                                    {polos.map((item, key) => (
                                                      <MenuItem key={key} value={item.id}>{item.polo_nome}</MenuItem>
                                                    ))}
                                                  </Select>
                                                </FormControl>
                                              </Grid>
                                            )}
                                            {paraPolo && (
                                              <Grid item xs={12} md={4}>
                                                <FormControl variant="outlined" fullWidth>
                                                  <InputLabel id="select-povoados">Povoados</InputLabel>
                                                  <Select
                                                    labelId="select-povoados"
                                                    id="select-povoados"
                                                    value={povoado}
                                                    onChange={handleChangePovoados}
                                                    label="Povoados"
                                                  >
                                                    <MenuItem value="">
                                                      <em>Nenhum</em>
                                                    </MenuItem>
                                                    {povoados.map((item, key) => (
                                                      <MenuItem key={key} value={item.id}>{item.povoado_nome}</MenuItem>
                                                    ))}
                                                  </Select>
                                                </FormControl>
                                              </Grid>
                                            )}
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="nameEscola">Nome da Escola</InputLabel>
                                                  <OutlinedInput
                                                    error={!nomeEscola ? true : false}
                                                    id="nameEscola"
                                                    label={'Nome da Escola'}
                                                    value={nomeEscola}
                                                    onChange={handleChangeNomeEscola}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="nameEndereco">Endereço da Escola</InputLabel>
                                                  <OutlinedInput
                                                    error={!esnderecoEscola ? true : false}
                                                    id="nameEndereco"
                                                    label={"Endereço da Escola"}
                                                    value={esnderecoEscola}
                                                    onChange={handleChangeEnderecoEscola}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            {/* <Grid item xs={12} md={4}>
                                              <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="select-epolo">É Polo</InputLabel>
                                                <Select
                                                  labelId="select-epolo"
                                                  id="select-epolo"
                                                  value={ePolo}
                                                  onChange={handleChangeEPolo}
                                                  label="É Polo"
                                                >
                                                  <MenuItem value="Não">Não</MenuItem>
                                                  <MenuItem value="Sim">Sim</MenuItem>
                                                </Select>
                                              </FormControl>
                                            </Grid> */}
                                            <Grid item xs={12} md={4}>
                                              <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="select-coordenador">Coordenador</InputLabel>
                                                <Select
                                                  labelId="select-coordenador"
                                                  id="select-coordenador"
                                                  value={coordenador}
                                                  onChange={handleChangeCoordenador}
                                                  label="Coordenador"
                                                >
                                                  {coordenadores.map((item, key) => (
                                                    <MenuItem 
                                                      key={key} 
                                                      value={item.id}
                                                      style={{
                                                        background: item.escolaCoordenador.length > 0 ? 'red' : '',
                                                        color: item.escolaCoordenador.length > 0 ? '#ffff' : ''
                                                      }}
                                                    >
                                                      {item.name} 
                                                    </MenuItem>
                                                  ))}
                                                </Select>
                                              </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                              <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="select-gestor">Gestor</InputLabel>
                                                <Select
                                                  labelId="select-gestor"
                                                  id="select-gestor"
                                                  value={gestor}
                                                  onChange={handleChangeGestor}
                                                  label="Gestor"
                                                >
                                                  {gestores.map((item, key) => (
                                                    <MenuItem
                                                      key={key} 
                                                      value={item.id}
                                                      style={{
                                                        background: item.escolaGestor.length > 0 ? 'red' : '',
                                                        color: item.escolaGestor.length > 0 ? '#ffff' : ''
                                                      }}
                                                    >
                                                        {item.name}
                                                    </MenuItem>
                                                  ))}
                                                </Select>
                                              </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Button
                                                  disabled={!validForm}
                                                  style={{maxWidth: 150, height: 50, marginRight: 10}}
                                                  type="submit" variant="outlined" color="primary"
                                                >
                                                  {txtBtn}
                                                </Button>
                                                {tipo === 'edit' &&(
                                                  <Button
                                                    disabled={!validForm}
                                                    style={{maxWidth: 150, height: 50}}
                                                    variant="outlined" color="secondary"
                                                    onClick={deletar}
                                                  >
                                                    Deletar
                                                  </Button>
                                                ) }
                                            </Grid>
                                          </Grid>
                                        </form>
                                        <ToastContainer
                                          position="top-right"
                                          autoClose={5000}
                                          hideProgressBar={false}
                                          newestOnTop={false}
                                          closeOnClick
                                          rtl={false}
                                          pauseOnFocusLoss
                                          draggable
                                          pauseOnHover
                                        />
                                    </div>
                                </Paper>
                            )}
                            {!skeleton && (
                              <Paper style={{minHeight: 0}} className={'paper'}>
                                {addTurmas && (
                                  <div style={{marginTop: 30}} className="paperContant">
                                    <Grid container spacing={3}>
                                      <Grid item xs={12} md={12}>
                                        <span>Adicionar turma</span>
                                      </Grid>
                                      <Grid item xs={12} md={2}>
                                        <FormControl variant="outlined" fullWidth>
                                          <InputLabel id="select-polo">Ano</InputLabel>
                                          <Select
                                            labelId="select-anoLetivo"
                                            id="select-anoLetivo"
                                            value={anoLetivo}
                                            onChange={handleChangeAnoLetivo}
                                            label="Ano"
                                          >
                                            <MenuItem value="">
                                              <em>Nenhum</em>
                                            </MenuItem>
                                            {anoLetivos.map((item, key) => (
                                              <MenuItem key={key} value={item.id}>{item.nome_ano}</MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={12} md={2}>
                                        <TextField 
                                          fullWidth
                                          id="outlined-nome-turma" 
                                          label="Nome da Turma" 
                                          variant="outlined" 
                                          value={turmaNome}
                                          onChange={handleChangeTurmaNome}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={2}>
                                        <TextField 
                                          fullWidth
                                          id="outlined-qto-vaga" 
                                          label="Qto vagas" 
                                          variant="outlined" 
                                          type="number"
                                          value={qtoVaga}
                                          onChange={handleChangeQtoVaga}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <FormControl variant="outlined" fullWidth>
                                          <InputLabel id="select-polo">Turno</InputLabel>
                                          <Select
                                            labelId="select-turno"
                                            id="select-turno"
                                            value={turno}
                                            onChange={handleChangeTurno}
                                            label="Tornos"
                                          >
                                            <MenuItem selected={true} value={'Matutino'}>Matutino</MenuItem>
                                            <MenuItem value={'Vespertino'}>Vespertino</MenuItem>
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                      <Grid style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} item xs={12} md={2}>
                                        <Button onClick={() => {cadastrarTurma(idEditar)}} variant="contained" size="large" color="primary">
                                          Cadastrar
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </div>
                                )}
                                {!addTurmas && (
                                  <div style={{
                                    background: '#f8f8f8', 
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 10
                                  }}>
                                    Cadastro de turmas não está disponível
                                  </div>
                                )}
                              </Paper>
                            )}
                            {!skeleton && (
                              <Paper style={{minHeight: 0}} className={'paper'}>
                                <span>Turmas cadastradas</span>
                                <div style={{marginTop: 30}} className="paperContant">
                                  {turmas.map((item, key) => (
                                    <Grid key={key} container spacing={3}>
                                      <Grid item xs={12} md={1}>
                                        <TextField 
                                          disabled={true}
                                          fullWidth
                                          id="outlined-nome-cod" 
                                          label="COD" 
                                          variant="outlined" 
                                          value={item.id}
                                          onChange={handleChangeTurmaNome}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={3}>
                                        <FormControl variant="outlined" fullWidth>
                                          <InputLabel id="select-ano-letivo">Ano</InputLabel>
                                          <Select
                                            disabled={true}
                                            labelId="select-anoLetivo"
                                            // id="select-anoLetivo"
                                            value={item.ano_letivo_id}
                                            // onChange={handleChangeAnoLetivo}
                                            label="Ano"
                                          >
                                            <MenuItem value="">
                                              <em>Nenhum</em>
                                            </MenuItem>
                                            {anoLetivos.map((item, key) => (
                                              <MenuItem key={key} value={item.id}>{item.nome_ano}</MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <TextField 
                                          disabled={true}
                                          fullWidth
                                          id="outlined-nome-turma" 
                                          label="Nome da Turma" 
                                          variant="outlined" 
                                          value={item.turma_nome}
                                          onChange={handleChangeTurmaNome}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={2}>
                                        <TextField 
                                          disabled={true}
                                          fullWidth
                                          id="outlined-qto-vaga" 
                                          label="Qto vagas" 
                                          variant="outlined" 
                                          type="number"
                                          value={item.qto_vagas}
                                          onChange={handleChangeQtoVaga}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={2}>
                                        <TextField 
                                          disabled={true}
                                          fullWidth
                                          id="outlined-turno" 
                                          label="Turno" 
                                          variant="outlined" 
                                          value={item.turno}
                                          // onChange={handleChangeQtoVaga}
                                        />
                                      </Grid>
                                      {/* <Grid style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} item xs={12} md={2}>
                                        <Button onClick={() => {cadastrarTurma(idEditar)}} variant="contained" size="large" color="primary">
                                          Cadastrar
                                        </Button>
                                      </Grid> */}
                                    </Grid>
                                  ))}
                                </div>
                                {turmas.length === 0 && (
                                  <div style={{
                                    background: '#f8f8f8', 
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 10
                                  }}>
                                    Nenhum turma cadastrada
                                  </div>
                                )}
                              </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
            {loading && (
                <div className="loading">
                    <FadeLoader color={'rgb(63, 1, 121)'} loading={loading} size={150} />
                </div>
            )}
        </div>
    );
}
