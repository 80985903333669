import axios from 'axios';
/* eslint-disable */
const api = axios.create({
    // baseURL: 'http://webapp269883.ip-45-79-39-83.cloudezapp.io/',
    // baseURL: 'https://diarionawebapi.pinheiro.ma.gov.br/',
    // baseURL: 'http://localhost:3333/',
    baseURL: 'http://apidiario.ezcash.digital/diaionaweb/',
    headers: {
      'Content-Type': 'application/json', 
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
});

export default api;
