import React, { useState } from 'react';
/* eslint-disable */
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import api from '../../../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// function rand() {
//     return Math.round(Math.random() * 20) - 10;
// }

// function getModalStyle() {
//     const top = 50 + rand();
//     const left = 50 + rand();

//     return {
//         top: `${top}%`,
//         left: `${left}%`,
//         transform: `translate(-${top}%, -${left}%)`
//     };
// }

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: 10
    }
}));

export default function Index(props) {
    const classes = useStyles();
    const dad = props;
    const open = dad.openModal;
    const dados = dad.dados;
    const closedModal = dad.closedModal;
    console.log('dad', dad);
    const [create, setCreate] = useState(dados.create === 0 ? 'negar' : 'permitir');
    const [update, setUpdate] = useState(dados.update === 0 ? 'negar' : 'permitir');
    const [del, setDel] = useState(dados.delete === 0 ? 'negar' : 'permitir');
    const [read, setRead] = useState(dados.read === 0 ? 'negar' : 'permitir');
    // getModalStyle is not a pure function, we roll the style only on the first render
    // const [modalStyle] = React.useState(getModalStyle);

    function handleChangeCreate(event){
      setCreate(event.target.value)
    }
    function handleChangeUpdate(event){
      setUpdate(event.target.value)
    }
    function handleChangeDel(event){
      setDel(event.target.value)
    }
    function handleChangeRead(event){
      setRead(event.target.value)
    }

    function todasPermissoes(){
      setCreate('permitir')
      setUpdate('permitir')
      setDel('permitir')
      setRead('permitir')
    }

    async function editar(idEditar){
      var token = localStorage.getItem('@token_diario_web');

      let form_data = new FormData();
  
      //'create', 'update', 'delete', 'read',
      form_data.append("create", create);
      form_data.append("update", update);
      form_data.append("delete", del);
      form_data.append("read", read);
      
      await api
          .post(`/tela-acesso/${idEditar}`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Tela de acesso editado", res.data)
              toast.success('🦄 Tela de acesso editado com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              closedModal()
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            toast.error('🦄 Erro ao editar Tela de acesso, tente novamente ou fale com o suporte!', {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
    }

    const body = (
        <div className={classes.paper}>
            <h2>{dados.menu.nome}</h2>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              background: '#f7f7f7',
              marginBottom: 10,
              padding: 5,
            }}>
              <span>Ver</span>
              <FormControl component="fieldset">
                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                  <FormControlLabel
                    value="permitir"
                    control={<Radio color="primary" />}
                    label="Permitir"
                    labelPlacement="start"
                    checked={read === 'permitir' ? true : false}
                    onChange={handleChangeRead}
                  />
                  <FormControlLabel
                    value="negar"
                    control={<Radio color="primary" />}
                    label="Negar"
                    labelPlacement="start"
                    checked={read === 'negar' ? true : false}
                    onChange={handleChangeRead}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              background: '#f7f7f7',
              marginBottom: 10,
              padding: 5,
            }}>
              <span>Criar</span>
              <FormControl component="fieldset">
                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                  <FormControlLabel
                    value="permitir"
                    control={<Radio color="primary" />}
                    label="Permitir"
                    labelPlacement="start"
                    checked={create === 'permitir' ? true : false}
                    onChange={handleChangeCreate}
                  />
                  <FormControlLabel
                    value="negar"
                    control={<Radio color="primary" />}
                    label="Negar"
                    labelPlacement="start"
                    checked={create === 'negar' ? true : false}
                    onChange={handleChangeCreate}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              background: '#f7f7f7',
              marginBottom: 10,
              padding: 5,
            }}>
              <span>Editar</span>
              <FormControl component="fieldset">
                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                  <FormControlLabel
                    value="permitir"
                    control={<Radio color="primary" />}
                    label="Permitir"
                    labelPlacement="start"
                    checked={update === 'permitir' ? true : false}
                    onChange={handleChangeUpdate}
                  />
                  <FormControlLabel
                    value="negar"
                    control={<Radio color="primary" />}
                    label="Negar"
                    labelPlacement="start"
                    checked={update === 'negar' ? true : false}
                    onChange={handleChangeUpdate}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              background: '#f7f7f7',
              marginBottom: 10,
              padding: 5,
            }}>
              <span>Deletar</span>
              <FormControl component="fieldset">
                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                  <FormControlLabel
                    value="permitir"
                    control={<Radio color="primary" />}
                    label="Permitir"
                    labelPlacement="start"
                    checked={del === 'permitir' ? true : false}
                    onChange={handleChangeDel}
                  />
                  <FormControlLabel
                    value="negar"
                    control={<Radio color="primary" />}
                    label="Negar"
                    labelPlacement="start"
                    checked={del === 'negar' ? true : false}
                    onChange={handleChangeDel}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              background: '#f7f7f7',
              marginBottom: 10,
              padding: 5,
            }}>
              <Button
                onClick={() =>{
                  todasPermissoes()
                }}
                variant="contained" color="secondary">
                Marcar todos
              </Button>
              <Button
                onClick={() =>{
                  editar(dados.id)
                }}
                variant="contained" color="primary">
                Salvar
              </Button>
            </div>
        </div>
    );

    return (
        <div>
            <Modal
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                open={open}
                onClose={closedModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                {body}
            </Modal>
        </div>
    );
}
