import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
// import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
// import api from '../../../services/api';
/* eslint-disable */
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableUsuarios from '../../../Components/Tables/usuarios';
import { useLocation } from 'react-router-dom';
import {veryAuth} from '../../../auth';
import DadosAluno from '../../../Components/DadosAluno';

export default function Index() {
    let history = useHistory();
    const location = useLocation();
    // console.log("location infoAlunos", location.state)
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);
    const [title, setTitle] = useState('Informações do Aluno');
    const [liberaAcesso, setLiberaAcesso] = useState(false);
    const tipoUsuario = location.state ? location.state.usuario : ''
    const idaluno = location.state ? location.state.idaluno : ''
    const nomeAluno = location.state ? location.state.nomeAluno : ''
    const [roles, setRoles] = useState('');
    const [permissaoTelas, setPermissaoTelas] = useState('');

    useEffect(() => {
        // Atualiza o titulo do documento usando a API do browser
        verificarPermissao()
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function verificarPermissao(){
      // console.log("location.state", location.state)
      if(!location.state){
        history.push({
          pathname: `/`,
        });
        return
      } 
      veryAuth()
      .then((result) => {
        console.log('result verificarPermissao', result);
        // setPermissaoTelas(location.state.telasuser[0])
        if(result.data.role === 'admin'){
          setLiberaAcesso(true)
          // usuario()
        }else{
          history.push({
            pathname: `/`,
          });
          return
        }
        setRoles(result.data.role)
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.response, error.message);
      });
    }

    
    // async function usuario(){
    //   var token = localStorage.getItem('@token_diario_web');
    //   var config = {
    //     headers: {Authorization: 'bearer ' + token},
    //   };
    //   try {
    //     const {data : result} = await api.get(`usuario-por-role/${tipoUsuario}`, config);
    //     console.log("Result usuario por role", result)
    //     setData(result)
    //     toast.success('🦄 Carregando alunos' , {
    //       position: "bottom-left",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   } catch (error) {
    //     console.log("Erro ao carregar escolas", error, error.response);
    //     toast.error('🦄 Error ao carregar escolas!', {
    //       position: "bottom-left",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   }
    // }


  
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link className="linkBreadcrumbs" color="inherit" href="/">
                                    Dashboard
                                </Link>
                                <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    // href="/getting-started/installation/"
                                    // onClick={handleClick}
                                >
                                    Aluno
                                </Link>
                                <Typography variant="body2" color="textPrimary">
                                    {title}
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>{title}</h2>
                            </div>
                        </Grid>
                       
                        {liberaAcesso && (
                          <Grid item xs={12} md={12} lg={12}>
                              {skeleton && <Skeleton variant="rect" className="paper" />}
                              {!skeleton && (
                                  <Paper className={'paper'}>
                                    <div className="paperContant">
                                      <DadosAluno 
                                        tipoUsuario={tipoUsuario} 
                                        idaluno={idaluno} 
                                        nomeAluno={nomeAluno} 
                                      />
                                    </div>
                                  </Paper>
                              )}
                          </Grid>
                        )}
                        {!liberaAcesso && (
                          <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                              <Paper className={'paper'}>
                                <div className="paperContantDois">
                                    <div>Acesso Negado</div>
                                </div>
                              </Paper>
                            )}
                          </Grid>
                        )}
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
        </div>
    );
}
