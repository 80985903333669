import React, { useState, useEffect } from 'react';
import '../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import api from '../../services/api';
import {veryAuth} from '../../auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableDiarios from '../../Components/Tables/diarios-professores';
import {format} from 'date-fns';

export default function Index() {
    let history = useHistory();
    const location = useLocation();
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);
    const [roles, setRoles] = useState('');
    const [dadosUsuario, setDadosUsuario] = useState('');

    const idescola = location.state.idescola
    const nomeescola = location.state.nomeescola;
    const anoAtual = format(new Date(), 'yyyy');
    // const [pesquisar, setPesquisar] = useState('');

    useEffect(() => {
        // Atualiza o titulo do documento usando a API do browser
        verificarPermissao()
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function verificarPermissao(){
      veryAuth()
      .then((result) => {
        // console.log("result verificarPermissao dashboard", result);
        setRoles(result.data.role)
        setDadosUsuario(result.data.user)
        diarios(result.data.role)
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.response, error.message);
      });
    }
    
    async function diarios(resultRole){
      console.log('resultRole', resultRole)
      var token = localStorage.getItem('@token_diario_web');
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      if(resultRole === 'admin'){
        try {
          const {data : result} = await api.get(`diarios/${anoAtual}`, config);
          console.log("Result diarios da escola", result)
          setData(result)
          toast.success('🦄 Carregando diários!', {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } catch (error) {
          console.log("Erro ao carregar escolas", error, error.response);
          toast.error('🦄 Error ao carregar escolas!', {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }else if(resultRole === 'professor'){
        try {
           const {data : result} = await api.get(`diarios-escolas-professor/${idescola}/${anoAtual}`, config);
          console.log("Result diarios da escola", result)
          setData(result)
          toast.success('🦄 Carregando diários!', {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } catch (error) {
          console.log("Erro ao carregar escolas", error, error.response);
          toast.error('🦄 Error ao carregar escolas!', {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }else if(resultRole === 'coordenador'){
        try {
           const {data : result} = await api.get(`diarios-escolas-professor/${idescola}/${anoAtual}`, config);
          console.log("Result diarios da escola", result)
          setData(result)
          toast.success('🦄 Carregando diários!', {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } catch (error) {
          console.log("Erro ao carregar escolas", error, error.response);
          toast.error('🦄 Error ao carregar escolas!', {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }

    function deletando(){
      diarios()
    }

    function handleChangePesquisar(event){
      if(event.target.value){
        pesquisarDiario(event.target.value)
      }else{
        diarios(roles)
      }
      // setPesquisar(event.target.value)
    }

    async function pesquisarDiario(termo) {
      //setLoading(true);
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
  
  
      try {
        const {data : result} = await api.get(`pesquisar-diario/${encodeURI(termo)}`, config);
        console.log("Result pesquisar", result)
        setData(result)
      } catch (error) {
        console.log("Erro ao buscar ", error, error.response);
      }
    }
  

    //tabela
    function create() {
        history.push({
            pathname: '/diarios-classe-view',
            // search: '?query=abc',
            state: { type: 'view' }
        });
      }

    function createData(id, professor, escola, ano, anoletivo, turma, turno) {
      return {id, professor, escola, ano, anoletivo, turma, turno};
    }
  
    var rows = [];
    if(data){
      rows = data.map((row) => {
        return createData(
          row.id, 
          row.user.name, 
          row.escola.escola_nome, 
          row.ano, 
          row.anoLetivo.nome_ano,
          row.escolaTurma.turma_nome,
          row.turno
        )
      })
    }
  
    const columns = [
      { id: 'professor', numeric: false, disablePadding: true, label: 'Professor(a)' },
      { id: 'escola', numeric: false, disablePadding: true, label: 'Escola' },
      { id: 'ano', numeric: false, disablePadding: true, label: 'Ano' },
      { id: 'anoletivo', numeric: false, disablePadding: true, label: 'Ano letivo / Turma' },
      { id: 'edit', numeric: true, disablePadding: false, label: '' },
    ];
  
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link className="linkBreadcrumbs" color="inherit" href="/">
                                    Dashboard
                                </Link>
                                <Link
                                  className="linkBreadcrumbs"
                                  color="inherit"
                                  href="/escolas-coordenadores"
                                  >
                                  Escolas
                                </Link>
                                <Link
                                  className="linkBreadcrumbs"
                                  color="inherit"
                                  style={{ cursor: 'pointer'}}
                                  onClick={() => {
                                    history.goBack()
                                  }}
                                  >
                                  Opções
                                </Link>
                                <Typography variant="body2" color="textPrimary">
                                    Diários
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>Diários</h2>
                                {/* <Button
                                    className="button-add"
                                    onClick={create}
                                    variant="contained"
                                    color="primary">
                                    Novo
                                </Button> */}
                            </div>
                        </Grid>
                        {roles === 'admin' && (
                          <Grid item xs={12} md={12} lg={12}>
                              <Paper className={'paperSearch'}>
                                  <TextField
                                      className="textField"
                                      id="outlined-secondary"
                                      label="Digite o nome do professor(a)"
                                      variant="outlined"
                                      // color="secondary"
                                      // value={pesquisar}
                                      onChange={handleChangePesquisar}
                                  />
                              </Paper>
                          </Grid>
                        )}
                        {roles != 'admin' && (
                          <Grid item xs={12} md={12}>
                            <div style={{display: 'flex', flexDirection: 'column', background: '#ffff', padding: 10, borderRadius: 10}}>
                              <span>Escola</span>
                              <span style={{fontSize: 21, fontWeight: 'bold'}}>
                                {nomeescola}
                              </span>
                            </div>
                          </Grid>
                        )}
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                  {rows.length !== 0 && (
                                    <div className="paperContant">
                                      <div style={{
                                        background: '#f7f7f7',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: 10
                                      }}>
                                        <span>Número de diários cadastrados {' - '}</span>
                                        <span style={{fontSize: 21, fontWeight:'bold'}}>{'  '}{rows.length}</span>
                                      </div>
                                      <TableDiarios 
                                        columns={columns} 
                                        rows={rows} 
                                        deletando={deletando}
                                        idescola={idescola}
                                        nomeescola={nomeescola}
                                        nivelAcesso={roles}
                                      />
                                    </div>
                                  )}
                                  {rows.length === 0 && (
                                    <div className="paperContantDois">
                                        <div>Nenhum registro encontrado</div>
                                    </div>
                                  )}
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
        </div>
    );
}
