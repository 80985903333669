import React, { useState, useEffect } from 'react';
/* eslint-disable */
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import '../../../../styles/global.css';
import Settings from '@material-ui/icons/Settings';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import Assignment from '@material-ui/icons/Assignment';
import ThumbUpAlt from '@material-ui/icons/ThumbUpAlt';
import ThumbDownAlt from '@material-ui/icons/ThumbDownAlt';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../../services/api';
import {format, subDays, addDays, isAfter, parseISO} from 'date-fns';
import Tooltip from '@material-ui/core/Tooltip';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
// import Add from '@material-ui/icons/Add';
import CloseBtn from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';


const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    container: {
        overflow: 'none'
    }
});

export default function Index(props) {
    console.log('Props', props);
    const classes = useStyles();
    let history = useHistory();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [openModal, setOpenModal] = useState(false);
    const [idAluno, setIdAluno] = useState('');
    const [nomeAluno, setNomeAluno] = useState('');
    const [diagnostico, setDiagnostico] = useState('');
    const [existeDisgnostico, setExisteDiagnostico] = useState(false);
    const [idEditar, setIdEditar] = useState('');
    const [diagnosticosCadastrados, setDiagnosticosCadastrados] = useState([]);
    const [periodos, setPeriodos] = useState([]);
    const [periodo, setPeriodo] = useState('');
    const [idPeriodo, setIdPeriodo] = useState('');
    const [frequenciasAluno, setFrequenciasAluno] = useState([]);
    const [totalPresenca, setTotalPresenca] = useState(0);
    const [totalFalta, setTotalFalta] = useState(0);

    const columns = props.columns

    const rows = props.rows

    const configr = props.config;

    const iddodiario = props.iddodiario;

    const dataAtual = format(new Date(), 'yyyy-MM-dd')
    const anoAtual = format(new Date(), 'yyyy')

    let myArray = [];

    const StyledTableCell = withStyles((theme) => ({
      head: {
        backgroundColor: '#f7f7f7',
        color: theme.palette.common.black,
      },
      body: {
        fontSize: 14,
      },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }))(TableRow);


    useEffect(() => {
      console.log("rows", rows)
      buscarPeriodo()
    }, []);

    // function editar(id, name, email){
    //   // history.push('/fatura-create');
    //   history.push({
    //       // pathname: '/cliente-edit',
    //       pathname: `${props.tipoUsuario}-create`,
    //       // search: '?query=abc',
    //       state: { 
    //         type: 'edit', 
    //         id: id, 
    //         nome: name, 
    //         email: email,
    //         usuario: props.tipoUsuario,
    //       }
    //   });
    // }

    // const handleOpenModal = (id, nome, periodo) => {
    //   editar(id)
    //   setOpenModal(true);
    //   setIdAluno(id)
    //   setNomeAluno(nome)
    //   setIdPeriodo(periodo.id)
    //   buscarPeriodos()
    //   //idaluno, idperiodo, iddiario
    //   buscarPresencas(id, periodo.id, iddodiario)
    // };
  
    const handleCloseModal = () => {
      setOpenModal(false);
    };
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    // function handleChangeDiagnostico(event){
    //   setDiagnostico(event.target.value);
    // }

    // function diagnosticoExisteuser(idaluno){
    //   console.log("diagnosticosCadastrados.includes(idaluno)", diagnosticosCadastrados.includes(idaluno))
    //   return diagnosticosCadastrados.includes(idaluno)
    // }


    // async function buscarPresencas(idaluno, idperiodo, iddiario){
      
    //   var token = localStorage.getItem('@token_diario_web');
  
    //   var config = {
    //     headers: {Authorization: 'bearer ' + token},
    //   };

    //   try {
    //     //:idaluno/:idperiodo/:iddiario
    //     const {data: frequencia} = await api.get(`/frequencias/${idaluno}/${idperiodo}/${iddiario}`, config);
    //     console.log("buscar frequencia", frequencia)
    //     var qtoPresenca = 0;
    //     var qtoFalta = 0;
    //     for (let p = 0; p < frequencia.length; p++) {
    //       const element = frequencia[p];
    //       if(element.presenca === 'Sim'){
    //         qtoPresenca += 1
    //       }      
    //       if(element.presenca === 'Não'){
    //         qtoFalta += 1
    //       }      
    //     }
    //     setTotalPresenca(qtoPresenca)
    //     setTotalFalta(qtoFalta)
    //     setFrequenciasAluno(frequencia)
 
    //   } catch (error) {
    //     console.log("Erro buscar frequência", error, error.response);
    //     toast.error('🦄 Erro buscar frequência, volte e tente novamente!', {
    //       position: "bottom-left",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //     // setloading(false);
    //   }
    // }
  
    async function buscarPeriodo(){
      
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };

      try {
        const {data: periodo} = await api.get(`pesquisar-periodo/${dataAtual}`, config);
        console.log("buscarPeriodo", periodo)
        setPeriodo(periodo[0])
 
      } catch (error) {
        console.log("Erro buscar período", error, error.response);
        toast.error('🦄 Erro buscar período, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setloading(false);
      }
    }
  
    // async function buscarPeriodos(idperiodo){
    //   // buscar todos os periodos do ano
    //   var token = localStorage.getItem('@token_diario_web');
  
    //   var config = {
    //     headers: {Authorization: 'bearer ' + token},
    //   };

    //   try {
    //     const {data: periodo} = await api.get(`pesquisar-periodo-ano/${anoAtual}`, config);
    //     console.log("buscarPeriodos", periodo)
    //     setPeriodos(periodo)
 
    //   } catch (error) {
    //     console.log("Erro buscar períodos", error, error.response);
    //     toast.error('🦄 Erro buscar períodos, volte e tente novamente!', {
    //       position: "bottom-left",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //     // setloading(false);
    //   }
    // }
  

    // async function editar(id){
    //   setExisteDiagnostico(false)
    //   setIdEditar('')
    //   setDiagnostico('')
    //   var token = localStorage.getItem('@token_diario_web');
  
    //   var config = {
    //     headers: {Authorization: 'bearer ' + token},
    //   };

    //   try {
    //     const {data: edit} = await api.get(`/diagnostico/${id}`, config);
    //     console.log("editar diagnóstico", edit)
    //     if(edit.length != 0){
    //       setExisteDiagnostico(true)
    //       setIdEditar(edit[0].id)
    //       setDiagnostico(edit[0].diagnostico)
    //     }

    //   } catch (error) {
    //     console.log("Erro buscar diagnóstico", error, error.response);
    //     toast.error('🦄 Erro buscar diagnóstico, volte e tente novamente!', {
    //       position: "bottom-left",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //     // setloading(false);
    //   }
    // }
  

    // async function salvar(id, idperiodo, presenca) {
    //   //setloading(true);
    //   var token = localStorage.getItem('@token_diario_web');
  
    //   let form_data = new FormData();

    //   form_data.append("user_id", id);
    //   form_data.append("periodo_id", idperiodo);
    //   form_data.append("diario_id", iddodiario);
    //   form_data.append("data", dataAtual);
    //   form_data.append("presenca", presenca);

    //     await api
    //         .post(`/frequencia`, form_data, {
    //           headers: {
    //             "content-type": "application/json",
    //             Authorization: "bearer " + token,
    //             Accept: "application/json"
    //           }
    //         })
    //         .then(res => {
    //             console.log("Adicionando Frequência", res.data)
    //             handleCloseModal()
    //             toast.success('🦄 Frequência cadastrada com sucesso!', {
    //               position: "bottom-left",
    //               autoClose: 5000,
    //               hideProgressBar: false,
    //               closeOnClick: true,
    //               pauseOnHover: true,
    //               draggable: true,
    //               progress: undefined,
    //             });
    //         })
    //         .catch(error => {
    //           console.log("error: ", error, error.response)
    //           console.log("response: ", error.response.data.message)
              
    //           toast.error(`🦄 Erro ao cadatrar Frequência, ${error.response.data.message}!`, {
    //             position: "bottom-left",
    //             autoClose: 5000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //           });
    //           // setloading(false);
    //           // throw error;
    //         });
 
    //     // await api
    //     //   .post(`/diagnostico/${ideditar}`, form_data, {
    //     //     headers: {
    //     //       "content-type": "application/json",
    //     //       Authorization: "bearer " + token,
    //     //       Accept: "application/json"
    //     //     }
    //     //   })
    //     //   .then(res => {
    //     //       console.log("Diagnótico editado", res.data)
    //     //       editar(res.data.user_id)
    //     //       // setloading(false);
    //     //       toast.success('🦄 Diagnótico editado com sucesso!', {
    //     //         position: "bottom-left",
    //     //         autoClose: 5000,
    //     //         hideProgressBar: false,
    //     //         closeOnClick: true,
    //     //         pauseOnHover: true,
    //     //         draggable: true,
    //     //         progress: undefined,
    //     //       });
    //     //       editar(res.data.id)
    //     //   })
    //     //   .catch(error => {
    //     //     console.log("error: ", error, error.response)
    //     //     toast.error('🦄 Erro ao editar diagnótico, tente novamente ou fale com o suporte!', {
    //     //       position: "bottom-left",
    //     //       autoClose: 5000,
    //     //       hideProgressBar: false,
    //     //       closeOnClick: true,
    //     //       pauseOnHover: true,
    //     //       draggable: true,
    //     //       progress: undefined,
    //     //     });
    //     //     // setloading(false);
    //     //   });


    // }

    // async function deletar(id) {
      
    //   // setloading(true)
    //   var token = localStorage.getItem('@token_diario_web');
  
    //   var config = {
    //     headers: {Authorization: 'bearer ' + token},
    //   };
    //   try {
    //     const {data: prod} = await api.delete(`/usuario/${id}`, config);
    //     console.log("prod", prod)
    //     // limpaCampos();
    //     // setloading(false);
    //     toast.success('🦄 Deletado com sucesso!', {
    //       position: "bottom-left",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //     props.deletando()
    //   } catch (error) {
    //     console.log("Erro ao deletar", error, error.response);
    //     // setloading(false);
    //     toast.error('🦄 Erro ao tentar deletar, volte e tente novamente!', {
    //       position: "bottom-left",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   }
    // }

    return (
        <Paper className={'paper'}>
            <TableContainer style={{border: '1px solid #212121'}} className={classes.container}>
                <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, key) => (
                                <TableCell
                                    key={column.id}
                                    style={{ 
                                      width: key === 0 ? '80%' : '10%', 
                                      textAlign: key != 0 ? 'center' : 'left',
                                      background: '#e8e8e8',
                                      borderRight: key <= 4 ? '1px solid #212121' : ''
                                    }}
                                    align={key != 0 ? 'right' : 'left'}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow
                                        className="tableRow"
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id}>
                                          <TableCell style={{borderRight: '1px solid #212121'}} component="th" scope="row">{row.aluno}</TableCell>
                                          <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121'}}>
                                            {row.result[0].qto}
                                          </TableCell>
                                          <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121'}}>
                                            {row.result[1].qto}
                                          </TableCell>
                                          <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121'}}>
                                            {row.result[2].qto}
                                          </TableCell>
                                          <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121'}}>
                                            {row.result[3].qto}
                                          </TableCell>
                                          <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121'}}>
                                            <strong>
                                              {row.result[0].qto + row.result[1].qto + row.result[2].qto + row.result[3].qto}
                                            </strong>
                                          </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Por página'}
            />
        </Paper>
    );
}
