import React, { useState, useEffect } from 'react';
import '../../../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../../Components/Header';
import Footer from '../../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
/* eslint-disable */
import Skeleton from '@material-ui/lab/Skeleton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FadeLoader from "react-spinners/FadeLoader";
import api from '../../../../services/api';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import Edit from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {format} from 'date-fns';

export default function Index() {
    let history = useHistory();
    const location = useLocation();
    const [skeleton, seSkeleton] = useState(true);
    const [loading, setloading] = useState(false);

    //cadastro
    const [tipo, setTipo] = useState('');
    const [txtBtn, setTxtBtn] = useState('');
    const [idEditar, setIdEditar] = useState('');
    const [title, setTitle] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [periodoEdit, setPeriodoEdit] = useState('');

    const [texto, setTexto] = useState();


    useEffect(() => {
      console.log("location.state.", location.state)
      if(location.state){
        setTipo(location.state.type)
        setTxtBtn(location.state.type === 'edit' ? 'Editar' : 'Salvar')
      }else{
        history.push('/')
      }
      if(location.state.type === 'edit'){
        editar(parseInt(location.state.id))
        // setTitle('Configurações do ' + tipoUsuario )
      }else{
        // setTitle('Cadastro de ' + tipoUsuario )
        setloading(false)
        setDisabled(false)
      }
      setTimeout(function () {
          seSkeleton(false);
      }, 3000);
    }, []);

    function rota(link) {
      history.push({
          pathname: link,
          // search: '?query=abc',
          state: { 
            type: 'new',
          }
      });
    }

    function handleChangeTexto(event){
      setTexto(event.target.value);
    }

    async function deletar() {
      console.log("id", idEditar)
      setloading(true)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: prod} = await api.delete(`/professor-escola/${idEditar}`, config);
        // limpaCampos();
        setloading(false);
        // setOpenAlert(true)
        // setTypeAlert('success')
        // setMsgAlert('Fatura foi deletada com sucesso.')
        toast.success('🦄 Mapeamento deletado com sucesso!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push('/mapeamento-professor')
        // history.push('/clientes')
      } catch (error) {
        console.log("Erro ao deletar", error, error.response);
        setloading(false);
        // setOpenAlert(true)
        // setTypeAlert('error')
        // setMsgAlert('Erro ao deletar, tente novamente')
        toast.error('🦄 Erro ao deletar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    async function editar(id){
      setDisabled(false)
      setIdEditar(id)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      console.log("aqui", id)
      try {
        const {data: edit} = await api.get(`/reorganizacao-planejamento/${id}`, config);
        console.log("editar mapeamento", edit)
        setloading(false);
        setTexto(edit[0].reorganizacao_planejamento)
        setPeriodoEdit(edit[0].periodo)
      } catch (error) {
        console.log("Erro buscar dados para editar", error, error.response);
        toast.error('🦄 Erro ao buscar dados para editar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setloading(false);
      }
    }
  

    async function salvar(event) {
      event.preventDefault();
      // console.log("event", event)
      setloading(true)

      //setloading(true);
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
  
      let form_data = new FormData();
  
      form_data.append("diario_id", location.state.iddodiario);
      form_data.append("periodo_id", location.state.periodo.id);
      form_data.append("reorganizacao_planejamento", texto);
  
      if(tipo === 'new'){
        await api
          .post(`/reorganizacao-planejamento`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Cadastrando Reorganização de planejamento", res.data)
              // limpaCampos();
              setloading(false);
              toast.success('🦄 Reorganização de planejamento cadastrado com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setDisabled(true)
              setTimeout(function () {
                history.goBack()
              }, 5000);
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            console.log("response: ", error.response.data.message)
            toast.error(`🦄 Erro ao cadatrar reorganização de planejamento, ${error.response.data.message}!`, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setloading(false);
            // throw error;
          });
  
      }else if(tipo === 'edit'){
  
        await api
          .post(`/reorganizacao-planejamento/${idEditar}`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Reorganização de planejamento editado", res.data)
              setloading(false);
              toast.success('🦄 Reorganização de planejamento editado com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              editar(res.data.id)
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            toast.error('🦄 Erro ao editar mapeamento, tente novamente ou fale com o suporte!', {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setloading(false);
          });
  
      }
  
    }

    //validações
    const validation = [];
    validation.push(texto);

    // if (tipo) {
    //   validation.push(nome && nome.trim());
    //   validation.push(repetirSenha);
    //   validation.push(senha === repetirSenha);
    // }

    const validForm = validation.reduce((all, v) => all && v);

  

    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Breadcrumbs aria-label="breadcrumb">
                            <Link className="linkBreadcrumbs" color="inherit" href="/">
                                Dashboard
                            </Link>
                            <Link className="linkBreadcrumbs" color="inherit" href="/diarios-classe/">
                                Diários
                            </Link>
                            <Link 
                              className="linkBreadcrumbs" 
                              color="inherit" 
                              style={{ cursor: 'pointer'}}
                              onClick={() => {
                                history.goBack()
                              }}
                            >
                              Configurações do Diário
                            </Link>
                            <Link 
                              className="linkBreadcrumbs" 
                              color="inherit" 
                              style={{ cursor: 'pointer'}}
                              onClick={() => {
                                history.goBack()
                              }}
                            >
                              Reorganização de planejamento
                            </Link>
                            <Typography variant="body2" color="textPrimary">
                              { location.state.type === 'edit' ? 'Editar ' + title : 'Cadastrar ' + title}
                            </Typography>
                          </Breadcrumbs>
                          <div className="headerPage">
                              <h2>{ location.state.type === 'edit' ? 'Editar ' + title : 'Cadastrar ' + title}</h2>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                    <div className="paperContant">
                                        <form noValidate autoComplete="off" onSubmit={salvar}>
                                          <Grid container spacing={3}>
                                              <Grid item xs={12} md={12}>
                                                <FormControl variant="outlined" fullWidth>
                                                  <div>{location.state.type === 'edit' ? periodoEdit.nome_periodo : location.state.periodo.nome_periodo}</div>
                                                </FormControl>
                                              </Grid>
                                              <Grid item xs={12} md={12}>
                                                <TextField
                                                  disabled={disabled}
                                                  fullWidth
                                                  label="Reorganização de planejamento"
                                                  id="outlined-multiline"
                                                  placeholder="Seu texto aqui"
                                                  multiline
                                                  rows={20}
                                                  variant="outlined"
                                                  value={texto}
                                                  onChange={handleChangeTexto}
                                                />
                                              </Grid>
                                            <Grid item xs={12} md={12}>
                                              <Button
                                                disabled={!validForm}
                                                style={{maxWidth: 150, height: 50, marginRight: 10}}
                                                type="submit" variant="outlined" color="primary"
                                              >
                                                {txtBtn}
                                              </Button>

                                                {/* <Button
                                                  style={{maxWidth: 150, height: 50, marginRight: 10}}
                                                  variant="outlined" color="primary"
                                                  onClick={() => {gerarDiario(idEditar)}}
                                                >
                                                  Gerar Diário
                                                </Button> */}
                                                
                                                {/* {tipo === 'edit' &&(
                                                  <Button
                                                    disabled={!validForm}
                                                    style={{maxWidth: 150, height: 50}}
                                                    variant="outlined" color="secondary"
                                                    onClick={deletar}
                                                  >
                                                    Deletar
                                                  </Button>
                                                )} */}
                                            </Grid>
                                          </Grid>

                                        </form>
                                        <ToastContainer
                                          position="top-right"
                                          autoClose={5000}
                                          hideProgressBar={false}
                                          newestOnTop={false}
                                          closeOnClick
                                          rtl={false}
                                          pauseOnFocusLoss
                                          draggable
                                          pauseOnHover
                                        />
                                    </div>
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
            {loading && (
                <div className="loading">
                    <FadeLoader color={'rgb(63, 1, 121)'} loading={loading} size={150} />
                </div>
            )}
        </div>
    );
}
