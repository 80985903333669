/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import api from '../../services/api';
import { useHistory } from 'react-router-dom';
// import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';
import '../../styles/global.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Index() {
  const classes = useStyles();
  let history = useHistory();
  const [open, setOpen] = React.useState(true);
  const [alunos, setAlunos] = useState([]);
  const [qtoAlunos, setQtoAlunos] = useState(0);

  useEffect(() => {
    alunosDaTurma()
  }, []);

  async function alunosDaTurma(){
    var token = localStorage.getItem('@token_diario_web');
    var config = {
      headers: {Authorization: 'bearer ' + token},
    };
    try {
      const {data : result} = await api.get(`alerta-alunos-falta/2`, config);
      console.log("Result faltas alunos", result, result.length)
      setAlunos(result)
      setQtoAlunos(result.length)

    } catch (error) {
      console.log("Erro ao carregar faltas de alunos", error, error.response);
    }
  }

  function abrir(vls){
    // history.push('/fatura-create');
    history.push({
        pathname: 'alunos-faltosos',
        // search: '?query=abc',
        state: { 
          alunos: vls
        }
    });
  }

  return (
    <div className={classes.root}>
      {qtoAlunos != 0 && (
        <Collapse in={open}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="primary"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Veja alunos que estão com muitas faltas ({qtoAlunos})
            <Button 
              color="primary"
              style={{
                marginLeft: 10
              }}
              onClick={() => {
                abrir(alunos)
              }}
            >
                Ver alunos
            </Button>
          </Alert>
        </Collapse>
      )}
    </div>
  );
}
