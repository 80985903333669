import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
// import TextField from '@material-ui/core/TextField';
/* eslint-disable */
import Skeleton from '@material-ui/lab/Skeleton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FadeLoader from "react-spinners/FadeLoader";
import api from '../../../services/api';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import Edit from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';

export default function Index() {
    let history = useHistory();
    const location = useLocation();
    const [skeleton, seSkeleton] = useState(true);
    const [loading, setloading] = useState(false);

    //cadastro
    const [tipo, setTipo] = useState('');
    const [txtBtn, setTxtBtn] = useState('');
    const [idEditar, setIdEditar] = useState('');
    const [name, setName] = useState('');
    const [status, setStatus] = useState('Ativo');
    const [title, setTitle] = useState('Cadastrar Polo');

    useEffect(() => {
        // Atualiza o titulo do documento usando a API do browser
        console.log("um", location.pathname);
        console.log("dois", location);
        if(location.state){
          setTipo(location.state.type)
          setTxtBtn(location.state.type === 'edit' ? 'Editar' : 'Salvar')
        }else{
          history.push('/')
        }
        if(location.state.type === 'edit'){
          editar(parseInt(location.state.id))
          setTitle('Configurações do polo')
        }else{
          setloading(false)
        }
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function abrirPageServicos(){
      console.log("location.state.id", location.state.id, location.state.nome, location.state.email)
      history.push({
          pathname: '/listar-servicos-cliente',
          // search: '?query=abc',
          state: { id: location.state.id, nome: location.state.nome , email: location.state.email }
      });
    }

    function handleChangeName(event){
      setName(event.target.value);
    }

    async function editar(id){
      setIdEditar(id)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: edit} = await api.get(`/polo/${id}`, config);
        console.log("Dados para editar", edit)
        setloading(false);
        setName(edit.polo_nome)
        setStatus(edit.status)
      } catch (error) {
        console.log("Erro buscar dados para editar", error, error.response);
        toast.error('🦄 Erro ao buscar dados para editar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setloading(false);
      }
    }
  

    async function deletar() {
    console.log("id", idEditar)
    setloading(true)
    var token = localStorage.getItem('@token_diario_web');

    var config = {
      headers: {Authorization: 'bearer ' + token},
    };
    try {
      const {data: prod} = await api.delete(`/polo/${idEditar}`, config);
      console.log("prod", prod)
      // limpaCampos();
      setloading(false);
      toast.success('🦄 Polo deletado com sucesso!', {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push('/polos')
    } catch (error) {
      console.log("Erro ao deletar", error, error.response);
      setloading(false);
      toast.error('🦄 Erro ao deletar, volte e tente novamente!', {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

    async function salvar(event) {
      event.preventDefault();
      // console.log("event", event)
      setloading(true)
  
      //setloading(true);
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
  
      let form_data = new FormData();
  
      form_data.append("polo_nome", name);
      form_data.append("status", status);
      
      if(tipo === 'new'){
        await api
          .post(`/polo`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Adicionando polo", res.data)
              limpaCampos();
              setloading(false);
              toast.success('🦄 Polo cadastrado com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            console.log("response: ", error.response.data.message)
            toast.error(`🦄 Erro ao cadatrar Polo, ${error.response.data.message}!`, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setloading(false);
            // throw error;
          });
  
      }else if(tipo === 'edit'){
  
        await api
          .post(`/polo/${idEditar}`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Polo editado", res.data)
              setloading(false);
              toast.success('🦄 Polo editado com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              editar(res.data.id)
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            toast.error('🦄 Erro ao editar Polo, tente novamente ou fale com o suporte!', {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setloading(false);
          });
  
      }
  
    }

    function limpaCampos(){
      setName('')
    }

    //validações
    const validation = [];
    validation.push(name);


    const validForm = validation.reduce((all, v) => all && v);

  

    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link className="linkBreadcrumbs" color="inherit" href="/">
                                    Dashboard
                                </Link>
                                <Link className="linkBreadcrumbs" color="inherit" href="/polos/">
                                    Polos
                                </Link>
                                <Typography variant="body2" color="textPrimary">
                                    {title}
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>{title}</h2>
                            </div>
                        </Grid>
              
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                    <div className="paperContant">
                                        <form noValidate autoComplete="off" onSubmit={salvar}>
                                          <Grid container spacing={3}>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="name">Nome do Polo</InputLabel>
                                                  <OutlinedInput
                                                    error={!name ? true : false}
                                                    id="name"
                                                    label={'Nome do Polo'}
                                                    value={name}
                                                    onChange={handleChangeName}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Button
                                                  disabled={!validForm}
                                                  style={{maxWidth: 150, height: 50, marginRight: 10}}
                                                  type="submit" variant="outlined" color="primary"
                                                >
                                                  {txtBtn}
                                                </Button>
                                                {tipo === 'edit' &&(
                                                  <Button
                                                    disabled={!validForm}
                                                    style={{maxWidth: 150, height: 50}}
                                                    variant="outlined" color="secondary"
                                                    onClick={deletar}
                                                  >
                                                    Deletar
                                                  </Button>
                                                ) }
                                            </Grid>
                                          </Grid>

                                        </form>
                                        <ToastContainer
                                          position="top-right"
                                          autoClose={5000}
                                          hideProgressBar={false}
                                          newestOnTop={false}
                                          closeOnClick
                                          rtl={false}
                                          pauseOnFocusLoss
                                          draggable
                                          pauseOnHover
                                        />
                                    </div>
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
            {loading && (
                <div className="loading">
                    <FadeLoader color={'rgb(63, 1, 121)'} loading={loading} size={150} />
                </div>
            )}
        </div>
    );
}
