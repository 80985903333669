import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
/* eslint-disable */
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableMapeamentoAluno from '../../../Components/Tables/mapeamentos-aluno';
import { useLocation } from 'react-router-dom';
import {veryAuth} from '../../../auth';

export default function Index() {
    let history = useHistory();
    const location = useLocation();
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);
    const [title, setTitle] = useState('Matrícula');
    const tipoUsuario = 'gestor'

    useEffect(() => {
        verificarPermissao()
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function verificarPermissao(){
      veryAuth()
      .then((result) => {
        if(result.data.role === 'gestor'){
          carregarMapeamento()
        }else{
          history.push({
            pathname: `/`,
          });
          return
        }
        // setRoles(result.data.role)
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.response, error.message);
      });
    }

    
    async function carregarMapeamento(){
      var token = localStorage.getItem('@token_diario_web');
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data : result} = await api.get(`aluno-escola`, config);
        console.log("Result matrículas", result)
        setData(result)
        toast.success('🦄 Carregando matrículas dos alunos' , {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro ao carregar matrículas dos alunos", error, error.response);
        toast.error('🦄 Error ao carregar matrículas!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    function deletando(){
      carregarMapeamento()
    }

    function handleChangePesquisar(event){
      if(event.target.value){
        pesquisarMapeamento(event.target.value)
      }else{
        carregarMapeamento()
      }
      // setPesquisar(event.target.value)
    }

    async function pesquisarMapeamento(termo) {
      //setLoading(true);
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
  
  
      try {
        const {data : result} = await api.get(`pesquisar-aluno-escola/${encodeURI(termo)}`, config);
        console.log("Result pesquisar", result)
        setData(result)
      } catch (error) {
        console.log("Erro ao buscar ", error, error.response);
      }
    }
  
    function create() {
      history.push({
          pathname: `matricula-gestor-create`,
          // search: '?query=abc',
          state: { 
            type: 'new',
          }
      });
    }

    //tabela

    function createData(
      id, 
      name, 
      anoletivo,
      turma,
      ano,
      escola, 
      created_at, 
      telefone,
    ) {
      return {
        id, 
        name, 
        anoletivo,
        turma,
        ano,
        escola, 
        created_at, 
        telefone, 
      };
    }
  
    var rows = [];
    if(data){
      rows = data.map((row) => {
        return createData(
          row.id, 
          row.user.name, 
          row.ano,
          row.escolaTurma.turma_nome,
          row.anoLetivo.nome_ano,
          row.escola.escola_nome, 
          row.created_at, 
          row.user.telefone, 
        )
      })
    }
  
    const columns = [
      { id: 'aluno', numeric: false, disablePadding: true, label: 'Aluno' },
      { id: 'ano_letivo', numeric: false, disablePadding: true, label: 'Ano Letivo' },
      { id: 'turma', numeric: false, disablePadding: true, label: 'Turma' },
      { id: 'ano_serie', numeric: false, disablePadding: true, label: 'Ano/Série' },
      { id: 'escola', numeric: true, disablePadding: false, label: 'Escola' },
      { id: 'data', numeric: true, disablePadding: false, label: 'Data' },
      { id: 'telefone', numeric: true, disablePadding: false, label: 'Telefone' },
      { id: 'edit', numeric: true, disablePadding: false, label: 'Editar' },
    ];
  
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link className="linkBreadcrumbs" color="inherit" href="/">
                                    Dashboard
                                </Link>
                                {/* <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/getting-started/installation/"
                                    onClick={handleClick}>
                                    Core
                                </Link> */}
                                <Typography variant="body2" color="textPrimary">
                                    {title}
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>{title}</h2>
                                <Button
                                    className="button-add"
                                    onClick={create}
                                    variant="contained"
                                    color="primary">
                                    Nova Matrícula
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={'paperSearch'}>
                                <TextField
                                    className="textField"
                                    id="outlined-secondary"
                                    label="Nome do aluno(a)"
                                    variant="outlined"
                                    // color="secondary"
                                    // value={pesquisar}
                                    onChange={handleChangePesquisar}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                  {rows.length !== 0 && (
                                    <div className="paperContant">
                                      <TableMapeamentoAluno
                                        columns={columns} 
                                        rows={rows} 
                                        deletando={deletando}
                                        tipoUsuario={tipoUsuario}
                                      />
                                    </div>
                                  )}
                                  {rows.length === 0 && (
                                    <div className="paperContantDois">
                                        <div>Nenhum registro encontrado</div>
                                    </div>
                                  )}
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
        </div>
    );
}
