import React, { useState } from 'react';
/* eslint-disable */
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import api from '../../../../services/api';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import Edit from '@material-ui/icons/Edit';
import ModalEdit from './modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles({
    table: {
        minWidth: 650
    }
});

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9)
];

export default function Index(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [dados, setDados] = useState();
    // const [data, setData] = useState([]);
    var data = props.telas

    function editar(dados){
      console.log("dados carregados", dados)
      setDados(dados)
      setOpen(!open)
    }

    function closedModal(){
      setOpen(false)
    }
    
    // useEffect(() => {
    //   console.log('Props tela de acesso', props);
    //   setData(props.telas)
    //   // console.log("carregou aqui")
    //   // editar(props.idEditar)
    // }, []);

    async function deletar(idEditar) {
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: prod} = await api.delete(`/tela-acesso/${idEditar}`, config);
        toast.success('🦄 Permissão deletada com sucesso!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      } catch (error) {
        console.log("Erro ao deletar", error, error.response);
        // setOpenAlert(true)
        // setTypeAlert('error')
        // setMsgAlert('Erro ao deletar, tente novamente')
        toast.error('🦄 Erro deletar Permissão!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }


    // async function editar(id) {
    //     var token = localStorage.getItem('@token_diario_web');

    //     var config = {
    //         headers: { Authorization: 'bearer ' + token }
    //     };
    //     // console.log("aqui", id)
    //     try {
    //         const { data: list } = await api.get(`/telas-acesso/${props.idEditar}`, config);
    //         console.log('telas de acesso', list);
    //         setData(list)
    //         setLoading(false);
    //     } catch (error) {
    //         console.log('Erro buscar telas de acesso', error, error.response);

    //         // setloading(false);
    //     }
    // }
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Tela</TableCell>
                        <TableCell align="right">Criar</TableCell>
                        <TableCell align="right">Editar</TableCell>
                        <TableCell align="right">Exibir</TableCell>
                        <TableCell align="right">Deletar</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                                {row.menu.nome}
                            </TableCell>
                            <TableCell style={{color: row.create === 0 ? 'red' : ''}} align="right">{row.create === 0 ? 'Não' : 'Sim'}</TableCell>
                            <TableCell style={{color: row.create === 0 ? 'red' : ''}} align="right">{row.update === 0 ? 'Não' : 'Sim'}</TableCell>
                            <TableCell style={{color: row.create === 0 ? 'red' : ''}} align="right">{row.read === 0 ? 'Não' : 'Sim'}</TableCell>
                            <TableCell style={{color: row.create === 0 ? 'red' : ''}} align="right">{row.delete === 0 ? 'Não' : 'Sim'}</TableCell>
                            <TableCell align="right">
                              <Tooltip title={`Editar Acesso`} aria-label="add">
                                <IconButton onClick={() => {editar(row)}} color="primary">
                                    <Edit />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={`Deletar Tela`} aria-label="add">
                                <IconButton onClick={() => {deletar(row.id)}} color="inherit">
                                    <DeleteSweepIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {open && (
              <ModalEdit 
                closedModal={closedModal} 
                openModal={open} 
                dados={dados}
              />
            )}
        </TableContainer>
    );
}
