import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import '../../styles/global.css';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
/* eslint-disable */
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import MenuIcon from '@material-ui/icons/Menu';
import Avatar from '@material-ui/core/Avatar';
// import NotificationsIcon from '@material-ui/icons/Notifications';
// import Badge from '@material-ui/core/Badge';
// import { mainListItems, secondaryListItems } from './listmenu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import DraftsIcon from '@material-ui/icons/Drafts';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import Icon from '@material-ui/core/Icon';
import logoEz from '../../assets/image/logo.jpg'
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    toolbar: {
        paddingRight: 24 // keep right padding when drawer closed
    },
    appBar: {
        background: '#ffff',
        boxShadow: 'none',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: 36,
        left: 60,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButtonHidden: {
        // display: 'none'
        left: 0,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    title: {
        flexGrow: 1
    },
    drawerPaper: {
        background: 'rgb(63, 1, 121)',
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9)
        }
    },
    iconesHeader: {
        position: 'absolute',
        right: 20
    },
    list:{
      color: '#fff',
      '& div':{
        color: '#fff',
      }
    },
    nested:{
      paddingLeft: 20,
      background: 'rgb(40 0 78)',

    }
}));

const StyledMenu = withStyles({
    paper: {
        border: 'none',
        marginTop: 20
    }
})((props) => (
    <Menu
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
        }}
        {...props}
    />
));

// const StyledMenuItem = withStyles((theme) => ({
//     root: {
//         '&:focus': {
//             backgroundColor: theme.palette.primary.main,
//             '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
//                 color: theme.palette.common.white
//             }
//         }
//     }
// }))(MenuItem);



export default function Index(props) {
    const classes = useStyles();
    let history = useHistory();
    const [open, setOpen] = useState(true);
    const [openSubItem, setOpenSubItem] = useState(true);
    const [listaMenu, setListaMenu] = useState([]);
    const [listaMenuAdmin, setListaMenuAdmin] = useState([]);
    const [listaMenuCoordenador, setListaMenuCoordenador] = useState([]);
    const [listaMenuGestor, setListaGestor] = useState([]);
    const [listaMenuAluno, setListaMenuAluno] = useState([]);


    useEffect(() => {
      menu()
      console.log("props header", props)
    }, []);

    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }

    async function menu(){
      var arraAdmin = []
      var arraCoordenador = []
      var arraGestor = []
      var arraAluno = []
      var token = localStorage.getItem('@token_diario_web');
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data : result} = await api.get('menu', config);
        console.log("Result menu header", result)
        for (let r = 0; r < result.length; r++) {
          var element = result[r];
          if(element.grupo === 'Administração'){
            // console.log("element ", element)
            arraAdmin.push(element)
          }

          if(element.grupo === 'Coordenador'){
            // console.log("element ", element)
            arraCoordenador.push(element)
          }

          if(element.grupo === 'Gestor'){
            // console.log("element ", element)
            arraAdmin.push(element)
          }

          if(element.grupo === 'Aluno'){
            // console.log("element ", element)
            arraAluno.push(element)
          }
        }
        setListaMenuAdmin(arraAdmin)
        setListaMenuCoordenador(arraCoordenador)
        setListaMenuAluno(arraAluno)
        // setListaGestor(arraGestor)
        // setData(result)
        // setListaMenu(result)
      } catch (error) {
        console.log("Erro menu", error, error.response);
      }
    }


    const handleDrawerOpen = () => { // eslint-disable-line
        setOpen(!open);
    };
    const handleDrawerOpenSubItem = () => {
      setOpenSubItem(!openSubItem);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {// eslint-disable-line
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function abrirPerfil() {
        history.push('/perfil');
    }
    function sair() {
        localStorage.removeItem('@token_diario_web'); // remover do localStorage
        // localStorage.clear();
        history.push('/login');
    }

    function abrirPagina(rota, telasuser, type, usuario) {
      //item.link, item.telasUser, 'new', 'admin'
      console.log("dados da rota", rota, telasuser, type, usuario)
      history.push({
          pathname: rota,
          // search: '?query=abc',
          state: {
            telasuser, 
            type,
            usuario
          }
      });
    }

    return (
        <>
            <AppBar
                position="absolute"
                className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
                        <MenuIcon style={{ color: 'rgb(63, 1, 121)' }} />
                    </IconButton>
                    {/* <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}>
                        Dashboard
                    </Typography> */}
                    <div className={classes.iconesHeader}>
                        {/* <IconButton color="inherit">
                            <Badge badgeContent={4} color="secondary">
                                <NotificationsIcon style={{ color: 'rgb(63, 1, 121)' }} />
                            </Badge>
                        </IconButton> */}
        
                        <IconButton onClick={sair} color="inherit">
                            <PowerSettingsNewIcon style={{ color: 'rgb(63, 1, 121)' }} />
                        </IconButton>
                        <IconButton onClick={handleClick} color="inherit">
                            <Avatar alt={'D'} src="/static/images/avatar/1.jpg" />
                        </IconButton>
                    </div>
                </Toolbar>
                <StyledMenu
                    style={{right: 10}}
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}>
                      <div onClick={abrirPerfil} className="listItemIcon">
                        <PermIdentityIcon fontSize="small" />
                        <span>Perfil</span>
                      </div>
                </StyledMenu>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
                }}
                open={open}>
                <div className="imgMenu">
                    <img
                        src={'https://i1.wp.com/pinheiro.ma.gov.br/wp-content/uploads/2021/02/LOGO-DE-PINHEIRO-1-min-1-1-min.png?fit=800%2C249'}
                        alt="Logo"
                    />
                </div>
                {/* <p>
                listaMenuAdmin {listaMenuAdmin.length}
                </p>
                <p>
                  listaMenuCoordenador {listaMenuCoordenador.length}
                </p> */}
                {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                  <span style={{color: '#ffff'}}>Usuário</span>
                  <span style={{color: '#ffff'}}>Nível de acesso</span>
                </div> */}
                {listaMenu.map((item, key) => (
                    <ListItemLink key={key} href={item.link}>
                        <ListItemIcon style={{ minWidth: 30}}>
                            <Icon className={'fa fa-' + item.icon} style={{ color: '#ffff', width: 40 }} />
                        </ListItemIcon>
                        <ListItemText style={{ color: '#ffff' }} primary={item.nome} />
                    </ListItemLink>
                ))}
                <ListItemLink href={'/'}>
                    <ListItemIcon style={{ minWidth: 30}}>
                        <Icon className={'fa fa-home'} style={{ color: '#ffff', width: 40 }} />
                    </ListItemIcon>
                    <ListItemText style={{ color: '#ffff' }} primary={'Página principal'} />
                </ListItemLink>
                <Divider />
                <List
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  // subheader={
                  //   <ListSubheader component="div">
                  //    Administração
                  //   </ListSubheader>
                  // }
                  className={classes.list}
                >
                  {listaMenuAdmin.length != 0 && (
                    <ListItem button onClick={handleDrawerOpenSubItem}>
                      <ListItemIcon style={{ minWidth: 30}}>
                        <Icon className={'fa fa-users-cog'} style={{ color: '#ffff', width: 40 }} />
                      </ListItemIcon>
                      <ListItemText primary="Administração" />
                      {openSubItem ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                  )}
                  {listaMenuAdmin.length != 0 && (
                    <Collapse in={openSubItem} timeout="auto" unmountOnExit>
                      {listaMenuAdmin.map((item, key) => (
                          <ListItemLink key={key}
                            onClick={() => {
                              console.log("item listaMenuAdmin", item)
                              if(item.link === '/administradores'){
                                abrirPagina(item.link, item.telasUser, 'new', 'admin')
                              }else if(item.link === '/coordenadores'){
                                abrirPagina(item.link, item.telasUser, 'new', 'coordenador')
                              }else if(item.link === '/gestores'){
                                abrirPagina(item.link, item.telasUser, 'new', 'gestor')
                              }else if(item.link === '/professores'){
                                abrirPagina(item.link, item.telasUser, 'new', 'professor')
                              }else if(item.link === '/alunos'){
                                abrirPagina(item.link, item.telasUser, 'new', 'aluno')
                              }else{
                                abrirPagina(item.link, item.telasUser, '')
                              }
                            }}
                          >
                              <ListItemIcon style={{ minWidth: 30}}>
                                  <Icon className={'fa fa-' + item.icon} style={{ color: '#ffff', width: 40 }} />
                              </ListItemIcon>
                              <ListItemText style={{ color: '#ffff' }} primary={item.nome} />
                          </ListItemLink>
                      ))}
                      {/* <List component="div" disablePadding>
                        <ListItemLink button className={classes.nested} href={'/periodos'}>
                          <ListItemIcon style={{ minWidth: 30}}>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText primary="Períodos" />
                        </ListItemLink>
                      </List>
                      <List component="div" disablePadding>
                        <ListItemLink button className={classes.nested} href={'/polos'}>
                          <ListItemIcon style={{ minWidth: 30}}>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText primary="Polos" />
                        </ListItemLink>
                      </List>
                      <List component="div" disablePadding>
                        <ListItemLink button className={classes.nested} href={'/povoados'}>
                          <ListItemIcon style={{ minWidth: 30}}>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText primary="Povoados" />
                        </ListItemLink>
                      </List>
                      <List component="div" disablePadding>
                        <ListItemLink button className={classes.nested} href={'/escolas'}>
                          <ListItemIcon style={{ minWidth: 30}}>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText primary="Escolas" />
                        </ListItemLink>
                      </List>
                      <List component="div" disablePadding>
                        <ListItemLink 
                          button 
                          className={classes.nested} 
                          onClick={() => {
                            abrirPagina('/coordenadores', 'new', 'coordenador')
                          }}
                        >
                          <ListItemIcon style={{ minWidth: 30}}>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText primary="Coordenadores" />
                        </ListItemLink>
                      </List>
                      <List component="div" disablePadding>
                        <ListItemLink 
                          button 
                          className={classes.nested} 
                          onClick={() => {
                            abrirPagina('/gestores', 'new', 'gestor')
                          }}
                        >
                          <ListItemIcon style={{ minWidth: 30}}>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText primary="Gestores" />
                        </ListItemLink>
                      </List>
                      <List component="div" disablePadding>
                        <ListItemLink 
                          button 
                          className={classes.nested} 
                          onClick={() => {
                            abrirPagina('/professores', 'new', 'professor')
                          }}
                        >
                          <ListItemIcon style={{ minWidth: 30}}>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText primary="Professores" />
                        </ListItemLink>
                      </List>
                      <List component="div" disablePadding>
                        <ListItemLink 
                          button 
                          className={classes.nested} 
                          onClick={() => {
                            abrirPagina('/alunos', 'new', 'aluno')
                          }}
                        >
                          <ListItemIcon style={{ minWidth: 30}}>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText primary="Alunos/Responsáveis" />
                        </ListItemLink>
                      </List>
                      <List component="div" disablePadding>
                        <ListItemLink button className={classes.nested} href={'/mapeamento-professor'}>
                          <ListItemIcon style={{ minWidth: 30}}>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText primary="Mapeamento Professor" />
                        </ListItemLink>
                      </List>
                      <List component="div" disablePadding>
                        <ListItemLink button className={classes.nested} href={'/matricula'}>
                          <ListItemIcon style={{ minWidth: 30}}>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText primary="Matriculas" />
                        </ListItemLink>
                      </List>
                      <List component="div" disablePadding>
                        <ListItemLink button className={classes.nested} href={'/diarios-classe'}>
                          <ListItemIcon style={{ minWidth: 30}}>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText primary="Diários de Classe" />
                        </ListItemLink>
                      </List> */}
                    </Collapse>
                  )}
                  {listaMenuCoordenador.length != 0 && (
                    <List>
                      {listaMenuCoordenador.map((item, key) => (
                        <ListItem 
                          button 
                          key={key} 
                          // href={item.link}
                          onClick={() => {
                            abrirPagina(item.link, '', '')
                          }}
                        >
                          <ListItemIcon style={{ minWidth: 30}}>
                              <Icon className={'fa fa-' + item.icon} style={{ color: '#ffff', width: 40 }} />
                          </ListItemIcon>
                          <ListItemText primary={item.nome} />
                        </ListItem>
                      ))}
                    </List>
                  )}
                  {listaMenuAluno.length != 0 && (
                    <List>
                      {listaMenuAluno.map((item, key) => (
                        <ListItem 
                          button 
                          key={key} 
                          // href={item.link}
                          onClick={() => {
                            abrirPagina(item.link, '', '')
                          }}
                        >
                          <ListItemIcon style={{ minWidth: 30}}>
                              <Icon className={'fa fa-' + item.icon} style={{ color: '#ffff', width: 40 }} />
                          </ListItemIcon>
                          <ListItemText primary={item.nome} />
                        </ListItem>
                      ))}
                    </List>
                  )}
                  {/* <ListItem button>
                    <ListItemIcon>
                      <SendIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sent mail" />
                  </ListItem>
                   */}
                </List>
            </Drawer>
        </>
    );
}
