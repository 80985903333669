import React, { useState, useEffect } from 'react';
import '../../../../styles/global.css';
/* eslint-disable */
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../../Components/Header';
import Footer from '../../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
import api from '../../../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Table from '../../Components/Tabelas/tableParecerTecnico';
import { useLocation } from 'react-router-dom';

export default function Index() {
    let history = useHistory();
    const location = useLocation();
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);
    const [title, setTitle] = useState('Parecer Técnico / Orientação');

    const iddodiario = location.state.iddiario;
    const periodo = location.state.periodo;
    const nivelAcesso = location.state.nivelAcesso;
    

    useEffect(() => {
      console.log("location.state", location.state)
        // Atualiza o titulo do documento usando a API do browser
        carregarPareceTecnico()
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    
    async function carregarPareceTecnico(){
      var token = localStorage.getItem('@token_diario_web');
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data : result} = await api.get(`parecer-tecnicos/${iddodiario}`, config);
        console.log("Result parecer técnico", result)
        setData(result)
        toast.success('🦄 Carregando parecer técnico' , {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro ao carregar parecer técnico", error, error.response);
        toast.error('🦄 Error ao carregar parecer técnico!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    //tabela
    function create() {
      history.push({
          pathname: `parecer-tecnico-create`,
          // search: '?query=abc',
          state: { 
            type: 'new',
            iddodiario: iddodiario,
            periodo: periodo,
            nivelAcesso: nivelAcesso
          }
      });
    }

    function createData(
      id, 
      periodo, 
      parece_tecnico,
      parece_tecnico_pendente,
      created_at, 
      updated_at,
    ) {
      return {
        id, 
        periodo, 
        parece_tecnico,
        parece_tecnico_pendente,
        created_at, 
        updated_at,
      };
    }
  
    var rows = [];
    if(data){
      rows = data.map((row) => {
        return createData(
          row.id, 
          row.periodo.nome_periodo, 
          row.parece_tecnico,
          row.parece_tecnico_pendente,
          row.created_at, 
          row.updated_at
        )
      })
    }
  
    const columns = [
      { id: 'periodo', numeric: false, disablePadding: true, label: 'Período' },
      { id: 'criado', numeric: true, disablePadding: false, label: 'Criado em' },
      { id: 'atualizacao', numeric: true, disablePadding: false, label: 'Atualizado em' },
      { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
      { id: 'acao', numeric: true, disablePadding: false, label: '' },
    ];
  
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Breadcrumbs aria-label="breadcrumb">
                            <Link className="linkBreadcrumbs" color="inherit" href="/">
                                Dashboard
                            </Link>
                            <Link className="linkBreadcrumbs" color="inherit" href="/diarios-classe/">
                                Diários
                            </Link>
                            <Link 
                              className="linkBreadcrumbs" 
                              color="inherit" 
                              style={{ cursor: 'pointer'}}
                              onClick={() => {
                                history.goBack()
                              }}
                            >
                              Configurações do Diário
                            </Link>
                            <Typography variant="body2" color="textPrimary">
                              {title}
                            </Typography>
                          </Breadcrumbs>
                          <div className="headerPage">
                              <h2>{title}</h2>
                              {nivelAcesso === 'coordenador' && (
                                <Button
                                    className="button-add"
                                    onClick={create}
                                    variant="contained"
                                    color="primary">
                                    Novo
                                </Button>
                              )}
                          </div>
                          <div className="headerPage">
                              <Button
                                  style={{marginTop: 10}}
                                  className="button-add"
                                  onClick={() => {
                                    history.goBack()
                                  }}
                                  variant="contained"
                                  color="primary">
                                  Retornar ao diário
                              </Button>
                          </div>
                        </Grid>
                        {/* <Grid item xs={12} md={12} lg={12}>
                            <Paper className={'paperSearch'}>
                                <TextField
                                    className="textField"
                                    id="outlined-secondary"
                                    label="Pesquisar professor(a)"
                                    variant="outlined"
                                    // color="secondary"
                                    // value={pesquisar}
                                    onChange={handleChangePesquisar}
                                />
                            </Paper>
                        </Grid> */}
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                  <div className="diarioHeader">
                                    <div className="ih">
                                      <span>Ano letivo:</span>
                                      <span><strong>{location.state.periodo.ano_periodo}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Escola:</span>
                                      <span><strong>{location.state.adicionais.escola}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Professor:</span>
                                      <span><strong>{location.state.adicionais.professor}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Ano:</span>
                                      <span><strong>{location.state.adicionais.anoLetivo}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Turno:</span>
                                      <span><strong>{location.state.turno}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Turma:</span>
                                      <span><strong>{location.state.turma}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Período:</span>
                                      <span><strong>{location.state.periodo.nome_periodo}</strong></span>
                                    </div>
                                  </div>
                                  {rows.length !== 0 && (
                                    <div className="paperContant">
                                      <Table
                                        columns={columns} 
                                        rows={rows} 
                                        iddodiario={iddodiario}
                                        periodo={periodo}
                                        nivelAcesso={nivelAcesso}
                                      />
                                    </div>
                                  )}
                                  {rows.length === 0 && (
                                    <div className="paperContantDois">
                                        <div>Nenhum registro encontrado</div>
                                    </div>
                                  )}
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
        </div>
    );
}
