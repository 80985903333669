import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import Link from '@material-ui/core/Link';
// import Grid from '@material-ui/core/Grid';
/* eslint-disable */
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormControl from '@material-ui/core/FormControl';
import CpfCnpj from '@react-br-forms/cpf-cnpj-mask';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
// import logoEz from '../../assets/image/logo.jpg';
// import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  container:{
    display: 'flex',
    position: 'relative',
    width: '100vw',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'white',
      padding: '10px',
      borderRadius: '10px'
  },
  loadingLogin:{
    position: 'absolute !important',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(255 255 255 / 68%)'
  },
  avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main
  },
  form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1)
  },
  submit: {
      margin: theme.spacing(3, 0, 2)
  }
}));

export default function Index() {
    const classes = useStyles();
    let history = useHistory();
    const [loading, setloading] = useState(false);

    const [cpf, setCpf] = useState('');
    // const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [lembrar, setLembrar] = useState(false);

    useEffect(() => {
        // const authenticated = localStorage.getItem('@token_diario_web');
        // if (authenticated) {
        //     history.push('/');
        // }
        // var emailStorage = localStorage.getItem('@lembrarEmail:gerenciador');
        // var senhaStorage = localStorage.getItem('@lembrarSenha:gerenciador');
        // console.log('emailStorage', emailStorage, senhaStorage);
        // if (emailStorage && senhaStorage) {
        //     setEmail(emailStorage);
        //     setSenha(senhaStorage);
        //     setLembrar(true);
        // }
    }, []);

    function handleChangeCpf(event) {
        let cpfDifitado = event.target.value;
        console.log('event.target', cpfDifitado.length);
        if (cpfDifitado.length <= 14) {
            setCpf(cpfDifitado);
        }
    }

    // function handleChangeEmail(event) {
    //     setEmail(event.target.value);
    // }

    function handleChangeSenha(event) {
        setSenha(event.target.value);
    }

    function handleChangeLembrar(event) {
        // console.log('event.target.checked', event.target.checked);
        if (event.target.checked) {
            // console.log('aqui');
            localStorage.setItem('@lembrarEmail:gerenciador', cpf);
            localStorage.setItem('@lembrarSenha:gerenciador', senha);
        } else {
            localStorage.setItem('@lembrarEmail:gerenciador', '');
            localStorage.setItem('@lembrarSenha:gerenciador', '');
            setCpf('');
            setSenha('');
        }
        setLembrar(event.target.checked);
    }

    // async function buscarPerfil(token) {
    //     var config = {
    //         headers: { Authorization: 'bearer ' + token }
    //     };

    //     try {
    //         const { data: edit } = await api.get(`/perfil/`, config);
    //         console.log('edit', edit);
    //         localStorage.setItem('@perfil:name', edit.name);
    //         localStorage.setItem('@perfil:email', edit.email);
    //         localStorage.setItem('@perfil:tipo_conta', edit.tipo_conta);
    //     } catch (error) {
    //         console.log('Erro buscar dados para editar', error, error.response);
    //         toast.error(`Error, ${error.response.data.message}`, {
    //             position: 'bottom-left',
    //             autoClose: 5000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined
    //         });
    //     }
    // }

    async function login(event) {
        event.preventDefault();
        setloading(true);
        try {
            const response = await api.post('/sessions', {
                cpf: cpf.trim(),
                password: senha,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            });
            // console.log('Sucesso, ', response.data.token);
            //Alert.alert("Sucesso", response.data.token);
            localStorage.setItem('@token_diario_web', response.data.token);
            // buscarPerfil(response.data.token);
            // await AsyncStorage.setItem('@tokenEmpresa', response.data.token);
            setloading(false);
            history.push('/');
        } catch (error) {
            // setOpenAlert(true);
            // setTypeAlert('error');
            // setMsgAlert('Login ou senha, incorretos!');
            console.log('Error login', error, error.response);
            if (
                error.response.data[0].field === 'cpf' &&
                error.response.data[0].message === 'Cannot find user with provided cpf'
            ) {
                toast.error(`O CPF informado não foi encontrado`, {
                    position: 'bottom-left',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
            if (
                error.response.data[0].field === 'password' &&
                error.response.data[0].message === 'Invalid user password'
            ) {
                toast.error(`Você está usando uma senha inválida.`, {
                    position: 'bottom-left',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
            setloading(false);
        }
    }

    return (
        <Container className={classes.container} component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <img
                    style={{ maxWidth: 150 }}
                    src={
                        'https://i1.wp.com/pinheiro.ma.gov.br/wp-content/uploads/2021/02/LOGO-DE-PINHEIRO-1-min-1-1-min.png?fit=800%2C249'
                    }
                    alt=""
                />
                <Typography component="h3" variant="h6">
                    Entre com CPF e Senha
                </Typography>
                <form onSubmit={login} className={classes.form} noValidate>
                    {/* <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="cpf"
                        label="CPF"
                        name="cpf"
                        // autoComplete="email"
                        value={cpf}
                        onChange={handleChangeCpf}
                    /> */}
                    <FormControl fullWidth className="formControl" variant="outlined">
                        <CpfCnpj
                            className="customizedInput"
                            placeholder="Digite um cpf do aluno ou responsável"
                            type="tel"
                            value={cpf}
                            onChange={handleChangeCpf}
                        />
                    </FormControl>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={senha}
                        onChange={handleChangeSenha}
                    />
                    <FormControlLabel
                        disabled={cpf && senha ? false : true}
                        control={<Checkbox value="remember" color="primary" />}
                        label="Lembrar-me"
                        checked={lembrar}
                        onChange={handleChangeLembrar}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}>
                        Entrar
                    </Button>
                    {/* <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Esqueci minha senha!
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/cadastro" variant="body2">
                                {'Criar conta'}
                            </Link>
                        </Grid>
                    </Grid> */}
                </form>
            </div>
            {loading && (
              <div className={classes.loadingLogin}>
                <Player
                  autoplay
                  loop
                  src="https://assets9.lottiefiles.com/packages/lf20_ySJvyR.json"
                  style={{ height: '100px', width: '100px' }}
                >
                </Player>
              </div>
            )}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </Container>
    );
}
