import React, { useState, useEffect, createRef } from 'react';
import '../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
// import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import { useLocation } from 'react-router-dom';
/* eslint-disable */
import api from '../../services/api';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { useHistory } from 'react-router-dom';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import {format, subDays, addDays} from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FadeLoader from "react-spinners/FadeLoader";
import Chip from '@material-ui/core/Chip';

export default function Index() {
    let history = useHistory();
    const location = useLocation();
    const [skeleton, seSkeleton] = useState(true);
    const wrapper = createRef();

    const [loading, setloading] = useState(false);
    //alert
    const [openAlert, setOpenAlert] = useState(false);
    const [typeAlert, setTypeAlert] = useState('warning');
    const [msgAlert, setMsgAlert] = useState('Carregando');

    //cadastro
    const [tipo, setTipo] = useState('new');
    const [txtBtn, setTxtBtn] = useState('Salvar');
    const [idEditar, setIdEditar] = useState('');
    const [categoria, setCategoria] = useState('');
    const [dadosClientes, setDadosClientes] = useState([]);
    const [dadosServicoClientes, setDadosServicoClientes] = useState([]);
    const [dadosMetodosPagamentos, setDadosMetodosPagamentos] = useState([]);
    const [recorrencia, setRecorrencia] = useState('');

    //edit
    const [dadosEdit, setDadosEdit] = useState([]);

    const [name, setName] = useState('');
    const [dadosCategorias, setDadosCategorias] = useState([]);
    const [valor, setValor] = useState(0);
    const [status, setStatus] = useState(1);
    const [title, setTitle] = useState('Criar serviço');

    useEffect(() => {

        // Atualiza o titulo do documento usando a API do browser
        console.log("um", location.pathname);
        console.log("dois", location);
        carregardadosEmpresa()
        if(location.state){
          setTipo(location.state.type)
          setTxtBtn(location.state.type === 'edit' ? 'Editar' : 'Salvar')
        }else{
          history.push('/')
        }
        if(location.state.type === 'edit'){
          editar(parseInt(location.state.id))
          setTitle('Editar serviço')
        }else{
          // setloading(false)

          // setDataVencimento(format(addDays(new Date(), 5), 'dd/MM/yyyy'))
          // setDataVencimento()
        }
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);


  //carregar serviços do cliente
  async function carregarServicoClientes(id) {
    var token = localStorage.getItem('@token_diario_web');

    var config = {
      headers: {Authorization: 'bearer ' + token},
    };
    try {
      const {data: resultado} = await api.get(`/servicos-cliente/${id}`, config);
      console.log('resultado dados serviço clientes', resultado);
      if(resultado.length === 0){
        setOpenAlert(true)
        setTypeAlert('warning')
        toast.warn('🦄 O cliente ainda nao possui nenhum serviço cadastrado, cadastre um serviço e volte aqui!!', {
          position: "bottom-left",
          autoClose: 9000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }else{
        toast.success('🦄 Buscando serviços do cliente!', {
          position: "bottom-left",
          autoClose: 9000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setDadosServicoClientes(resultado);
    } catch (error) {
      console.log("Erro ao carregar serviços", error, error.response);
      toast.error('🦄 Erro ao carregar serviços!!', {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  //carregar dados do serviços do cliente
  async function carregarServicoDoClientes(id) {
    var token = localStorage.getItem('@token_diario_web');

    var config = {
      headers: {Authorization: 'bearer ' + token},
    };
    try {
      const {data: resultado} = await api.get(`/servico-cliente/${id}`, config);
      console.log('resultado dados do serviço clientes', resultado);
      for (let sc = 0; sc < resultado.length; sc++) {
        const elementResultado = resultado[sc];
        setServicoId(elementResultado.servico.id)
      }
      // toast.success('🦄 Carregando serviços do cliente!!', {
      //   position: "bottom-left",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });

      // setDadosServicoClientes(resultado);
    } catch (error) {
      console.log("Erro ao carregar serviços", error, error.response);
      // toast.error('🦄 Erro ao carregar serviços do cliente!!', {
      //   position: "bottom-left",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
    }

  }


    function handleChangeName(event){
      setName(event.target.value);
    }
    function handleChangeCategoria(event){
      setCategoria(event.target.value);
    }
    function handleChangeRecorrencia(event){
      setRecorrencia(event.target.value);
    }
    function handleChangeValor(event){
      setValor(event.target.value);
    }
    function handleChangeStatus(event){
      setStatus(event.target.value);
    }

    async function carregardadosEmpresa() {
      try {
        const {data: resultado} = await api.get(`/categorias/`);
        console.log('resultado dados categorias', resultado);
        setDadosCategorias(resultado);
      } catch (error) {
        console.log("Erro ao carregar categorias", error, error.response);
      }
    }

    async function deletar() {
      console.log("id", idEditar)
      setloading(true)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: prod} = await api.delete(`/fatura/${idEditar}`, config);
        console.log("prod", prod)
        // limpaCampos();
        setloading(false);
        // setOpenAlert(true)
        // setTypeAlert('success')
        // setMsgAlert('Fatura foi deletada com sucesso.')
        toast.success('🦄 Fatura deletada com sucesso!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push('/faturas')
      } catch (error) {
        console.log("Erro ao deletar", error, error.response);
        setloading(false);
        // setOpenAlert(true)
        // setTypeAlert('error')
        // setMsgAlert('Erro ao deletar, tente novamente')
        toast.error('🦄 Erro ao buscar dados para editar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    async function editar(id){
      setIdEditar(id)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      console.log("aqui", id)
      try {
        const {data: edit} = await api.get(`/servico/${id}`, config);
        console.log("result edit", edit)
        setloading(false);
        for (let i = 0; i < edit.length; i++) {
          const element = edit[i];
          setCategoria(element.categoria_id)
          setName(element.nome)
          setRecorrencia(element.recorrencia)
          setValor(element.valor)
          setStatus(element.status)
        }
      } catch (error) {
        console.log("Erro buscar dados para editar", error, error.response);
        setOpenAlert(true)
        toast.error('🦄 Erro ao buscar dados para editar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    async function salvar(event) {
      event.preventDefault();
      // console.log("event", event)
      setloading(true)
      // setOpenAlert(false)
  
      //setloading(true);
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
  
      let form_data = new FormData();

      form_data.append("categoria_id", categoria ? categoria : '');
      form_data.append("nome", name ? name : '');
      form_data.append("recorrencia", recorrencia ? recorrencia : '');
      form_data.append("valor", valor ? valor : '');
      form_data.append("status", status ? status : '');

  
      if(tipo === 'new'){
        await api
          .post(`/servico`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Adicionando serviço", res.data)
              limpaCampos();
              setloading(false);
              // setOpen(false);
              // carregarDados();
              // setOpenAlert(true)
              // setTypeAlert('success')
              // setMsgAlert('Serviço cadastrado com sucesso.')
              toast.success('🦄 Serviço cadastrado com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            console.log("response: ", error.response.data.message)
            toast.error('🦄 Erro ao cadatrar serviço, tente novamente ou fale com o suporte!', {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // setOpenAlert(true)
            // setTypeAlert('error')
            // setMsgAlert(error.response.data.message)
            setloading(false);
            // throw error;
          });
  
      }else if(tipo === 'edit'){
  
        await api
          .post(`/servico/${idEditar}`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Cliente editado", res.data)
              setloading(false);
              toast.success('🦄 Serviço editado com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              editar(res.data.id)
              // setOpenAlert(true)
              // setTypeAlert('success')
              // setMsgAlert('Dados da fatura editado com sucesso.')
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            // alert("Erro", "Erro ao cadastrar servico, tente novamente");
            setloading(false);
            toast.error('🦄 Erro ao editar serviço, tente novamente ou fale com o suporte!', {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // setOpenAlert(true)
            // setTypeAlert('error')
            // setMsgAlert(error.response.data.message)
            // throw error;
          });

      }
    }

    function limpaCampos(){
      setCategoria("");
      setName("");
      setRecorrencia("");
      setValor(0);
      setTxtBtn('Salvar');
    }

     //validações
  const validation = [];
  validation.push(categoria && categoria.length !== 0);
  validation.push(name && name.length !== 0);
  validation.push(valor && valor.length !== 0);

  // if (tipo) {
  //   validation.push(nome && nome.trim());
  //   validation.push(repetirSenha);
  //   validation.push(senha === repetirSenha);
  // }
  const validForm = validation.reduce((all, v) => all && v);

  function moedaBR(amount, decimalCount = 2, decimal = ",", thousands = "."){
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      console.log(e)
    }
  }

    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link className="linkBreadcrumbs" color="inherit" href="/">
                                    Dashboard
                                </Link>
                                <Link className="linkBreadcrumbs" color="inherit" href="/servicos/">
                                    Serviços
                                </Link>
                                <Typography variant="body2" color="textPrimary">
                                    {title}
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>{title}</h2>
                            </div>
                        </Grid>
                          <Grid item xs={12} md={12} lg={12}>
                              {skeleton && <Skeleton variant="rect" className="paper" />}
                              {!skeleton && (
                                  <Paper className={'paper'}>
                                      <div className="paperContant">
                                        <form noValidate autoComplete="off" onSubmit={salvar}>
                                          <Grid container spacing={3}>

                                              <Grid item xs={12} md={4}>
                                                <div ref={wrapper}>
                                                  <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="categorias-label">Categoria</InputLabel>
                                                    <Select
                                                      labelId="categorias-label"
                                                      id="categorias"
                                                      value={categoria}
                                                      onChange={handleChangeCategoria}
                                                      label="Categoria"
                                                      error={!categoria ? true : false}
                                                    >
                                                      {dadosCategorias.map((itens) =>(
                                                        <MenuItem key={itens.id} value={itens.id}>{itens.nome}</MenuItem>
                                                      ))}
                                                    </Select>
                                                  </FormControl>
                                                </div>
                                              </Grid>
                                              <Grid item xs={12} md={4}>
                                                  <FormControl fullWidth variant="outlined">
                                                      <InputLabel htmlFor="name">Nome</InputLabel>
                                                      <OutlinedInput
                                                        error={!name ? true : false}
                                                        id="name"
                                                        value={name ? name : ''}
                                                        onChange={handleChangeName}
                                                        labelWidth={60}
                                                      />
                                                  </FormControl>
                                              </Grid>
                                              <Grid item xs={12} md={2}>
                                                  <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="recorrencia-label">Recorrência</InputLabel>
                                                    <Select
                                                      labelId="recorrencia-label"
                                                      id="recorrencia"
                                                      value={recorrencia}
                                                      onChange={handleChangeRecorrencia}
                                                      label="Recorrência"
                                                      error={!recorrencia ? true : false}
                                                    >
                                                      <MenuItem value={'30'}>Mensal</MenuItem>
                                                      <MenuItem value={'0'}>Único</MenuItem>
                                                    </Select>
                                                  </FormControl>
                                              </Grid>
                                              <Grid item xs={12} md={2}>
                                                  <FormControl fullWidth variant="outlined">
                                                    <InputLabel htmlFor="valor">Valor</InputLabel>
                                                    <OutlinedInput
                                                      id="valor"
                                                      value={valor}
                                                      onChange={handleChangeValor}
                                                      labelWidth={60}
                                                      error={!valor ? true : false}
                                                    />
                                                  </FormControl>
                                              </Grid>

                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel id="status-label">Status</InputLabel>
                                                  <Select
                                                    labelId="status-label"
                                                    id="status"
                                                    value={status}
                                                    onChange={handleChangeStatus}
                                                    label="Status"
                                                  >
                                                    <MenuItem value={1}>Ativo</MenuItem>
                                                    <MenuItem value={0}>Inativo</MenuItem>
                                                  </Select>
                                                </FormControl>
                                            </Grid>
                                              
                                              <Grid item xs={12} md={12}>
                                                  <Button
                                                    disabled={!validForm}
                                                    style={{maxWidth: 150, height: 50, marginRight: 10}}
                                                    type="submit" variant="outlined" color="primary"
                                                  >
                                                    {txtBtn}
                                                  </Button>
                                                  {tipo === 'edit' &&(
                                                    <Button
                                                      style={{maxWidth: 150, height: 50}}
                                                      variant="outlined" color="secondary"
                                                      onClick={deletar}
                                                    >
                                                      Deletar Fatura
                                                    </Button>
                                                  ) }

                                              </Grid>
                                          </Grid>
                                        </form>
                                      </div>
                                      <ToastContainer
                                        position="top-right"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                      />
                                  </Paper>
                              )}
                          </Grid>

                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
            {loading && (
                <div className="loading">
                    <FadeLoader color={'rgb(63, 1, 121)'} loading={loading} size={150} />
                </div>
            )}
        </div>
    );
}
