import React from 'react';
/* eslint-disable */
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { isAuthenticated } from './auth';

import Login from './pages/Login';
import Cadastro from './pages/Cadastro';
import Dashboard from './pages/Dashboard';
/*eslint-disable */

//administração
import Periodos from './pages/Administracao/Periodos';
import CreatePeriodos from './pages/Administracao/Periodos/createEdit';
import Polos from './pages/Administracao/Polos/';
import CreatePolos from './pages/Administracao/Polos/createEdit';
import Povoados from './pages/Administracao/Povoados/';
import CreatePovoado from './pages/Administracao/Povoados/createEdit';
import Escolas from './pages/Administracao/Escolas/';
import CreateEscola from './pages/Administracao/Escolas/createEdit';
import Administradores from './pages/Administracao/Usuario/administradores';
import CreateAdministradores from './pages/Administracao/Usuario/createEdit';
import Coordenadores from './pages/Administracao/Usuario/coordenadores';
import CreateCoordenadores from './pages/Administracao/Usuario/createEdit';
import Gestores from './pages/Administracao/Usuario/gestores';
import CreateGestores from './pages/Administracao/Usuario/createEdit';
import Professores from './pages/Administracao/Usuario/professores';
import CreateProfessores from './pages/Administracao/Usuario/createEdit';
import Alunos from './pages/Administracao/Usuario/alunos';
import CreateAlunos from './pages/Administracao/Usuario/createEdit';
import InforAlunoAdmin from './pages/Administracao/Usuario/infoAluno';
import AlunosMuitasFaltas from './pages/Administracao/AlunosMuitasFaltas/alunos';
import Mapeamento from './pages/Administracao/Mapeamento';
import CreateMapeamento from './pages/Administracao/Mapeamento/createEdit';
import MapeamentoAluno from './pages/Administracao/MapeamentoAluno';
import CreateMapeamentoAluno from './pages/Administracao/MapeamentoAluno/createEdit';
// import Diarios from './pages/Administracao/Diarios';
// import CreateDiarios from './pages/Administracao/Diarios/createEdit';

//Diário
import Diarios from './pages/Diarios';
import DiariosEscolaProfessor from './pages/Diarios/opcoes_diario';
import DiarioIdentificacaoTurma from './pages/Diarios/Components/Identificacao_tuma/alunos';
import DiarioDiagnosticoInicial from './pages/Diarios/Components/Diagnostico_inicial';
import DiarioRegistroFrequencia from './pages/Diarios/Components/Registro_frequencia';
import ExperienciasDesenvolvidas from './pages/Diarios/Components/ExperienciasDesenvolvidas';
import DiarioRelatorioAvaliacao from './pages/Diarios/Components/Relatorio_avaliacao';
import DiarioRelatorioFinal from './pages/Diarios/Components/Relatorio_final_individual';
import ReorganizacaoPlanejamento from './pages/Diarios/Components/Reorganizacao_Planejamento';
import CreateReorganizacaoPlanejamento from './pages/Diarios/Components/Reorganizacao_Planejamento/createEdit';
import ParecerTecnico from './pages/Diarios/Components/ParecerTecnico';
import CreateParecerTecnico from './pages/Diarios/Components/ParecerTecnico/createEdit';
import FaltasAnuais from './pages/Diarios/Components/FaltasAnuais';
import CargaHorariaAnual from './pages/Diarios/Components/CargaHorariaAnual';

// coordenador
import EscolasCoordenador from './pages/Coordenador/Escolas/';
import OpcoesEscola from './pages/Coordenador/OpcoesEscola';
// import DiariosCoordenadores from './pages/Coordenador/Diarios';
// import DiariosEscolaCoordenadores from './pages/Coordenador/Diarios/createEdit';
// import DiarioIdentificacaoTurmaCoordenadores from './pages/Coordenador/Diarios/Components/Identificacao_tuma/alunos';
// import DiarioDiagnosticoInicialCoordenadores from './pages/Coordenador/Diarios/Components/Diagnostico_inicial';
// import DiarioRegistroFrequenciaCoordenadores from './pages/Coordenador/Diarios/Components/Registro_frequencia';
// import ExperienciasDesenvolvidasCoordenadores from './pages/Coordenador/Diarios/Components/ExperienciasDesenvolvidas';
// import DiarioRelatorioAvaliacaoCoordenadores from './pages/Coordenador/Diarios/Components/Relatorio_avaliacao';
// import DiarioRelatorioFinalCoordenadores from './pages/Coordenador/Diarios/Components/Relatorio_final_individual';
// import ReorganizacaoPlanejamentoCoordenadores from './pages/Coordenador/Diarios/Components/Reorganizacao_Planejamento';
// import ParecerTecnicoCoordenadores from './pages/Coordenador/Diarios/Components/ParecerTecnico';
// import CreateParecerTecnicoCoordenadores from './pages/Coordenador/Diarios/Components/ParecerTecnico/createEdit';
// import FaltasAnuaisCoordenadores from './pages/Coordenador/Diarios/Components/FaltasAnuais';
// import CargaHorariaAnualCoordenadores from './pages/Coordenador/Diarios/Components/CargaHorariaAnual';

//gestores
import AlunosGestor from './pages/Gestor/Alunos';
import AlunosGestorCreate from './pages/Gestor/Alunos/createEdit';
import InforAlunoGestor from './pages/Gestor/Alunos/infoAluno';
import MatriculasGestor from './pages/Gestor/Matriculas';
import MatriculasGestorCreate from './pages/Gestor/Matriculas/createEdit';
import ProfessoresGestor from './pages/Gestor/Professores';

// professores
import EscolasProfessor from './pages/Professores/Escolas/';
import OpcoesEscolaProfessor from './pages/Professores/OpcoesEscola';
// import DiariosEscolaProfessor from './pages/Professores/Diarios/createEdit';
// import DiarioIdentificacaoTurmaProfessor from './pages/Professores/Diarios/Components/Identificacao_tuma/alunos';
// import DiarioDiagnosticoInicialProfessor from './pages/Professores/Diarios/Components/Diagnostico_inicial';
// import DiarioRegistroFrequenciaProfessor from './pages/Professores/Diarios/Components/Registro_frequencia';
// import ExperienciasDesenvolvidasProfessor from './pages/Professores/Diarios/Components/ExperienciasDesenvolvidas';
// import DiarioRelatorioAvaliacaoProfessor from './pages/Professores/Diarios/Components/Relatorio_avaliacao';
// import DiarioRelatorioFinalProfessor from './pages/Professores/Diarios/Components/Relatorio_final_individual';
// import ReorganizacaoPlanejamentoProfessor from './pages/Professores/Diarios/Components/Reorganizacao_Planejamento';
// import ParecerTecnicoProfessor from './pages/Professores/Diarios/Components/ParecerTecnico';
// import CreateParecerTecnicoProfessor from './pages/Professores/Diarios/Components/ParecerTecnico/createEdit';
// import FaltasAnuaisProfessor from './pages/Professores/Diarios/Components/FaltasAnuais';
// import CargaHorariaAnualProfessor from './pages/Professores/Diarios/Components/CargaHorariaAnual';

//Alunos
import PresencasAluno from './pages/Aluno/Presencas';

import ModeloUm from './pages/ModeloUm';
import Table from './pages/Table';
import Servico from './pages/Servicos';
import CreateServico from './pages/Servicos/createEdit';
import ServicoCliente from './pages/Servicos/servicoCliente';
import ListaServicoCliente from './pages/Servicos/listaServicoCliente';
// import Faturas from './pages/Faturas';
// import CreateFaturas from './pages/Faturas/createEdit';
import Perfil from './pages/Perfil';
import NadaEncontrado from './pages/404';

const PrivateRoute = ({ component: Component, ...rest }) => ( 
    <Route
        {...rest}
        render={(props) =>
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )
        }
    />
);
/*eslint-enable */

export default function Routes(props) {
    console.log('Props, ', props);
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/cadastro" component={Cadastro} />
                <PrivateRoute path="/" exact component={Dashboard} />

                <PrivateRoute path="/periodos" component={Periodos} />
                <PrivateRoute path="/periodo-create" component={CreatePeriodos} />
                <PrivateRoute path="/periodo-edit" component={CreatePeriodos} />
                <PrivateRoute path="/polos" component={Polos} />
                <PrivateRoute path="/polo-create" component={CreatePolos} />
                <PrivateRoute path="/polo-edit" component={CreatePolos} />
                <PrivateRoute path="/povoados" component={Povoados} />
                <PrivateRoute path="/povoado-create" component={CreatePovoado} />
                <PrivateRoute path="/povoado-edit" component={CreatePovoado} />
                <PrivateRoute path="/escolas" component={Escolas} />
                <PrivateRoute path="/escola-create" component={CreateEscola} />
                <PrivateRoute path="/escola-edit" component={CreateEscola} />

                <PrivateRoute path="/administradores" component={Administradores} />
                <PrivateRoute path="/admin-create" component={CreateAdministradores} />
                <PrivateRoute path="/admin-edit" component={CreateCoordenadores} />

                <PrivateRoute path="/coordenadores" component={Coordenadores} />
                <PrivateRoute path="/coordenador-create" component={CreateCoordenadores} />
                <PrivateRoute path="/coordenador-edit" component={CreateCoordenadores} />
                <PrivateRoute path="/gestores" component={Gestores} />
                <PrivateRoute path="/gestor-create" component={CreateGestores} />
                <PrivateRoute path="/gestor-edit" component={CreateGestores} />
                <PrivateRoute path="/professores" component={Professores} />
                <PrivateRoute path="/professor-create" component={CreateProfessores} />
                <PrivateRoute path="/professor-edit" component={CreateProfessores} />
                <PrivateRoute path="/alunos" component={Alunos} />
                <PrivateRoute path="/aluno-create" component={CreateAlunos} />
                <PrivateRoute path="/aluno-edit" component={CreateAlunos} />
                <PrivateRoute path="/info-aluno-admin" component={InforAlunoAdmin} />
                <PrivateRoute path="/alunos-faltosos" component={AlunosMuitasFaltas} />
                <PrivateRoute path="/mapeamento-professor" component={Mapeamento} />
                <PrivateRoute path="/mapeamento-professor-create" component={CreateMapeamento} />
                <PrivateRoute path="/mapeamento-professor-edit" component={CreateMapeamento} />
                <PrivateRoute path="/matricula" component={MapeamentoAluno} />
                <PrivateRoute path="/matricula-create" component={CreateMapeamentoAluno} />
                <PrivateRoute path="/matricula-edit" component={CreateMapeamentoAluno} />
                {/* <PrivateRoute path="/diarios-classe-create" component={CreateDiarios} /> */}
                {/* <PrivateRoute path="/diarios-classe-view" component={CreateDiarios} /> */}
                {/* <PrivateRoute path="/diarios-classe-edit" component={CreateDiarios} /> */}
                <PrivateRoute path="/diarios" component={Diarios} />
                <PrivateRoute path="/diarios-escola" component={DiariosEscolaProfessor} />
                <PrivateRoute
                    path="/diario-identificacao-turma"
                    component={DiarioIdentificacaoTurma}
                />
                <PrivateRoute
                    path="/diario-diagnostico-inicial"
                    component={DiarioDiagnosticoInicial}
                />

                <PrivateRoute
                    path="/diario-registro-frenquencia"
                    component={DiarioRegistroFrequencia}
                />
                <PrivateRoute
                    path="/experiencias-desenvolvidas"
                    component={ExperienciasDesenvolvidas}
                />
                <PrivateRoute
                    path="/relatorio-avaliacao-individual"
                    component={DiarioRelatorioAvaliacao}
                />
                <PrivateRoute path="/relatorio-final-individual" component={DiarioRelatorioFinal} />
                <PrivateRoute
                    path="/reorganizacao-planejamento"
                    component={ReorganizacaoPlanejamento}
                />
                <PrivateRoute
                    path="/reorganizacao-planejamento-create"
                    component={CreateReorganizacaoPlanejamento}
                />
                <PrivateRoute path="/parecer-tecnico" component={ParecerTecnico} />
                <PrivateRoute path="/parecer-tecnico-create" component={CreateParecerTecnico} />
                <PrivateRoute path="/faltas-anuais" component={FaltasAnuais} />
                <PrivateRoute path="/carga-horaria-anual" component={CargaHorariaAnual} />

                <PrivateRoute path="/escolas-coordenadores" component={EscolasCoordenador} />
                <PrivateRoute path="/opcoes-escola" component={OpcoesEscola} />
                {/* <PrivateRoute path="/diarios-coordenadores" component={DiariosCoordenadores} /> */}
                {/* <PrivateRoute
                    path="/diarios-escola-coordenador"
                    component={DiariosEscolaCoordenadores}
                /> */}
                {/* <PrivateRoute
                    path="/diario-identificacao-turma-coordenadores"
                    component={DiarioIdentificacaoTurmaCoordenadores}
                />
                <PrivateRoute
                    path="/diario-diagnostico-inicial-coordenadores"
                    component={DiarioDiagnosticoInicialCoordenadores}
                />
                <PrivateRoute
                    path="/diario-registro-frenquencia-coordenadores"
                    component={DiarioRegistroFrequenciaCoordenadores}
                />
                <PrivateRoute
                    path="/experiencias-desenvolvidas-coordenadores"
                    component={ExperienciasDesenvolvidasCoordenadores}
                />
                <PrivateRoute
                    path="/relatorio-avaliacao-individual-coordenadores"
                    component={DiarioRelatorioAvaliacaoCoordenadores}
                />
                <PrivateRoute
                    path="/relatorio-final-individual-coordenadores"
                    component={DiarioRelatorioFinalCoordenadores}
                />
                <PrivateRoute
                    path="/reorganizacao-planejamento-coordenadores"
                    component={ReorganizacaoPlanejamentoCoordenadores}
                />
                <PrivateRoute
                    path="/parecer-tecnico-coordenadores"
                    component={ParecerTecnicoCoordenadores}
                />
                <PrivateRoute
                    path="/parecer-tecnico-create-coordenadores"
                    component={CreateParecerTecnicoCoordenadores}
                />
                <PrivateRoute
                    path="/faltas-anuais-coordenadores"
                    component={FaltasAnuaisCoordenadores}
                />
                <PrivateRoute
                    path="/carga-horaria-anual-coordenadores"
                    component={CargaHorariaAnualCoordenadores}
                /> */}

                <PrivateRoute path="/alunos-gestor" component={AlunosGestor} />
                <PrivateRoute path="/aluno-gestor-create" component={AlunosGestorCreate} />
                {/* <PrivateRoute path="/aluno-gestor-create" component={AlunosGestorCreate} /> */}
                <PrivateRoute path="/info-aluno-gestor" component={InforAlunoGestor} />
                <PrivateRoute path="/matriculas-gestor" component={MatriculasGestor} />
                <PrivateRoute path="/matricula-gestor-create" component={MatriculasGestorCreate} />
                <PrivateRoute path="/professores-gestor" component={ProfessoresGestor} />

                <PrivateRoute path="/escolas-professor" component={EscolasProfessor} />
                <PrivateRoute path="/opcoes-escola-professor" component={OpcoesEscolaProfessor} />
                {/* <PrivateRoute path="/diarios" component={Diarios} /> */}
                {/* <PrivateRoute path="/diarios-escola-professor" component={DiariosEscolaProfessor} /> */}
                {/* <PrivateRoute
                    path="/diario-identificacao-turma-professor"
                    component={DiarioIdentificacaoTurmaProfessor}
                />
                <PrivateRoute
                    path="/diario-diagnostico-inicial-professor"
                    component={DiarioDiagnosticoInicialProfessor}
                />
                <PrivateRoute
                    path="/diario-registro-frenquencia-professor"
                    component={DiarioRegistroFrequenciaProfessor}
                />
                <PrivateRoute
                    path="/experiencias-desenvolvidas-professor"
                    component={ExperienciasDesenvolvidasProfessor}
                />
                <PrivateRoute
                    path="/relatorio-avaliacao-individual-professor"
                    component={DiarioRelatorioAvaliacaoProfessor}
                />
                <PrivateRoute
                    path="/relatorio-final-individual-professor"
                    component={DiarioRelatorioFinalProfessor}
                />
                <PrivateRoute
                    path="/reorganizacao-planejamento-professor"
                    component={ReorganizacaoPlanejamentoProfessor}
                />
                <PrivateRoute
                    path="/parecer-tecnico-professor"
                    component={ParecerTecnicoProfessor}
                />
                <PrivateRoute
                    path="/parecer-tecnico-create-professor"
                    component={CreateParecerTecnicoProfessor}
                />
                <PrivateRoute path="/faltas-anuais-professor" component={FaltasAnuaisProfessor} />
                <PrivateRoute
                    path="/carga-horaria-anual-professor"
                    component={CargaHorariaAnualProfessor}
                /> */}

                <PrivateRoute path="/presenca-aluno" component={PresencasAluno} />

                <PrivateRoute path="/servicos" component={Servico} />
                <PrivateRoute path="/servico-create" component={CreateServico} />
                <PrivateRoute path="/servico-edit" component={CreateServico} />
                <PrivateRoute path="/servico-cliente" component={ServicoCliente} />
                <PrivateRoute path="/listar-servicos-cliente" component={ListaServicoCliente} />
                {/* <PrivateRoute path="/faturas" component={Faturas} />
                <PrivateRoute path="/fatura-create" component={CreateFaturas} />
                <PrivateRoute path="/fatura-edit" component={CreateFaturas} /> */}
                <PrivateRoute path="/perfil" component={Perfil} />
                <PrivateRoute path="/modelo-um" component={ModeloUm} />
                <PrivateRoute path="/table" component={Table} />
                <Route path="*" component={NadaEncontrado} />
                {/* <Route path="/galerias/:slug" exact component={Galerias} > 
          <BlogPost />
        </Route> */}
                {/* <Route path="/galerias/" exact component={Galerias} />
        <PrivateRoute path="/admin-clientes" component={Clientes} />
        <PrivateRoute path="/admin-categorias" component={Categoria} />
        <PrivateRoute path="/admin-depoimentos" component={Depoimento} />
        <PrivateRoute path="/admin-albuns" component={Albuns} />
        <PrivateRoute path="/admin-albuns-fotos" component={AlbumFotos} /> */}
            </Switch>
        </BrowserRouter>
    );
}
