import React, { useState, useEffect } from 'react';
import '../../../../styles/global.css';
/* eslint-disable */
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../../Components/Header';
import Footer from '../../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import api from '../../../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Table from '../Tabelas/tableCargaHoraira';

export default function Index() {
    let history = useHistory();
    const location = useLocation();
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    // const [pesquisar, setPesquisar] = useState('');
    let newObjeto = [];
    let newObjeto2 = [];

    const iddodiario = location.state.iddiario;
    const nivelAcesso = location.state.nivelAcesso;
    const idProfessor = location.state.adicionais.idProfessor

    useEffect(() => {
      console.log("location.state.", location.state)
      console.log("iddodiario", iddodiario)
        // Atualiza o titulo do documento usando a API do browser
        periodos()
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    
    async function periodos(){
      var token = localStorage.getItem('@token_diario_web');
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data : result} = await api.get(`pesquisar-periodo-ano/${location.state.periodo.ano_periodo}`, config);
        console.log("Result periodos do ano", result)
        for (let a = 0; a < result.length; a++) {
          const elementResult = result[a];
          experienciasDesenvolvidas(elementResult, elementResult.id)
          // frequenciaAlunoTurno(elementResult, elementResult.id, 0)
        }
        setData(result)
        toast.success('🦄 Carregando diários!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro ao carregar escolas", error, error.response);
        toast.error('🦄 Error ao carregar escolas!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    async function experienciasDesenvolvidas(dados, idperido){
      var iduser = nivelAcesso === 'professor' ? 0 : idProfessor
      var token = localStorage.getItem('@token_diario_web');
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        ///experiencias-desenvolvidas-perido/:iduser/:idperido/:iddiario
        const {data : result} = await api.get(`experiencias-desenvolvidas-perido/${iduser}/${idperido}/${iddodiario}`, config);
        console.log("Result experiência desenvolvida", result)
        newObjeto = { ...dados, result};
        newObjeto2.push(newObjeto)
        // var newhere = newObjeto.concat(result);
        // console.log("newObjeto2", newObjeto2.sort())
        // var res = newObjeto2.sort(function(a, b) {
        //   return a.nome_periodo - b.nome_periodo;
        // });
        newObjeto2.sort(function(a, b) {
          if(a.nome_periodo < b.nome_periodo) {
            return -1;
          } else {
            return true;
          }
        });
        console.log("res", newObjeto2)
        setData2(newObjeto2)
        // setData(result)
        toast.success('🦄 Carregando experiências desenvolvidas!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro ao carregar Experiências desenvolvidas", error, error.response);
        toast.error('🦄 Error ao carregar experiências desenvolvidas!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    // async function frequenciaAlunoTurno(resultAnterior, id, presenca){
      
    //   var token = localStorage.getItem('@token_diario_web');
    //   var config = {
    //     headers: {Authorization: 'bearer ' + token},
    //   };
    //   try {
    //     const {data : result} = await api.get(`frequencia-aluno-periodo/${id}/${presenca}`, config);
    //     // console.log("Result frequência alunos", resultAnterior, result)
    //     newObjeto = { ...resultAnterior, result};
    //     newObjeto2.push(newObjeto)
    //     // var newhere = newObjeto.concat(result);
    //     setData2(newObjeto2)
    //     console.log("Result frequência alunos 2", newObjeto)
    //   } catch (error) {
    //     console.log("Erro ao carregar escolas", error, error.response);
    //     toast.error('🦄 Error ao carregar frequência!', {
    //       position: "bottom-left",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   }
    // }

    function createData(id, periodo, inicio, fim, ano, qtoaulas, aulas) {
      return {id, periodo, inicio, fim, ano, qtoaulas, aulas};
    }
  
    var rows = [];
    if(data2){
      rows = data2.map((row) => {
        return createData(
          row.id, 
          row.nome_periodo,
          row.inicio,
          row.fim,
          row.ano_periodo,
          row.aulas_previstas,
          row.result,
        )
      })
    }
  
    const columns = [
      { id: 'periodo', numeric: false, disablePadding: true, label: 'Período' },
      { id: 'aulas_previstas', numeric: false, disablePadding: true, label: 'Aulas Previstas' },
      { id: 'aulas_trabalhadas', numeric: false, disablePadding: true, label: 'Aulas Trabalhadas' },
      { id: 'aulas_complementares', numeric: false, disablePadding: true, label: 'Aulas complementares' },
      { id: 'deficit', numeric: false, disablePadding: true, label: 'Déficit' },
      { id: 'total', numeric: false, disablePadding: true, label: 'Total' }
    ];
  
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                              <Breadcrumbs aria-label="breadcrumb">
                                <Link className="linkBreadcrumbs" color="inherit" href="/">
                                    Dashboard
                                </Link>
                                <Link className="linkBreadcrumbs" color="inherit" href="/diarios-classe/">
                                    Diários
                                </Link>
                                <Link 
                                  className="linkBreadcrumbs" 
                                  color="inherit" 
                                  style={{ cursor: 'pointer'}}
                                  onClick={() => {
                                    history.goBack()
                                  }}
                                >
                                  Configurações do Diário
                                </Link>
                                <Typography variant="body2" color="textPrimary">
                                  Carga horária anual
                                </Typography>
                            </Breadcrumbs>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>Carga horária anual</h2>
                                {/* <Button
                                    className="button-add"
                                    onClick={create}
                                    variant="contained"
                                    color="primary">
                                    Novo
                                </Button> */}
                            </div>
                            <div className="headerPage">
                              <Button
                                  style={{marginTop: 10}}
                                  className="button-add"
                                  onClick={() => {
                                    history.goBack()
                                  }}
                                  variant="contained"
                                  color="primary">
                                  Retornar ao diário
                              </Button>
                            </div>
                        </Grid>
                        {/* <Grid item xs={12} md={12} lg={12}>
                            <Paper className={'paperSearch'}>
                                <TextField
                                    className="textField"
                                    id="outlined-secondary"
                                    label="Digite o nome do professor(a)"
                                    variant="outlined"
                                    // color="secondary"
                                    // value={pesquisar}
                                    onChange={handleChangePesquisar}
                                />
                            </Paper>
                        </Grid> */}
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                  <div className="diarioHeader">
                                    <div className="ih">
                                      <span>Ano letivo:</span>
                                      <span><strong>{location.state.periodo.ano_periodo}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Escola:</span>
                                      <span><strong>{location.state.adicionais.escola}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Professor:</span>
                                      <span><strong>{location.state.adicionais.professor}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Ano:</span>
                                      <span><strong>{location.state.adicionais.anoLetivo}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Turno:</span>
                                      <span><strong>{location.state.turno}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Turma:</span>
                                      <span><strong>{location.state.turma}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Período:</span>
                                      <span><strong>{location.state.periodo.nome_periodo}</strong></span>
                                    </div>
                                  </div>
                                  {rows.length !== 0 && (
                                    <div className="paperContant">
                                      {/* <div style={{
                                        background: '#f7f7f7',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: 10
                                      }}>
                                        <span>Número de alunos {' - '}</span>
                                        <span style={{fontSize: 21, fontWeight:'bold'}}>{'  '}{rows.length}</span>
                                      </div> */}
                                      <Table
                                        columns={columns} 
                                        rows={rows} 
                                        // data2={data2}
                                        config='registro_frequencia'
                                        iddodiario={iddodiario}
                                      />
                                    </div>
                                  )}
                                  {rows.length === 0 && (
                                    <div className="paperContantDois">
                                        <div>Nenhum registro encontrado</div>
                                    </div>
                                  )}
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
        </div>
    );
}
