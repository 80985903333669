import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
// import TextField from '@material-ui/core/TextField';
/* eslint-disable */
import Skeleton from '@material-ui/lab/Skeleton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FadeLoader from "react-spinners/FadeLoader";
import api from '../../../services/api';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import Edit from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {format} from 'date-fns';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {veryAuth} from '../../../auth';

import SelectProfessor from '../../../Components/Select/pesqusarProfessor';

export default function Index() {
    let history = useHistory();
    const location = useLocation();
    const [skeleton, seSkeleton] = useState(true);
    const [loading, setloading] = useState(false);

    //cadastro
    const [tipo, setTipo] = useState('');
    const [txtBtn, setTxtBtn] = useState('');
    const [idEditar, setIdEditar] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [cep, setCep] = useState('');
    const [pais, setPais] = useState('');
    const [estado, setEstado] = useState('');
    const [cidade, setCidade] = useState('');
    const [rua, setRua] = useState('');
    const [numero, setNumero] = useState('');
    const [bairro, setBairro] = useState('');
    const [telefone, setTelefone] = useState('');
    const [whatsapp, setWhatsApp] = useState('');
    const [password, setPassWord] = useState('');
    const [title, setTitle] = useState('');
    const [linkVolta, setLinkVolta] = useState('');
    const [disabled, setDisabled] = useState(false);

    const [professores, setProfessores] = useState([]);
    const [professor, setProfessor] = useState('');
    const [professorArray, setProfessorArray] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [grupo, setGrupo] = useState('');
    const [escolas, setEscolas] = useState([]);
    const [escola, setEscola] = useState('');
    const [anoLetivos, setAnoLetivos] = useState([]);
    const [anoLetivo, setAnoLetivo] = useState('');
    const [turno, setTurno] = useState('');
    const [ano, setAno] = useState(format(new Date(), "yyyy"));
    const [turma, setTurma] = useState('');
    const [componenteCurricular, setComponenteCurricular] = useState([]);
    const [componenteCurricularCad, setComponenteCurricularCad] = useState([]);
    const [exibirComponente, setExibirComponente] = useState(false);
    const [turmasEscola, setTurmasEscola] = useState([]);



    const [roles, setRoles] = useState('');

    const permissaoTelas = location.state.telasuser;


    useEffect(() => {
      console.log("location.state.", location.state)
      // Atualiza o titulo do documento usando a API do browser
      // console.log("um", location.pathname);
      // console.log("dois", location);
      verificarPermissao()
      carregarGrupos()
      carregarProfessores();
      carregarAnoLetivo();
      carregarComponenteCurricular();

      if(location.state.dadosProfessor){
        setProfessorArray(location.state.dadosProfessor)
        setProfessor(location.state.dadosProfessor.id);
      }

      if(location.state){
        setTipo(location.state.type)
        setTxtBtn(location.state.type === 'edit' ? 'Editar' : 'Salvar')
      }else{
        history.push('/')
      }
      if(location.state.type === 'edit'){
        editar(parseInt(location.state.id))
        // setTitle('Configurações do ' + tipoUsuario )
        setDisabled(true)
      }else{
        // setTitle('Cadastro de ' + tipoUsuario )
        setloading(false)
        gerarPassword();
        setDisabled(false)
      }
      setTimeout(function () {
          seSkeleton(false);
      }, 3000);
    }, []);

    function verificarPermissao(){
      veryAuth()
      .then((result) => {
        if(result.data.role === 'admin' || result.data.role === 'gestor'){
          setLiberaAcesso(true)
        }else{
          history.push({
            pathname: `/`,
          });
          return
        }
        setRoles(result.data.role)
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.response, error.message);
      });
    }


    function verificar(id){
      var res = componenteCurricularCad.find( fruit => fruit.componente_curricular_id === id )
      console.log("res ", res)
      return res ? true : false
    }

    async function carregarComponenteCurricular(){
      var token = localStorage.getItem('@token_diario_web');
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data : result} = await api.get(`componente-curricular`, config);
        console.log("listar formações profissionais", result)
        setComponenteCurricular(result)
        toast.success('🦄 Carregando componente curricular' , {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro ao carregar componente curricular", error, error.response);
        toast.error('🦄 Error ao carregar componente curricular!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    async function carregarGrupos() {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get('grupo', config);
          console.log('resultado grupos', result);
          setGrupos(result);
      } catch (error) {
          console.log('Erro ao buscar grupos', error, error.response);
      }

    } 

    async function carregarAnoLetivo() {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get('ano-letivo', config);
          console.log('resultado ano letivos', result);
          setAnoLetivos(result);
      } catch (error) {
          console.log('Erro ao buscar letivos', error, error.response);
      }

    } 

    async function carregarProfessores() {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get(`usuario-por-role/professor/desk/null`, config);
          console.log('resultado professores', result);
          setProfessores(result);
      } catch (error) {
          console.log('Erro ao buscar professores', error, error.response);
      }
    } 

    async function carregarEscolas(idGrupo) {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get(`pesquisar-escola-grupo/${idGrupo}`, config);
          console.log('resultado escola', result);
          setEscolas(result);
      } catch (error) {
          console.log('Erro ao buscar escola', error, error.response);
      }
    } 

    async function carregarAno(idescola) {
      var token = localStorage.getItem('@token_diario_web');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get(`pesquisar-ano-escola/${idescola}`, config);
          console.log('resultado turma da escola', result);
          setTurmasEscola(result)
      } catch (error) {
          console.log('Erro ao buscar turmas da escola', error, error.response);
      }
    } 


    function rota(link) {
      history.push({
          pathname: link,
          // search: '?query=abc',
          state: { 
            type: 'new',
          }
      });
    }

    function abrirPageServicos(){
      console.log("location.state.id", location.state.id, location.state.nome, location.state.email)
      history.push({
          pathname: '/listar-servicos-cliente',
          // search: '?query=abc',
          state: { id: location.state.id, nome: location.state.nome , email: location.state.email }
      });
    }

    function handleChangeProfessores(event){
      // console.log("event handleChangeProfessores", event)
      // setProfessor(event.target.value);
      if(event){
        setProfessor(event.id);
      }
    }
    function handleChangeGrupos(event){
      setGrupo(event.target.value);
      carregarEscolas(event.target.value)
    }
    function handleChangeEscola(event){
      carregarAno(event.target.value)
      setEscola(event.target.value);
    }
    function handleChangeTurno(event){
      setTurno(event.target.value);
    }
    function handleChangeAnoLetivo(event){
      if(event.target.value > 4){
        setExibirComponente(true)
      }else{
        setExibirComponente(false)
      }
      setAnoLetivo(event.target.value);
    }
    function handleChangeAno(event){
      setAno(event.target.value);
    }
    function handleChangeTurma(event){
      setTurma(event.target.value);
    }

    function handleChangeComponenteCurricular(event){
      // setStateFormacao({ ...stateFormacao, [event.target.name]: event.target.checked });
      console.log("---> ", event.target.name, event.target.checked)
      // setStateFormacao({ ...stateFormacao, [event.target.name]: event.target.name });
      salvarComponenteCurricular(idEditar, event.target.name, event.target.checked )
      editar(parseInt(location.state.id))
    };

    async function gerarDiario(ideditar){

      //setloading(true);
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
  
      let form_data = new FormData();
  
      form_data.append("user_id", professor);
      form_data.append("escola_id", escola);
      form_data.append("professor_escola_id", ideditar);
      form_data.append("ano_letivo_id", anoLetivo);
      form_data.append("ano", ano);
      form_data.append("turno", turno);
      form_data.append("escola_turma_id", turma);

      await api
          .post(`/diario`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Cadastrando diário", res.data)
              // limpaCampos();
              setloading(false);
              toast.success('🦄 Diário cadastrado com sucesso, aguarde ...', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              // eviar para editar
              setTimeout(function () {
                history.goBack()
                // history.push({
                //   // pathname: '/cliente-edit',
                //   pathname: `mapeamento-professor`,
                //   // search: '?query=abc',
                //   // state: { 
                //   //   type: 'edit', 
                //   //   id: ideditar, 
                //   // }
                // });
              }, 2000);
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            console.log("response: ", error.response.data.message)
            toast.error(`🦄 Erro ao cadatrar diário, ${error.response.data.message}!`, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setloading(false);
            // throw error;
          });
      
    }

    async function deletar() {
      console.log("id", idEditar)
      setloading(true)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: prod} = await api.delete(`/professor-escola/${idEditar}`, config);
        // limpaCampos();
        setloading(false);
        // setOpenAlert(true)
        // setTypeAlert('success')
        // setMsgAlert('Fatura foi deletada com sucesso.')
        toast.success('🦄 Mapeamento deletado com sucesso!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // history.push('/mapeamento-professor')
        history.goBack()
        // history.push('/clientes')
      } catch (error) {
        console.log("Erro ao deletar", error, error.response);
        setloading(false);
        // setOpenAlert(true)
        // setTypeAlert('error')
        // setMsgAlert('Erro ao deletar, tente novamente')
        toast.error('🦄 Erro ao deletar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    async function editar(id){
      setIdEditar(id)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      console.log("aqui", id)
      try {
        const {data: editar} = await api.get(`/professor-escola/${id}`, config);
        console.log("editar mapeamento", editar)
        var edit = editar[0]
        setProfessorArray(edit.user)
        carregarAno(edit.escola_id)
        setloading(false);
        setProfessor(edit.user_id)
        setGrupo(edit.grupo_id)
        setEscola(edit.escola_id)
        setAnoLetivo(edit.ano_letivo_id)
        setTurno(edit.turno)
        setTurma(edit.escola_turma_id)
        carregarEscolas(edit.grupo_id)
        setComponenteCurricularCad(edit.professorEscolaComponente)
      } catch (error) {
        console.log("Erro buscar dados para editar", error, error.response);
        toast.error('🦄 Erro ao buscar dados para editar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setloading(false);
      }
    }

    async function salvarComponenteCurricular(idprofEscola, value, bolean ) {
      //idEditar, event.target.name, event.target.checked 

      //setloading(true);
      var token = localStorage.getItem('@token_diario_web');

      let form_data = new FormData();
  
      form_data.append("professor_escola_id", idprofEscola);
      form_data.append("componente_curricular_id", value);

      if(bolean){
        await api
          .post(`/professor-escola-componente-curricular`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Cadastrando componente curricular no mapeamento", res.data)
              // limpaCampos();
              setloading(false);
              toast.success('🦄 Componente curricular cadastrado no mapeamento com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              editar(parseInt(location.state.id))
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            console.log("response: ", error.response.data.message)
            toast.error(`🦄 Erro ao cadatrar componente curricular no mapeamento, ${error.response.data.message}!`, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setloading(false);
            // throw error;
          });
  
      }else{
  
        await api
          .delete(`/professor-escola-componente-curricular/${idEditar}/${value}`, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Mapeamento removido", res.data)
              setloading(false);
              toast.success('🦄 Componente curricular removido com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              editar(parseInt(location.state.id))
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            toast.error('🦄 Erro ao remover componente curricular, tente novamente ou fale com o suporte!', {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setloading(false);
          });
  
      }
  
    }
  
  

    async function salvar(event) {
      event.preventDefault();
      // console.log("event", event)
      setloading(true)

      //setloading(true);
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
  
      let form_data = new FormData();
  
      form_data.append("professor_id", professor);
      form_data.append("grupo_id", grupo);
      form_data.append("escola_id", escola);
      form_data.append("ano_letivo_id", anoLetivo);
      form_data.append("escola_turma_id", turma);
      form_data.append("turno", turno);
      form_data.append("ano", ano);
      form_data.append("obs", '');
  
      if(tipo === 'new'){
        if(permissaoTelas.create === 0){
          toast.error('🦄 Você não tem permissão!', {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setloading(false)
          return
        }
        await api
          .post(`/professor-escola`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Cadastrando mapeamento", res.data)
              // limpaCampos();
              setloading(false);
              gerarDiario(res.data.id)
              toast.success('🦄 Mapeamento cadastrado com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            console.log("response: ", error.response.data.message)
            toast.error(`🦄 Erro ao cadatrar mapeamento, ${error.response.data.message}!`, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setloading(false);
            // throw error;
          });
  
      }else if(tipo === 'edit'){
        if(permissaoTelas.update === 0){
          toast.error('🦄 Você não tem permissão para editar!', {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setloading(false)
          return
        }
  
        await api
          .post(`/professor-escola/${idEditar}`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Mapeamento editado", res.data)
              setloading(false);
              toast.success('🦄 Mapeamento editado com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              editar(res.data.id)
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            toast.error('🦄 Erro ao editar mapeamento, tente novamente ou fale com o suporte!', {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setloading(false);
          });
  
      }
  
    }

    //gerar senha aleatório
    function gerarPassword() {
      setPassWord(Math.random().toString(36).slice(-10))
    }

    //validações
    const validation = [];
    validation.push(professor && escola);

    // if (tipo) {
    //   validation.push(nome && nome.trim());
    //   validation.push(repetirSenha);
    //   validation.push(senha === repetirSenha);
    // }

    const validForm = validation.reduce((all, v) => all && v);

  

    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link className="linkBreadcrumbs" color="inherit" href="/">
                                    Dashboard
                                </Link>
                                <Link className="linkBreadcrumbs" color="inherit" href="/mapeamento-professor">
                                    Mapeamentos
                                </Link>
                                <Typography variant="body2" color="textPrimary">
                                    { location.state.type === 'edit' ? 'Editar ' + title : 'Novo Mapeamento' + title}
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>{ location.state.type === 'edit' ? 'Editar ' + title : 'Novo Mapeamento' + title}</h2>
                            </div>
                        </Grid>
                        {/* {tipo === 'edit' && (
                            <Grid item xs={12} md={12} lg={12}>
                                {skeleton && <Skeleton variant="rect" className="paper" />}
                                {!skeleton && (
                                    <Paper className={'paper'}>
                                        <div className="itemConfig">
                                            <div onClick={abrirPageServicos} className="itemConfigDois">
                                              <SettingsApplicationsIcon style={{ fontSize: 40, color: 'rgb(63, 1, 121)' }} />
                                              <div>Saques</div>
                                            </div>
                                            <div onClick={habilitarEdit} className="itemConfigDois">
                                              <Edit style={{ fontSize: 40, color: 'rgb(63, 1, 121)' }} />
                                              <div>Editar perfil</div>
                                            </div>
                                        </div>
                                    </Paper>
                                )}
                            </Grid>
                        )} */}

                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                    <div className="paperContant">
                                        <form noValidate autoComplete="off" onSubmit={salvar}>
                                          <Grid container spacing={3}>
                                              <Grid item xs={12} md={12}>
                                                <SelectProfessor handleChangeProfessores={handleChangeProfessores} professorArray={professorArray}/>
                                                {/* <FormControl variant="outlined" fullWidth>
                                                  <InputLabel id="select-polo">Professores</InputLabel>
                                                  <Select
                                                    disabled={disabled}
                                                    labelId="select-professores"
                                                    id="select-professores"
                                                    value={professor}
                                                    onChange={handleChangeProfessores}
                                                    label="Professores"
                                                  >
                                                    <MenuItem value="">
                                                      <em>Nenhum</em>
                                                    </MenuItem>
                                                    {professores.map((item, key) => (
                                                      <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                                    ))}
                                                  </Select>
                                                </FormControl> */}
                                              </Grid>
                                              {professor && (
                                                <Grid item xs={12} md={4}>
                                                  <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="select-polo">Grupos de escola</InputLabel>
                                                    <Select
                                                      disabled={disabled}
                                                      labelId="select-polo"
                                                      id="select-polo"
                                                      value={grupo}
                                                      onChange={handleChangeGrupos}
                                                      label="Grupos de escola"
                                                    >
                                                      <MenuItem value="">
                                                        <em>Nenhum</em>
                                                      </MenuItem>
                                                      {grupos.map((item, key) => (
                                                        <MenuItem key={key} value={item.id}>{item.grupo_nome}</MenuItem>
                                                      ))}
                                                    </Select>
                                                  </FormControl>
                                                </Grid>
                                              )}
                                              {grupo && (
                                                <Grid item xs={12} md={4}>
                                                  <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="select-polo">Escolas</InputLabel>
                                                    <Select
                                                      disabled={disabled}
                                                      labelId="select-escola"
                                                      id="select-escola"
                                                      value={escola}
                                                      onChange={handleChangeEscola}
                                                      label="Escolas"
                                                    >
                                                      <MenuItem value="">
                                                        <em>Nenhum</em>
                                                      </MenuItem>
                                                      {escolas.map((item, key) => (
                                                        <MenuItem key={key} value={item.id}>{item.escola_nome}</MenuItem>
                                                      ))}
                                                    </Select>
                                                  </FormControl>
                                                </Grid>
                                              )}
                                              {escola && (
                                                <Grid item xs={12} md={4}>
                                                  <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="select-polo">Turnos</InputLabel>
                                                    <Select
                                                      disabled={disabled}
                                                      labelId="select-turno"
                                                      id="select-turno"
                                                      value={turno}
                                                      onChange={handleChangeTurno}
                                                      label="Tornos"
                                                    >
                                                      <MenuItem value="">
                                                        <em>Nenhum</em>
                                                      </MenuItem>
                                                      <MenuItem value={'Matutino'}>Matutino</MenuItem>
                                                      <MenuItem value={'Vespertino'}>Vespertino</MenuItem>
                                                    </Select>
                                                  </FormControl>
                                                </Grid>
                                              )}
                                              {turno && (
                                                <Grid item xs={12} md={4}>
                                                  <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="select-turma">Turma</InputLabel>
                                                    <Select
                                                      disabled={disabled}
                                                      labelId="select-turma"
                                                      id="select-turma"
                                                      value={turma}
                                                      onChange={handleChangeTurma}
                                                      label="Turma"
                                                    >
                                                      {turmasEscola.map((item, key) => (
                                                        <MenuItem key={key} value={item.id}>{item.turma_nome}</MenuItem>
                                                      ))}
                                                    </Select>
                                                  </FormControl>
                                                </Grid>
                                              )}
                                              {turma && (
                                                <Grid item xs={12} md={4}>
                                                  <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="select-polo">Ano</InputLabel>
                                                    <Select
                                                      disabled={disabled}
                                                      labelId="select-anoLetivo"
                                                      id="select-anoLetivo"
                                                      value={anoLetivo}
                                                      onChange={handleChangeAnoLetivo}
                                                      label="Ano"
                                                    >
                                                      <MenuItem value="">
                                                        <em>Nenhum</em>
                                                      </MenuItem>
                                                      {anoLetivos.map((item, key) => (
                                                        <MenuItem key={key} value={item.id}>{item.nome_ano}</MenuItem>
                                                      ))}
                                                    </Select>
                                                  </FormControl>
                                                </Grid>
                                              )}
                                              {anoLetivo && (
                                                <Grid item xs={12} md={4}>
                                                  <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="select-polo">Ano Letivo</InputLabel>
                                                    <Select
                                                      disabled={disabled}
                                                      labelId="select-ano"
                                                      id="select-ano"
                                                      value={ano}
                                                      onChange={handleChangeAno}
                                                      label="Ano Letivo"
                                                    >
                                                      <MenuItem value={ano}>{ano}</MenuItem>
                                                    </Select>
                                                  </FormControl>
                                                </Grid>
                                              )}
                                              
                                              {/* <Grid item xs={12} md={4}>
                                                <FormControl variant="outlined" fullWidth>
                                                  <TextField 
                                                    disabled={disabled}
                                                    id="outlined-turma" 
                                                    label="Turma" 
                                                    variant="outlined" 
                                                    value={turma}
                                                    onChange={handleChangeTurma}
                                                  />
                                                </FormControl>
                                              </Grid> */}
                                              {exibirComponente && (
                                                <Grid item xs={12} md={12}>
                                                  <span>Componente curricular</span>
                                                  <FormGroup row>
                                                    {componenteCurricular.map((item, key) => (
                                                      <FormControlLabel
                                                        key={key}
                                                        control={
                                                          <Checkbox
                                                            // checked={formacoesCadastradas.find(isCherries)}
                                                            checked={verificar(item.id)}
                                                            onChange={handleChangeComponenteCurricular}
                                                            name={item.id}
                                                            color="primary"
                                                          />
                                                        }
                                                        label={item.componente_curricular_nome}
                                                      />
                                                    ))}
                                                  </FormGroup>
                                                </Grid>
                                              )}
                                              <Grid item xs={12} md={12}>
                                                {tipo === 'new' && (
                                                  <Button
                                                    disabled={!validForm}
                                                    style={{maxWidth: 150, height: 50, marginRight: 10}}
                                                    type="submit" variant="outlined" color="primary"
                                                  >
                                                    {txtBtn}
                                                  </Button>
                                                )}

                                                  {/* <Button
                                                    style={{maxWidth: 150, height: 50, marginRight: 10}}
                                                    variant="outlined" color="primary"
                                                    onClick={() => {gerarDiario(idEditar)}}
                                                  >
                                                    Gerar Diário
                                                  </Button> */}
                                                  
                                                  {tipo === 'edit' &&(
                                                    <Button
                                                      disabled={!validForm}
                                                      style={{maxWidth: 150, height: 50}}
                                                      variant="outlined" color="secondary"
                                                      onClick={deletar}
                                                    >
                                                      Deletar
                                                    </Button>
                                                  )}
                                              </Grid>
                                          </Grid>

                                        </form>
                                        <ToastContainer
                                          position="top-right"
                                          autoClose={5000}
                                          hideProgressBar={false}
                                          newestOnTop={false}
                                          closeOnClick
                                          rtl={false}
                                          pauseOnFocusLoss
                                          draggable
                                          pauseOnHover
                                        />
                                    </div>
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
            {loading && (
                <div className="loading">
                    <FadeLoader color={'rgb(63, 1, 121)'} loading={loading} size={150} />
                </div>
            )}
        </div>
    );
}
