import React, { useState, useEffect } from 'react';
/* eslint-disable */
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import '../../../../styles/global.css';
import Settings from '@material-ui/icons/Settings';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import Add from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../../services/api';
import {format, subDays, addDays, isAfter, parseISO} from 'date-fns';
import Tooltip from '@material-ui/core/Tooltip';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import CloseBtn from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    container: {
        overflow: 'none'
    }
});

export default function Index(props) {
    console.log('Propos', props);
    const classes = useStyles();
    let history = useHistory();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [openModal, setOpenModal] = useState(false);
    const [idAluno, setIdAluno] = useState('');
    const [nomeAluno, setNomeAluno] = useState('');
    const [diagnostico, setDiagnostico] = useState('');
    const [existeDisgnostico, setExisteDiagnostico] = useState(false);
    const [idEditar, setIdEditar] = useState('');
    const [diagnosticosCadastrados, setDiagnosticosCadastrados] = useState([]);

    const columns = props.columns

    const rows = props.rows

    console.log('rows ', rows)

    const configr = props.config;

    const iddodiario = props.iddodiario;

    const nivelAcesso = props.nivelAcesso;

    let myArray = [];


    useEffect(() => {
      buscarDiagnosticos(iddodiario)
      
    }, []);

    // function editar(id, name, email){
    //   // history.push('/fatura-create');
    //   history.push({
    //       // pathname: '/cliente-edit',
    //       pathname: `${props.tipoUsuario}-create`,
    //       // search: '?query=abc',
    //       state: { 
    //         type: 'edit', 
    //         id: id, 
    //         nome: name, 
    //         email: email,
    //         usuario: props.tipoUsuario,
    //       }
    //   });
    // }

    const handleOpenModal = (idaluno, nome) => {
      editar(idaluno)
      setOpenModal(true);
      setIdAluno(idaluno)
      setNomeAluno(nome)
    };
  
    const handleCloseModal = () => {
      setOpenModal(false);
    };
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    function handleChangeDiagnostico(event){
      setDiagnostico(event.target.value);
    }

    function diagnosticoExisteuser(idaluno){
      console.log("diagnosticosCadastrados.includes(idaluno)", diagnosticosCadastrados.includes(idaluno))
      return diagnosticosCadastrados.includes(idaluno)
    }


    async function buscarDiagnosticos(id){
      
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };

      try {
        const {data: diag} = await api.get(`/diagnosticos/${id}`, config);
        console.log("buscar diagnóstico", diag)
        for (let i = 0; i < diag.length; i++) {
          const element = diag[i];
          const resultEle = element.user_id;
          // console.log("element ", resultEle)
          myArray.push(resultEle)
          setDiagnosticosCadastrados(myArray)
        }
 
      } catch (error) {
        console.log("Erro buscar diagnóstico", error, error.response);
        toast.error('🦄 Erro buscar diagnóstico, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setloading(false);
      }
    }
  

    async function editar(idaluno){
      setExisteDiagnostico(false)
      setIdEditar('')
      setDiagnostico('')
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };

      try {
        const {data: edit} = await api.get(`/diagnostico/${idaluno}/${iddodiario}`, config);
        console.log("editar diagnóstico", edit)
        if(edit.length != 0){
          setExisteDiagnostico(true)
          setIdEditar(edit[0].id)
          setDiagnostico(edit[0].diagnostico)
        }

      } catch (error) {
        console.log("Erro buscar diagnóstico", error, error.response);
        toast.error('🦄 Erro buscar diagnóstico, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setloading(false);
      }
    }
  

    async function salvar(id, ideditar, existe) {
      //setloading(true);
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
  
      let form_data = new FormData();

      form_data.append("user_id", id);
      form_data.append("diario_id", iddodiario);
      form_data.append("diagnostico", diagnostico);

      if(!existe){
        await api
            .post(`/diagnostico`, form_data, {
              headers: {
                "content-type": "application/json",
                Authorization: "bearer " + token,
                Accept: "application/json"
              }
            })
            .then(res => {
                console.log("Adicionando Diagnóstico", res.data)
                handleCloseModal()
                toast.success('🦄 Diagnótico cadastrado com sucesso!', {
                  position: "bottom-left",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
            })
            .catch(error => {
              console.log("error: ", error, error.response)
              console.log("response: ", error.response.data.message)
              
              toast.error(`🦄 Erro ao cadatrar Diagnótico, ${error.response.data.message}!`, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              // setloading(false);
              // throw error;
            });
      }else if(existe){
        await api
          .post(`/diagnostico/${ideditar}`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Diagnótico editado", res.data)
              editar(res.data.user_id)
              // setloading(false);
              toast.success('🦄 Diagnótico editado com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              editar(res.data.id)
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            toast.error('🦄 Erro ao editar diagnótico, tente novamente ou fale com o suporte!', {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // setloading(false);
          });
      }

    }

    async function deletar(id) {
      
      // setloading(true)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: prod} = await api.delete(`/usuario/${id}`, config);
        console.log("prod", prod)
        // limpaCampos();
        // setloading(false);
        toast.success('🦄 Deletado com sucesso!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.deletando()
      } catch (error) {
        console.log("Erro ao deletar", error, error.response);
        // setloading(false);
        toast.error('🦄 Erro ao tentar deletar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    function moedaBR(amount, decimalCount = 2, decimal = ",", thousands = "."){
      try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  
        const negativeSign = amount < 0 ? "-" : "";
  
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
  
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
      } catch (e) {
        console.log(e)
      }
    }

    return (
        <Paper className={'paper'}>
            {configr == 'diagnostico_inicial' && (
              <div style={{display: 'flex', justifyContent: 'flex-end', padding: 10}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5}}>
                  <InsertCommentIcon style={{ color:'green'}} />
                  <span style={{marginLeft: 5}}>Já foi cadastrado</span>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5}}>
                  <InsertCommentIcon style={{ color:'red'}} />
                  <span style={{marginLeft: 5}}>Falta o cadastro</span>
                </div>
              </div>
            )}
            <TableContainer style={{border: '1px solid #212121'}} className={classes.container}>
                <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, key) => (
                                <TableCell
                                    key={column.id}
                                    style={{ 
                                      width: key === 0 ? '80%' : '10%', 
                                      textAlign: key != 0 ? 'center' : 'left',
                                      background: '#e8e8e8',
                                      borderRight: key <= 2 ? '1px solid #212121' : ''
                                    }}
                                    align={key != 0 ? 'right' : 'left'}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow
                                        className="tableRow"
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id}>
                                          <TableCell style={{borderRight: '1px solid #212121'}} component="th" scope="row">
                                              {row.nome}
                                          </TableCell>
                                          <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121'}}>{row.sexo}</TableCell>
                                          <TableCell align="right" style={{textAlign: 'center', borderRight: '1px solid #212121'}}>{ format(new Date(row.nascimento), 'dd/MM/yyyy')}</TableCell>
                                          {configr == 'diagnostico_inicial' && (
                                            <TableCell align="right" style={{textAlign: 'center'}}>
                                              <Tooltip title={diagnosticoExisteuser(row.idAluno) ? "Edite o Diagnóstico cadastrado" : "Adicionar Diagnóstico inicial do aluno" } aria-label="add">
                                                <IconButton onClick={() => { handleOpenModal(row.idAluno, row.nome) }} color="inherit">
                                                    <InsertCommentIcon style={{ color: diagnosticoExisteuser(row.idAluno) ? 'green' : 'red' }} />
                                                </IconButton>
                                              </Tooltip>
                                            </TableCell>
                                          )}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              className="modal"
            >
              <div className="modalContent">
                <div className="modalContentHeader">
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    Diagnóstico inicial
                    <strong>{nomeAluno}</strong>
                  </div>
                  <Tooltip title="Sair do cadastro" aria-label="add">
                    <IconButton onClick={() => { handleCloseModal() }} color="inherit">
                        <CloseBtn style={{ color: 'red' }} />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className="modalContentCenter">
                  <Grid container spacing={3}>
                      <Grid item xs={12} md={12} lg={12}>
                        <TextField
                          fullWidth
                          id="outlined-multiline-static"
                          label="Aspectos observados"
                          multiline
                          rows={20}
                          placeholder={`Digite aqui o seu diagnóstico inicial do aluno(a) ${nomeAluno}`}
                          variant="outlined"
                          value={diagnostico}
                          onChange={handleChangeDiagnostico}
                        />
                      </Grid>
                      {nivelAcesso === 'professor' && (
                        <Grid item xs={12} md={12} lg={12}>
                          <Tooltip title="Adicionar Diagnóstico inicial do aluno" aria-label="add">
                            <Button onClick={() => { salvar(idAluno, idEditar, existeDisgnostico)}} variant="contained" color="primary">
                              {existeDisgnostico ? 'Editar' : 'Salvar'}
                            </Button>
                          </Tooltip>
                        </Grid>
                      )}
                  </Grid>
                </div>
              </div>
            </Modal>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Por página'}
            />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
        </Paper>
    );
}
