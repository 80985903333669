import React, { useState, useEffect } from 'react';
/* eslint-disable */
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logoEz from '../../assets/image/logo.jpg';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import FormControl from '@material-ui/core/FormControl';
import InputMask from 'react-input-mask';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://webativa.com.br">
                WebAtiva
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        float: 'right'
    }
}));

export default function SignUp() {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    //Seus Dados
    const [nome, setNome] = useState('');
    const [cpf, setCpf] = useState('');
    const [email, setEmail] = useState('');
    const [contato, setContato] = useState('');
    const [whatsApp, setWhatsApp] = useState('');
    //endereços
    const [cep, setCep] = useState('');
    const [pais, setPais] = useState('Brasil');
    const [estado, setEstado] = useState('');
    const [cidade, setCidade] = useState('');
    const [rua, setRua] = useState('');
    const [bairro, setBairro] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [checked, setChecked] = useState(false);
    //finalizar
    const [senha, setSenha] = useState('');
    const [mask, setMask] = useState("");

    useEffect(() => { 
      //
      getPassword()
    },[]);

    function getPassword() {
      var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJLMNOPQRSTUVWXYZ!@#$%^&*()+?><:{}[]";
      var passwordLength = 16;
      var password = "";

      for (var i = 0; i < passwordLength; i++) {
        var randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber + 1);
      }
      setSenha(password)
    }
      

    //seus dados
    function handleChangeNomeCompleto(event){
      setNome(event.target.value);
    }
    function handleChangeCpf(event){
      setCpf(event.target.value);
    }
    function handleChangeEmail(event){
      setEmail(event.target.value);
    }
    function handleChangeContato(event){
      setContato(event.target.value);
    }
    function handleChangeWhatsApp(event){
      setWhatsApp(event.target.value);
    }
    // Endereços
    function handleChangeCep(event){
      setCep(event.target.value);
    }
    function handleChangePais(event){
      setPais(event.target.value);
    }
    function handleChangeEstado(event){
      setEstado(event.target.value);
    }
    function handleChangeCidade(event){
      setCidade(event.target.value);
    }
    function handleChangeRua(event){
      setRua(event.target.value);
    }
    function handleChangeBairro(event){
      setBairro(event.target.value);
    }
    function handleChangeNumero(event){
      setNumero(event.target.value);
    }
    //Finaliza
    function handleChangeSenha(event){
      setSenha(event.target.value);
    }

    const handleChange = (event) => {
      setChecked(event.target.checked);
    };

    async function salvar(event) {
      console.log("Fazendo cadastro")
      // event.preventDefault();
      setLoading(true)
  
      let form_data = new FormData();
  
      form_data.append("name", nome);
      form_data.append("email", email);
      form_data.append("password", senha);
      form_data.append("tipo_conta", 'usuario');
      form_data.append("cpf", cpf);
      form_data.append("cep", cep);
      form_data.append("pais", pais);
      form_data.append("estado", estado);
      form_data.append("cidade", cidade);
      form_data.append("rua", rua);
      form_data.append("numero", numero);
      form_data.append("bairro", bairro);
      form_data.append("telefone", contato);
      form_data.append("celular_whatsapp", whatsApp);
  
      await api
          .post(`/perfil`, form_data, {
            headers: {
              "content-type": "application/json",
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Adicionando usuarios", res.data)
              limpaCampos();
              setLoading(false);
              toast.success('🦄 Cadastro feito com sucesso, vá para o login.!', {
                position: "bottom-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            console.log("response: ", error.response.data.message)
            toast.error(`Error, ${error.response.data.message}`, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

          });
    }

    function limpaCampos(){
      setNome('')
      setCpf('')
      setEmail('')
      setContato('')
      setWhatsApp('')
      setCep('')
      setCidade('')
      setRua('')
      setBairro('')
      setNumero('')
    }

    //validações
    const validation = [];
    validation.push('...');
    validation.push(nome);
    validation.push(cpf);
    validation.push(email);
    validation.push(contato);
    validation.push(whatsApp);
    validation.push(cep);
    validation.push(pais);
    validation.push(estado);
    validation.push(cidade);
    validation.push(rua);
    validation.push(bairro);
    validation.push(numero);
    validation.push(checked);


    const validForm = validation.reduce((all, v) => all && v);

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
                <img style={{ maxWidth: 150 }} src={logoEz} alt="" />
                <Typography component="h3" variant="h6">
                    Cadastre-se
                </Typography>
                <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8}>
                          <TextField
                            required
                            variant="outlined"
                            id="nome"
                            name="nome"
                            label="Nome completo"
                            fullWidth
                            autoComplete="name"
                            onChange={handleChangeNomeCompleto}
                            value={nome}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth className="formControl" variant="outlined">
                            <CpfCnpj
                              className="customizedInput"
                              placeholder="Digite um CPF ou CNPJ"
                              type="tel"
                              value={cpf}
                              onChange={(event, type) => {
                                setCpf(event.target.value);
                                setMask(type === "CPF");
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            variant="outlined"
                            id="email"
                            name="email"
                            label="Email"
                            fullWidth
                            autoComplete="email"
                            onChange={handleChangeEmail}
                            type="email"
                            value={email}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <InputMask 
                            mask="99 99999 9999" 
                            maskChar={null} 
                            onChange={handleChangeContato}
                            value={contato}
                            className="customizedInput"
                            placeholder="Celular"
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <InputMask 
                            mask="99 99999 9999" 
                            maskChar={null} 
                            value={whatsApp}
                            onChange={handleChangeWhatsApp}
                            className="customizedInput"
                            placeholder="Whatsapp"
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            required
                            variant="outlined"
                            id="cep"
                            name="cep"
                            label="CEP"
                            fullWidth
                            autoComplete="postal-code"
                            onChange={handleChangeCep}
                            value={cep}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            required
                            variant="outlined"
                            id="pais"
                            name="pais"
                            label="País"
                            fullWidth
                            autoComplete="country-name"
                            onChange={handleChangePais}
                            value={pais}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <FormControl fullWidth className="formControl" variant="outlined">
                            <InputLabel id="select-estado">Estado</InputLabel>
                            <Select
                              labelId="select-estado"
                              id="select-estado"
                              value={estado}
                              onChange={handleChangeEstado}
                              label="Estado"
                            >
                              <MenuItem value="">
                                <em>Nenhum</em>
                              </MenuItem>
                              <MenuItem value="AC">Acre</MenuItem>
                              <MenuItem value="AL">Alagoas</MenuItem>
                              <MenuItem value="AP">Amapá</MenuItem>
                              <MenuItem value="AM">Amapolos</MenuItem>
                              <MenuItem value="BA">Bahia</MenuItem>
                              <MenuItem value="CE">Ceará</MenuItem>
                              <MenuItem value="DF">Distrito Federal</MenuItem>
                              <MenuItem value="ES">Espírito Santo</MenuItem>
                              <MenuItem value="GO">Goiás</MenuItem>
                              <MenuItem value="MA">Maranhão</MenuItem>
                              <MenuItem value="MT">Mato Grosso</MenuItem>
                              <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
                              <MenuItem value="MG">Minas Gerais</MenuItem>
                              <MenuItem value="PA">Pará</MenuItem>
                              <MenuItem value="PB">Paraíba</MenuItem>
                              <MenuItem value="PR">Paraná</MenuItem>
                              <MenuItem value="PE">Pernambuco</MenuItem>
                              <MenuItem value="PI">Piauí</MenuItem>
                              <MenuItem value="RJ">Rio de Janeiro</MenuItem>
                              <MenuItem value="RN">Rio Grande do Norte</MenuItem>
                              <MenuItem value="RS">Rio Grande do Sul</MenuItem>
                              <MenuItem value="RO">Rondônia</MenuItem>
                              <MenuItem value="RR">Roraima</MenuItem>
                              <MenuItem value="SC">Santa Catarina</MenuItem>
                              <MenuItem value="SP">São Paulo</MenuItem>
                              <MenuItem value="SE">Sergipe</MenuItem>
                              <MenuItem value="TO">Tocantins</MenuItem>
                            </Select>
                          </FormControl>
                          
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            required
                            variant="outlined"
                            id="cidade"
                            name="cidade"
                            label="Cidade"
                            fullWidth
                            // autocomplete="address-level2"
                            onChange={handleChangeCidade}
                            value={cidade}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            variant="outlined"
                            id="rua"
                            name="rua"
                            label="Rua"
                            fullWidth
                            // autocomplete="street-address"
                            onChange={handleChangeRua}
                            value={rua}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            required  
                            variant="outlined"
                            id="bairro"
                            name="bairro"
                            label="Bairro"
                            fullWidth
                            // autoComplete="address-line2"
                            onChange={handleChangeBairro}
                            value={bairro}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            required
                            variant="outlined"
                            id="numero"
                            name="numero"
                            label="Número"
                            fullWidth
                            // autoComplete="cc-number"
                            onChange={handleChangeNumero}
                            value={numero}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            required
                            variant="outlined"
                            id="senha"
                            name="senha"
                            label="Defina uma senha"
                            fullWidth
                            autoComplete="senha"
                            onChange={handleChangeSenha}
                            value={senha}
                          />
                        </Grid>
                        <Grid item xs={12}>
                            {/* <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" />}
                                label="EU ACEITO OS"
                            /> */}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checked}
                                  onChange={handleChange}
                                  name="checkedB"
                                  color="primary"
                                />
                              }
                              label="EU ACEITO OS"
                            />
                            <Link href="#" style={{marginTop:10}} variant="body1">
                                TERMOS E CONDIÇÕES
                            </Link>
                        </Grid>
                    </Grid>
                    <Button
                        disabled={!validForm}
                        // fullWidth
                        onClick={() =>{
                          salvar()
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.submit}>
                        Cadastrar
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link href="/login" variant="body2">
                                Fazer login
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
        </Container>
    );
}
