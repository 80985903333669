import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import '../../styles/global.css';
/* eslint-disable */
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import EditPolo from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../services/api';
import {format, subDays, addDays, isAfter, parseISO} from 'date-fns';
import Swal from 'sweetalert2'

const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    container: {
        overflow: 'none'
    }
});

export default function Index(props) {
    console.log('Propos escola', props);
    const classes = useStyles();
    let history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const columns = props.columns

    const rows = props.rows

    function editar(id){
      // history.push('/fatura-create');
      history.push({
          pathname: '/escola-edit',
          // search: '?query=abc',
          state: { type: 'edit', id: id }
      });
    }
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function confirmarDelete(id){
        Swal.fire({
          title: 'Tem Certeza? ',
          text: "Esta é uma ação irreversível",
          icon: 'error',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, quero deletar'
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire(
              'Deletado!',
              'Este arquivo foi removido com sucesso',
              'success'
            )
            deletar(id)
          }
        })
    }
      
    async function deletar(id) {
      // setloading(true)
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: prod} = await api.delete(`/escola/${id}`, config);
        console.log("prod", prod)
        // limpaCampos();
        // setloading(false);
        toast.success('🦄 Escola deletada com sucesso!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.deletando()
      } catch (error) {
        console.log("Erro ao deletar", error, error.response);
        // setloading(false);
        toast.error('🦄 Erro ao deletar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    return (
        <Paper className={'paper'}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, key) => (
                                <TableCell
                                    key={column.id}
                                    align={key >= 1 ? 'right' : 'left'}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow
                                        className="tableRow"
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id}>
                                          <TableCell component="th" scope="row">
                                              {row.name}
                                              {row.gestor.length > 0 && (
                                                  <div style={{marginTop: 10}}>
                                                      {row.gestor.map((item, key) => (
                                                        <div key={key}>
                                                            <span>Gestor: <strong>{item.name}</strong></span>
                                                        </div>
                                                      ))}
                                                      {row.coordenador.map((item, key) => (
                                                        <div key={key}>
                                                            <span>Coordenador: <strong>{item.name}</strong></span>
                                                        </div>
                                                      ))}
                                                  </div>
                                              )}
                                          </TableCell>
                                          <TableCell align="right">{row.endereco}</TableCell>
                                          <TableCell align="right">{row.escola_polo_sede}</TableCell>
                                          <TableCell align="right">
                                            <IconButton onClick={() => {editar(row.id)}} color="inherit">
                                                <EditPolo style={{ color: 'green' }} />
                                            </IconButton>
                                            <IconButton onClick={() => {confirmarDelete(row.id)}} color="inherit">
                                                <DeleteSweepIcon style={{ color: '#ff0000' }} />
                                            </IconButton>
                                          </TableCell>
                                        
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Por página'}
            />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
        </Paper>
    );
}
