import React, { useState, useEffect } from 'react';
import '../../../../styles/global.css';
/* eslint-disable */
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../../Components/Header';
import Footer from '../../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import api from '../../../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableAlunos from '../Tabelas/tableAlunosRelatorioFinal';

export default function Index() {
    let history = useHistory();
    const location = useLocation();
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);
    // const [pesquisar, setPesquisar] = useState('');


    const iddodiario = location.state.iddiario;
    const nivelAcesso = location.state.nivelAcesso;

    useEffect(() => {
      console.log("location.state.", location.state)
      console.log("iddodiario", iddodiario)
        // Atualiza o titulo do documento usando a API do browser
        alunosDaTurma()
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    
    async function alunosDaTurma(){
      var token = localStorage.getItem('@token_diario_web');
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data : result} = await api.get(`diario-alunos/${location.state.idEscola}/${location.state.anoLetivo}/${location.state.turno}`, config);
        console.log("Result diario alunos da turma", result)
        setData(result)
        toast.success('🦄 Carregando diários!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro ao carregar escolas", error, error.response);
        toast.error('🦄 Error ao carregar escolas!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    function deletando(){
      diarios()
    }

    function handleChangePesquisar(event){
      if(event.target.value){
        pesquisarDiario(event.target.value)
      }else{
        diarios()
      }
      // setPesquisar(event.target.value)
    }

    async function pesquisarDiario(termo) {
      //setLoading(true);
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
  
  
      try {
        const {data : result} = await api.get(`pesquisar-diario/${encodeURI(termo)}`, config);
        console.log("Result pesquisar", result)
        setData(result)
      } catch (error) {
        console.log("Erro ao buscar ", error, error.response);
      }
    }
  

    function createData(id, idAluno, nome, sexo, nascimento) {
      return {id, idAluno,nome, sexo, nascimento};
    }
  
    var rows = [];
    if(data){
      rows = data.map((row) => {
        return createData(
          row.id, 
          row.user.id, 
          row.user.name, 
          row.user.sexo, 
          row.user.data_nascimento, 
        )
      })
    }
  
    const columns = [
      { id: 'nome', numeric: false, disablePadding: true, label: 'Nome do Aluno' },
      { id: 'sexo', numeric: false, disablePadding: true, label: 'Sexo' },
      { id: 'data_nascimento', numeric: false, disablePadding: true, label: 'Data Nascimento' },
      { id: 'acao', numeric: false, disablePadding: true, label: '' },
    ];
  
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                              <Breadcrumbs aria-label="breadcrumb">
                                <Link className="linkBreadcrumbs" color="inherit" href="/">
                                    Dashboard
                                </Link>
                                <Link className="linkBreadcrumbs" color="inherit" href="/diarios-classe/">
                                    Diários
                                </Link>
                                <Link 
                                  className="linkBreadcrumbs" 
                                  color="inherit" 
                                  style={{ cursor: 'pointer'}}
                                  onClick={() => {
                                    history.goBack()
                                  }}
                                >
                                  Configurações do Diário
                                </Link>
                                <Typography variant="body2" color="textPrimary">
                                  Relatório final individual
                                </Typography>
                            </Breadcrumbs>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>Relatório final individual</h2>
                                {/* <Button
                                    className="button-add"
                                    onClick={create}
                                    variant="contained"
                                    color="primary">
                                    Novo
                                </Button> */}
                            </div>
                            <div className="headerPage">
                                <Button
                                    style={{marginTop: 10}}
                                    className="button-add"
                                    onClick={() => {
                                      history.goBack()
                                    }}
                                    variant="contained"
                                    color="primary">
                                    Retornar ao diário
                                </Button>
                            </div>
                        </Grid>
                        {/* <Grid item xs={12} md={12} lg={12}>
                            <Paper className={'paperSearch'}>
                                <TextField
                                    className="textField"
                                    id="outlined-secondary"
                                    label="Digite o nome do professor(a)"
                                    variant="outlined"
                                    // color="secondary"
                                    // value={pesquisar}
                                    onChange={handleChangePesquisar}
                                />
                            </Paper>
                        </Grid> */}
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                  <div className="diarioHeader">
                                    <div className="ih">
                                      <span>Ano letivo:</span>
                                      <span><strong>{location.state.periodo.ano_periodo}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Escola:</span>
                                      <span><strong>{location.state.adicionais.escola}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Professor:</span>
                                      <span><strong>{location.state.adicionais.professor}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Ano:</span>
                                      <span><strong>{location.state.adicionais.anoLetivo}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Turno:</span>
                                      <span><strong>{location.state.turno}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Turma:</span>
                                      <span><strong>{location.state.turma}</strong></span>
                                    </div>
                                    <div className="ih">
                                      <span>Período:</span>
                                      <span><strong>{location.state.periodo.nome_periodo}</strong></span>
                                    </div>
                                  </div>
                                  {rows.length !== 0 && (
                                    <div className="paperContant">
                                      <div style={{
                                        background: '#f7f7f7',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: 10
                                      }}>
                                        <span>Número de alunos {' - '}</span>
                                        <span style={{fontSize: 21, fontWeight:'bold'}}>{'  '}{rows.length}</span>
                                      </div>
                                      <TableAlunos 
                                        columns={columns} 
                                        rows={rows} 
                                        eletando={deletando}
                                        config='relatorio_final'
                                        iddodiario={iddodiario}
                                        nivelAcesso={nivelAcesso}
                                      />
                                    </div>
                                  )}
                                  {rows.length === 0 && (
                                    <div className="paperContantDois">
                                        <div>Nenhum registro encontrado</div>
                                    </div>
                                  )}
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
        </div>
    );
}
