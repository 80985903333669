import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import Button from '@material-ui/core/Button';
/* eslint-disable */
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableUsuarios from '../Components/usuarios';
import { useLocation } from 'react-router-dom';
import {veryAuth} from '../../../auth';
import SelectEscolas from '../../../Components/Select/escolas';

export default function Index() {
    let history = useHistory();
    const location = useLocation();
    console.log("location ", location.state)
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);
    const [title, setTitle] = useState('Alunos');
    const [liberaAcesso, setLiberaAcesso] = useState(false);
    const tipoUsuario = 'aluno'
    const [roles, setRoles] = useState('');
    const [permissaoTelas, setPermissaoTelas] = useState('');
    const [idEscola, setIdEscola] = useState('');

    useEffect(() => {
        // Atualiza o titulo do documento usando a API do browser
        verificarPermissao()
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function verificarPermissao(){
      if(!location.state){
        history.push({
          pathname: `/`,
        });
        return
      } 
      veryAuth()
      .then((result) => {
        console.log('result verificarPermissao', result);
        // setPermissaoTelas(location.state.telasuser[0])
        if(result.data.role === 'admin' || result.data.role === 'gestor'){
          console.log('result verificarPermissao permitido',);
          setLiberaAcesso(true)
        }else{
          history.push({
            pathname: `/`,
          });
          return
        }
        setRoles(result.data.role)
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.response, error.message);
      });
    }

    function idescola(id){
      setIdEscola(id)
      usuario(id)
    }

    
    async function usuario(id){
      var token = localStorage.getItem('@token_diario_web');
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        ///usuario-por-role-escola/:role/:idescola
        const {data : result} = await api.get(`alunos-escola/${id}`, config);
        console.log("Result usuario por id da escola", result)
        setData(result)
        toast.success('🦄 Carregando alunos' , {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro ao carregar escolas", error, error.response);
        toast.error('🦄 Error ao carregar escolas!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    // function deletando(){
    //   usuario()
    // }

    function handleChangePesquisar(event){
      if(event.target.value){
        pesquisarAluno(event.target.value)
      }else{
        usuario(idEscola)
      }
      // setPesquisar(event.target.value)
    }

    async function pesquisarAluno(termo) {
      //setLoading(true);
      var token = localStorage.getItem('@token_diario_web');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
  
  
      try {
        const {data : result} = await api.get(`pesquisar-usuario-idescola/${encodeURI(termo)}/${encodeURI('Aluno')}/${idEscola}`, config);
        // console.log("Result pesquisar de alunos", result.length)
        setData(result)
      } catch (error) {
        console.log("Erro ao buscar pesquisar alunos", error, error.response);
      }
    }

    function create() {
      history.push({
          pathname: `aluno-gestor-create`,
          // search: '?query=abc',
          state: { 
            // telasuser: location.state.telasuser[0],
            type: 'new',
            usuario: 'aluno', 
            // type: 'new',
            // usuario: location.state.usuario, 
            // usuarioLogado: roles
          }
      });
    }
  

    function createData(id, name, cadastro, email, telefone) {
      return {id, name, cadastro, email, telefone};
    }
  
    var rows = [];

    if(data){
      rows = data.map((row) => {
        console.log("Row", row)
        return createData(row.user.id, row.user.name, row.user.created_at, row.user.email, row.user.telefone)
      })
    }
  
    const columns = [
      { id: 'name', numeric: false, disablePadding: true, label: 'Nome' },
      { id: 'cadastro', numeric: true, disablePadding: false, label: 'Cadastro' },
      { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
      { id: 'telefone', numeric: true, disablePadding: false, label: 'Telefone' },
      { id: 'edit', numeric: true, disablePadding: false, label: '' },
    ];
  
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link className="linkBreadcrumbs" color="inherit" href="/">
                                    Dashboard
                                </Link>
                                {/* <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/getting-started/installation/"
                                    onClick={handleClick}>
                                    Core
                                </Link> */}
                                <Typography variant="body2" color="textPrimary">
                                    {title}
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>{title}</h2>
                                <Button
                                    className="button-add"
                                    onClick={create}
                                    variant="contained"
                                    color="primary">
                                    Cadastrar aluno
                                  </Button>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={'paperSearch'}>
                                <SelectEscolas idescola={idescola} />
                            </Paper>
                        </Grid>
                        {data.length > 0 && (
                          <Grid item xs={12} md={12} lg={12}>
                              <Paper className={'paperSearch'}>
                                  <TextField
                                      className="textField"
                                      id="outlined-secondary"
                                      label="Digite o nome do aluno"
                                      variant="outlined"
                                      // color="secondary"
                                      // value={pesquisar}
                                      onChange={handleChangePesquisar}
                                  />
                              </Paper>
                          </Grid>
                        )}
                        {liberaAcesso && (
                          <Grid item xs={12} md={12} lg={12}>
                              {skeleton && <Skeleton variant="rect" className="paper" />}
                              {!skeleton && (
                                  <Paper className={'paper'}>
                                    {rows.length !== 0 && (
                                      <div className="paperContant">
                                        <TableUsuarios 
                                          columns={columns} 
                                          rows={rows} 
                                          // deletando={deletando}
                                          tipoUsuario={tipoUsuario}
                                          // telasuser={permissaoTelas}
                                        />
                                      </div>
                                    )}
                                    {rows.length === 0 && (
                                      <div className="paperContantDois">
                                          <div>Nenhum registro encontrado</div>
                                      </div>
                                    )}
                                  </Paper>
                              )}
                          </Grid>
                        )}
                        {!liberaAcesso && (
                          <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                              <Paper className={'paper'}>
                                <div className="paperContantDois">
                                    <div>Acesso Negado</div>
                                </div>
                              </Paper>
                            )}
                          </Grid>
                        )}
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
        </div>
    );
}
